<template>
  <v-app id="app_user_role_list">

    <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">USER ROLES</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">User roles & their permissions registered in the
            system.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="createUser()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;CREATE USER
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large" color="#BB0000">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large" color="#2E7D32">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="userCatagory" :items="userCatagaryItems" clearable item-title="name" item-value="name"
                  label="User Category"
                  multiple placeholder="User Type" variant="outlined" density="comfortable"
                  class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="userRoleTypeFilter" :items="userTypes" clearable item-title="name" item-value="name"
                  label="User Role Type"
                  multiple placeholder="User Type" variant="outlined" density="comfortable"
                  class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" clearable hide-details label="Search"
                            placeholder="Search" variant="outlined" density="comfortable"
                            class="custom_input_field_color"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table :headers="headers" :loading="loading" :items="filteredItems" class="custom_table_normal"
                        item-value="name">
            <template v-slot:[`item.roleType`]="{ item }">
              <span class="red-text">{{ item.roleType }}</span>
            </template>

            <template v-slot:[`item.description`]="{ item }">
              <span class="description-text">{{ item.description }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ new Date(item.created_at).toISOString().split('T')[0] }}</span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_user_role_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" v-if="permissions.write || permissions.delete" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.write" @click="openEditDialog(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- Edit products dialog -->
      <v-dialog v-model="edit_user_role_dialog" id="app_user_role_list" fullscreen>
        <v-card>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="edit_user_role_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-14  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE USER ROLE</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9"></v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-0 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateUserRole">Update
                        User Role
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider class="mt-2"></v-divider>
                  </v-col>
                </v-row>

                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-alert color="#F0F0F0" prominent density="default" border="border-lg"
                                 text="These user roles help you grant permissions and assign related functions to users in the system.">
                          <template v-slot:prepend>
                            <v-icon color="orange" size="x-large">mdi-alert</v-icon>
                          </template>
                        </v-alert>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <p class="custom_page_main_sub_title">
                          <strong>
                            SELECT USER CATEGORY
                          </strong>
                        </p>
                        <v-divider class="mt-4"></v-divider>
                      </v-col>

                      <v-col cols="6">
                        <v-checkbox v-model="selectedUser" :value="'Web User'" color="#BB0000"
                                    hide-details label="WEB USER" rounded></v-checkbox>
                      </v-col>

                      <v-col cols="6">
                        <v-checkbox v-model="selectedUser" :value="'Mobile User'"
                                    color="#BB0000" hide-details label="MOBILE USER"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="roleForm.roleType" :items="userTypes" label="Role type *"
                        placeholder="Type User Role Here"
                        variant="outlined" density="comfortable" color="#BB0000"
                    ></v-text-field>

                    <v-textarea
                        v-model="roleForm.description" class="mt-2" label="Description *"
                        placeholder="Type user description here" required rows="6" variant="outlined"
                        color="#BB0000"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit products dialog -->


      <!-- Delete product confirmation dialog -->
      <v-dialog v-model="deleteUserListDialog" id="app_user_role_list" max-height="500" max-width="500" persistent>
        <v-card id="app_product_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE USER ROLE</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteUserListDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this user role ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This user role will be deleted immediately. Once deleted, it can no longer be used in any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="deleteUserListDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete product confirmation dialog -->

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: 'UserRoleList',
  data() {
    return {
      centerLoading: false,
      search: '',
      headers: [
        {text: 'USER CATEGORY', value: 'category', title: 'USER CATEGORY'},
        {text: 'Role Type', value: 'role_type', title: 'Role Type'},
        {text: 'Creation Date', value: 'created_at', title: 'Creation Date'},
        {text: 'Description', value: 'description', title: 'Description'},
        {text: 'Action', value: 'action', sortable: false, title: 'Action'}
      ],
      userCatagory: '',
      userCatagaryItems: ['Web User', 'Mobile User'],
      userTypesItem: [],
      userRoleTypeFilter: '',
      isEditMode: false,
      roleForm: {
        id: null,
        roleType: '',
        description: '',
        permissions: [],
        dashboard: []
      },
      items: [],
      selectedUser: "",
      relatedValue: null,
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,
      deleteUserListDialog: false,
      edit_user_role_dialog: false,
      roleToDelete: null,
      userRoles: [],
      userTypes: [],
      selectedItem: {
        id: null,
        user_role_id: null,
        description: '',
        dashboard: [],
      },
      loading: false,
      deleteItemId: null,
      permissionsItems: [],
      permissions: {},
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
    };
  },

  mounted() {
    const dashboardId = 4;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },

  created() {
    this.fetchUserRoles();
  },

  computed: {
    filteredItems() {
      // Ensure search query is a string and convert to lowercase
      const query = this.search ? this.search.toLowerCase() : '';

      // Handle user category and role filters
      const selectedCategories = this.userCatagory || [];
      const selectedRoles = this.userRoleTypeFilter || [];

      return this.items.filter((item) => {
        // Match the search query
        const matchesQuery = query
            ? Object.keys(item).some((key) =>
                String(item[key] || '').toLowerCase().includes(query)
            )
            : true;

        // Match the selected user category
        const matchesCategory =
            selectedCategories.length === 0 ||
            selectedCategories.includes(item.category);

        // Match the selected user role
        const matchesRole =
            selectedRoles.length === 0 ||
            selectedRoles.includes(item.role_type);

        // Return true only if all conditions are met
        return matchesQuery && matchesCategory && matchesRole;
      });
    }
  },

  methods: {
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      // Map filteredItems to prepare table data with clear headers
      const tableData = this.filteredItems.map((item) => ({
        'User Category': item.category || 'N/A', // Add default value for empty fields
        'User Role': item.role_type || 'N/A',
        'Creation Date': new Date(item.created_at).toISOString().split('T')[0], // Format the date
        'Description': item.description || 'N/A',
      }));

      // Create a worksheet from the table data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Adjust column width for better readability
      const columnWidths = [
        {wch: 20}, // Width for 'User Category'
        {wch: 20}, // Width for 'User Role'
        {wch: 15}, // Width for 'Creation Date'
        {wch: 30}, // Width for 'Description'
      ];
      worksheet['!cols'] = columnWidths;

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Role');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'user_role_analysis.xlsx');
    },

    exportPDF() {
      const doc = new jsPDF();
      // Set the title for the document with enhanced styling
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text('User Role Analysis', 14, 20);

      // Subtitle or description
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text('This report provides an overview of user roles and their details.', 14, 28);

      // Prepare the data for the table
      const tableData = this.filteredItems.map((item, index) => [
        index + 1, // Serial number
        item.category || '-', // User category
        item.role_type || '-', // User role
        new Date(item.created_at).toISOString().split('T')[0], // Creation date
        item.description || '-', // Description
      ]);

      // Define the columns for the table
      const tableColumns = [
        '#',
        'User Category',
        'User Role',
        'Creation Date',
        'Description'
      ];

      // AutoTable plugin to create a table with enhanced styling
      doc.autoTable({
        startY: 35, // Start position for the table
        head: [tableColumns],
        body: tableData,
        theme: 'grid',
        headStyles: {
          fillColor: [100, 149, 237], // Cornflower blue
          textColor: [255, 255, 255], // White text
          fontSize: 10,
          halign: 'center',
          fontStyle: 'bold',
        },
        bodyStyles: {
          fontSize: 9,
          textColor: [0, 0, 0],
          halign: 'left',
          valign: 'middle',
        },
        alternateRowStyles: {
          fillColor: [240, 248, 255], // Alice blue for alternate rows
        },
        columnStyles: {
          0: {cellWidth: 10, halign: 'center'}, // Serial number column
          1: {cellWidth: 40}, // User category column
          2: {cellWidth: 40}, // User role column
          3: {cellWidth: 30, halign: 'center'}, // Creation date column
          4: {cellWidth: 60}, // Description column
        },
        margin: {top: 35}, // Margin for the table
      });

      // Footer with generation date
      const pageHeight = doc.internal.pageSize.height; // Get page height
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, pageHeight - 10);

      // Save the PDF with a specific file name
      doc.save('user_role_analysis.pdf');
    },

    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: {read: false, write: false, delete: false},
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.role_type
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    async fetchUserRoles() {
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('/api/allCreatedUserRoles', {
          userId: userId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data.success === "Get Dashboard successfully") {
          const roles = response.data.data;
          console.log('Roles data:', roles); // Log the roles data
          this.loading = false;

          // this.items = roles.map(role => ({
          //   roleType: role.userrole ? role.userrole.role_type : 'N/A',
          //   creationDate: new Date(role.created_at).toLocaleDateString(),
          //   description: role.description || 'No description',
          //   // permissionLevel: this.formatPermissionLevel(role.dashboard)
          //   dashboard: role.dashboard || [],
          //   id: role.id,
          // }));

          this.items = roles;
          // Sort the data by created_at in descending order (newest first)
          // this.items = roles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(role => ({
          //   roleType: role.userrole ? role.userrole.role_type : 'N/A',
          //   creationDate: new Date(role.created_at).toLocaleDateString(),
          //   description: role.description || 'No description',
          //   // permissionLevel: this.formatPermissionLevel(role.dashboard)
          //   dashboard: role.dashboard || [],
          //   id: role.id,
          // }));

          this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
        this.loading = false;
        // Optionally handle errors or show user feedback
      }
    },

    formatPermissionLevel(dashboard) {
      if (dashboard.length === 0) return 'No Permissions';

      const permissions = dashboard.map(d => {
        let perms = [];
        if (d.read_premission) perms.push('Read');
        if (d.write_premission) perms.push('Write');
        if (d.delete_premission) perms.push('Delete');
        return perms.join(' / ');
      });

      return `Permissions for: ${permissions.join(', ')}`;
    },

    backPageRoute() {
      this.$router.back();
    },

    createUser() {
      this.$router.push({path: '/user-registration'});
    },

    handleSwitchChange(permission) {
      if (!permission.isOn) {
        // Reset the permissions when the switch is turned off
        permission.permissions.read = false;
        permission.permissions.write = false;
        permission.permissions.delete = false;
      } else {
        // Optionally, you can enable default permissions here if needed
        // For example, you might want to enable read by default when the main switch is turned on
        permission.permissions.read = true;
      }
    },

    handlePermissionChange(permission, permissionType) {
      // Check if 'delete' permission is being modified
      if (permissionType === 'delete') {
        if (permission.permissions.delete) {
          // Enable 'read' and 'write' if 'delete' is enabled
          permission.permissions.read = true;
          permission.permissions.write = true;
        } else {
          // Ensure 'read' and 'write' are disabled if 'delete' is turned off
          if (!permission.permissions.read && !permission.permissions.write) {
            permission.permissions.delete = false;
          }
        }
      }

      // Check if 'read' or 'write' permissions are being modified
      if (permissionType === 'read' || permissionType === 'write') {
        if (!permission.permissions.read && !permission.permissions.write) {
          // Disable 'delete' if both 'read' and 'write' are turned off
          permission.permissions.delete = false;
        }
      }
    },


    getPermissionLevel(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'All Permissions';
      } else if (hasRead && hasWrite) {
        return 'Permissions for Read and Write';
      } else if (hasRead) {
        return 'Permission for Read';
      } else if (hasWrite) {
        return 'Permission for Write';
      } else if (hasDelete) {
        return 'Permission for Delete';
      } else {
        return 'No Permissions';
      }
    },

    getPermissionColor(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'red';
      } else if (hasRead && hasWrite) {
        return 'green';
      } else if (hasRead || hasWrite || hasDelete) {
        return 'blue';
      } else {
        return 'grey';
      }
    },

    getPrimaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'All Permissions';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ')[2];
      } else {
        return permission;
      }
    },

    getSecondaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'Read, Write, Delete';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ').slice(-2).join(' ');
      } else {
        return null;
      }
    },

    confirmDelete(item) {
      this.selectedItem = item;
      this.deleteItemId = item.id;
      this.deleteUserListDialog = true;
    },

    async deleteItem() {
      this.centerLoading = true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const userRoleId = this.deleteItemId;

      // console.log('Deleting item with ID:', userDiscriptionId);
      console.log('Deleting item with token:', token);
      console.log('Deleting item with userId:', userId);

      try {
        const response = await axios.post(`/api/user/delete-role`, {
          userId,
          userRoleId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('API Response:', response.data); // Log API response

        if (response.data.success) {
          // Close the dialog and reset deletion state
          this.deleteUserListDialog = false;
          this.deleteItemId = null;
          this.centerLoading = false;
          this.showSnackbar("User role deleted successfully!", "success");

          // Refresh the data table
          await this.fetchUserRoles();
        } else {
          console.error('Failed to delete the user role:', response.data);
          this.showSnackbar("Failed to delete user role.", "error");
        }
      } catch (error) {
        console.error('Error deleting user role:', error);
        this.deleteUserListDialog = false;
        this.showSnackbar("Error deleting user role.", "error");
      }
      this.centerLoading = false;
    },

    openEditDialog(item) {
      this.isEditMode = true;
      this.selectedItem = item;

      this.edit_user_role_dialog = true;

      this.roleForm.roleType = item.role_type;
      this.roleForm.description = item.description;
      this.selectedUser = item.category;
      console.log('Editing item:', item);
      console.log('Role Form:', this.roleForm);
    },

    updateUserRole() {
      this.centerLoading = true;
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      const payload = {
        userId,
        userRoleId: this.selectedItem.id,
        userRoleType: this.roleForm.roleType,
        userRoleDescription: this.roleForm.description, // Correctly bind description
        userRoleCategory: this.selectedUser,

      };
      // Print the payload as a formatted JSON string
      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      // Convert the payload to a JSON string for sending in the request
      const jsonPayload = JSON.stringify(payload);

      // Log the payload being sent
      console.log('Payload being sent:', jsonPayload);

      axios.post('/api/user/edit-role', payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(response => {
            console.log('User role created:', response.data);
            this.fetchUserRoles();
            this.showSnackbar("User role permission Updated successfully!", "success");
            this.edit_user_role_dialog = false;
            this.centerLoading = false;
            // Handle successful creation (e.g., display a success message, reset form)
          })
          .catch(error => {
            console.error('Error creating user role:', error);
            // Handle error (e.g., display an error message)
            this.centerLoading = false;
          });
    },
  }
};
</script>


<style>
@import "../../styles/user_management/user_role_list.scss";
</style>