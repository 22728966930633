<template>
  <div id="app_dashboard_admin">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-card  class="mx-auto custom_card_box_dashboard" elevation="0"  border="sm opacity-1" rounded="lg">
              <v-card flat>
                <template v-slot:title>
                  <p class="ml-5 font-weight-bold custom_page_component_main_title">TOP SELLING PRODUCTS</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="ml-5 custom_page_main_sub_title_very_small_red">LAST 30 DAYS</p>-->
                </template>

                <template v-slot:append>
                    <v-date-input class="mt-6 custom_input_field_color" style="width: 330px!important;"
                        v-model="dates" multiple="range"
                        prepend-inner-icon="$calendar" prepend-icon=""
                        label="Date Range"  density="comfortable"  variant="outlined"
                                  :max="maxDate"
                        @click:append-inner="clearDateRange"
                    ></v-date-input>

                     <v-btn
                        class="ml-4 custom_btn_secondary"
                        icon rounded
                        variant="outlined"
                        v-if="dates.length > 0"
                        @click="resetDateRange()"
                    >
                      <v-icon>mdi mdi-refresh</v-icon>
                      <v-tooltip activator="parent" location="top">Reset</v-tooltip>
                    </v-btn>

                    <v-btn  class="custom_excel_btn ml-6 mr-4" variant="outlined" @click="exportExcel">
                      <v-icon size="large" color="#2E7D32">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
                    </v-btn>
                </template>
              </v-card>

              <v-divider></v-divider>

              <v-card-text>
                <v-data-table :headers="headers" :items="topSellingProductsList"  class="mt-8 custom_table_normal" item-value="name" items-per-page="5" :loading="tblLoading">
                
                    <template v-slot:[`item.nos`]="{ index }">
                      <div>{{ (index + 1) + (itemsPerPage * (page - 1))}}</div>
                    </template>

                    <!-- Product Name Column -->
                    <template v-slot:[`item.productName`]="{ item }">
                      <div class="products-column mb-2 mt-2">
                        <div class="d-flex align-center">
                          <img :src="item.productImage != 'Not Found' ? `${imageURL}/${item.productImage}` : `${defaultImage}`"
                              class="product-image mr-3 mb-2" max-height="50" max-width="50"/>
                          <span>{{ item.productName }}</span>
                        </div>
                      </div>
                    </template>

                    <!-- Outlet Name Column -->
                    <template v-slot:[`item.outlets`]="{ item }">
                      <div class="price-column">
                        <div v-for="(outlet, index) in item.outlets" :key="index">
                          <div><b>Name - </b>{{outlet.name }} <b> | Province - </b>{{outlet.province }}</div>
                          <v-divider v-if="(item.outlets.length) - 1 != index" class="my-1"></v-divider>
                        </div>
                      </div>
                    </template>

                    <!-- Assigned Agent Column -->
                    <template v-slot:[`item.agents`]="{ item }">
                      <div class="price-column">
                        <div v-for="(agent, index) in item.agents" :key="index">
                          <div><b>Name - </b>{{agent.name }} <b> | ID : </b>{{agent.RegNo }}</div>
                          <v-divider v-if="(item.agents.length) - 1 != index" class="my-1"></v-divider>
                        </div>
                      </div>
                    </template>
                </v-data-table>
              </v-card-text>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'TopSellingProducts',
    data() {
      return {
        maxDate: new Date().toISOString().substr(0, 10), // Current date in 'YYYY-MM-DD' format
        imageURL:"",
        defaultImage: require('@/assets/no-image.png'),
        page: 1,
        itemsPerPage: 5,
        weeklyMenu: false,
        weeklyDate: null,
        monthlyMenu: false,
        monthlyDate: null,
        tblLoading:false,
        topSellingHeaders: [
          { text: 'PRODUCT NAME', value: 'order_id', title: 'PRODUCT NAME' },
          { text: 'PRICE', value: 'sales_amount', title: 'PRICE(LKR)' },
          { text: 'QUANTITY', value: 'quantity_sold', title: 'QUANTITY' },
          { text: 'AMOUNT', value: 'paid_amount', title: 'AMOUNT(LKR)' },
          { text: 'OUTLETS NAME', value: 'store_name', title: 'OUTLETS NAME' },
          { text: 'ASSIGNED AGENT', value: 'agent', title: 'ASSIGNED NAME' },
        ],
        topSellingProductsList: [],
        headers: [
          { text: '#', value: 'nos', title: '#' },
          { text: 'PRODUCT NAME', value: 'productName', title: 'PRODUCT NAME' },
          { text: 'SOLD QUANTITY', value: 'salesQuantity', title: 'SOLD QUANTITY' },
          { text: 'TOTAL QUANTITY', value: 'totalStock', title: 'TOTAL QUANTITY' },
          { text: 'SALES RATIO', value: 'percentage', title: 'SALES RATIO' },
          { text: 'OUTLETS NAME', value: 'outlets', title: 'OUTLETS NAME' },
          { text: 'ASSIGNED AGENT', value: 'agents', title: 'ASSIGNED AGENT' },
        ],

        dates:[],
      };
    },

    created() {
      this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
      console.log("Check",this.imageURL);
      // this.getTopSellingProduct();
      this.getTopSellingProducts();
    },
    watch:{
      dates(){
        
        this.getTopSellingProducts();
      }
    },

    computed: {
    },

    methods:{

      resetDateRange(){
        this.dates = [];
        this.getTopSellingProducts();
      },

      clearDateRange() {
        this.dates = [];
        console.log("Date Range");
        this.getTopSellingProducts();
      },

      // Unwanted Function
      getTopSellingProduct(){
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`/api/sales/all/view`,
          { userId: userId,
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            this.topSellingProductsList = response.data.data;
            console.log('topsellingProduct:', this.topSellingProductsList);

          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
          });
      },

      getTopSellingProducts(){
        this.tblLoading = true;
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`/api/sales/get/topSelling`,
          { userId: userId, date: this.dates},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            this.topSellingProductsList = response.data.data;
            console.log('topsellingProduct:', this.topSellingProductsList);
            this.tblLoading = false;
          })
          .catch(error => {
            this.tblLoading = false;
            console.error('Error fetching sub categories:', error);
          });
      },
      exportExcel() {
        // Flattening the data for Excel export
        // const tableData = this.topSellingProductsList.flatMap((item, index) =>
           const tableData = this.topSellingProductsList.map((product) => ({
              'PRODUCT NAME': product.productName,
              'PRODUCT IMAGE': this.imageURL +"/"+ product.productImage,
              'SOLD QUANTITY': product.salesQuantity,
              'TOTAL QUANTITY': product.totalStock,
              'SALES RATIO': product.percentage,
              'OUTLETS': product.outlets.map(outlet => `${outlet.name} (${outlet.province || 'N/A'})`).join(', '),
              'ASSIGNED AGENT': product.agents.map(agent => `${agent.name} (${agent.RegNo || 'N/A'})`).join(', ')
            }))
        // );

        // Creating a worksheet from the flattened data
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Creating a new workbook and appending the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Top Selling Products');

        // Writing the workbook to a file
        XLSX.writeFile(workbook, 'top_selling_products_analysis.xlsx');
      },
    }
  };
</script>

<style>
  @import "../../styles/dashboard/dashboard.scss";
</style>