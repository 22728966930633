<template>
  <v-app id="app_collection_management">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">COLLECTIONS DATA</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse collection data in a variety of stores.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-expansion-panels>
          <v-expansion-panel elevation="0">
            <v-expansion-panel-title expand-icon="mdi-menu-down">
              <v-icon class="mr-3 ">mdi-filter-menu-outline</v-icon>
              <span class="text-body-1 font-weight-bold">FILTERS</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="mt-2">
<!--                <v-col cols="12" md="4">-->
<!--                  <v-date-input-->
<!--                      v-model="dates" density="comfortable"   class="custom_input_field_color" label="Date"-->
<!--                      prepend-inner-icon="$calendar" prepend-icon="" multiple="range" placeholder="Select Date Range" variant="outlined"-->
<!--                  ></v-date-input>-->
<!--                </v-col>-->

                <v-col cols="12" md="4">
                  <v-date-input
                      v-model="dates" append-inner-icon="mdi-close" prepend-inner-icon="$calendar" prepend-icon=""
                      label="Select Date Range" multiple="range" density="comfortable"   class="custom_input_field_color"
                      variant="outlined" @click:append-inner="clearDateRange"
                  ></v-date-input>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select v-model="selectedAgent" :items="agentLists" density="comfortable"
                            clearable item-title="text" item-value="value"  class="custom_input_field_color"
                            label="Agent" multiple placeholder="Select agent name" variant="outlined"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedRoute" :items="routeLists" clearable density="comfortable"
                                  item-title="text" item-value="value" label="Route Name" class="custom_input_field_color"
                                  multiple placeholder="Select route name" variant="outlined"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectDistrict" :items="getAllDoneDistrict" clearable item-title="text"
                                  item-value="value" label="District" multiple class="custom_input_field_color"
                                  placeholder="Select District" variant="outlined" density="comfortable"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectDsd" :items="getAllDsd" clearable item-title="text" item-value="value"
                                  label="DSD" multiple placeholder="Select DSD" variant="outlined" density="comfortable" class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectGnd" :items="getAllGnd" clearable item-title="text" item-value="value" label="GND" multiple
                                  placeholder="Select GND" variant="outlined" density="comfortable" class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedCollectionType" :items="getCollectionType" clearable item-title="text" item-value="value"
                                  label="Collection Type" multiple placeholder="Select Type" density="comfortable" class="custom_input_field_color"
                                  variant="outlined"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedStatus" :items="getStatus" clearable item-title="text" item-value="value"
                                  label="Status" multiple placeholder="Select Status" density="comfortable" class="custom_input_field_color"
                                  variant="outlined"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="search" clearable label="Search" placeholder="Search" variant="outlined"
                                density="comfortable" class="custom_input_field_color"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="mb-3 mt-4"></v-divider>

<!--              <v-row class="mt-0">-->
<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="mainCategory" clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Main Category" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Main Category" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->

<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="subCategory"  clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Sub Category" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Sub Category" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->

<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="products"  clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Products" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Products" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--              </v-row>-->

              <v-divider class="mb-2"></v-divider>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-text>
          <v-data-table :headers="headers" :items="filteredCollections"    :search="search" :items-per-page="5" item-value="id"  class="px-2 custom_table_normal" @page-count="totalPages = $event">

            <template v-slot:[`item.agent.name`]="{ item }">
              <p class="font-weight-medium text-body-2 mt-2 ">{{ item.agent?.name || 'N/A' }}</p>
              <v-divider  class="mb-2" thickness="2"></v-divider>
              <p  style="color: #BB0000;font-size: 10px" class="mb-2">REG NO: {{ item.agent.registration_num }}</p>
            </template>

            <template v-slot:[`item.route_name`]="{ item }">
              {{ item.route_name || 'N/A' }}
            </template>

            <template v-slot:[`item.sales_amount`]="{ item }">
              <p class="custom_font_color_delete text-body-2 font-weight-medium">Rs. {{ item.sales_amount || 'N/A' }}</p>

              <v-divider  class="mb-2" thickness="2"></v-divider>

              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-chip v-if="item.payment_status  === 'Partialy Payment'" class="custom_blue_chip" size="small" variant="outlined">
                    Partially Payment
                    <v-icon class="ml-9">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.payment_status  === 'Pending Payment'" class="custom_orange_chip"  size="small" variant="outlined">
                    Pending Payment
                    <v-icon class="ml-12">mdi-progress-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.payment_status  === 'Full Payment'" class="custom_green_chip"  size="small" variant="outlined">
                    Paid Complete
                    <v-icon class="ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.payment_status  === 'Overdue'" class="custom_red_chip" size="small" variant="outlined">
                    Overdue Payment
                    <v-icon class="ml-8">mdi-close</v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </template>


            <template v-slot:[`item.store`]="{ item }">
              <div class="column-content ">{{ item.store }}</div>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
              <div class="column-content">{{ new Date(item.updated_at).toISOString().split('T')[0] }}</div>
            </template>


            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_collection_management">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item  v-if="permissions.write"  @click="editItem(item)">-->
<!--                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>-->
<!--                  </v-list-item>-->
                  <v-list-item  v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View User Dialog -->
      <v-dialog v-model="view_Dialog" fullscreen id="app_collection_management">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="view_Dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <Collection_Record/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="editDialog" fullscreen id="app_collection_management">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="editDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <Collection_Record_Edit/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit User dialog -->


      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteDialog" id="app_collection_management" max-height="500" max-width="500" persistent>
        <v-card id="app_collection_management" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE COLLECTION DATA</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this collection record ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  TThis collection record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->



    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios';
  import Collection_Record_Edit from "@/components/COLLECTION_MANAGEMENT/Collection_Record_Edit.vue";
  import {EventBus} from "@/plugins/event-bus";
  import {mapActions} from "vuex";
  import Collection_Record from "@/components/COLLECTION_MANAGEMENT/Collection_Record.vue";
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from 'xlsx';
  import _ from 'lodash';
  import {getPermissions} from "@/plugins/permissions";

  export default {
    components: {
      Collection_Record,
      Collection_Record_Edit
    },

    data() {
      return {
        mainCategory:"",
        subCategory:"",
        products:"",
        selectedStatus:"",
        getStatus: [
          { text: 'Paid Complete', value: 'Full Payment' },
          { text: 'Pending Payment', value: 'Pending Payment' },
          { text: 'Partially Payment', value: 'Partialy Payment' },
          { text: 'Overdue Payment', value: 'Overdue' },
        ],
        filteredCollections: [], // Data displayed in the table
        routeLists: [], // Dropdown options for route names
        selectedRoute: [], // Selected routes from the dropdown
        agentLists: [],
        selectedAgent: '',
        getAllDoneDistrict: [],
        getAllDsd: [],
        getAllGnd: [],
        selectedCollectionType: "",
        getCollectionType: [],
        selectDsd: "",
        selectGnd: "",
        selectDistrict: '',
        menu: false,
        dates: [],
        dateRange: '',
        search: '',
        deleteDialog: false,
        itemToDelete: null,
        view_Dialog: false,
        editDialog: false,
        agentID: null,
        headers: [
          {text: 'Order Id', value: 'order_id', title: 'ORDER ID' , sortable: true},
          {text: 'Store Name', value: 'store_name', title: 'OUTLET NAME' , sortable: true},
          {text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME' , sortable: true},
          {text: 'Quantity Sold', value: 'sales_amount', title: 'TOTAL COLLECTIONS' , sortable: true},
          {text: 'Quantity Sold', value: 'route_name', title: 'ROUTE NAME' , sortable: true},
          {text: 'LAST PAID DATE', value: 'updated_at', title: 'LAST PAID DATE' , sortable: true},
          {text: 'LAST PAID DATE', value: 'sales_type', title: 'COLLECTION TYPE' , sortable: true},
          {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
        ],
        collections: [],
        permissions: {},
      }
    },
    methods: {

      clearDateRange() {
        this.dates = [];
      },

      ...mapActions(['updateSharedDataCollection']),

      getPaymentStatusColor(status) {
        switch (status) {
          case 'Partialy Payment':return 'blue';
          case 'OVERDUE':return 'red';
          case 'PENDING PAYMENT':return 'orange';
          case 'PAID COMPLETE':return 'green';
          default:return 'grey';
        }
      },

      // Get Agent List
      getAllAgentsDeteails() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`/api/agentUser`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.agentLists = response.data.data;
          console.log("API Response:", response.data.data);
        })
        .catch(error => {
          console.error("There was an error fetching the sales data!", error);
        });
      },

      fetchCollectionsData() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log("API Response collections:", response.data);
          this.collections = Array.isArray(response.data) ? response.data : [];
          console.log('data', this.collections);

          // Extract unique agent names
          const agents = _.uniqBy(this.collections, 'agent.name');
          this.agentLists = agents.map(agent => ({
            text: agent.agent.name,
            value: agent.agent.id
          }));

          const routes = _.uniqBy(this.collections, 'route_name');
          this.routeLists = routes
          .filter(route => route.route_name) // Exclude null or undefined route_name
          .map(route => ({
            text: route.route_name,
            value: route.route_name
          }));

          const districts = _.uniqBy(this.collections, 'district');
          this.getAllDoneDistrict = districts
          .filter(route => route.district) // Exclude null or undefined district
          .map(route => ({
            text: route.district,
            value: route.district
          }));

          const dsds = _.uniqBy(this.collections, 'dsd');
          this.getAllDsd = dsds
          .filter(route => route.dsd) // Exclude null or undefined district
          .map(route => ({
            text: route.dsd,
            value: route.dsd
          }));

          const gnds = _.uniqBy(this.collections, 'gnd');
          this.getAllGnd = gnds
          .filter(route => route.gnd) // Exclude null or undefined district
          .map(route => ({
            text: route.gnd,
            value: route.gnd
          }));

          const collectionTypes = _.uniqBy(this.collections, 'sales_type');
          this.getCollectionType = collectionTypes
          .filter(route => route.sales_type) // Exclude null or undefined district
          .map(route => ({
            text: route.sales_type,
            value: route.sales_type
          }));

          console.log("Agent lists:", this.agentLists);

          // Initially show all collections
          this.filteredCollections = [...this.collections];

          console.log("District", this.getAllDoneDistrict);
          console.log("collections after assignment:", this.collections);
        })
        .catch(error => {
          console.error("There was an error fetching the collections data!", error);
          this.collections = [];
        });
      },

      updateDateRange() {
        if (this.dates.length === 2) {
          this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
        }
      },

      confirmDelete(item) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },

      deleteItem() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/delete/${this.itemToDelete.id}`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.collections = this.collections.filter(sale => sale.id !== this.itemToDelete.id);
          this.deleteDialog = false;
          this.itemToDelete = null;
        })
        .catch(error => {
          console.error("There was an error deleting the sale!", error);
        });
      },

      exportPDF() {
        const doc = new jsPDF();
        // Set the title for the document
        doc.text('Collection Analysis', 14, 10);

        // Prepare the data for the table
        const tableData = this.collections.map((item, index) => [
          index + 1,
          item.order_id,
          item.store_name,
          item.agent.name,
          item.sales_amount
        ]);

        // Define the columns for the table
        const tableColumns = ['#', 'Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

        // AutoTable plugin to create a table
        doc.autoTable({
          head: [tableColumns],
          body: tableData,
        });

        // Save the PDF with a name
        doc.save('collection_analysis.pdf');
      },

      exportExcel() {
        const tableData = this.collections.map((item, index) => ({
          '#': index + 1,
          'Order ID': item.order_id,
          'Store Name': item.store_name,
          'Agent Name': item.agent.name,
          'Total Collections': item.sales_amount
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Collections');
        XLSX.writeFile(workbook, 'collection_analysis.xlsx');
      },

      // extra function
      otherXL() {
        // Define headers
        const headers = ['Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

        // Prepare the data
        const data = this.collections.map((item) => [
          item.order_id,
          item.store_name,
          item.agent.name,
          item.sales_amount,
        ]);

        // Combine headers and data
        const worksheetData = [headers, ...data];

        // Create a new worksheet
        const ws = XLSX.utils.aoa_to_sheet(worksheetData);

        // Apply bold font to header row
        const headerRange = XLSX.utils.decode_range("A1:D1");
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
          if (!ws[cellAddress]) continue;
          ws[cellAddress].s = {font: {bold: true}};
        }
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Collections Data');

        // Write the workbook to a file
        XLSX.writeFile(wb, 'collection_analysis.xlsx');
      },

      viewItem(item) {
        this.updateSharedDataCollection(item.id);
        this.view_Dialog = true;
      },

      editItem(item) {
        //  functionality to edit item details
        this.updateSharedDataCollection(item.id);
        this.editDialog = true;
      },

      closeD() {
        this.view_Dialog = false;
        this.editDialog = false;
      },

      backPageRoute() {
        this.$router.push('/dashboard');
      },

      collectionsManagementButtonClick() {
        // Logic to execute when the second component button is clicked
        this.view_Dialog = false;
        this.editDialog = true;
      },

      s2() {
        this.editDialog = false;
        this.view_Dialog = false;
      },

      setupEventListeners() {
        EventBus.on('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
        EventBus.on('collectionsRecodeManagementButtonClick2', this.s2);
      },

      removeEventListeners() {
        EventBus.off('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
        EventBus.off('collectionsRecodeManagementButtonClick2', this.s2);
      }
    },

    mounted() {
      const dashboardId = 6;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);
      this.setupEventListeners();
      this.fetchCollectionsData();
      this.getAllAgentsDeteails();
      console.log('Headers:', this.headers);
      console.log('collections:', this.collections);
    },

    beforeUnmount() {
      this.removeEventListeners();
    },

    computed: {
      // eslint-disable-next-line vue/no-dupe-keys
      filteredCollections() {
        return this.collections.filter(collection => {

          const formatDate = (date, addOneDay = false) => {
            if (!date) return null;
            const d = new Date(date);
            if (isNaN(d.getTime())) return null;
            if (addOneDay) {
              d.setDate(d.getDate() + 1);
            }
            return d.toISOString().split('T')[0];
          };

          const startDate = this.dates.length ? formatDate(this.dates[0], true) : null;
          const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

          const startDateObj = startDate ? new Date(startDate) : null;
          const endDateObj = endDate ? new Date(endDate) : null;

          const saleAssignDate = collection.created_at ? collection.created_at.split('T')[0] : null;
          const saleAssignDateObj = saleAssignDate ? new Date(saleAssignDate) : null;

          const withinDateRange = !startDateObj || !endDateObj || (saleAssignDateObj && saleAssignDateObj >= startDateObj && saleAssignDateObj <= endDateObj);

          const matchesAgent = this.selectedAgent.length === 0 ||
              (collection.agent && this.selectedAgent.includes(collection.agent.id));
          const matchesRoute = this.selectedRoute.length === 0 ||
              (collection.route_name && this.selectedRoute.includes(collection.route_name));
          const matchesDistrict = this.selectDistrict.length === 0 ||
              (collection.district && this.selectDistrict.includes(collection.district));
          const matchesDSD = this.selectDsd.length === 0 ||
              (collection.dsd && this.selectDsd.includes(collection.dsd));
          const matchesGND = this.selectGnd.length === 0 ||
              (collection.gnd && this.selectGnd.includes(collection.gnd));
          const matchCollectionType = this.selectedCollectionType.length === 0 ||
              (collection.sales_type && this.selectedCollectionType.includes(collection.sales_type));
          const matchselectedStatus = this.selectedStatus.length === 0 ||
              (collection.payment_status && this.selectedStatus.includes(collection.payment_status));
          return matchesAgent && matchesRoute && matchesDistrict && matchesDSD && matchesGND && matchCollectionType && matchselectedStatus && withinDateRange;
        });
      },

      filteredRoutes() {
        return this.collections.filter((sale) => {
          if (!sale) return false;
          console.log(sale);
          // Check if the sale's created_at date is within the selected date range
          const withinDateRange =
              !this.dates.length ||
              (sale.created_at &&
                  new Date(sale.created_at) >= new Date(this.dates[0]) &&
                  new Date(sale.created_at) <= new Date(this.dates[1])
              );

          // Check if the sale matches the search query
          const matchesSearch =
              !this.search ||
              (sale.store_name && sale.store_name.toLowerCase().includes(this.search.toLowerCase())) ||
              (sale.agent && sale.agent.name && sale.agent.name.toLowerCase().includes(this.search.toLowerCase())) ||
              (sale.order_id && sale.order_id.toLowerCase().includes(this.search.toLowerCase())) ||
              (sale.order_id && sale.order_id.toString().includes(this.search)) ||
              (sale.sales_amount && sale.sales_amount.toString().includes(this.search));

          // Check Agent
          const agent = !this.selectedAgent || (sale.agent.name && sale.agent.name.toLowerCase().includes(this.selectedAgent.toLowerCase()))
          // Check District
          const district = !this.selectDistrict || (sale.district && sale.district.toLowerCase().includes(this.selectDistrict.toLowerCase()))
          // Check Dsd
          const dsd = !this.selectDsd || (sale.dsd && sale.dsd.toLowerCase().includes(this.selectDsd.toLowerCase()))
          // Check Gnd
          const gnd = !this.selectGnd || (sale.gnd && sale.gnd.toLowerCase().includes(this.selectGnd.toLowerCase()))
          // Return true if both conditions are met
          return withinDateRange && matchesSearch && agent && district && dsd && gnd;
        });
      }
    },

    watch: {
      selectedAgent(newAgents) {
        if (newAgents.length === 0) {
          // No agents selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected agents
          this.filteredCollections = this.collections.filter(collection =>
              newAgents.includes(collection.agent.id)
          );
        }
      },

      selectedRoute(newRoutes) {
        if (!newRoutes || newRoutes.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.route_name && newRoutes.includes(collection.route_name)
          );
        }
      },

      selectDistrict(newDistricts) {
        if (!newDistricts || newDistricts.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.district && newDistricts.includes(collection.district)
          );
        }
      },

      selectDsd(selectDsd) {
        if (!selectDsd || selectDsd.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.dsd && selectDsd.includes(collection.dsd)
          );
        }
      },

      selectGnd(selectGnd) {
        if (!selectGnd || selectGnd.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.gnd && selectGnd.includes(collection.gnd)
          );
        }
      },

      selectedCollectionType(selectedCollectionType) {
        if (!selectedCollectionType || selectedCollectionType.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.sales_type && selectedCollectionType.includes(collection.sales_type)
          );
        }
      },
      selectedStatus(selectedStatus) {
        if (!selectedStatus || selectedStatus.length === 0) {
          // No routes selected, show all collections
          this.filteredCollections = [...this.collections];
        } else {
          // Filter collections by selected routes, ensuring route_name is not null
          this.filteredCollections = this.collections.filter(collection =>
              collection.payment_status && selectedStatus.includes(collection.payment_status)
          );
        }
      },

      dates() {
        this.updateDateRange();
      }
    },
  }
</script>


<style>
  @import "../../styles/collection_management/collection_management.scss";
</style>