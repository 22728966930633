<template>
  <v-app id="app_create_organization">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">SETUP FUNCTIONALITIES</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Select which functions should be enabled according to the organization being
            processed.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Setup modules </p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row>
<!--              <v-col cols="12" xs="12" sm="12" md="6" lg="6">-->
<!--                <v-text-field-->
<!--                     label="Organization Name * " placeholder="Type organization name here"-->
<!--                    required variant="outlined" density="comfortable" class="custom_input_field_color"-->
<!--                ></v-text-field>-->

<!--                <v-text-field class="mt-3 custom_input_field_color" density="comfortable"-->
<!--                              label="Primary Color" variant="outlined"-->
<!--                              @click="dialogPrimaryColor = true"></v-text-field>-->

<!--                <v-dialog v-model="dialogPrimaryColor" max-width="350px" >-->
<!--                  <v-card id="app_authentication" rounded="md">-->
<!--                    <div class="pa-4">-->
<!--                      <v-card color="#F0F0F0" flat>-->
<!--                        <template v-slot:prepend>-->
<!--                          <v-card-title class="text-subtitle-1 font-weight-bold">SET PRIMARY COLOR</v-card-title>-->
<!--                        </template>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <template v-slot:append>-->
<!--                          <v-btn @click="dialogPrimaryColor = false" icon="mdi-close" size="x-small"></v-btn>-->
<!--                        </template>-->
<!--                      </v-card>-->

<!--                      <v-text-field-->
<!--                          class="mt-4 custom_input_field_color"-->
<!--                          label="Color Code" density="comfortable" placeholder="Type your color code here" variant="outlined"-->
<!--                      ></v-text-field>-->


<!--                      <v-color-picker width="318px" ></v-color-picker>-->


<!--                      <v-divider class="mt-3 "></v-divider>-->

<!--                      <div class="float-end mt-4 ">-->
<!--                        <v-btn size="large" class="custom_btn_primary" @click="dialogPrimaryColor = false">Done</v-btn>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </v-card>-->
<!--                </v-dialog>-->


<!--                <v-text-field class="mt-3 custom_input_field_color" density="comfortable"-->
<!--                              label="Secondary Color" variant="outlined"-->
<!--                              @click="dialogSecondaryColor = true"></v-text-field>-->

<!--                <v-dialog v-model="dialogSecondaryColor" max-width="350px" >-->
<!--                  <v-card id="app_authentication" rounded="md">-->
<!--                    <div class="pa-4">-->
<!--                      <v-card color="#F0F0F0" flat>-->
<!--                        <template v-slot:prepend>-->
<!--                          <v-card-title class="text-subtitle-1 font-weight-bold">SET SECONDARY COLOR</v-card-title>-->
<!--                        </template>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <template v-slot:append>-->
<!--                          <v-btn @click="dialogSecondaryColor = false" icon="mdi-close" size="x-small"></v-btn>-->
<!--                        </template>-->
<!--                      </v-card>-->

<!--                      <v-text-field-->
<!--                          class="mt-4 custom_input_field_color"-->
<!--                          label="Color Code" density="comfortable" placeholder="Type your color code here" variant="outlined"-->
<!--                      ></v-text-field>-->
<!--                      <v-color-picker width="318px" ></v-color-picker>-->

<!--                      <v-divider class="mt-3 "></v-divider>-->

<!--                      <div class="float-end mt-4 ">-->
<!--                        <v-btn size="large" class="custom_btn_primary" @click="dialogSecondaryColor = false">Done</v-btn>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </v-card>-->
<!--                </v-dialog>-->

<!--                <v-card flat>-->
<!--                  <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">Upload organization logo</p>-->
<!--                  <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop" @dragover.prevent="onDragOver">-->
<!--                    <input ref="fileInput" accept="image/*" class="hidden-file-input" type="file" @change="onFileChange"/>-->
<!--                    <div v-if="previewImage" class="image-card-container">-->
<!--                      <v-card class="image-card">-->
<!--                        <v-img :alt="'Image preview'" :src="previewImage" class="image-preview"></v-img>-->
<!--                        <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage">-->
<!--                          <v-icon color="red" size="small">mdi-delete</v-icon>-->
<!--                        </v-btn>-->
<!--                      </v-card>-->
<!--                    </div>-->
<!--                    <div v-if="!previewImage" class="placeholder-container">-->
<!--                      <v-icon size="36">mdi-cloud-upload</v-icon>-->
<!--                      <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </v-card>-->
<!--              </v-col>-->

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-divider class="mb-3 mt-3"></v-divider>
                <v-btn size="x-large" class="custom_btn_primary" @click="toggleAll">{{ toggleAllText }}</v-btn>
                <v-divider class="mb-3 mt-3"></v-divider>
                <v-card class="mb-1 mt-4 custom_card_box" rounded="lg">
                  <div v-for="(item) in items" :key="item.text">
                    <div class="d-flex" style="height: 55px !important;">
                      <v-switch class="ml-5" v-model="item.isOn" :color="item.isOn ? '#BB0000' : 'grey'" @change="handleSwitchChange(item)"></v-switch>
                      <p :class="{ 'custom_page_main_sub_title_very_small_red': item.isOn, 'grey-text': !item.isOn }" class="font-weight-medium text-body-2 float-start mt-4 ml-6">{{item.text}}</p>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </v-card>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

<!--        <v-divider thickness="2"></v-divider>-->

<!--        <v-card-actions class="float-end">-->
<!--          <v-btn size="x-large"  class="custom_btn_primary">Create new organization</v-btn>-->
<!--          <v-btn size="x-large" class="custom_btn_secondary" >Cancel</v-btn>-->
<!--        </v-card-actions>-->
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
  import {mapActions, mapState} from 'vuex';

  export default {
    name: 'OrganizationManagement',
    data() {
      return {
        // Image handling
        previewImage: null,
        selectedImage: '',
        dialogPrimaryColor:false,
        dialogSecondaryColor:false,
        selectAll: false, // Initial state flag
        items: [
          {text: 'Dashboard', isOn: true},
          {text: 'Product Management', isOn: true},
          {text: 'Leads Management', isOn: true},
          {text: 'User Management', isOn: true},
          {text: 'Sales Management', isOn: true},
          {text: 'Collection Management', isOn: true},
          {text: 'Tracking Management', isOn: true},
          {text: 'Route Management', isOn: true},
          {text: 'Outlet Management', isOn: true}
        ]
      };
    },

    computed: {
      toggleAllText() {
        return this.selectAll ? 'Clear All' : 'Select All';
      },
      ...mapState(['organizationSettings']),
    },

    watch: {
      // Watch for changes in items and check the selection state
      items: {
        handler() {
          this.checkAllSelectedOrCleared();
        },
        deep: true // Needed to detect changes in nested properties
      }
    },

    methods: {
      ...mapActions(['updateSharedDataOrganization']),

      selectImage(image) {
        this.selectedImage = image;
      },

      triggerFileInput() {
        this.$refs.fileInput.click();
      },

      onFileChange(event) {
        const file = event.target.files[0]; // Get the first file from the input
        if (file) {
          this.previewImage = URL.createObjectURL(file); // Set the preview image
          this.handleFiles(file); // Pass the file to handleFiles
        }
      },

      onDrop(event) {
        const file = event.dataTransfer.files[0]; // Get the first file from the drop
        if (file) {
          this.previewImage = URL.createObjectURL(file); // Set the preview image
          this.handleFiles(file); // Pass the file to handleFiles
        }
      },

      onDragOver(event) {
        event.preventDefault();
      },

      removeImage() {
        this.previewImage = null;
        this.$refs.fileInput.value = null;
      },

      handleFiles(file) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

        if (!validImageTypes.includes(file.type)) {
          alert('Only image files are allowed!');
          return;
        }

        if (!(file instanceof File)) {
          console.error('Not a valid File object:', file);
          return;
        }
      },


      handleSwitchChange(item) {
        // item.isOn is automatically updated via v-model, so no need to toggle manually
        const value = item.isOn ? '1' : '0';
        this.updateSharedDataOrganization({[item.text]: value});

        const currentSettings = JSON.parse(localStorage.getItem('organizationSettings')) || {};
        currentSettings[item.text] = value;
        localStorage.setItem('organizationSettings', JSON.stringify(currentSettings));
      },

      backPageRoute() {
        this.$router.back();
      },

      complete() {
        const settings = {};

        // Update each item using handleSwitchChange for consistent logic
        this.items.forEach(item => {
          // Call handleSwitchChange to toggle and update each switch
          this.handleSwitchChange(item);

          // Add updated state to settings object
          settings[item.text] = item.isOn ? '1' : '0';
        });

        // After updating each item, we store the final state in Vuex and localStorage
        this.updateSharedDataOrganization(settings);
        localStorage.setItem('organizationSettings', JSON.stringify(settings));
      },

      toggleAll() {
        if (this.selectAll) {
          this.clearSwitches();
        } else {
          this.selectSwitches();
        }
        this.selectAll = !this.selectAll; // Toggle state
      },

      clearSwitches() {
        const settings = {};

        this.items.forEach(item => {
          item.isOn = false;
          settings[item.text] = '0';
        });

        this.updateSharedDataOrganization(settings);
        localStorage.setItem('organizationSettings', JSON.stringify(settings));
      },

      selectSwitches() {
        const settings = {};

        this.items.forEach(item => {
          item.isOn = true;
          settings[item.text] = '1';
        });

        this.updateSharedDataOrganization(settings);
        localStorage.setItem('organizationSettings', JSON.stringify(settings));
      },
      checkAllSelectedOrCleared() {
        // Check if all items are selected or cleared to set the button text
        this.selectAll = this.items.every(item => item.isOn);
      }
    },

    created() {
      // Load initial settings from localStorage if available
      const savedSettings = JSON.parse(localStorage.getItem('organizationSettings'));
      if (savedSettings) {
        this.items.forEach(item => {
          if (savedSettings[item.text] !== undefined) {
            item.isOn = savedSettings[item.text] === '1';
          }
        });
        // Update Vuex with loaded settings from localStorage
        this.updateSharedDataOrganization(savedSettings);
      }
    }
  };
</script>


<style>
  @import "../../styles/organization_management/create_organization.scss";
</style>

