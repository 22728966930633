<template>

  <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <v-app id="app_add_leads">
    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE LEADS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Create leads according to your requirements.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="leadsBulkUploadDialog = true">
              <v-icon>mdi-file-upload-outline</v-icon>&nbsp;&nbsp;LEADS BULK UPLOAD
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Create lead details</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-text-field
                    v-model="lead.title"
                    :rules="[v => !!v || 'Lead Title is required',v => /^[a-zA-Z\s]+$/.test(v) || 'Only letters and spaces are allowed']"
                    label="Lead Title *" @input="validateleadTitle" placeholder="Type title here" variant="outlined"
                    density="comfortable" class="custom_input_field_color"
                ></v-text-field>

                <p v-if="errorMessage" class="mt-0" style="color: red;" type="error">
                  {{ errorMessage }}
                </p>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-autocomplete v-model="lead.mainCategory" :items="categories" :loading="mainFilterLoading"
                                :rules="[v => !!v || 'Main Category is required']" clearable
                                item-text="main_filter" item-value="id" label="Main Category *"
                                placeholder="Select main category"
                                variant="outlined" @update:modelValue="fetchSubCategories(lead.mainCategory)"
                                class="custom_input_field_color"
                                density="comfortable"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-autocomplete v-model="lead.subCategory" :items="subCategories" :loading="subFilterLoading"
                                :rules="[v => !!v || 'Sub Category is required']" :v-if="isDisabledProvinceList"
                                clearable item-text="sub_filter" item-value="id" label="Sub Category *"
                                placeholder="Select sub category" variant="outlined"
                                density="comfortable" class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="lead.province" :items="provinces" :loading="mProvinceLoading"
                                :rules="[v => !!v || 'Province is required']" clearable
                                item-text="label" item-value="value" label="Province *" placeholder="Select province"
                                variant="outlined"
                                @update:modelValue="getAllDistrictAccProv" density="comfortable"
                                class="custom_input_field_color"
                ></v-autocomplete>
                <v-label class="text-caption custom_page_main_sub_title_very_small_red font-weight-medium">
                  <v-icon color="#BB0000">mdi-arrow-up-bold</v-icon>
                  Hint: Once you select a province, it can't be changed. To modify it, just refresh the page.
                </v-label>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="lead.assignAgent" :items="filteredAgents"
                                :rules="[v => !!v || 'Assign Agent is required']" clearable
                                item-title="name" item-value="id" label="Assign Agent *" placeholder="Select agent"
                                variant="outlined" density="comfortable"
                                class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-autocomplete v-model="lead.district" :disabled="isDisabledDistrictList" :items="districts"
                                :loading="mDistrictLoading"
                                clearable item-text="label" item-value="value" label="District"
                                placeholder="Select district" variant="outlined"
                                @update:modelValue="getAllDsd" density="comfortable" class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-autocomplete v-model="lead.dsd" :disabled="isDisabledDSDLIST" :items="dsdList" :loading="mDSDLoading"
                                clearable
                                item-text="label" item-value="value" label="DSD" placeholder="Select dsd"
                                variant="outlined" @update:modelValue="getAllGnd"
                                density="comfortable" class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-autocomplete v-model="lead.gnd" :disabled="isDisabledGNDList" :items="gndList" :loading="mGNDLoading"
                                clearable label="GND" placeholder="Select GND" variant="outlined" density="comfortable"
                                @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                                class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-date-input v-model="lead.assignDate" :min="new Date().toISOString().split('T')[0]"
                              :rules="[v => !!v || 'Assign Date is required']" clearable
                              label="Assign Date *" prepend-inner-icon="$calendar" prepend-icon=""
                              placeholder="Select a Date" variant="outlined" density="comfortable"
                              class="custom_input_field_color"
                ></v-date-input>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider class="mb-4"></v-divider>
                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description Word Count 300</p>
                    <v-card class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="300"
                            min-height="300">
                      <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                      <v-divider></v-divider>
                      <Editor v-model="this.lead.description" :defaultConfig="editorConfig" :mode="mode"
                              @onCreated="handleCreated"/>
                    </v-card>
                  </v-col>
                </v-row>
                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider class="mt-1 mb-3"></v-divider>
                <p class="custom_page_main_sub_title">
                  <strong>SELECTED LEADS LIST</strong>
                </p>
                <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
                  The following table shows all the outlets/institutes related to the above-defined lead.
                </p>


                <v-card class="mt-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
                  <v-toolbar color="white" class="pa-3" flat>

                    <v-card-text>
                      <v-switch class="ml-1 mt-4" color="#BB0000" v-model="selectAll" label="Select All Leads"
                                @change="toggleSelectAll"></v-switch>
                    </v-card-text>

                    <v-divider vertical></v-divider>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-col cols="5">
                      <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"
                                    class="custom_input_field_color"
                                    clearable hide-details label="Search" placeholder="Search Lead" variant="outlined"/>
                    </v-col>
                  </v-toolbar>

                  <v-divider></v-divider>

                  <v-card-text>
                    <v-data-table :headers="headers" :items="filteredItems" :items-per-page="itemsPerPage"
                                  :search="search" density="comfortable" class="custom_table_normal">

                      <!-- Customize the header for the checkbox column -->
                      <!-- <template v-slot:[`column.data-table-select`]="{}">
                        <span></span>
                      </template> -->

                      <template v-slot:[`item.data-table-select`]="{ item }">
                        <v-switch color="#BB0000" v-model="item.selected" @change="updateSelectedLeads"></v-switch>
                      </template>

                      <template v-slot:[`item.subCategory`]="{ item }">
                        <span class="red-text">{{ item.subCategory }}</span>
                      </template>

                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large" class="custom_btn_primary" @click="addLead">Create Lead</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="backPageRoute()">Cancel</v-btn>
        </v-card-actions>
      </v-card>


      <!-- Lead Bulk Upload Dialog -->
      <v-dialog v-model="leadsBulkUploadDialog" fullscreen id="app_add_leads">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="leadsBulkUploadDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPLOAD SPREADSHEET
                  </v-card-title>
                </template>
              </v-card>

              <v-card class="px-3 mx-6 mt-3 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                    <p class="text-body-1 font-weight-medium  mb-4 mt-4">
                      Upload your lead bulk data set using excel or a csv file.
                    </p>
                    <v-divider></v-divider>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-file-input accept=".csv, .xls, .xlsx"
                                    clearable density="comfortable"
                                    class="custom_input_field_color mt-3" label="Upload your Excel or CSV file *"
                                    counter show-size variant="outlined"
                                    @change="handleFileUpload"></v-file-input>
                    </v-col>
                  </v-col>

                  <v-col class="d-flex align-center justify-center" cols="1">
                    <v-divider :thickness="2" vertical></v-divider>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="#F0F0F0" flat
                                width="100%">
                          <card class="mr-4" border="lg">
                            <p class="mb-0 text-body-2 font-weight-bold">DOWNLOAD SAMPLE TEMPLATE</p>
                          </card>
                          <v-divider vertical></v-divider>
                          <v-btn class="custom_btn_primary" size="x-large" variant="text" @click="downloadTemplate">
                            DOWNLOAD
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-card v-if="bulkItems.length > 0" flat>
                  <v-card-text>
                    <v-divider class="mb-4 "></v-divider>
                    <v-data-table :headers="bulkHeaders" :items="bulkItems" :items-per-page="itemsPerPage"
                                  class="custom_table_normal">
                      <template v-slot:[`item.subCategory`]="{ item }">
                        <span class="red--text">{{ item.subCategory }}</span>
                      </template>
                      <template v-slot:[`item.assignAgent`]="{ item }">
                        <span>{{ item.assignAgent }}<br/><span class="red--text">{{ item.regNum }}</span></span>
                      </template>
                    </v-data-table>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="float-end">
                    <v-btn :disabled="bulkItems.length === 0" class="custom_btn_primary  mt-2 mb-3" size="large"
                           variant="text" @click="uploadBulkLead">Upload
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Lead Bulk Upload Dialog -->

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top-right" outlined>
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>


      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>


<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import _ from 'lodash';

//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import {i18nChangeLanguage} from '@wangeditor/editor'

i18nChangeLanguage('en')
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
//This is related to wangeditor ---END

export default {
  components: {
    Editor,
    Toolbar,
  },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = {placeholder: 'Type here...'}

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END

  name: 'AddLeads',
  data() {
    return {
      dataDescription: '',
      errorMessage: "",
      formatting: [],
      showColorPicker: false,
      selectedColor: '#000',
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },
      snackbar: false,
      snackbarColor: '',
      snackbarMessage: '',
      centerLoading: false,
      // Data properties for the form
      lead: {
        title: '',
        mainCategory: '',
        subCategory: '',
        province: '',
        district: '',
        description: '',
        dsd: '',
        gnd: '',
        assignAgent: '',
        assignDate: null,
      },
      excelFiles: null,
      selectedCategory: null,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      selectAll: false,
      categories: [],
      subCategories: [],
      allCategories: [],
      isDisabledProvinceList: false,
      isDisabledDistrictList: false,
      isDisabledDSDLIST: false,
      isDisabledGNDList: false,
      selectedDistrict: [],
      selectedProvince: "",
      selectedDSD: [],
      selectedGND: [],
      formValidate: "",
      agents: [],
      filteredAgents: [],
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        {text: 'Lead Title', value: 'Lead Title', title: 'Lead Title'},
        {text: 'Main Category', value: 'Main Category', title: 'Main Category'},
        {text: 'Sub Category', value: 'Sub Category', title: 'Sub Category'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'Lead Description', value: 'Lead Description', title: 'Lead Description'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        {
          text: 'Assign Agent Registration Number',
          value: 'Assign Agent Registration Number',
          title: 'Assign Agent Registration Number'
        },
        {text: 'Assign Date', value: 'Assign Date', title: 'Assign Date'},
        {text: 'Sub Categorys', value: 'Sub Categorys', title: 'Sub Categories'},
        {text: 'Institute Names', value: 'Institute Names', title: 'Institute Names'},
        {text: 'Addresses', value: 'Addresses', title: 'Addresses'},
        {text: 'Latitudes', value: 'Latitudes', title: 'Latitudes'},
        {text: 'Longitudes', value: 'Longitudes', title: 'Longitudes'},
      ],
      bulkHeadersTwo: [
        {text: 'Lead Title', value: 'Lead Title', title: 'Lead Title'},
        {text: 'Main Category', value: 'Main Category', title: 'Main Category'},
        {text: 'Sub Category', value: 'Sub Category', title: 'Sub Category'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'Lead Description', value: 'Lead Description', title: 'Lead Description'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        {
          text: 'Assign Agent Registration Number',
          value: 'Assign Agent Registration Number',
          title: 'Assign Agent Registration Number'
        },
        {text: 'Assign Date', value: 'Assign Date', title: 'Assign Date'},
        {text: 'Sub Categorys', value: 'Sub Categorys', title: 'Sub Categorys'},
        {text: 'Institute Names', value: 'Institute Names', title: 'Institute Names'},
        {text: 'Addresses', value: 'Addresses', title: 'Addresses'},
        {text: 'Latitudes', value: 'Latitudes', title: 'Latitudes'},
        {text: 'Longitudes', value: 'Longitudes', title: 'Longitudes'},
      ],

      // add leads
      headers: [
        {text: '', value: 'data-table-select', title: 'data-table-select'},
        {text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category'},
        {text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name'},
        {text: 'Address', value: 'address', sortable: true, title: 'Address'},
        // { text: 'Action', value: 'Action', sortable: true, title: 'Action' },
      ],
      checkLeadTileExist: [],
      items: [],
      mainFilterLoading: false,
      subFilterLoading: false,
      mDistrictLoading: false,
      mProvinceLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,
      search: '',
      leadsBulkUploadDialog: false,
      assignDateMenu: false,
    };
  },

  computed: {
    // Filtering items based on search input
    filteredItems() {
      const searchText = (this.search || '').toLowerCase();

      return this.items.filter(item => {
        return (
            (item.subCategory?.toLowerCase() || '').includes(searchText) ||
            (item.instituteName?.toLowerCase() || '').includes(searchText) ||
            (item.lat?.toString() || '').includes(searchText) ||
            (item.lng?.toString() || '').includes(searchText) ||
            (item.DSD_N?.toLowerCase() || '').includes(searchText) ||
            (item.DISTRICT_N?.toLowerCase() || '').includes(searchText) ||
            (item.GND_N?.toLowerCase() || '').includes(searchText) ||
            (item.address?.toLowerCase() || '').includes(searchText)
        );
      });
    }
  },

  watch: {
    'lead.mainCategory': function (newValue) {
      // Example of using newValue
      console.log('Main Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.subCategory': function (newValue) {
      console.log('Sub Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.province': function (newValue) {
      console.log('Province changed to:', newValue);
      this.fetchLeads();
    },
    'lead.district': function (newValue) {
      console.log('District changed to:', newValue);
      this.fetchLeads();
    },
    'lead.dsd': function (newValue) {
      console.log('DSD changed to:', newValue);
      this.fetchLeads();
    },
    'lead.gnd': function (newValue) {
      console.log('GND changed to:', newValue);
      this.fetchLeads();
    }
  },

  mounted() {
    this.fetchMainCategories();
    this.getAllProvinces();
    this.fetchAllAgents();
    this.fetchDataintoTable();
  },

  methods: {

    validateleadTitle() {
      // Allow only alphabetic characters and spaces
      const textRegex = /^[a-zA-Z\s]*$/;

      // If the input doesn't match the regex, revert to the last valid input
      if (!textRegex.test(this.lead.title)) {
        this.lead.title = this.lead.title.replace(/[^a-zA-Z\s]/g, '');
      }
      // Check if the role already exists
      this.chechalreadyHasUserRole();
    },

    chechalreadyHasUserRole() {
      if (
          Array.isArray(this.checkLeadTileExist) &&
          this.checkLeadTileExist.includes(this.lead.title.trim())
      ) {
        this.errorMessage = 'Lead Title already exists';
      } else {
        this.errorMessage = '';
      }
    },

    fetchDataintoTable() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
      this.loading = true;
      const formData = new FormData();
      formData.append("userId", userId);

      axios.post("/api/getLeadNameList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {

        this.checkLeadTileExist = response.data.data;
        console.log('lead list name :', response.data.data);
        this.loading = false;
      })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },


    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle = this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight = this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration = this.textareaStyles.textDecoration === 'underline' ? 'none' : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    toggleSelectAll() {
      // Toggle the select all state
      const newState = this.selectAll;

      // Update the selected property for each item
      this.items.forEach(item => {
        item.selected = newState;
      });
    },

    // Call this method whenever mainCategory, subCategory, or location fields change
    async fetchLeads() {
      await this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
    },

    async loadLeadsList(mainCategory, subCategory) {
      try {
        // Determine the location_type and location values based on selected values
        let locationType = '';
        let locationValue = {
          province: '',
          district: '',
          dsd: '',
          gnd: ''
        };

        console.log('province', this.lead.province);
        console.log('District', this.lead.district);
        console.log('DSD', this.lead.dsd);
        console.log('GND', this.lead.gnd);

        if (this.lead.province && this.lead.province !== "") {
          locationType = 'province';
          locationValue.province = this.lead.province;
        }

        if (this.lead.district && this.lead.district.length > 0) {
          locationType = 'district';
          locationValue.district = this.lead.district;
        }

        if (this.lead.dsd && this.lead.dsd.length > 0) {
          locationType = 'dsd';
          locationValue.dsd = this.lead.dsd;
        }

        if (this.lead.gnd && this.lead.gnd.length > 0) {
          locationType = 'gnd';
          locationValue.gnd = this.lead.gnd;
        }

        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {
          });
          ro.observe(document.body);
        }

        // Construct the payload
        const payload = {
          type: 'Spatial',
          country: 'SriLanka',
          district: locationValue.district ? [locationValue.district] : [],
          province: locationValue.province ? [locationValue.province] : [],
          dsd: locationValue.dsd ? [locationValue.dsd] : [],
          gnd: locationValue.gnd ? [locationValue.gnd] : [],
          location_type: locationType,
          main_filter: mainCategory,
          sub_filter: subCategory
        };

        console.log('payloadData', payload);

        // Make the API call
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

        console.log('table data for leads:', payload);
        if (response.data.http_status === 'success') {
          console.log('table data for leads two:', payload);
          console.log('funcking geoBizz data', response.data.data);
          this.items = response.data.data.map(item => ({
            subCategory: item.Type,
            instituteName: item.name,
            address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.Province_N || item.PROVINCE_N || item.Province}`,

            lat: item.lat,
            lng: item.lng,
            district: item.DISTRICT_N,
            dsd: item.DSD_N,
            gnd: item.GND_N,
            selected: false // Add a default value for selection
          }));

        } else {
          console.error("Failed to load leads:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },
    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/agentUser', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        }
      })
          .then(response => {
            let sample = response.data.data;
            console.log('fk', sample);
            this.agents = sample.map(item => {
              return {
                id: item.id,
                name: item.name,
                district: item.district,
                regsterNumber: item.registration_num,
                province: item.province
              };
            });
            console.log('Agents:', this.agents);
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });
    },


    getAgentDistrictWise(district) {
      console.log('districtpakoo', district);
      this.filteredAgents = this.agents.filter(agent => agent.district === district || agent.province === district);
      console.log(`Agents in district ${district}:`, this.filteredAgents);
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mProvinceLoading = true;

      this.lead.province = null;
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.items = [];
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);

      this.getAgentDistrictWise(province);
      this.lead.assignAgent = "";
      this.districts = [];
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.items = [];
      this.mDistrictLoading = true;
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.items = [];
      this.lead.assignAgent = "";
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      this.getAgentDistrictWise(district);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.lead.gnd = [];
      this.items = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },


    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        this.items = [];
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
              .filter(val => val.spatial_type !== 'Point')
              .map(val => ({
                data: null,
                name: val.sub_filter,
                main_type: val.main_filter,
                sub_directory: val.data_model
              }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset) {

      this.isDisabledDistrictList = false;
      this.isDisabledDSDLIST = false;
      this.isDisabledGNDList = false;
      this.lead.province = null;
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.lead.subCategory = [];
      this.items = [];
      console.log('Dataset:', dataset);

      // Ensure that `this.allCategories` is correctly populated
      console.log('All Categories:', this.allCategories);

      let sub_filter = _.map(this.allCategories, function (o) {
        if (o.main_filter === dataset) return o.sub_filter
      });

      sub_filter = _.reject(sub_filter, _.isNil);
      this.subCategories = sub_filter.sort();

      // Debugging: Verify the final result
      console.log('Sub Categories:', this.subCategories);
    },


    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');

        const expectedHeaders = this.bulkHeadersTwo.map(header => header.text);

        const headersMatch = headers.length === expectedHeaders.length &&
            headers.every((header, index) => header === expectedHeaders[index]);
        console.log('Headers:', headers, 'Expected:', expectedHeaders);

        if (!headersMatch) {
          this.snackbar = true;
          this.snackbarMessage = 'UPLOADED FILE WAS WRONG';
          this.snackbarColor = 'error';
          return;
        }

        const isSerialDate = value => !isNaN(value) && value > 0 && value < 2958465; // Excel date range
        const excelDateToJsDate = serial => {
          const jsDate = new Date(Date.UTC(1900, 0, serial - 1)); // Subtract 1 for Excel's 1900 leap year bug
          return jsDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        };

        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            let value = row[index];
            if (header === 'Assign Date' && isSerialDate(value)) {
              value = excelDateToJsDate(parseFloat(value));
            }
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });

        this.bulkItems = dataRows;
      };

      reader.readAsArrayBuffer(file);
    },

    async uploadBulkLead() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      // Validate file selection
      if (!this.bulkFile) {
        this.showSnackbar('No file selected for upload', 'error');
        console.error('No file selected for upload');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId);

      this.isLoading = true; // Show loading state

      try {
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/leads/bulk-upload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );

        // Handle success
        console.log('Bulk upload response:', response.data);
        this.showSnackbar('Bulk upload successful', 'success');
        window.location.href = "/leads-list";
      } catch (error) {
        // Enhanced error handling
        if (error.response) {
          // Backend returned a status code
          const status = error.response.status;
          const message = error.response.data.error || 'An error occurred.';

          console.error(`Error ${status}: ${message}`);

          // Handle specific status codes
          switch (status) {
            case 400:
              this.showSnackbar(`Validation Error: ${message}`, 'error');
              break;
            case 401:
              this.showSnackbar('Unauthorized. Please log in again.', 'error');
              // Optionally redirect to login
              this.$router.push('/login');
              break;
            case 404:
              this.showSnackbar('User or resource not found.', 'error');
              break;
            case 500:
              this.showSnackbar('Server error. Please try again later.', 'error');
              break;
            default:
              this.showSnackbar(`Error: ${message}`, 'error');
          }
        } else if (error.request) {
          // No response received
          console.error('No response received:', error.request);
          this.showSnackbar('No response from the server. Please try again.', 'error');
        } else {
          // Other errors
          console.error('Error setting up request:', error.message);
          this.showSnackbar('An unexpected error occurred. Please try again.', 'error');
        }
      } finally {
        this.isLoading = false; // Hide loading state
      }
    },


    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/leads/download-template?userId=${userId}`;


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_leads_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        this.showSnackbar("Template download successfully!", "success");
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },

    uploadData() {
      // Your API call logic
      axios.post('/your-api-endpoint', this.items)
          .then(response => {
            console.log('Data uploaded successfully:', response.data);
          })
          .catch(error => {
            console.error('Error uploading data:', error);
          });
    },

    backPageRoute() {
      this.$router.back();
    },

    homeReload() {
      window.location.href = '/dashboard';
    },

    validateForm() {
      // Validate Lead Title
      if (!this.lead.title || this.lead.title.trim() === '') {
        this.showSnackbar('Lead Title is required.', 'error');
        return false;
      }


      // Check if the Lead Title already exists
      if (this.errorMessage) {
        this.showSnackbar('Lead Title already exists. Please choose a different title.', 'error');
        return false;
      }

      // Validate Main Category
      if (!this.lead.mainCategory) {
        this.showSnackbar('Main Category is required.', 'error');
        return false;
      }

      // Validate Sub Category
      if (!this.lead.subCategory || this.lead.subCategory === null || this.lead.subCategory.length == 0) {
        this.showSnackbar('Sub Category is required.', 'error');
        return false;
      }

      // Validate Province
      if (!this.lead.province) {
        this.showSnackbar('Province is required.', 'error');
        return false;
      }

      // Validate Assign Agent
      if (!this.lead.assignAgent) {
        this.showSnackbar('Assign Agent is required.', 'error');
        return false;
      }

      // Validate Assign Date
      if (!this.lead.assignDate) {
        this.showSnackbar('Assign Date is required.', 'error');
        return false;
      }

      // Validate Selected Leads
      const selectedLeads = this.items.filter(item => item.selected);
      if (selectedLeads.length === 0) {
        this.showSnackbar('Please select at least one lead.', 'error');
        return false;
      }
      // If all validations pass
      return true;
    },
    async addLead() {
      try {
        // Wait for the validation result
        // Validate the form fields and selected leads
        const isValid = this.validateForm();
        if (!isValid) {
          return; // Stop execution if validation fails
        }

        if (this.lead.description.length > 300) {
          this.showSnackbar('The description exceeds 300 characters. Please provide a shorter description.', 'error');
          return;
        }

        this.centerLoading = true;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        // Gather selected leads and map to the required format
        const selectedLeads = this.items
            .filter(item => item.selected)
            .map((item, index) => ({
              id: index + 1,
              instiName: item.instituteName,
              address: item.address,
              lat: item.lat,
              lng: item.lng,
              district: item.district,
              dsd: item.dsd,
              gnd: item.gnd,
            }));


        const selectedLeadsJSON = JSON.stringify(selectedLeads);

        // Handle the assign date formatting
        const date = new Date(this.lead.assignDate);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        const assignDate = date.toISOString().split('T')[0];


        // Convert Delta to HTML
        //const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
        //const html = converter.convert(); // This is the styled HTML
        //console.log('fk2',html );
        const leadData = {
          leadsTitle: this.lead.title || '',
          mainCatagory: this.lead.mainCategory || '',
          subCatagory: this.lead.subCategory || '',
          province: this.lead.province || '',
          district: Array.isArray(this.lead.district) ? this.lead.district.join(',') : this.lead.district,
          dsd: Array.isArray(this.lead.dsd) ? this.lead.dsd.join(',') : this.lead.dsd,
          gnd: Array.isArray(this.lead.gnd) ? this.lead.gnd.join(',') : this.lead.gnd,
          assignAgent: this.lead.assignAgent,
          assignDate: assignDate,
          leadsLists: selectedLeadsJSON,
          description: this.lead.description || '',
          userId,
        };

        // Post data to API
        const response = await axios.post('/api/addLeads', leadData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        console.log('Lead added successfully:', response.data);
        this.showSnackbar("NEW LEAD WAS REGISTERED SUCCESSFULLY", "success");
        this.centerLoading = false;
        window.location.href = "/add-leads";

      } catch (error) {
        console.error('Error adding lead:', error);
        this.showSnackbar("Error adding Lead. Please try again.", "error");
        this.centerLoading = false;
      }
    }
  }
};
</script>


<style>
@import "../../styles/lead_management/add_leads.scss";
</style>
