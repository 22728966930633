<template>
  <v-app id="app_lead_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">LOGS DATA</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse all logs data using a variety of parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="isClickedDownloadPDFBtn()">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="isClickedDownloadExcelBtn()">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedArea" :items="areaOptions" chips clearable item-title="text" item-value="value" label="Management Area"
                              multiple placeholder="Select area" variant="outlined"  density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedActions" :items="filterActions" chips clearable item-title="text" item-value="value"
                              label="Action" multiple placeholder="Select area" variant="outlined"  density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-date-input v-model="dates" label="Select Date Range" prepend-icon="" prepend-inner-icon="$calendar" multiple="range"
                            variant="outlined" clearable @click:clear="clearDateRange()" density="comfortable" class="custom_input_field_color"
              ></v-date-input>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="selectedUsers"
                  :items="filterUsers"
                  chips
                  clearable
                  :item-title="item => `${item.text} (${item.no})`"
                  item-value="value"
                  label="User"
                  multiple
                  placeholder="Select area"
                  variant="outlined"
                  density="comfortable"
                  class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>



            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedUserRoles" :items="userRoles" chips clearable item-title="text" item-value="value"
                              label="User Role" multiple placeholder="Select area" variant="outlined"  density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" clearable hide-details label="Search"
                            placeholder="Search data" variant="outlined"  density="comfortable" class="custom_input_field_color"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table :headers="headersUserLog" :items="filteredLogs" class="custom_table_normal" :loading="userLogTblLoading">

            <template v-slot:[`item.user.name`]="{ item }">
              <div>
                <p>{{ item.user.name }}</p>
                <p class="text-muted custom_font_color_delete">Reg num: {{ item.user.registration_num }}</p>
              </div>
            </template>

            <template v-slot:[`item.description`]="{ item }">
              <p class="custom_font_color_delete">
                {{ item.description }}
              </p>
            </template>
            <template v-slot:bottom>
              <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="totalPages"
                    :total-visible="10"
                ></v-pagination>
              </div>
            </template>
          </v-data-table>
          <!--          <v-pagination v-model="page" :length="totalPages" @input="fetchUserLog"></v-pagination>-->
        </v-card-text>
      </v-card>


      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";

export default {
  components: {},
  name: 'UserLog',
  data() {
    return {
      page: 0,
      itemsPerPage: 10,
      totalPages: 0,
      userLogTblLoading:false,
      areaOptions: [], // Renamed from managementAreaDashboards
      selectedArea: [],// Renamed from filters.managementArea
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      filters: {
        managementArea: [],
        action: [],
        users: [],
        userType: [],
        dates: [],
        search: '',
      },
      dates:[],
      managementAreaDashboards: [],
      filterActions: [],
      selectedActions: [],
      selectedUsers: [],
      selectedUserRoles: [],
      filterUsers: [],
      userRoles: [],
      search: '',
      dateRange: '',
      headersUserLog: [
        {text: 'DESCRIPTION', value: 'description', title: 'DESCRIPTION', align: 'start', sortable: false},
        {text: 'ACTION TIME & DATE', value: 'action_date_time', title: 'ACTION TIME & DATE'},
        {text: 'ACTION', value: 'action_type', title: 'ACTION'},
        {text: 'USER NAME', value: 'user.name', title: 'USER NAME'},
        {text: 'IP ADDRESS', value: 'ip_address', title: 'IP ADDRESS'},
        {text: 'ROLE', value: 'user_role', title: 'ROLE'}
      ],
      filteredLogs: [],
      filteredLogsForReport:[],
      isDownloadedExcel:false,
      isDownloadedPDF:false,
      // totalPages: [],
      // page: [],
      fetchDataintoTable: [], // This will hold the filtered data.
    }
  },

  watch: {
    'selectedArea': {
      handler() {
        this.fetchUserLog(this.page); // Fetch data whenever managementArea changes
      },
      immediate: true,
    },

    'selectedActions': {
      handler() {
        this.fetchUserLog(this.page);
      },
    },
    'selectedUsers': {
      handler() {
        this.fetchUserLog(this.page);
      },
    },
    'selectedUserRoles': {
      handler() {
        this.fetchUserLog(this.page);
      },
    },
    'dates': {
      handler() {
        this.fetchUserLog(this.page);
      },
    },
    'search': {
      handler() {
        this.fetchUserLog(this.page);
      },
      immediate: true,
    },

    page( newVal, oldVal){
      console.log("Current page click", newVal, oldVal);
      this.fetchUserLog(newVal);
    },

  },

  computed: {

  },

  mounted() {
    // this.getDashboardPermissions();
  },

  methods: {
    // updateSelectedUsers(selected) {
    //   // Transform selectedUsers to only include the `text` property for filtering
    //   this.selectedUsers = selected.map(item => {
    //     const foundUser = this.filterUsers.find(user => user.value === item);
    //     return foundUser ? foundUser.value : item;
    //   });
    // },

    clearDateRange(){
      this.dates = [];
      this.fetchUserLog(this.page);
    },

    fetchUserLog(page) {
      console.log("page is", page);
      this.userLogTblLoading = true;
      const token = localStorage.getItem("token");

      const filters = {
        management_area: this.selectedArea,
        actions: this.selectedActions,
        users: this.selectedUsers,
        user_roles: this.selectedUserRoles,
        date_range: this.dates,
        search: this.search,
        is_downloaded: this.isDownloadedExcel,
        is_downloaded_pdf: this.isDownloadedPDF,
      };

      console.log("filters is", filters);

      axios.get(`/api/audit-logs?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          filters,
        },
      })
          .then((response) => {
            this.filteredLogs = response.data.data.map((log) => {
              // Assign default values for missing user data
              if (!log.user) {
                log.user = {
                  name: "Guest",
                  registration_num: "N/A", // Default registration number
                };
              }
              return log;
            });

            this.filteredLogsForReport = response.data.dataReport;
            this.totalPages = response.data.pagination.last_page;
            this.page = response.data.pagination.current_page;
            this.areaOptions = response.data.managementArea;
            this.filterActions = response.data.filterActions;
            this.filterUsers = response.data.filterUsers;
            this.userRoles = response.data.userRoles;

            console.log("Result", this.filteredLogs);
            this.userLogTblLoading = false;

            if (this.isDownloadedExcel) {
              this.exportExcel();
            }

            if (this.isDownloadedPDF) {
              this.exportPDF();
            }
          })
          .catch((error) => {
            this.userLogTblLoading = false;
            console.error("Error fetching user logs:", error);
          });
    },

    // fetchUserLog(page) {
    //
    //   console.log("page is", page);
    //   this.userLogTblLoading = true;
    //   const token = localStorage.getItem("token");
    //
    //   // Collect the filter parameters
    //   const filters = {
    //     management_area: this.selectedArea,
    //     actions: this.selectedActions,
    //     users: this.selectedUsers,
    //     user_roles: this.selectedUserRoles,
    //     date_range: this.dates,  // If you are using a date range filter
    //     search: this.search,
    //     is_downloaded: this.isDownloadedExcel,
    //     is_downloaded_pdf: this.isDownloadedPDF,
    //   };
    //
    //   console.log("filters is", filters);
    //
    //   axios.get(`/api/audit-logs?page=${page}`, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`
    //     },
    //     params:{
    //       filters,
    //     }
    //   })
    //       .then(response => {
    //         this.filteredLogs = response.data.data.map((log) => {
    //           // Assign default values for missing user data
    //           if (!log.user) {
    //             log.user = {
    //               name: "Guest",
    //               registration_num: "N/A", // Default registration number
    //             };
    //           }
    //           return log;
    //         });
    //         this.filteredLogs = [];
    //         // Save the logs data into a reactive property
    //         this.filteredLogs = response.data.data; // Adjust based on response structure
    //         this.filteredLogsForReport = response.data.dataReport; // Adjust based on response structure
    //         this.totalPages = response.data.pagination.last_page; // Set the total number of pages
    //         this.page = response.data.pagination.current_page; // Set the current page
    //         this.areaOptions = response.data.managementArea;
    //         this.filterActions = response.data.filterActions;
    //         this.filterUsers = response.data.filterUsers;
    //         this.userRoles = response.data.userRoles;
    //         console.log('Result', this.filterUsers);
    //         this.userLogTblLoading = false;
    //
    //         if(this.isDownloadedExcel){
    //           this.exportExcel();
    //         }
    //
    //         if(this.isDownloadedPDF){
    //           this.exportPDF();
    //         }
    //
    //       })
    //       .catch(error => {
    //         this.userLogTblLoading = false;
    //         console.error('Error fetching user logs:', error);
    //       });
    // },

    isClickedDownloadExcelBtn(){
      this.isDownloadedExcel = true;
      this.fetchUserLog(this.page);
    },

    isClickedDownloadPDFBtn(){
      this.isDownloadedPDF = true;
      this.fetchUserLog(this.page);
    },

    exportExcel() {
      const MAX_CHAR_LIMIT = 32767;
      const tableData = this.filteredLogsForReport.map((item, index) => ({
        '#': index + 1,
        'DESCRIPTION': item.description?.substring(0, MAX_CHAR_LIMIT),
        'ACTION TIME & DATE': item.action_date_time,
        'ACTION': item.action_type,
        'Management Area': item.entity_area,
        'New Value': item.new_values?.substring(0, MAX_CHAR_LIMIT),
        'Old Value': item.old_values?.substring(0, MAX_CHAR_LIMIT),
        'USER NAME': item.user ? item.user.name : "Not Found",
        'USER ROLE': item.user_role,
        'IP ADDRESS': item.ip_address,
        'Created At': item.created_at,
        'Updated At': item.updated_at,
      }));

      // Create worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'UserLog');

      // Set column widths for readability
      const columnWidths = [
        { wch: 5 },    // "#"
        { wch: 50 },   // "DESCRIPTION"
        { wch: 25 },   // "ACTION TIME & DATE"
        { wch: 15 },   // "ACTION"
        { wch: 20 },   // "Management Area"
        { wch: 50 },   // "New Value"
        { wch: 50 },   // "Old Value"
        { wch: 20 },   // "USER NAME"
        { wch: 15 },   // "USER ROLE"
        { wch: 15 },   // "IP ADDRESS"
        { wch: 20 },   // "Created At"
        { wch: 20 },   // "Updated At"
      ];
      worksheet['!cols'] = columnWidths;

      // Optional: Apply bold header style if using xlsx-style
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: { bold: true },
            alignment: { vertical: 'center', horizontal: 'center' },
          };
        }
      }
      this.isDownloadedExcel = false;
      // Write to file
      XLSX.writeFile(workbook, 'user_log.xlsx');

    },

    exportPDF() {
      const doc = new jsPDF();
      // Set the title for the document
      doc.text('User Log', 14, 10);

      const tableData = this.filteredLogsForReport.map((item, index) => [
        index + 1,
        item.description,
        item.action_date_time,
        item.action_type,
        item.user ? item.user.name : "Not Found",
        item.user_role,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'DESCRIPTION', 'ACTION TIME & DATE', 'ACTION', 'USER NAME','USER ROLE'];
      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      this.isDownloadedPDF = false;
      // Save the PDF with a name
      doc.save('user_log.pdf');
    },


  },


};
</script>

<style>
@import "../../styles/user_logs/user_logs.scss";
</style>