<template>
  <div id="app_dashboard_admin">
    <REPORTCARDCOMPONENT
        @filterDateRange="changeDateFn($event)"
        @ClearfilterDateRange="getDashboardDetails()"
    />


    <TOPSELLINGPRODUCTS/>
    <salesAndCollectionAnalysis/>
    <outletMap/>
  </div>
</template>

<script>
import REPORTCARDCOMPONENT from "@/components/MAIN_DASHBOARD/report_card_component.vue";
import TOPSELLINGPRODUCTS from "@/components/MAIN_DASHBOARD/top_selling_products.vue";
import salesAndCollectionAnalysis from "@/components/MAIN_DASHBOARD/sales_and_collection_analysis.vue";
import outletMap from "@/components/MAIN_DASHBOARD/outlet_map.vue";
import axios from 'axios';

export default {
  components: {
    REPORTCARDCOMPONENT,
    TOPSELLINGPRODUCTS,
    salesAndCollectionAnalysis,
    outletMap,
  },

  data() {
    return {};
  },

  mounted() {
    window.Echo.channel('TracingApp')
        .listen('LocationUpdated', (e) => {
          console.log('User updated:', e.location);
          // Update the dashboard with the new user data
          this.updateUser(e);
        });
  },

  created() {
    this.getDashboardDetails();
  },

  methods: {

    changeDateFn(e) {
      // Convert dates to 'YYYY-MM-DD' format

      console.log("Start Date (before):", e.start);
      const startDate = new Date(e.start).toISOString().split('T')[0]; // Extracts the date portion
      const endDate = new Date(e.end).toISOString().split('T')[0]; // Extracts the date portion

      console.log("Start Date (Formatted):", startDate);
      console.log("End Date (Formatted):", endDate);

      axios.post(
          '/api/getFilteredCount',
          {startDate, endDate}, // Pass the payload directly as the second argument
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
      )
          .then(response => {
            console.log("Res data", response.data);
            const dashboardData = {
              totalSales: response.data.totalSales,
              salesPercentage: response.data.SalesPercentage,

              totalCollection: response.data.totalCollection,
              collectionPercentage: response.data.CollectionPercentage,

              outletPercentage: response.data.OutletPresantage,
              totalOutlet: response.data.totalOutlet,

              totalStock: response.data.totalStock,
              stockPercentage: response.data.StockPercentage,

              profitPercentage: response.data.profitPresantage,
              totalProfit: response.data.totalProfit,

              totalLeasds: response.data.totalLeasds,
              leadsPercentage: response.data.leadsPresantage,
            };

            console.log('Dashboard Data:', response.data);
            this.$store.dispatch('updateDashboardDetails', dashboardData);
          })
          .catch(error => {
            console.error('Error fetching filtered data:', error);
          });
    },
    getDashboardDetails() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);

      axios.post('/api/getCount', formData, {
        headers:
            {
              'Authorization': `Bearer ${token}`
            }
      })
          .then(response => {
            const dashboardData = {

              totalSales: response.data.totalSales,
              SalesPresentage: response.data.SalesPresentage,

              totalCollection: response.data.totalCollection,
              CollectionPresentage: response.data.CollectionPresentage,

              OutletPresantage: response.data.OutletPresantage,
              totalOutlet: response.data.totalOutlet,

              totalStock: response.data.totalStock,
              StockPresantage: response.data.StockPresantage,

              profitPresantage: response.data.profitPresantage,
              totalProfit: response.data.totalProfit,

              totalLeasds: response.data.totalLeasds,
              leadsPresantage: response.data.leadsPresantage,
            }

            console.log('adad', response.data);
            this.$store.dispatch('updateDashboardDetails', dashboardData);
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
  }
}
</script>

<style>
@import "../../styles/dashboard/dashboard.scss";
</style>