<template>
  <v-app id="app_routes_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">ROUTE PLAN LISTS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse the created route list using a variety of parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-date-input v-model="dates" append-inner-icon="mdi-close" label="Select Date Range" multiple="range" repend-inner-icon="$calendar" prepend-icon=""
                  variant="outlined" @click:append-inner="clearDateRange" density="comfortable" class="custom_input_field_color"
              ></v-date-input>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedCategories" :items="categoryOptions" append-inner-icon="mdi-close" item-title="category_name" label="Select Category"
                  multiple variant="outlined" @change="fetchData" @click:append-inner="clearCategory" density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedRouteStatus" :items="routeCategoryOptions" append-inner-icon="mdi-close" chips label="Route Status"
                  multiple outlined variant="outlined" @change="fetchData" @click:append-inner="clearRouteStatus" density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>
            <!-- Search Input -->
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="searchQuery"
                  label="Search By Route Name, Reg Number, Assign Date"
                  variant="outlined"
                  prepend-inner-icon="mdi-magnify"
                  density="comfortable"
                  class="custom_input_field_color"
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table  :headers="headers" :items="filteredRoutes" :items-per-page="5" :search="search" class="custom_table_normal"
                        item-key="id" item-value="id"
          >

            <template v-slot:[`item.agent.name`]="{ item }">
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.agent.name }}</p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-2">Reg num: {{ item.agent?.registration_num }}</p>
            </template>

            <template v-slot:[`item.selected_outlets`]="{ item }">
              <v-row>
                <v-col cols="12" md="8" lg="8">
                  <v-chip v-if="item.overallStatus === 'Visited'" class="custom_green_chip" size="small" variant="outlined">
                    Visited
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.overallStatus === 'Pending'" class="custom_orange_chip"  size="small" variant="outlined">
                    Pending
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.overallStatus === 'Not Visited'" class="custom_red_chip" size="small" variant="outlined">
                    Not Visited
                    <v-icon class="justify-end ml-6">mdi-close</v-icon>
                  </v-chip>
                </v-col>

                <v-col cols="12" md="4" lg="4">
                  <div>
                    <span class="text-blue">{{ countVisitedOutlets(item) }}</span>
                  </div>
                </v-col>
              </v-row>
            </template>




            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_product_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="permissions.write" @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteDialog" id="app_routes_list" max-height="500" max-width="500" persistent>
        <v-card  rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE ROUTE PLAN DATA</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this route plan record ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This route plan record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->


      <!-- View User Dialog -->
      <v-dialog v-model="view_Dialog" fullscreen id="app_routes_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="view_Dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <RoutePlan/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="editDialog" fullscreen id="app_routes_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="editDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <RoutePlanEdit/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
        {{ snackbarMessage }}
      </v-snackbar>
      <!-- Edit User dialog -->

    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import {EventBus} from '@/plugins/event-bus';
import RoutePlan from "@/components/RouteManagement/RoutePlan.vue";
import RoutePlanEdit from "@/components/RouteManagement/RoutePlanEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: "RoutesList",
  components: {
    RoutePlanEdit,
    RoutePlan,
  },
  data() {
    return {
      searchQuery: '',
      Snackbar: false,
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      menu: false,
      dates: [],
      dateRange: '',
      search: '',
      deleteDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      selectedCategories: [],
      categoryOptions: [],
      selectedRouteStatus: [],
      routeCategoryOptions: ['Visited', 'Not Visited', 'Pending'],
      headers: [
        {text: 'Category', value: 'route_category', title: 'CATEGORY'},
        {text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME'},
        {text: 'Route Name', value: 'route_name', title: 'ROUTE NAME'},
        {text: 'Route Status', value: 'selected_outlets', title: 'ROUTE STATUS'},
        {text: 'Assigned Date', value: 'assign_date', title: 'ASSIGN DATE'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      routes: [], // Initially empty, will be filled by API call
      id: '',
      permissions: {},
    };
  },

  methods: {
    getFieldValue(item, fieldPath) {
      return fieldPath.split('.').reduce((obj, key) => obj && obj[key], item);
    },
    ...mapActions(['updateSharedDataRoutes']),
    countVisitedOutlets(route) {
      console.log("Route count:", route.selected_outlets);
      if (!route.selected_outlets || route.selected_outlets.length === 0) {
        return "0/0"; // No outlets case
      }
      const totalOutlets = route.selected_outlets.length;
      const visitedOutlets = route.selected_outlets.filter(outlet => outlet.status === "Visited").length;
      return `${visitedOutlets}/${totalOutlets}`;
    },


    fetchRoutes() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.routes = response.data;
        console.log("API Response:", response.data);
      })
      .catch(error => {
        console.error('Error fetching routes:', error);
      });
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
        this.deleteDialog = false;
        this.itemToDelete = null;
        this.snackbarMessage = 'Successfully Delete the Route';
        this.snackbarColor = 'success';
        this.snackbar = true;
      })
      .catch(error => {
        this.Snackbar = true;
        this.snackbarMessage = error.message;
        this.snackbarColor = "error"
        console.error('Error deleting route:', error);
      });
    },

    updateDateRange() {
      if (this.dates.length === 2) {
        this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
      }
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Route Details', 14, 10);

      // Prepare the data for the table
      const tableData = this.filteredRoutes.map((item, index) => [
        index + 1,
        item.route_category,
        `${item.agent.name} \n Reg num: ${item.agent?.registration_num}`, // Adding the reg num below the agent name
        item.route_name,
        item.overallStatus,
        item.assign_date,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Category', 'Agent Name', 'Route Name', 'Route Status', 'Assigned Date'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        styles: {cellPadding: 3, fontSize: 10}, // Adjusting cell padding and font size if needed
        didParseCell: function (data) {
          if (data.column.index === 2 && data.row.index >= 0) {
            data.cell.styles.fontStyle = 'bold';
          }
        },
      });
      // Save the PDF with a name
      doc.save('Route_Details.pdf');
    },

    exportExcel() {
      const tableData = this.filteredRoutes.map((item, index) => ({
        '#': index + 1,
        'Category': item.route_category,
        'Agent Name': `${item.agent.name} \n Reg num: ${item.agent?.registration_num}`, // Add Reg num below the Agent Name
        'Route Name': item.route_name,
        'Route Status': item.overallStatus,
        'Assigned Date': item.assign_date,
        'Province': item.province,
        'District': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set the alignment for "Agent Name" column to wrap text
      const agentNameCol = worksheet['B2']; // Assuming "Agent Name" column is at B
      if (agentNameCol) {
        worksheet['B2'].s = {alignment: {wrapText: true}};
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Route Details');
      XLSX.writeFile(workbook, 'Route_Details.xlsx');
    },

    viewItem(item) {
      console.log('items', item)
      this.id = item.id
      this.updateSharedDataRoutes(item);
      this.view_Dialog = true;
    },

    editItem(item) {
      // Functionality to edit item details
      this.id = item.id
      this.updateSharedDataRoutes(item);
      this.editDialog = true;
    },

    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },

    backPageRoute() {
      this.$router.push('/dashboard');
    },

    roteManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.view_Dialog = false;
      this.editDialog = true;
    },

    r2() {
      this.view_Dialog = false;
      this.routes = [...this.routes.filter(route => route.id !== this.id)];
      this.fetchRoutes()
      this.id = null;
    },

    setupEventListeners() {
      EventBus.on('routeRecodeManagementButtonClick', this.roteManagementButtonClick);
      EventBus.on('routeRecodeManagementButtonClick2', this.r2);
    },

    removeEventListeners() {
      EventBus.off('routeRecodeManagementButtonClick', this.roteManagementButtonClick);
      EventBus.off('routeRecodeManagementButtonClick2', this.r2);
    },

    toggleSwitches() {
      this.showSwitches = !this.showSwitches;  // Toggle the visibility of the switches
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.categoryOptions = response.data;
        console.log("API Response category list:", response.data);
      })
      .catch(error => {
        console.error('Error fetching main categories:', error);
      });
    },

    clearDateRange() {
      this.dates = [];
    },

    clearRouteStatus() {
      this.selectedRouteStatus = [];
    },

    clearCategory() {
      this.selectedCategories = [];
    }
  },

  mounted() {
    const dashboardId = 8;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);
    this.setupEventListeners();
    this.fetchRoutes(); // Fetch routes when component is mounted
    this.fetchMainCategories();
  },

  beforeUnmount() {
    this.removeEventListeners();
  },

  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => {
        // Observer to prevent layout shifting
        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {});
          ro.observe(document.body);
        }

        // Helper to format dates
        const formatDate = (date) => {
          const d = new Date(date);
          return d.toISOString().split('T')[0];
        };

        // Date range filter
        const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

        const withinDateRange =
            !startDate || !endDate ||
            (route.assign_date &&
                formatDate(route.assign_date) >= startDate &&
                formatDate(route.assign_date) <= endDate);

        // Category filter
        const matchesCategory =
            !this.selectedCategories.length ||
            this.selectedCategories.includes(route.route_category);

        // Outlet status calculation
        const outletStatuses = route.selected_outlets.map((outlet) => outlet.status);
        const allOutletsVisited = outletStatuses.every((status) => status === 'Visited');
        const hasVisitedAndNotVisited = outletStatuses.includes('Visited') && outletStatuses.includes('Not Visited');

        let overallStatus;
        if (hasVisitedAndNotVisited) {
          overallStatus = 'Pending';
        } else if (allOutletsVisited) {
          overallStatus = 'Visited';
        } else {
          overallStatus = 'Not Visited';
        }

        route.overallStatus = overallStatus;

        // Route status filter
        const matchesRouteStatus =
            !this.selectedRouteStatus.length ||
            this.selectedRouteStatus.includes(overallStatus);

        // Search filter
        const searchFields = ['route_name', 'agent.registration_num', 'assign_date'];
        const matchesSearchQuery = !this.searchQuery || searchFields.some((field) => {
          const fieldValue = this.getFieldValue(route, field);
          return fieldValue && fieldValue.toLowerCase().includes(this.searchQuery.toLowerCase());
        });

        // Combine all filters
        return withinDateRange && matchesCategory && matchesRouteStatus && matchesSearchQuery;
      });
    },

  },

  watch: {
    dates() {
      this.updateDateRange();
    }
  },

}
</script>

<style>
  @import "../../styles/route_management/routes_list.scss";
</style>