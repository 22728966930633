<template>
  <div id="app_dashboard_admin">
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <v-card class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md">
            <v-card-text>
              <h6 class="text-h6 font-weight-bold">{{ collectionsActual }}</h6>
              <p class="font-weight-regular custom_page_main_sub_title">
                <v-icon color="#156CF7" size="small">mdi-square-rounded</v-icon>
                Collections
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <v-card class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md">
            <v-card-text>
              <h6 class="text-h6 font-weight-bold">{{ salesActual }}</h6>
              <p class="font-weight-regular custom_page_main_sub_title">
                <v-icon color="#BB0000" size="small">mdi-square-rounded</v-icon>
                Sales
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <div ref="chartdiv" style="width: 100%; height: 400px;" class="mt-2"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';

am4core.useTheme(am4themes_animated);

export default {
  name: 'LineChartDashboardComponentThree',
  props:{
    dates: {
      type: Array, // Assuming dates is an array; adjust the type as needed
      required: true
    }
  },
  data() {
    return {
      collectionsTarget: 0,
      salesTarget: 0,
      collectionsActual: 0,
      salesActual: 0,
      chartData:[],
    };
  },

  watch:{
    dates(){
        
        this.getSalesAndCollectionData();
      }
  },

  mounted() {
    this.getSalesAndCollectionData();
  },

   methods: {
    async getSalesAndCollectionData(){

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem('userId');

    axios.post('api/web/getChartData', { userId: userId, dateFilter: this.dates }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
        const data = response.data;
        console.log("getChartData is ",data);

        let totalCollectionsActual = data.total_collection_amount;
        let totalSalesActual = data.total_sales_amount;

        const scale = totalCollectionsActual < 100000 && totalSalesActual < 100000 ? 1000 : 1000000;
        this.unit = scale === 1000 ? " K" : " M"; // Store unit in `this` for global access

        totalCollectionsActual /= scale;
        totalSalesActual /= scale;

        this.collectionsActual = totalCollectionsActual.toFixed(1) + this.unit;
        this.salesActual = totalSalesActual.toFixed(1) + this.unit;
        this.collectionsTarget = (totalCollectionsActual * 1.1).toFixed(1) + this.unit;
        this.salesTarget = (totalSalesActual * 1.1).toFixed(1) + this.unit;

        // const salesData = data.sales.map(entry => ({
        //   date: new Date(entry.created_date),
        //   sales_actual: parseFloat(entry.sales_amount || 0) / scale,
        //   sales_target: parseFloat(entry.sales_amount || 0) * 1.1 / scale,
        //   collections_actual: 0,
        //   collections_target: 0
        // }));

        // const collectionsData = data.collections.map(entry => ({
        //   date: new Date(entry.created_date),
        //   collections_actual: parseFloat(entry.paid_amount || 0) / scale,
        //   collections_target: parseFloat(entry.paid_amount || 0) * 1.1 / scale,
        //   sales_actual: 0,
        //   sales_target: 0
        // }));

        // const year = new Date().getFullYear();
        // const filledSalesData = this.fillMissingMonths(salesData, year);
        // const filledCollectionsData = this.fillMissingMonths(collectionsData, year);

        // this.chartData = [...filledSalesData, ...filledCollectionsData];
        this.chartData = data.chartData;

        this.mountSalesAndCollectionChart();
      })
      .catch(error => console.error("Error fetching data:", error));
    },

    mountSalesAndCollectionChart(){
    let that = this;
    var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart3D);
            chart.fontSize = 12;
            chart.logo.disabled = true;
            // Add data
            chart.data = that.chartData;

            // Create axes
            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "month";  // x-axis represents months
            categoryAxis.title.text = "Month";

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Amount (Rs)";
            valueAxis.min = 0;

            // Create sales series
            var salesSeries = chart.series.push(new am4charts.ColumnSeries3D());
            salesSeries.dataFields.valueY = "sales";
            salesSeries.dataFields.categoryX = "month";
            salesSeries.name = "Sales";
            salesSeries.tooltipText = "{name}: [bold]Rs.{valueY}[/]";
            salesSeries.columns.template.fill = am4core.color("#BB0000");  // Set fill color to red
            salesSeries.columns.template.stroke = am4core.color("#BB0000");  // Set stroke color to red

            // Create collections series
            var collectionsSeries = chart.series.push(new am4charts.ColumnSeries3D());
            collectionsSeries.dataFields.valueY = "collections";
            collectionsSeries.dataFields.categoryX = "month";
            collectionsSeries.name = "Collections";
            collectionsSeries.tooltipText = "{name}: [bold]Rs.{valueY}[/]";
            collectionsSeries.columns.template.fill = am4core.color("#156CF7");  // Set fill color to blue
            collectionsSeries.columns.template.stroke = am4core.color("#156CF7");  // Set stroke color to blue

            // Add cursor
            chart.cursor = new am4charts.XYCursor();
  },

    fillMissingMonths(data, year){
      const months = Array.from({ length: 12 }, (_, i) => new Date(year, i, 1));
      const filledData = months.map(date => {
        const existingEntry = data.find(entry => {
          const entryDate = new Date(entry.date);
          return entryDate.getMonth() === date.getMonth() && entryDate.getFullYear() === date.getFullYear();
        });

        return (
            existingEntry || {
              date,
              sales_actual: 0,
              sales_target: 0,
              collections_actual: 0,
              collections_target: 0
            }
        );
      });
      return filledData;
    },
   },
   

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>


<style>
  @import "../../styles/dashboard/dashboard.scss";
</style>