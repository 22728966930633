<template>
  <div id="app_dashboard_admin">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <v-card class="mx-auto custom_card_box_dashboard" elevation="0" border="sm opacity-1" rounded="md">
            <v-card flat>
              <template v-slot:title>
                <p class="ml-5 font-weight-bold custom_page_component_main_title">SALES & COLLECTIONS ANALYSIS</p>
              </template>
              <template v-slot:subtitle>
<!--                <p class="ml-5 custom_page_main_sub_title_very_small_red">LAST 30 DAYS</p>-->
              </template>


              <template v-slot:append>
                <v-date-input v-model="dates" class="mt-6 custom_input_field_color" style="width: 330px!important;"
                              multiple="range" prepend-inner-icon="$calendar" prepend-icon=""
                              label="Date Range" density="comfortable" variant="outlined" :max="maxDate"
                ></v-date-input>

                 <v-btn
                    class="ml-4 custom_btn_secondary"
                    icon rounded
                    variant="outlined"
                    v-if="dates.length > 0"
                    @click="resetDateRange()"
                >
                  <v-icon>mdi mdi-refresh</v-icon>
                  <v-tooltip activator="parent" location="top">Reset</v-tooltip>
                </v-btn>


                <v-btn class="custom_pdf_btn ml-6 mr-4" variant="outlined" @click="generatePdf">
                  <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
                </v-btn>
              </template>
            </v-card>

            <v-divider></v-divider>
            <div ref="contentToExport">
              <LineChartDashboardComponentThree ref="chartComponent" :dates="dates"/>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LineChartDashboardComponentThree from "@/components/CHART/dashboardLineChartComponentThree.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      maxDate: new Date().toISOString().substr(0, 10), // Current date in 'YYYY-MM-DD' format
      dates:[],
    };
  },
  name: "salesAndCollectionAnalysis",
  components: {
    LineChartDashboardComponentThree,
  },
  methods: {

    resetDateRange() {
      this.dates = [];
      this.$refs.chartComponent.getSalesAndCollectionData(); // Call child method using ref
    },

    async generatePdf() {
      const element = this.$refs.contentToExport;

      if (!element) {
        console.error("Reference to contentToExport not found.");
        return;
      }

      try {
        // Convert content to canvas with higher scale
        const canvas = await html2canvas(element, {
          scale: 3, // Higher scale for better resolution
          useCORS: true, // Handles cross-origin issues
        });

        // Get canvas dimensions
        const imgData = canvas.toDataURL("image/png");
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // Create PDF with same dimensions as canvas
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [canvasWidth, canvasHeight],
        });

        pdf.addImage(imgData, "PNG", 0, 0, canvasWidth, canvasHeight);

        // Save the PDF
        pdf.save("Sales_and_Collections_Analysis.pdf");
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  },
};
</script>

<style>
@import "../../styles/dashboard/dashboard.scss";
</style>
