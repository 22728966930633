<template>
  <v-app id="app_product_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">PRODUCTS LIST</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse orders placed in your store</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="addProductsPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD PRODUCTS
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="selectedMainCategory" :clear-icon="selectedMainCategory ? 'mdi-close-circle' : null"
                  :items="mainCategories"
                  clearable item-title="name" item-value="id" label="Main Category" placeholder="Select main category"
                  variant="outlined" @update:modelValue="fetchSubCategories" class="custom_input_field_color"
                  density="comfortable"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="selectedSubCategory" :clear-icon="selectedSubCategory ? 'mdi-close-circle' : null"
                  :items="subCategories"
                  clearable item-title="sub_category_name" item-value="sub_category_name" label="Sub Category"
                  multiple placeholder="Select sub category" variant="outlined" class="custom_input_field_color"
                  density="comfortable"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" clearable
                            hide-details label="Search" placeholder="Search Products" variant="outlined"
                            class="custom_input_field_color" density="comfortable"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table density="comfortable"
                        v-model:page="page" :headers="headers" :loading="loading"
                        :items="filteredItems" :items-per-page="itemsPerPage" class="custom_table_normal"
                        @page="fetchProducts" @page-count="totalPages = $event">

            <template v-slot:[`item.product_title`]="{ item }">
              <div class="product-column">
                <v-row>
                  <v-col cols="5">
                    <img
                        v-if="item.product_image && item.product_image.length > 0"
                        :src="`${imageURL}/${item.product_image[0].image_url}`"
                        aspect-ratio="1" class="product_image mt-2 mb-1"
                        @click="selectImage(`${imageURL}/${item.product_image[0].image_url}`)"
                    />
                  </v-col>

                  <v-col class="d-flex align-center" cols="7">
                    <span class="product-title">{{ item.product_title }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>


            <template v-slot:[`item.available_stock`]="{ item }">
              <div class="product-column">
                <v-row>
                  <v-col cols="12">
                    <span>{{ item.available_stock }}</span>
                    <span class="ml-2">{{ item.unit }}</span> <!-- Adds space between the available stock and unit -->
                  </v-col>
                </v-row>
              </div>
            </template>


            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_product_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.write" @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View Products Dialog -->
      <v-dialog v-model="view_product_dialog" fullscreen id="app_product_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="view_product_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">PRODUCTS DETAILS</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0 mb-2">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="selectedImage ? selectedImage : ''" aspect-ratio="1"
                               class="product_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <p class="font-weight-bold custom_page_component_main_title">{{ product.product_title }}</p>

                        <div class="d-flex align-center">
                          <!-- Show only price if discount_rate is 0, 0.00, or null -->
                          <p
                              v-if="!product.discount_rate || product.discount_rate === 0 || product.discount_rate === '0.00'"
                              style="color: #BB0000 !important;"
                              class="font-weight-bold custom_page_component_main_title">
                            LKR. {{ product.price }}
                          </p>

                          <!-- Show discounted price details if discount_rate is valid (greater than 0) -->
                          <template v-else>
                            <p
                                style="color: #BB0000 !important;"
                                class="font-weight-bold custom_page_component_main_title text-decoration-line-through">
                              LKR. {{ product.price }}
                            </p>
                            <p class="font-weight-medium text-caption ml-4 mt-1">&nbsp;&nbsp;
                              Rs. {{ oldPrice }} &nbsp;&nbsp;
                            </p>
                            <p
                                style="color: #BB0000 !important;"
                                class="font-weight-bold text-caption">&nbsp;&nbsp;
                              {{ product.discount_rate }}%
                            </p>
                          </template>
                        </div>

                        <h4 class="d-flex align-center mt-1">
                          <p class="font-weight-bold custom_page_main_sub_title">STOCK</p>
                          <p
                              style="color: #BB0000 !important;"
                              class="font-weight-bold custom_page_main_sub_title">
                            &nbsp;&nbsp; {{ product.available_stock }}
                          </p>
                        </h4>

                        <v-row class="mt-1">
                          <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                            <v-img
                                :src="`${imageURL}/${image.image_url}`"
                                aspect-ratio="1"
                                class="custom_thumbnail_image"
                                @click="selectImage(`${imageURL}/${image.image_url}`)"
                            ></v-img>
                          </v-col>
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                          v-if="permissions.write" class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined" @click="editItem(product)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                          v-if="permissions.delete" class="custom_delete_btn" icon rounded
                          size="small" variant="outlined" @click="confirmDelete(product)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row class="mb-2">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="product.product_title" density="comfortable"
                        class="px-3 custom_input_field_class_view custom_input_field_color" label="Product Name"
                        readonly variant="outlined">
                    </v-text-field>

                    <v-text-field
                        v-model="product.main_category"
                        class="px-3 custom_input_field_class_view  custom_input_field_color"
                        label="Main Category" readonly variant="outlined" density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-model="product.sub_category"
                        class="px-3 custom_input_field_class_view  custom_input_field_color" label="Sub Category"
                        readonly variant="outlined" density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-model="product.price" class="px-3 custom_input_field_class_view  custom_input_field_color"
                        label="Unit Price" readonly
                        variant="outlined" density="comfortable"
                    ></v-text-field>
                    <!-- <v-textarea
                        v-model="sanitizedDescription" class="px-3 custom_input_field_class_view  custom_input_field_color"
                        label="Product Description" readonly variant="outlined"  density="comfortable"
                    ></v-textarea> -->

                    <div class="px-3">
                      <p class="custom_page_main_sub_title">Product Description</p>
                      <v-divider class="mt-3 mb-4"></v-divider>
                      <v-card color="#F7F7F7" flat border="sm opacity-1" outlined density="comfortable">
                        <v-card-text>
                          <div v-html="getSanitizedHTML(product.product_desc)" class=" ml-3 ql-container ql-snow"></div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="product.unit" class="px-3 custom_input_field_class_view custom_input_field_color"
                        label="Unit" readonly
                        variant="outlined" c density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-model="product.size" class="px-3 custom_input_field_class_view custom_input_field_color"
                        label="Number of Units" readonly variant="outlined" density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-model="product.color" class="px-3 custom_input_field_class_view custom_input_field_color"
                        label="Color" readonly
                        variant="outlined" density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-model="product.available_stock"
                        class="px-3 custom_input_field_class_view custom_input_field_color"
                        label="Available Stock (Quantity)" readonly
                        variant="outlined" density="comfortable"
                    ></v-text-field>
                    <v-text-field
                        v-if="product.discount_rate !== null"
                        v-model="product.discount_rate"
                        :value="formattedDiscountRate"
                        class="px-3 custom_input_field_class_view custom_input_field_color"
                        label="Discount Rate"
                        readonly
                        variant="outlined"
                        density="comfortable"
                    />

                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View products dialog -->

      <!-- Edit products dialog -->
      <v-dialog v-model="edit_product_dialog" fullscreen id="app_product_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="clesoDialog">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15 custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE PRODUCTS</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="selectedImage ? selectedImage : ''" aspect-ratio="1"
                               class="product_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <p class="font-weight-bold custom_page_component_main_title">{{ product.product_title }}</p>
                        <div class="d-flex align-center">
                          <p
                              v-if="!product.discount_rate || product.discount_rate === 0 || product.discount_rate === '0.00'"
                              style="color: #BB0000 !important;"
                              class="font-weight-bold custom_page_component_main_title">
                            LKR. {{ product.price }}
                          </p>
                          <template v-else>
                            <p
                                style="color: #BB0000 !important;"
                                class="font-weight-bold custom_page_component_main_title text-decoration-line-through">
                              LKR. {{ product.price }}
                            </p>
                            <p class="font-weight-medium text-caption ml-4 mt-1">&nbsp;&nbsp;
                              Rs. {{ oldPrice }} &nbsp;&nbsp;
                            </p>
                            <p
                                style="color: #BB0000 !important;"
                                class="font-weight-bold text-caption">&nbsp;&nbsp;
                              {{ product.discount_rate }}%
                            </p>
                          </template>
                        </div>
                        <h4 class="d-flex align-center mt-1">
                          <p class="font-weight-bold custom_page_main_sub_title">STOCK</p>
                          <p style="color: #BB0000 !important;" class="font-weight-bold custom_page_main_sub_title">
                            &nbsp;&nbsp; {{ product.available_stock }}</p>
                        </h4>
                        <v-row class="mt-1">
                          <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                            <v-img
                                :src="`${imageURL}/${image.image_url}`"
                                aspect-ratio="1"
                                class="custom_thumbnail_image"
                                @click="selectImage(`${imageURL}/${image.image_url}`)"
                            ></v-img>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-alert v-if="isProductDisabled" type="error"
                                   class="font-weight-bold custom_page_component_main_title" border="left"
                                   colored-border>
                            The product is already sold, so you cannot edit the details.
                          </v-alert>
                        </v-row>

                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateProduct">UPDATE
                        PRODUCT
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="product.product_title" :rules="[v => !!v || 'Product name is required']"
                        class="custom_input_field_color" label="Product Name *" required variant="outlined"
                        density="comfortable"
                        :readonly="isProductDisabled"
                    >
                    </v-text-field>

                    <v-autocomplete
                        v-model="product.main_category"
                        :clearable="!isProductDisabled && form.mainCategory !== null && form.mainCategory !== ''"
                        :items="mainCategories" :rules="[v => !!v || 'Main category is required']"
                        class="mt-2 custom_input_field_color" item-title="name" item-value="id"
                        label="Main Category *" placeholder="Select main category" required variant="outlined"
                        @update:modelValue="fetchSubCategories()" density="comfortable" :readonly="isProductDisabled"
                    ></v-autocomplete>

                    <v-autocomplete
                        v-model="product.sub_category" :items="subCategories" :loading="productLoading"
                        :rules="[v => !!v || 'Sub category is required']"
                        class="mt-2 custom_input_field_color" item-title="sub_category_name"
                        item-value="sub_category_name" label="Sub Category *" required
                        variant="outlined" density="comfortable" :readonly="isProductDisabled"
                    ></v-autocomplete>

                    <v-text-field
                        v-model="product.price" :rules="[v => !isNaN(Number(v)) || 'Price must be a number']"
                        class="mt-2 custom_input_field_color"
                        label="Unit Price *" required variant="outlined" density="comfortable"
                        :readonly="isProductDisabled"
                    ></v-text-field>

                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description </p>
                        <v-card class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="355"
                                min-height="355">
                          <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                          <v-divider></v-divider>
                          <Editor :readonly="isProductDisabled" v-model="product.product_desc"
                                  :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated"/>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-autocomplete
                        v-model="product.unit" :items="units" class="custom_input_field_color" item-title="Unit"
                        item-value="name" label="Unit" required
                        variant="outlined" density="comfortable"
                        :readonly="isProductDisabled"
                        :filterable="false"
                    >
                    </v-autocomplete>
                    <v-text-field
                        v-model="product.size" class="mt-2 custom_input_field_color" label="Numbers of Units"
                        variant="outlined" density="comfortable"
                        :readonly="isProductDisabled"
                        type="number"
                        :rules="[v => !isNaN(Number(v)) || 'Number of Units must be a number', v => Number(v) >= 0 || 'Number of Units must be 0 or higher']"
                    ></v-text-field>

                    <v-divider class="mb-6 mt-5"></v-divider>

                    <v-chip size="small">Previous Color: {{ product.color }}</v-chip>
                    <br>
                    <v-chip size="small" class="mt-2" v-if="changeColor">Selected Color: {{
                        getColorName(changeColor)
                      }}
                    </v-chip>

                    <v-divider class="mb-6 mt-5"></v-divider>

                    <v-text-field
                        class="mt-3 custom_input_field_color"
                        v-model="changeColor"
                        density="comfortable"
                        label="Select Color"
                        variant="outlined"
                        @click="dialog = true"
                    />

                    <v-dialog v-model="dialog" max-width="350px" id="app_product_list">
                      <v-card rounded="md">
                        <div class="pa-4">
                          <v-card color="#F0F0F0" flat>
                            <template v-slot:prepend>
                              <v-card-title class="text-subtitle-1 font-weight-bold">SET COLOR CODE</v-card-title>
                            </template>
                            <v-spacer></v-spacer>
                            <template v-slot:append>
                              <v-btn @click="dialog = false" icon="mdi-close" size="x-small"></v-btn>
                            </template>
                          </v-card>

                          <!-- Color Code Input -->
                          <v-text-field
                              v-model="changeColor"
                              class="mt-4 custom_input_field_color"
                              label="Color Code"
                              density="comfortable"
                              placeholder="Type your color code here"
                              variant="outlined"
                          ></v-text-field>

                          <!-- Mode Selector -->
                          <!-- <v-autocomplete
                            v-model="mode"
                            :items="modes"
                            class="custom_input_field_color"
                            density="comfortable"
                            variant="outlined"
                          ></v-autocomplete> -->

                          <!-- Color Picker -->
                          <v-color-picker
                              width="318px"
                              v-model="changeColor"
                              v-model:mode="mode"
                          ></v-color-picker>

                          <v-divider class="mt-3"></v-divider>

                          <div class="float-end mt-4">
                            <v-btn size="large" class="custom_btn_primary" @click="dialog = false">Done</v-btn>
                          </div>
                        </div>
                      </v-card>
                    </v-dialog>


                    <v-text-field
                        v-model="product.available_stock"
                        :rules="[v => !isNaN(Number(v)) || 'Available stock required and must be a number']"
                        class="mt-2 custom_input_field_color"
                        label="Available Stock (Quantity) *" required density="comfortable"
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field v-model="product.discount_rate"
                                  :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
                                  class="mt-2 custom_input_field_color" label="Discount Rate *" variant="outlined"
                                  density="comfortable"
                    ></v-text-field>

                    <v-card flat>
                      <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">Product Images</p>

                      <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop"
                           @dragover.prevent="onDragOver">
                        <input ref="fileInput" accept="image/*" class="hidden-file-input" multiple type="file"
                               @change="onFileChange"/>
                        <div v-for="(image, index) in previewImages" :key="index" class="image-card-container">
                          <v-card class="image-card">
                            <v-img :alt="'Image ' + (index + 1)" :src="image" class="image-preview"></v-img>
                            <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage(index)">
                              <v-icon color="red" size="small">mdi-delete</v-icon>
                            </v-btn>
                          </v-card>
                        </div>
                        <div v-if="previewImages.length === 0" class="placeholder-container">
                          <v-icon size="36">mdi-cloud-upload</v-icon>
                          <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>
                        </div>
                        <row v-if="previewImages.length >= 5" class="max-images-warning">
                          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                            <span style="color: #BB0000;">Maximum 5 images allowed</span>
                          </v-col>
                        </row>
                      </div>
                    </v-card>

                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit products dialog -->

      <!-- Delete product confirmation dialog -->
      <v-dialog v-model="delete_product_dialog" max-height="500" max-width="500" persistent id="app_product_list">
        <v-card rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE PRODUCT</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="delete_product_dialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this product ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This product will be deleted immediately. Once deleted, it can no longer be used in any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="delete_product_dialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete product confirmation dialog -->

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import ntc from 'ntc';
import {getPermissions} from "@/plugins/permissions";
import DOMPurify from 'dompurify';


//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import {i18nChangeLanguage} from '@wangeditor/editor'

i18nChangeLanguage('en')
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
//This is related to wangeditor ---END

export default {
  components: {
    Editor,
    Toolbar,
  },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = {placeholder: 'Type here...'}

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END

  name: 'ProductsList',

  data() {
    return {
      changeColor: "",
      isProductDisabled: false,
      dataDescription: '',
      formatting: [],
      imageURL: "",
      showColorPicker: false,
      selectedColor: '#000',
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },

      loading: false,
      form: {images: []},
      //search
      search: '',
      selectedMainCategory: null,
      selectedSubCategory: [],


      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,

      headers: [
        {text: 'PRODUCT NAME', value: 'product_title', title: 'PRODUCT NAME', sortable: true},
        {text: 'MAIN CATEGORY', value: 'main_category', title: 'MAIN CATEGORY', sortable: true},
        {text: 'SUB CATEGORY', value: 'sub_category', title: 'SUB CATEGORY', sortable: true},
        {text: 'PRICE', value: 'price', title: 'PRICE(LKR)', sortable: true},
        {text: 'STOCK AVAILABILITY', value: 'available_stock', title: 'STOCK AVAILABILITY', sortable: true},
        {text: 'ACTION', value: 'action', sortable: false, title: 'ACTION'},
      ],

      // Dialog state
      delete_product_dialog: false,
      view_product_dialog: false,
      productMainCatagary: '',
      sanitizedDescription: '',

      edit_product_dialog: false,
      typeDescription: "",
      productLoading: false,
      selectedProductId: null,
      product: {},

      numericRule: value => !isNaN(parseFloat(value)) || 'Price must be a number',
      item: [],
      units: [
        {Unit: 'Grams  (g)', name: 'g'},
        {Unit: 'Kilograms  (kg)', name: 'kg'},
        {Unit: 'Pound (lb)', name: 'lb'},
        {Unit: 'Liter  (L)', name: 'L'},
        {Unit: 'Milliliter (mL)', name: 'ml'},
        {Unit: 'Gallon', name: 'Gallon'},
        {Unit: 'Meters  (m)', name: 'm'},
        {Unit: 'Centimeters  (cm)', name: 'cm'},
        {Unit: 'Piece (pcs)', name: 'pcs'},
        {Unit: 'Box', name: 'Box'},
        {Unit: 'Packet (pkt)', name: 'pkt'},
        {Unit: 'Set ', name: 'Set'},
        {Unit: 'Dozen (dz)', name: 'dz'},
        {Unit: 'Pair', name: 'Pair'},
        {Unit: 'Roll', name: 'Roll'},
        {Unit: 'Bundle', name: 'Bundle'},
        {Unit: 'Unit', name: 'Unit'},
        {Unit: 'Case', name: 'Case'},
        {Unit: 'Carton', name: 'Carton'},
        {Unit: 'Tonne (ton)', name: 'ton'},
        {Unit: 'Barrel', name: 'Barrel'},
        {Unit: 'Can', name: 'Can'}
      ],

      mainCategories: [],
      subCategories: [],
      // List of products
      items: [],
      // Additional fields
      product_image: [], // List of images for upload
      // Image handling
      previewImages: [],
      selectedImage: '',

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      productcheck: null,
      dialog: false,
      modes: ['RGB', 'HSL'],
      removedImages: [],
      permissions: {},
    };
  },

  created() {
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    // this.fetchCategories();
    this.fetchProducts();
    this.fetchMainCategories();
  },

  methods: {


    getSanitizedHTML(html) {
      return DOMPurify.sanitize(html);
    },
    convertToPlainText(html) {
      const sanitizedHTML = this.getSanitizedHTML(html);
      // Create a temporary DOM element to strip HTML tags
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = sanitizedHTML;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
    applyFormat(format) {
      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    getColorName(hex) {
      const color = ntc.name(hex);
      return color[1];
    },

    sizeValidation(value) {
      const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
      return sizePattern.test(value.trim());
    },

    exportExcel() {
      // Map filtered data for Excel format with additional formatting
      const tableData = this.filteredItems.map((item) => ({

        'Product Name': item.product_title,
        'Main Category': item.main_category,
        'Sub Category': item.sub_category,
        'Price': item.price,
        'Stock Availability': item.available_stock,
        'Color': item.color,
        'Discount Rate': `${item.discount_rate}%`,
        'Size': item.size,
        'Unit': item.unit,
      }));

      // Convert JSON data to Excel sheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set column widths
      const columnWidths = [
        {wpx: 50},
        {wpx: 150},
        {wpx: 120},
        {wpx: 120},
        {wpx: 80},
        {wpx: 100},
        {wpx: 80},
        {wpx: 110},
        {wpx: 80},
        {wpx: 70},
      ];
      worksheet['!cols'] = columnWidths;

      // Style header cells
      Object.keys(worksheet).forEach(cell => {
        if (cell.startsWith('A1')) {
          worksheet[cell].s = {
            font: {bold: true, color: {rgb: "FFFFFF"}},
            fill: {fgColor: {rgb: "1F4E78"}},
            alignment: {horizontal: "center"},
          };
        }
      });

      // Create workbook and append the sheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Products Analysis');

      // Write and download Excel file
      XLSX.writeFile(workbook, 'products_analysis.xlsx');
    },


    exportPDF() {
      const doc = new jsPDF();

      // Set the document title with larger font size and bold style
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text('Product Analysis', 14, 20);

      // Add a subtitle or additional text if needed
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      doc.text('A comprehensive analysis of product data', 14, 28);

      // Prepare the data for the table using filtered items
      const tableData = this.filteredItems.map((item, index) => [
        index + 1,
        item.product_title,
        item.main_category,
        item.sub_category,
        item.price,
        item.available_stock,
        item.color,
        `${item.discount_rate}%`,
        item.size,
        item.unit,
      ]);

      // Define the columns for the table with specific widths
      const tableColumns = [
        {header: '#', dataKey: 'index'},
        {header: 'Product Name', dataKey: 'product_title'},
        {header: 'Main Category', dataKey: 'main_category'},
        {header: 'Sub Category', dataKey: 'sub_category'},
        {header: 'Unit Price', dataKey: 'price'},
        {header: 'Stock', dataKey: 'available_stock'},
        {header: 'Color', dataKey: 'color'},
        {header: 'Discount Rate', dataKey: 'discount_rate'},
        {header: 'Number Of Units', dataKey: 'size'},
        {header: 'Unit', dataKey: 'unit'},
      ];

      // Generate table with custom styles
      doc.autoTable({
        head: [tableColumns.map(col => col.header)],
        body: tableData,
        startY: 35,
        startX: 5,
        headStyles: {fillColor: [41, 128, 185], textColor: [255, 255, 255], fontSize: 8},
        bodyStyles: {fontSize: 8},
        columnStyles: {
          0: {cellWidth: 10},
          1: {cellWidth: 30},
          2: {cellWidth: 25},
          3: {cellWidth: 25},
          4: {cellWidth: 15},
          5: {cellWidth: 20},
          6: {cellWidth: 15},
          7: {cellWidth: 20},
          8: {cellWidth: 15},
          9: {cellWidth: 15},
        },
        styles: {cellPadding: 3, overflow: 'linebreak'},
        theme: 'striped',
        didDrawPage: function (data) {
          let pageSize = doc.internal.pageSize;
          let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text('Page ' + doc.internal.getNumberOfPages(), data.settings.margin.left, pageHeight - 10);
          doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 15);
        },
      });


      // Save the PDF with a meaningful filename
      doc.save('Product_Analysis_Report.pdf');
    },


    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/mainProduct', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
              return {
                id: item.id,
                name: item.main_category
              };
            });
            console.log('Main categories:', this.mainCategories);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },

    clesoDialog() {
      this.isProductDisabled = false;
      this.edit_product_dialog = false;
      this.selectedMainCategory = null;
      this.product.main_category = null;
    },

    fetchSubCategories() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      this.productLoading = true;
      this.selectedSubCategory = null;
      this.product.sub_category = null;
      let mainCatagoryId = this.selectedMainCategory || this.product.main_category
      // const mainCatagoryId = this.selectedMainCategory;
      // console.log('Main Category ID:', this.product.main_category);
      // console.log('Main Category ID t:', this.selectedMainCategory);

      console.log('MainID', mainCatagoryId);
      console.log('mainCatagaryName', this.product.main_category);

      axios.post(`/api/subcatagoryProduct`,
          {
            userId: userId,
            mainCatagoryId: mainCatagoryId,
            mainCatagaryName: this.product.main_category
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);

            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);

            this.product.main_category_name = selectedCategory ? selectedCategory.name : '';

            this.selectedMainCategory = selectedCategory ? selectedCategory.name : '';
            this.productLoading = false;
            console.log('Selected Main Category Name:', this.product.main_category_name);
          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
            this.productLoading = false;
          });
    },

    getCategoryName(categoryId) {
      console.log('Looking up categoryId:', categoryId);
      if (typeof categoryId === 'string') {
        categoryId = parseInt(categoryId, 10); // Convert string ID to number if needed
      }
      const category = this.mainCategories.find(cat => cat.id === categoryId);
      console.log('Found category:', category);
      return category ? category.name : 'Unknown';
    },

    //Helper method to show snackbar alerts
    showSnackAlert(type, message) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = type;
      this.snackAlert.snackMessage = message;
    },

    async updateProduct() {

      // Perform individual field validation
      if (!this.product.product_title) {
        this.showSnackAlert('error', 'Product title is required.');
        return;
      }


      if (!this.productMainCatagary) {
        this.showSnackAlert('error', 'Main category is required.');
        return;
      }

      if (!this.product.sub_category) {
        this.showSnackAlert('error', 'Sub-category is required.');
        return;
      }


      if (!this.product.price || isNaN(this.product.price) || this.product.price <= 0) {
        this.showSnackAlert('error', 'Valid price is required.');
        return;
      }


      if (this.product.discount_rate === null || this.product.discount_rate < 0) {
        this.product.discount_rate = 0;
      }


      if (!this.product.available_stock || isNaN(this.product.available_stock) || this.product.available_stock < 0) {
        this.showSnackAlert('error', 'Available stock must be 0 or greater.');
        return;
      }


      if (!this.previewImages.length) {
        this.showSnackAlert('error', 'At least one product image is required.');
        return;
      }

      if ((!this.product.unit || this.product.unit.length === 0) && (this.product.size === null || isNaN(this.product.size) || this.product.size < 0)) {
        this.product.unit = "Unit";  // Assign default value
        this.product.size = 0;  // Assign default value
      }

      if (this.product.size === null || this.product.size < 0) {
        this.product.size = 0;
      }

      if (this.product.unit === null) {
        this.product.unit = "Unit";
      }

      const formData = new FormData();
      formData.append('productId', this.product.id);
      formData.append('productTitle', this.product.product_title);
      formData.append('productDescription', this.product.product_desc);

      if (this.product.main_category_name !== undefined) {
        formData.append('mainCatagory', this.product.main_category_name);
      }
      formData.append('mainCatagory', this.productMainCatagary);

      formData.append('subCatagory', this.product.sub_category);
      formData.append('unit', this.product.unit);
      formData.append('size', this.product.size);
      // formData.append('color', this.product.color);


      if (this.changeColor) {
        // If changeColor is defined, get the color name and append it to formData
        const colorName = this.getColorName(this.changeColor);
        formData.append('color', colorName);

      } else {
        // Otherwise, use the color from this.product
        const colorName = this.product.color;
        formData.append('color', colorName);

      }

      formData.append('price', this.product.price);
      formData.append('discountRate', this.product.discount_rate);
      formData.append('availableStaock', this.product.available_stock);
      formData.append('userId', localStorage.getItem('userId'));

      // Convert images to Base64 and append them to FormData
      try {

        // Convert new images to base64
        const base64Images = await Promise.all(
            this.form.images.map(file => this.convertToBase64(file))
        );

        // Filter out removed images from previously existing images
        const previousImages = this.product.product_image
            .filter(img => !this.removedImages.includes(img.image_url))
            .map(img => ({image: img.image_url}));

        // Combine previous images and new base64 images
        const allImages = [
          ...previousImages, // Only the images that were not removed
          ...base64Images.map(image => ({image})) // New images (converted to base64)
        ];

        const imagesJson = JSON.stringify(allImages);
        formData.append('productImage', imagesJson);

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        // Send FormData to the API
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'Product updated successfully';
          this.view_product_dialog = false;
          this.edit_product_dialog = false;
          this.fetchProducts();
          this.product.main_category_name = '';
          this.selectedMainCategory = '';
          this.changeColor = '';
        } else {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Failed to update product';
          this.fetchProducts();
        }
      } catch (error) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
        console.error('Error updating product:', error.message);
      }
    },

    // Helper method to convert a file to a Base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Remove the prefix
            resolve(base64String);
          };
          reader.onerror = error => reject(error);
        } else {
          reject(new Error('Invalid file type'));
        }
      });
    },

    homeReload() {
      window.location.href = '/dashboard';
    },

    fetchProducts() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
      this.loading = true;
      const formData = new FormData();
      formData.append("userId", userId);

      axios.post('/api/getProductList', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            // Sort the data by created_at in descending order (newest first)
            this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            this.loading = false;
            // this.items = response.data.data;
            console.log('Result', response.data.data);
            // this.subCategories = response.data.subCategories;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
            this.loading = false;
          });
    },

    addProductsPageRoute() {
      this.$router.push({path: '/add-products'});
    },

    viewItem(item) {
      this.selectedProductId = item.id;
      this.product = {...item}; // Create a copy of the item

      // Prepend the URL to the selected image if it exists
      this.selectedImage = item.product_image.length > 0
          ? `${this.imageURL}/${item.product_image[0].image_url}`
          : '';

      // Prepend the URL for all preview images
      this.previewImages = item.product_image.map(image => ({
        ...image,
        image_url: `${this.imageURL}/${image.image_url}`
      })) || [];

      this.view_product_dialog = true;
      console.log("View item:", item);

      // this.sanitizedDescription = this.convertToPlainText(this.product.product_desc)
    },

    editItem(item) {
      this.edit_product_dialog = true;
      this.selectedProductId = item.id;
      this.product = {...item}; // Create a copy of the item

      this.productMainCatagary = item.main_category;

      // Prepend the URL to the selected image if it exists
      this.selectedImage = item.product_image.length > 0
          ? `${this.imageURL}/${item.product_image[0].image_url}`
          : '';

      // Prepend the URL for all preview images
      this.previewImages = item.product_image.map(img =>
          `${this.imageURL}/${img.image_url}`
      ) || [];

      // Clear any new images to start fresh
      this.form.images = [];
      this.typeDescription = this.product.product_desc;
      console.log('Editing item:', item);
      console.log("Edit item:", item);


      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      console.log('pId', item.id);

      axios.post(`/api/checkProductId`,
          {
            userId: userId,
            productId: item.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            let result = response.data.message;
            console.log('checkProduct:', result);

            if (result === 'hasProduct') {
              this.isProductDisabled = true;
            } else {
              this.isProductDisabled = false;
            }


          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
          });

    },

    confirmDelete(item) {
      this.delete_product_dialog = true;
      this.selectedProductId = item.id;
      console.log('Deleting item:', this.selectedProductId);
    },

    deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const ProductID = this.selectedProductId;
      console.log('delete id', ProductID);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteProduct`, {
        userId: userId,
        ProductID: ProductID,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchProducts(); // Refresh the user list
            this.delete_product_dialog = false; // Close the confirmation dialog
            this.view_product_dialog = false;
            console.log('product deleted successfully');
            this.showSnackbar("Product delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting Product', error);
            this.delete_product_dialog = false;
            this.fetchProducts();
          });
    },

    backPageRoute() {
      this.$router.back();
    },

    selectImage(image) {
      this.selectedImage = image;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const files = event.target.files;
      this.handleFiles(files);

    },

    onDrop(event) {
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },

    onDragOver(event) {
      event.preventDefault();
    },

    handleFiles(files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));
      const maxImages = 5;
      const remainingSlots = maxImages - this.previewImages.length;

      if (fileArray.length === 0) {
        alert('Only image files are allowed!');
        return;
      }

      if (remainingSlots <= 0) {
        this.showSnackbar(`You can only upload up to ${maxImages} images.`, "error");
        return;
      }

      // Ensure each item in the array is a File object
      fileArray.forEach(file => {
        if (!(file instanceof File)) {
          console.error('Not a valid File object:', file);
        }
      });

      // Add new files to form images
      this.form.images.push(...fileArray);

      // Preview the new image URLs alongside the existing ones
      this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));

    },


    removeImage(index) {
      let removedImage = this.previewImages[index];

      // Remove the specified prefix from removedImage
      // const prefix = 'http://sfa.backend.celata.org/SFA/public/';
      const prefix = `${this.imageURL}/`;
      removedImage = removedImage.replace(prefix, '');

      // Debug: Log to confirm URLs after prefix removal
      console.log('Product Image URLs (stripped):', this.product.product_image.map(img => img.image_url.replace(prefix, '')));
      console.log('Removed Image (stripped):', removedImage);

      // Check for the removed image by matching the stripped URL
      const existingImage = this.product.product_image.find(
          img => img.image_url.replace(prefix, '') === removedImage
      );

      // If a match is found, add it to removedImages
      if (existingImage) {
        this.removedImages.push(existingImage.image_url);
      } else {
        console.warn('Image not found in product images:', removedImage);
      }

      // Log all data for debugging
      console.log('Matched Existing Image:', existingImage);
      console.log('Removed Images Array:', this.removedImages);

      // Remove from form and preview lists
      this.form.images.splice(index, 1);
      this.previewImages.splice(index, 1);
    },
  },

  mounted() {
    const dashboardId = 2;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    if (this.product.product_image && this.product.product_image.length > 0) {
      this.selectedImage = this.product.product_image[0];
    }
  },

  computed: {
    formattedDiscountRate() {
      return (this.product.discount_rate ?? '0') + '%';
    },
    oldPrice() {
      const price = parseFloat(this.product.price);
      const discountRate = parseFloat(this.product.discount_rate);

      // Validate if price and discountRate are valid numbers
      if (isNaN(price) || isNaN(discountRate)) {
        console.error('Invalid price or discount rate');
        return '0.00';
      }

      // Calculate discounted price
      if (discountRate > 0) {
        const discountAmount = (price * discountRate) / 100;
        const discountedPrice = price - discountAmount;
        return discountedPrice.toFixed(2); // Return price rounded to 2 decimal places
      }

      // If no discount, the discounted price is the actual price
      return price.toFixed(2);
    },


    filteredItems() {
      return this.items.filter(product => {
        const matchesMainCategory = !this.selectedMainCategory || product.main_category === this.selectedMainCategory;

        // Check if the selectedSubCategory is an array (because it's now allowing multiple selections)
        const matchesSubCategory = !this.selectedSubCategory || this.selectedSubCategory.length === 0 ||
            this.selectedSubCategory.includes(product.sub_category_name) ||
            this.selectedSubCategory.includes(product.sub_category);

        const matchesSearch = !this.search ||
            product.product_title.toLowerCase().includes(this.search.toLowerCase()) ||
            (product.main_category && product.main_category.toLowerCase().includes(this.search.toLowerCase())) ||
            (product.sub_category && product.sub_category.toLowerCase().includes(this.search.toLowerCase())) ||
            (product.price && product.price.toString().toLowerCase().includes(this.search.toLowerCase())) ||
            (product.available_stock && product.available_stock.toString().toLowerCase().includes(this.search.toLowerCase()));

        return matchesMainCategory && matchesSubCategory && matchesSearch;
      });
    }
  },
};
</script>

<style>
@import "../../styles/product_management/products_list.scss";
</style>