<template>
  <v-app id="app_create_outlets">
    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE OUTLETS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Create or bulk upload outlet data set according to your requirements.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn  color="#BB0000" size="x-large" variant="text" @click="outletBulkUploadDialog = true">
              <v-icon>mdi-file-upload-outline</v-icon>&nbsp;&nbsp;OUTLETS BULK UPLOAD
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageOutlet()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Create new outlet</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field v-model="outlet.outlet_name" ref="outletNameField"  density="comfortable" class="custom_input_field_color"
                    :rules="outletNameRules" label="Outlet Name *" placeholder="Type outlet name" variant="outlined"
                />
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field v-model="outlet.mobile_number" ref="mobileNumberField"
                    :rules="[v => !!v || 'Mobile Number is required', v => /^[0-9]*$/.test(v) || 'Mobile Number must be numeric', v => v.length === 10 || 'Mobile Number must be 10 digits long']"
                    label="Mobile Number *" maxlength="10" placeholder="Type mobile number" variant="outlined"  density="comfortable" class="custom_input_field_color"
                ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field v-model="outlet.owner_name" ref="ownerNameField"
                    :rules="[v => !!v || 'Owner Name is required', v => /^[a-zA-Z\s]*$/.test(v) || 'Owner Name cannot contain numbers']"
                    label="Owner Name *" placeholder="Type owner name" variant="outlined"  density="comfortable" class="custom_input_field_color"
                ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="outlet.outlet_type" :items="outletTypes" ref="outletTypeField" :rules="[v => !!v || 'Outlet Type is required']"
                    item-title="outlet_name" item-value="id" label="Select Outlet Type *" variant="outlined" clearable  density="comfortable"
                    class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="7" lg="7">
                    <p class="custom_page_main_sub_title">
                      <strong>USE MAPS TO SELECT AN OUTLET LOCATION</strong>
                    </p>
                    <p class="custom_page_main_sub_title mt-2 " style="opacity: 0.6;line-height: 26px">
                      In here, you can set the outlet location by searching the location or dragging & placing it.
                      If this doesn't work correctly, set it up using the manual dropdown option.
                    </p>

                    <Map ref="map" :center="mapCenter" :zoom="7" class="d-flex custom_outlet_selection_map mt-2 custom_card_box" >
                      <Marker v-if="markerPosition" :icon="customIcon" :position="markerPosition"/>
                    </Map>
                    <v-btn class="position-absolute ml-2 mb-15 custom_lat_lon_label">
                      lat: {{outlet.latitude}} | lng: {{outlet.longitude}}
                    </v-btn>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-card  border="md opacity-1">
                          <v-card-text class="ma-4 mb-0">
                            <v-text-field  append-inner-icon="mdi-magnify"
                                label="Outlet Address" placeholder="Type outlet address" variant="outlined" id="search_address" density="comfortable" class="custom_input_field_color"
                            />
                          </v-card-text>
                        </v-card>
                        <v-divider class="mt-6"></v-divider>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-text-field v-model="outlet.outlet_address" ref="outletAddressField" :rules="outletAddressRules" label="Street Address *" placeholder="Type street address" variant="outlined"  density="comfortable" class="custom_input_field_color"></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-autocomplete v-model="outlet.province" ref="outletProvince" :items="provinces" :loading="provinceLoading" :rules="[v => !!v || 'Province is required']"
                            label="Province *" placeholder="Select province" variant="outlined" clearable :filterable="false"
                            @update:modelValue="onProvinceChange" density="comfortable" class="custom_input_field_color"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-autocomplete v-model="outlet.district" ref="outletDistrict" :items="districts" :loading="mDistrictLoading"
                            :rules="[v => !!v || 'District is required']" label="District *" placeholder="Select district" variant="outlined" :filterable="false"
                            clearable @update:modelValue="onDistrictChange" density="comfortable" class="custom_input_field_color"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-autocomplete v-model="outlet.dsd" ref="outletDSD" :items="dsdList" :loading="mDSDLoading"
                            :rules="[v => !!v || 'DSD is required']" label="DSD *" placeholder="Select DSD" :filterable="false"
                            variant="outlined" clearable @update:modelValue="onDsdChange" density="comfortable" class="custom_input_field_color"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-autocomplete v-model="outlet.gnd" ref="outletGND" :items="gndList" :loading="gndLoading" density="comfortable" class="custom_input_field_color" :filterable="false"
                            :rules="[v => !!v || 'GND is required']" label="GND *" placeholder="Select GND" variant="outlined" clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large"  class="custom_btn_primary" @click="createRoute">Create New Outlet</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="cancelPageOutlet()">Cancel</v-btn>
        </v-card-actions>
      </v-card>


      <!-- Lead Bulk Upload Dialog -->
      <v-dialog v-model="outletBulkUploadDialog" fullscreen id="app_create_outlets">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="outletBulkUploadDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon >mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPLOAD SPREADSHEET</v-card-title>
                </template>
              </v-card>

              <v-card class="px-3 mx-6 mt-3 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg" >
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                    <p class="text-body-1 font-weight-medium  mb-4 mt-4" >
                      Upload your outlet bulk data set using excel or a csv file.
                    </p>
                    <v-divider></v-divider>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-file-input accept=".csv, .xls, .xlsx"
                                    clearable density="comfortable"
                                    class="custom_input_field_color mt-3" label="Upload your Excel or CSV file"
                                    counter show-size  variant="outlined"
                                    @change="handleFileUpload"></v-file-input>
                    </v-col>
                  </v-col>

                  <v-col class="d-flex align-center justify-center" cols="1">
                    <v-divider :thickness="2" vertical></v-divider>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="#F0F0F0" flat width="100%">
                          <card class="mr-4" border="lg">
                            <p class="mb-0 text-body-2 font-weight-bold">DOWNLOAD SAMPLE TEMPLATE</p>
                          </card>
                          <v-divider vertical></v-divider>
                          <v-btn class="custom_btn_primary" size="x-large" variant="text" @click="downloadTemplate">
                            DOWNLOAD
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-card v-if="bulkItems.length > 0" flat>
                  <v-card-text>
                    <v-divider class="mb-4 "></v-divider>
                    <v-data-table :headers="bulkHeaders" :items="bulkItems" :items-per-page="itemsPerPage" class="custom_table_normal">
                      <template v-slot:[`item.STATES`]="{ item }">
                        <div>
                          <div>
                            <v-chip v-if="item.outlet_status === 'Allowed'" color="green"
                                    style="height: 20px; width:130px; font-size: 12px"
                                    variant="outlined">
                              ALLOWED
                              <v-icon class="justify-end ml-14">mdi-check</v-icon>
                            </v-chip>

                            <v-chip v-if="item.outlet_status === 'NotAllowed'" color="red"
                                    style="height: 20px; width:130px; font-size: 12px"
                                    variant="outlined">
                              NOT ALLOWED
                              <v-icon class="justify-end ml-6">mdi-close</v-icon>
                            </v-chip>
                          </div>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card-text>

                  <v-divider ></v-divider>

                  <v-card-actions class="float-end">
                    <v-btn :disabled="bulkItems.length === 0" class="custom_btn_primary  mt-2 mb-3" size="large" variant="text" @click="uploadBulkOutlets">Upload</v-btn>
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Lead Bulk Upload Dialog -->

      <v-snackbar v-model="snackbar" color="success" location="top-right" outlined timeout="3000">
        New outlet was created successfully.
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="snackbarBULK" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top-right" outlined>
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay v-model="loading" :scrim="false" class="centered-overlay">
        <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div v-if="loading" class="loading-spinner">
        Loading...
      </div>
      <div v-if="snackbarFile" class="snackbar">
        {{ snackbarMessage }}
      </div>

    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {Map, Marker} from '@fawmi/vue-google-maps';
import * as XLSX from 'xlsx';

export default {
  components: {
    Map,
    Marker,
  },
  data() {
    return {
      snackbarBULK: false,
      snackbarText: '',
      snackbarMessage: '',
      snackbarColor: '',
      snackbarFile: false,
      loading: false,
      snackbar: false,
      markers:[],
      outlet: {
        outlet_name: '',
        owner_name: '',
        mobile_number: '',
        outlet_type_id: null,
        outlet_address: '',
        latitude: '',
        longitude: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        outlet_status: '',
        outlet_type: '',
      },
      placeSearchQuery:'',
      states: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      outletTypes: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      provinceLoading: false,
      gndLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletBulkUploadDialog: false,
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        {text: 'Outlet Name', value: 'Outlet Name', title: 'Outlet Name'},
        {text: 'Owner Name', value: 'Owner Name', title: 'Owner Name'},
        {text: 'Mobile Number', value: 'Mobile Number', title: 'Mobile Number'},
        {text: 'Outlet Type', value: 'Outlet Type', title: 'Outlet Type'},
        {text: 'Outlet Address', value: 'Outlet Address', title: 'Outlet Address'},
        {text: 'Latitude', value: 'Latitude', title: 'Latitude'},
        {text: 'Longitude', value: 'Longitude', title: 'Longitude'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        // {text: 'Outlet Status', value: 'Outlet Status', title: 'Outlet Status'},
      ],
      bulkHeadersView: [
        {text: 'Outlet Name', value: 'Outlet Name', title: 'Outlet Name'},
        {text: 'Owner Name', value: 'Owner Name', title: 'Owner Name'},
        {text: 'Mobile Number', value: 'Mobile Number', title: 'Mobile Number'},
        {text: 'Outlet Type', value: 'Outlet Type', title: 'Outlet Type'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        // {text: 'Outlet Status', value: 'Outlet Status', title: 'Outlet Status'},
        {text: 'Latitude', value: 'Latitude', title: 'Latitude'},
        {text: 'Longitude', value: 'Longitude', title: 'Longitude'},
        {text: 'Outlet Address', value: 'Outlet Address', title: 'Outlet Address'},
      ],
      mapCenter: {lat: 7.863062567888, lng: 80.98859753968648},
      markerPosition: null,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: {width: 35, height: 40} // Adjust the size if needed
      },
      outlets: [],
      outletNameError: '',
      outletAddressError: '',
    };
  },

  mounted() {
    this.getAllProvinces();
    this.fetchOutletType();
    this.fetchOutlets();

    this.$refs.map.$mapPromise.then((map) => {
                // this.GMap = map;
                this.mapInit(map);
            });
    
  },

  computed: {
    outletNameRules() {
      return [
        v => !!v || 'Outlet Name is required',
        // v => !this.outlets.some(outlet => outlet.outlet_name.toLowerCase() === v.trim().toLowerCase()) || 'Outlet Name already exists'
      ];
    },

    outletAddressRules() {
      return [
        v => !!v || 'Outlet Address is required',
        v => !this.outlets.some(outlet => outlet.outlet_address.toLowerCase() === v.trim().toLowerCase()) || 'Outlet Address already exists'
      ];
    }
  },

  methods: {

    mapInit(map){
      const searchAddress_input = document.getElementById("search_address");
      const searchAddress_autocomplete = new google.maps.places.SearchBox(searchAddress_input);
      map.addListener("bounds_changed", () => {
                    searchAddress_autocomplete.setBounds(map.getBounds());
                });

        let markers = [];
                // let imageSearch ="img/map_makers/pin_point.png";
                searchAddress_autocomplete.addListener("places_changed", () => {
                    const places = searchAddress_autocomplete.getPlaces();
                    if (places.length == 0) {return;}
                    // Clear out the old markers.
                    markers.forEach((marker) => {
                        marker.setMap(null);
                    });
                    markers = [];
                    // For each place, get the icon, name and location.
                    const bounds = new google.maps.LatLngBounds();
                    places.forEach((place) => {
                        if (!place.geometry || !place.geometry.location) {
                            console.log("Returned place contains no geometry");
                            return;
                        }
                        const icon = {
                            url: require('@/assets/ICONS/maker.png'),
                            size: new google.maps.Size(80, 80),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(17, 34),
                            scaledSize: new google.maps.Size(35, 40),
                        };
                        // Create a marker for each place.
                        markers.push(
                            new google.maps.Marker({
                                map,
                                icon,
                                title: place.name,
                                position: place.geometry.location,
                            })
                        );
                        this.outlet.latitude = markers[0].getPosition().lat();
                        this.outlet.longitude = markers[0].getPosition().lng();
                        if (place.geometry.viewport) {
                            // Only geocodes have viewport.
                            bounds.union(place.geometry.viewport);
                        } else {
                            bounds.extend(place.geometry.location);
                        }
                    });
                    map.fitBounds(bounds);
                });

              google.maps.event.addListener(map, 'click', (event) => {
                markers.forEach((marker) => {
                        marker.setMap(null);
                    });
                    markers = [];
              this.outlet.latitude = event.latLng.lat();
              this.outlet.longitude = event.latLng.lng();

              const icon = {
                            url: require('@/assets/ICONS/maker.png'),
                            size: new google.maps.Size(80, 80),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(17, 34),
                            scaledSize: new google.maps.Size(35, 40),
                        };
                        // Create a marker for each place.
                        markers.push(
                            new google.maps.Marker({
                                map,
                                icon,
                                position: {lat : this.outlet.latitude, lng: this.outlet.longitude},
                            })
                        );
            });
    },

    onProvinceChange(value) {
      // Clear dependent fields when Province changes
      if (!value) {
        this.outlet.district = null;
        this.outlet.dsd = null;
        this.outlet.gnd = null;
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
      } else {
        this.getAllDistrictAccProv(value);
      }
    },

    onDistrictChange(value) {
      // Clear dependent fields when District changes
      if (!value) {
        this.outlet.dsd = null;
        this.outlet.gnd = null;
        this.dsdList = [];
        this.gndList = [];
      } else {
        this.getAllDsd(value);
      }
    },

    onDsdChange(value) {
      // Clear dependent fields when DSD changes
      if (!value) {
        this.outlet.gnd = null;
        this.gndList = [];
      } else {
        this.getAllGnd(value);
      }
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.outletTypes = response.data;
        console.log("API Response:", this.outletTypes);

      })
      .catch(error => {
        console.error('Error fetching routes:', error);
      });
    },

    fetchOutlets() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.outlets = response.data;
        console.log("API Response:", response.data);
      })
      .catch(error => {
        console.error('Error fetching routes:', error);
      });
    },

    async createRoute() {
      this.loading = true;
      const isNameValid = this.outletNameRules.every(rule => rule(this.outlet.outlet_name) === true);
      const isAddressValid = this.outletAddressRules.every(rule => rule(this.outlet.outlet_address) === true);

      if (!isNameValid) {
        this.snackbarMessage = 'Invalid Outlet Name';
        this.snackbarColor = 'error'
        this.snackbar = true;
      } else {
        this.outletNameError = '';
      }

      if (!isAddressValid) {
        this.snackbarMessage = 'Invalid Outlet Address';
        this.snackbarColor = 'error'
        this.snackbar = true;
      } else {
        this.outletAddressError = '';
      }
      // Validate required fields with specific error messages
      const missingFields = [];

      if (!this.outlet.outlet_name) missingFields.push('Outlet Name');
      if (!this.outlet.owner_name) missingFields.push('Owner Name');
      if (!this.outlet.mobile_number) missingFields.push('Mobile Number');
      if (!this.outlet.outlet_type) missingFields.push('Outlet Type');
      if (!this.outlet.province) missingFields.push('Province');
      if (!this.outlet.district) missingFields.push('District');
      if (!this.outlet.dsd) missingFields.push('DSD');
      if (!this.outlet.gnd) missingFields.push('GND');
      // if (!this.outlet.outlet_status) missingFields.push('Outlet Status');
      if (!this.outlet.outlet_address) missingFields.push('Outlet Address');
      if (!this.outlet.latitude || !this.outlet.longitude) missingFields.push('Select Location');


      if (missingFields.length > 0) {
        this.snackbarMessage = `Please fill out the following required fields: ${missingFields.join(', ')}.`;
        this.snackbarColor = 'error';
        this.snackbar = true;
        this.loading = false;
        return; // Stop the function if validation fails
      }

      if (/\d/.test(this.outlet.owner_name)) {
        this.snackbarMessage = 'Owner Name cannot contain numbers';
        this.snackbarColor = 'error';
        this.snackbar = true;
        this.loading = false;
        return; // Stop further execution if validation fails
      }

      if (!/^\d+$/.test(this.outlet.mobile_number) || this.outlet.mobile_number.length < 10 || this.outlet.mobile_number.length > 15) {
        this.snackbarMessage = 'Mobile Number must be numeric and between 10 to 15 digits';
        this.snackbarColor = 'error';
        this.snackbar = true;
        this.loading = false;
        return; // Stop further execution if validation fails
      }

      // Debugging: Log the selected outlet_type and outletTypes array
      console.log('Selected outlet_type:', this.outlet.outlet_type);
      console.log('outletTypes:', this.outletTypes);

      // Ensure IDs are of the same type
      const selectedOutlet = this.outletTypes.find(
          (outlet) => Number(outlet.id) === Number(this.outlet.outlet_type)
      );

      // Debugging: Log the found selectedOutlet
      console.log('Selected Outlet:', selectedOutlet);
      const selectedOutletName = selectedOutlet ? selectedOutlet.outlet_name : '';
      const userId = localStorage.getItem('userId');
      const outletData = {
        outlet_name: this.outlet.outlet_name,
        outlet_address: this.outlet.outlet_address,
        latitude: this.outlet.latitude,
        longitude: this.outlet.longitude,
        mobile_number: this.outlet.mobile_number,
        outlet_type_id: this.outlet.outlet_type,
        outlet_type: selectedOutletName,
        province: this.outlet.province,
        district: this.outlet.district,
        dsd: this.outlet.dsd,
        gnd: this.outlet.gnd,
        outlet_status: "ALLOWED",
        owner_name: this.outlet.owner_name,
        userId: userId,
      };

      console.log('Outlet Data:', outletData);

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`,
            outletData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        );
        console.log('Response:', response);

        // If the request is successful, show the success message using Snackbar
        this.snackbarMessage = 'Outlet created successfully!';
        this.snackbarColor = 'success';
        this.snackbar = true;
        window.location.reload();

      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Duplicate address error
          this.snackbar = true;
          this.snackbarMessage = 'OUTLET ALREADY EXIST';
          this.snackbarColor = 'error'
        } else {
          this.snackbar = true;
          this.snackbarMessage = 'Fill All Details Correctly';
          this.snackbarColor = 'error'
        }
      } finally {
        this.loading = false;
      }
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.provinces = response.data.data;
          this.mDistrictLoading = false;
        }
      })
      .catch(() => {
        this.mDistrictLoading = false;
      });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
      .then(response => {
        console.log('District API response:', response.data);
        if (response.data.http_status === 'success') {
          this.districts = response.data.data;
          console.log('District API response:', response.data);
          this.mDistrictLoading = false;

        }
      })
      .catch(error => {
        this.mDistrictLoading = false;
        console.error('Error fetching districts:', error);

      });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.dsdList = response.data.data;
          this.mDSDLoading = false;
        }
      })
      .catch(() => {
        this.mDSDLoading = false;
      });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.gndList = response.data.data;
          this.mGNDLoading = false;
        }
      })
      .catch(() => {
        this.mGNDLoading = false;
      });
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');
        const expectedHeaders = this.bulkHeadersView.map(header => header.text);

        const headersMatch = headers.length === expectedHeaders.length &&
            headers.every((header, index) => header === expectedHeaders[index]);
        console.log('ddddddddddddd', headers, expectedHeaders);

        if (!headersMatch) {
          this.snackbar = true;
          this.snackbarMessage = 'UPLOADED FILE WAS WRONG';
          this.snackbarColor = 'error'
          return;
        }
        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            const value = row[index];
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });
        this.bulkItems = dataRows;
      };
      reader.readAsArrayBuffer(file);
    },

    async uploadBulkOutlets() {
      this.loading = true;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (!this.bulkFile) {
        console.error('No file selected for upload');
        this.loading = false;
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId);

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlets/bulk-upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          console.log('Bulk upload response:', response.data);

          this.snackbarMessage = 'File uploaded successfully!';
          this.snackbarFile = true;
          this.outletBulkUploadDialog = false;
          // setTimeout(() => {
          //   this.outletBulkUploadDialog = false;
          // }, 5000); // Hide after 5 seconds
        }
      } catch (error) {
        console.error('Error uploading bulk outlets:', error);
        this.snackbarMessage = 'Failed to upload file. Please try again.';
        this.snackbarFile = true;
      } finally {
        this.loading = false;
      }
    },

    backPageOutlet() {
      this.$router.back();
    },

    cancelPageOutlet() {
      // Reset form data
      this.outlet = {
        outlet_name: '',
        outlet_address: '',
        latitude: '',
        longitude: '',
        mobile_number: '',
        outlet_type: null,
        province: null,
        district: null,
        dsd: null,
        gnd: null,
        outlet_status: null,
        owner_name: '',
      };

      // Clear dependent data
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];

      // Clear validation states for all fields
      const fields = [
        this.$refs.outletNameField,
        this.$refs.ownerNameField,
        this.$refs.mobileNumberField,
        this.$refs.outletAddressField,
        this.$refs.outletTypeField,
        this.$refs.outletProvince,
        this.$refs.outletDistrict,
        this.$refs.outletDSD,
        this.$refs.outletGND,
        this.$refs.outletOutletStatus,
        this.$refs.outletLatitudeField,
        this.$refs.outletLongitudeField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });
    },

    mapClicked(event) {
      // this.markers.forEach((marker) => {
      //   if (marker instanceof google.maps.Marker) {
      //     toRow(marker).setMap(null); // Remove marker from map
      //     Array.isArray(data) ? data.map(item => ({ ...item })) : []
      //   }
      // });
      // this.markers = [];
      this.markers.forEach((marker) => {
        marker.setMap(null);
        
        });
        // console.log("MARKER",this.markers);
        // Clear the markers array
        this.markers = [];

      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.outlet.latitude = this.markerPosition.lat;
      this.outlet.longitude = this.markerPosition.lng;
    },

    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/outlets/download-template?userId=${userId}`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_outlet_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
    homeReload() {
      window.location.href = '/dashboard';
    },
  },

  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    },
    'outlet.outlet_name'(newName) {
      if (newName.trim() === '') {
        this.outletNameError = 'Outlet Name is required';
      }
    },
    placeSearchQuery(placeAddress) {
      console.log("Place",placeAddress)

    },
    'outlet.outlet_address'(newAddress) {
      if (newAddress.trim() === '') {
        this.outletAddressError = 'Outlet Address is required';
      } else {
        const addressExists = this.outlets.some(
            outlet => outlet.outlet_address.toLowerCase() === newAddress.trim().toLowerCase()
        );
        this.outletAddressError = addressExists ? 'Outlet Address already exists' : '';
      }
    }
  }
};
</script>

<style>
  @import "../../styles/outlet_management/create_outlet.scss";
</style>