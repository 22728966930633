<template>
  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon >mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">ROUTE PLAN RECORD</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="imageSrc" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">{{ agentName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div class="d-flex mt-6">
              <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                ROUTE STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ routeStates }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
          <v-btn
              v-if="permissions.write" class="mr-3 custom_edit_btn"
              icon rounded size="small"
              variant="outlined"  @click="editViewRoute"
          >
            <v-icon>mdi-pencil</v-icon>
            <v-tooltip activator="parent" location="top">Edit</v-tooltip>
          </v-btn>

          <v-btn
              v-if="permissions.delete" class="custom_delete_btn"  icon rounded
              size="small" variant="outlined" @click="confirmDelete"
          >
            <v-icon>mdi-trash-can</v-icon>
            <v-tooltip activator="parent" location="top">Delete</v-tooltip>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="routeName" label="Route Name" readonly variant="outlined"
                      class="px-3 custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="routeCategory" label="Route Category" readonly variant="outlined"
                      class="px-3 custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="province" label="Province" readonly variant="outlined"
                      class="px-3 mt-2  custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="agentName" label="Agent Name" readonly variant="outlined"
                      class="px-3 mt-2 custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="district" label="District" readonly variant="outlined"
                      class="px-3 mt-2  custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="dsd" label="DSD" readonly variant="outlined"
                      class="px-3 mt-2  custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="gnd" label="GND" readonly variant="outlined"
                      class="px-3 mt-2  custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="assignDate" label="Assign Date" readonly variant="outlined"
                      class="px-3 mt-2  custom_input_field_class_view custom_input_field_color" density="comfortable"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="px-3">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider class="mt-1 mb-3"></v-divider>
        <p class="custom_page_main_sub_title">
          <strong>SELECT OUTLET LIST</strong>
        </p>
        <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
          The following table shows all the outlets related to the above-mentioned route.
        </p>

        <v-card class="mt-4 custom_card_box mb-3" elevation="1" border="sm opacity-1" rounded="lg">
          <v-card  flat color="white">
            <v-card-text class="custom_tool_bar_style">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"  class="float-start custom_input_field_color"
                            clearable hide-details  label="Search" placeholder="Search Lead" variant="outlined"></v-text-field>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-btn v-if="OverallStatus !== 'Visited'"  class=" custom_btn_primary" variant="outlined" size="x-large" @click="opensendReminderDialog()">
                SEND REMINDER
              </v-btn>
            </v-card-text>
          </v-card>

          <v-divider></v-divider>

          <v-card-text>
            <v-data-table  :headers="headers" :items="outletData" :search="search" density="comfortable" class="custom_table_normal">
              <template v-slot:[`item.route_category`]="{ item }">
                <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium">{{ item.route_category }}</p>
              </template>

              <template v-slot:[`item.outletName`]="{ item }">
                <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.outletName }}</p>
                <v-divider class="mt-2 mb-2"></v-divider>
                <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-2">Outlet Num: {{ item.id }}</p>
              </template>

              <template v-slot:[`item.visited_date`]="{ item }">
                <p v-if="item.status === 'Visited'" class="custom_page_main_sub_title_very_small_red font-weight-medium text-body-2 mb-2 mt-2">{{ item.visited_date }}</p>
                <v-btn v-if="item.status === 'Not Visited'"  size="large" class="float-end mb-4 mt-4 custom_btn_secondary"
                       @click="reasonBox(item) ">REASON
                </v-btn>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status === 'Visited'" class="custom_green_chip" size="small" variant="outlined">
                  <v-icon>mdi-check-circle-outline</v-icon>&nbsp;  VISITED
                </v-chip>

                <v-chip v-if="item.status === 'Not Visited'"  class="custom_red_chip" size="small" variant="outlined">
                  <v-icon>mdi-close</v-icon>&nbsp;   NOT VISITED
                </v-chip>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>


        <!-- Send reminder for not visited records -->
        <v-dialog  v-model="sendReminderDialog" max-height="700" id="app_routes_list" max-width="600" >
          <v-card  rounded="md"  style="overflow:hidden !important;">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat >
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">SEND REMINDER</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="sendReminderDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card flat class="mt-3">

                <v-text-field v-model="routeName" density="comfortable" label="Route Name" placeholder="Route Name" required
                              variant="outlined" class=" mt-3 custom_input_field_color"
                ></v-text-field>

                <v-text-field
                    v-model="this.assigneeDetails.name" density="comfortable" label="Assignee" placeholder="Assignee" required
                    variant="outlined"  class=" mt-2 custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="agentDetails.mobile_number[0].mobile_number" :rules="[v => !!v || 'Outlet Address is required']"
                              density="comfortable" label="Mobile" placeholder="Mobile" required variant="outlined"
                              class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="agentDetails.email" :rules="[v => !!v || 'Email is required']" density="comfortable"
                              label="Email" placeholder="Type Email Here" required
                              variant="outlined" class=" mt-2 custom_input_field_color"
                ></v-text-field>

                <v-textarea v-model="senderNote" :rules="[v => !!v || 'Note is required']" density="comfortable"
                            label="Note" placeholder="Type Note Here" required rows="3"
                            variant="outlined" class=" mt-2 custom_input_field_color"
                ></v-textarea>

                <v-divider class="mt-4"></v-divider>
                <div class="float-end mt-4">
                  <v-btn  class="custom_btn_primary" @click="sendReminder">SEND</v-btn>
                  <v-overlay :value="loading" opacity="0.7">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-overlay>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="sendReminderDialog = false">CANCEL</v-btn>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Send reminder for not visited records -->


        <!-- Agent message for details -->
        <v-dialog  v-model="reasonDialog" max-height="700" id="app_routes_list" max-width="600" >
          <v-card  rounded="md"  style="overflow:hidden !important;">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat >
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">AGENT MESSAGE</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="reasonDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card flat class="mt-3">
                <v-card flat>
                  <v-textarea v-model="notVisitedReason" density="comfortable" label="Not Visited Reason" placeholder="Not Visited yet" readonly
                      variant="outlined" class="mt-2  custom_input_field_class_view custom_input_field_color"
                  ></v-textarea>
                </v-card>
                <v-divider class="mt-4"></v-divider>
                <div class="float-end mt-4">
                  <v-btn  class="ml-3 custom_btn_secondary" @click="reasonDialog = false">CANCEL</v-btn>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Agent message for details -->

        <!-- Delete User confirmation dialog -->
        <v-dialog v-model="deleteDialog" id="app_routes_list" max-height="500" max-width="500" persistent>
          <v-card  rounded="md">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat>
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">DELETE ROUTE PLAN DATA</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card color="white" flat class="mt-3">
                <v-card-item>
                  <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                    <v-icon color="warning" size="small">mdi-alert</v-icon>
                    &nbsp;&nbsp;Do you want to delete this route plan record ?
                  </v-card-title>

                  <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                    This route plan record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                  </p>

                  <v-divider class="mt-4"></v-divider>

                  <div class="float-end mt-4 ">
                    <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                    <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                  </div>
                </v-card-item>
              </v-card>
            </div>
          </v-card>

        </v-dialog>
        <!-- Delete User confirmation dialog -->




        <!-- Snackbar to show notifications -->
        <v-snackbar  v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
          {{ snackAlert.snackMessage }}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-card>
</template>

<!--<v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" timeout="5000">-->
<!--{{ snackAlert.snackMessage }}-->
<!--</v-snackbar>-->

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {EventBus} from "@/plugins/event-bus";
// import RoutePlanEdit from "@/components/RouteManagement/RoutePlanEdit.vue";
import {it} from "vuetify/locale";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: "Route_Plan.vue",
  components: {
    // RoutePlanEdit
  },
  data() {
    return {
      tab: null,
      search:'',
      loading: false,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackVisible: false,
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      notVisitedReason: "",
      reasonDialog: false,
      OverallStatus: '',
      deleteDialog: false,
      agentDetails: "",
      assdineeDetails: "",
      itemToDelete: null,
      senderNote: '',
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      sendReminderDialog: false,
      showFirstCard: true,
      fetchData: '',
      routeStates: '',
      agentName: '',
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      assignDate: '',
      visitedDate: '',
      userImage: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      email: '',
      note: 'You should visit this place as soon as possible and report the information',
      headers: [
        {text: 'Category', value: 'route_category', title: 'Category'},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name'},
        {text: 'Outlet Address', value: 'address', title: 'Outlet Address'},
        {text: 'Status', value: 'status', title: 'Status'},
        {text: 'Visit Date', value: 'visited_date', title: 'Visit Date'},
      ],
      routeProducts: [],
      outletData: [],
      admin: {
        userName: '',
        mobileNum: '',
        email: '',
      },
      reason: '',
      permissions: {},
    };
  },

  methods: {
    reasonBox(item) {
      this.reasonDialog = true;
      // Ensure my_route_outlet exists before accessing its properties
      this.notVisitedReason = item.my_route_outlet
          ? item.my_route_outlet.not_visited_reason || "No reason provided"
          : "No reason provided";

      console.log('reason item', item);
    },


    // Send Reminder For Agent
    sendReminder() {
      // Show loading snackbar
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = 'info';
      this.snackAlert.snackMessage = 'Sending reminder...';

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("routeName", this.routeName);
      formData.append("assignee", this.agentName);
      formData.append("email", this.agentDetails.email);
      formData.append("senderNote", this.senderNote);


      axios.post(`/api/sendReminder`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        // Show success snackbar
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'success';
        this.snackAlert.snackMessage = 'Reminder sent successfully.';

        // Close dialog
        this.sendReminderDialog = false;
        console.log('Reminder sent successfully');
      })
      .catch(error => {
        // Show error snackbar
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Failed to send reminder. Please try again.';
        console.error('Error sending reminder:', error);
      });
    },

    opensendReminderDialog() {
      this.sendReminderDialog = true;
    },

    reminderBox(item) {
      this.reminderDialog = true;
      console.log('pako', item)
      const userName = localStorage.getItem('userName');
      const email = localStorage.getItem('email');
      const mobile = localStorage.getItem('mobileNumbers');


      this.outletName = item.outlet_name;
      this.outletAddress = item.address;
      this.admin.email = email;
      this.admin.mobileNum = mobile;
      this.admin.userName = userName;

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');


      axios.post(`/api/routes/getMesseage`, {userId: userId, outletId: item.outlet_id}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.submitReason = response.data.data.not_visited_reason;
        console.log('ddd', this.submitReason);
      })
      .catch(error => {
        console.error('Error deleting route:', error);
      });
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      // Implement your delete item logic here
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;

      console.log('Shared Data sett: ', this.sharedDataRoutes);
      console.log('fksss', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/delete/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        this.deleteDialog = false;
        this.itemToDelete = null;
        EventBus.emit('routeRecodeManagementButtonClick2');
      })
      .catch(error => {
        console.error('Error deleting route:', error);
      });
    },

    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    editViewRoute() {
      //  functionality to edit item details
      this.editDialog = true;
      EventBus.emit('routeRecodeManagementButtonClick');
    },

    reminderSend() {
      const userId = localStorage.getItem('userId');
      const routeData = {
        route_name: this.routeName,
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        assignee: this.admin.userId,
        mobile_number: this.admin.mobileNum,
        email: this.admin.email,
        note: this.note,
      };

      axios.post('/api/route/reminder', {routeData, userId})
      .then(response => {
        console.log('reminder send successfully:', response.data);
        // Handle successful addition (e.g., clear form, show success message)
      })
      .catch(error => {
        console.error('Error adding reminder:', error);
        // Handle error (e.g., show error message)
      });
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        console.log("API Response all:", response.data.route);
        if (response.data.route) {

          this.routeProducts = Array.isArray(response.data.route) ? response.data.route : [response.data.route];
          console.log("outletData111111111:", this.routeProducts);

          if (this.routeProducts.length > 0) {
            const route = this.routeProducts[0];
            console.log("data", route);
            this.agentName = route.agent.name;
            this.routeName = route.route_name;
            this.routeCategory = route.route_category;
            this.province = route.province;
            this.district = route.district;
            this.dsd = route.dsd;
            this.gnd = route.gnd;
            this.assignDate = route.assign_date;
            this.visitedDate = route.visited_date;
            this.routeStates = route.route_status;
            this.userImage = route.agent.user_image;

            this.mobileNumber = route.agent.mobile_number.length > 0 ? route.agent.mobile_number[0].mobile_number : null;

            this.admin.userName = route.assigner.name;
            this.admin.email = route.assigner.email;
            // this.admin.mobileNum = route.assigner.mobile_number.length > 0 ? route.assigner.mobile_number[0].mobile_number : null;


            console.log("sahan", this.mobileNumber)

          }
          console.log('main', this.agentName)

        } else {
          this.routeProducts = [];
        }
      })
      .catch(error => {
        console.log(error);
        this.routeProducts = [];
      });
    },

    fetchOutletData() {
      this.OverallStatus = this.sharedDataRoutes.overallStatus;
      console.log('Overall Status:', this.OverallStatus);
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('Route ID:', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        const data = response.data;
        this.agentDetails = data.route.agent;
        this.assigneeDetails = data.route.assigner;

        console.log('Assignee Details:', this.assigneeDetails);
        console.log('API Response:', data);

        if (data && data.route && data.route.selected_outlets) {
          this.outletData = data.route.selected_outlets;

          console.log("this.outletData is", this.outletData);

          // Extracting notVisitedReason from the first outlet's my_route_outlet
          const firstOutlet = this.outletData[0]; // Adjust index if necessary
          if (firstOutlet && firstOutlet.my_route_outlet) {
            this.notVisitedReason = firstOutlet.my_route_outlet.not_visited_reason || "No reason provided";
          } else {
            this.notVisitedReason = "No reason provided";
          }

          console.log("Not Visited Reason:", this.notVisitedReason);
        } else {
          console.log("Selected outlets data not found in the response");
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  computed: {
    it() {
      return it
    },
    ...mapGetters(['sharedDataRoutes']),

    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}/SFA/public/${this.userImage}`;
    }
  },

  created() {
    this.fetchOutletRecodeData();
  },

  mounted() {
    const dashboardId = 8;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.fetchOutletRecodeData();
    this.fetchOutletData();
  },
};
</script>


<style>
  @import "../../styles/route_management/routes_list.scss";
</style>