// export const getPermissions = (dashboardId) => {
//     const accessDashboard = JSON.parse(localStorage.getItem("accessDashboard"));
//     if (accessDashboard && Array.isArray(accessDashboard)) {
//         const permission = accessDashboard.find((item) => item.dashboard_id === dashboardId);
//         if (permission) {
//             return {
//                 read: permission.read_premission === 1,
//                 write: permission.write_premission === 1,
//                 delete: permission.delete_premission === 1,
//             };
//         }
//     }
//     return { read: false, write: false, delete: false }; // Default: No permissions
// };

export const getPermissions = (dashboardId) => {
    const accessDashboard = JSON.parse(localStorage.getItem("accessDashboard")) || [];
    const dashboardAccess = accessDashboard.find((item) => item.dashboard_id === dashboardId);

    if (dashboardAccess) {
        return {
            read: dashboardAccess.read_premission === 1,
            write: dashboardAccess.write_premission === 1,
            delete: dashboardAccess.delete_premission === 1,
        };
    }

    return { read: false, write: false, delete: false }; // Default: No permissions
};
