<template>

  <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <v-app id="app_add_products">
    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">ADD PRODUCTS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Orders placed across your store</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="addCategoryPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD CATEGORY
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Add products details</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="form.productTitle" :rules="[v => !!v || 'Product title is required']"
                    class="custom_input_field_color" label="Product Title *" placeholder="Type title here" required
                    variant="outlined" density="comfortable"
                ></v-text-field>

                <v-autocomplete
                    v-model="form.mainCategory" :clearable="form.mainCategory !== null && form.mainCategory !== ''"
                    :items="mainCategories" :rules="[v => !!v || 'Main category is required']"
                    item-title="name" item-value="id" label="Main Category *"
                    placeholder="Select main category" required variant="outlined"
                    @update:modelValue="fetchSubCategories()" density="comfortable"
                    class="mt-2 custom_input_field_color"
                />

                <v-autocomplete
                    v-model="form.subCategory" :clearable="form.subCategory !== null && form.subCategory !== ''"
                    :items="subCategories"
                    :loading="productLoading" :rules="[v => !!v || 'Sub category is required']"
                    item-title="sub_category_name" item-value="sub_category_name" label="Sub Category *"
                    placeholder="Select sub category" required variant="outlined" density="comfortable"
                    class="mt-2 custom_input_field_color"
                />

                <v-text-field
                    v-model="form.price"
                    :rules="[v => !isNaN(Number(v)) || 'Price must be a number', v => Number(v) >= 0 || 'Price must be 0 or higher']"
                    label="Price *" placeholder="Type price here" required
                    variant="outlined" density="comfortable" class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-divider class="mb-6 mt-2"></v-divider>

                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description Word Count 300</p>
                    <v-card class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="300"
                            min-height="300">
                      <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                      <v-divider></v-divider>
                      <Editor v-model="form.productDescription" :defaultConfig="editorConfig" :mode="mode"
                              @onCreated="handleCreated"/>
                    </v-card>
                  </v-col>
                </v-row>
                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete
                    v-model="form.unit" :items="units" clearable
                    item-title="Unit" item-value="name" label="Unit" placeholder="Select Unit"
                    required variant="outlined" class="custom_input_field_color" density="comfortable"
                >
                </v-autocomplete>

                <v-text-field type="number"
                              v-model="form.size" label="Number of Units" placeholder="Enter the number of units"
                              variant="outlined" density="comfortable" class="mt-2 custom_input_field_color"
                              :rules="[v => !isNaN(Number(v)) || 'Number of Units must be a number', v => Number(v) >= 0 || 'Number of Units must be 0 or higher']"
                ></v-text-field>

                <v-chip size="small">Selected Color: {{ getColorName(form.color) }}</v-chip>
                <v-divider class="mb-2 mt-2"></v-divider>
                <v-text-field class="mt-3 custom_input_field_color" v-model="form.color" density="comfortable"
                              label="Color" variant="outlined"
                              @click="dialog = true"></v-text-field>

                <v-dialog v-model="dialog" max-width="350px">
                  <v-card id="app_authentication" rounded="md">
                    <div class="pa-4">
                      <v-card color="#F0F0F0" flat>
                        <template v-slot:prepend>
                          <v-card-title class="text-subtitle-1 font-weight-bold">SET YOUR COLOR CODE</v-card-title>
                        </template>
                        <v-spacer></v-spacer>
                        <template v-slot:append>
                          <v-btn @click="dialog = false" icon="mdi-close" size="x-small"></v-btn>
                        </template>
                      </v-card>

                      <!-- Color Code Text Field -->
                      <v-text-field
                          v-model="form.color"
                          class="mt-4 custom_input_field_color"
                          label="Color Code"
                          density="comfortable"
                          placeholder="Type your color code here"
                          variant="outlined"
                      ></v-text-field>

                      <!-- Mode Selector -->
                      <!-- <v-autocomplete
                        v-model="mode"
                        :items="modes"
                        class="custom_input_field_color"
                        density="comfortable"
                        variant="outlined"
                      ></v-autocomplete> -->

                      <!-- Color Picker -->
                      <v-color-picker
                          width="318px"
                          v-model="form.color"
                          v-model:mode="mode"
                      ></v-color-picker>

                      <v-divider class="mt-3"></v-divider>

                      <div class="float-end mt-4">
                        <v-btn size="large" class="custom_btn_primary" @click="dialog = false">Done</v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-dialog>


                <v-text-field
                    v-model="form.availableStock" class="mt-2 custom_input_field_color"
                    :rules="[v => !isNaN(Number(v)) || 'Stock must be a number', v => Number(v) >= 0 || 'Stock must be 0 or higher']"
                    label="Available Stock *" placeholder="Type stock here" required density="comfortable"
                    variant="outlined"
                ></v-text-field>

                <v-text-field type="number"
                              v-model="form.discountRate" class="mt-2 custom_input_field_color"
                              :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
                              label="Discount Rate" density="comfortable" placeholder="Type discount rate here"
                              variant="outlined"
                ></v-text-field>


                <v-card flat>
                  <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">Product Images</p>

                  <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop"
                       @dragover.prevent="onDragOver">
                    <input ref="fileInput" accept="image/*" class="hidden-file-input" multiple type="file"
                           @change="onFileChange"/>
                    <div v-for="(image, index) in previewImages" :key="index" class="image-card-container">
                      <v-card class="image-card">
                        <v-img :alt="'Image ' + (index + 1)" :src="image" class="image-preview"></v-img>
                        <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage(index)">
                          <v-icon color="red" size="small">mdi-delete</v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <div v-if="previewImages.length === 0" class="placeholder-container">
                      <v-icon size="36">mdi-cloud-upload</v-icon>
                      <span>Drag your photo here OR click to <span
                          style="color:#156CF7;">Browse from device</span></span>
                    </div>
                    <row v-if="previewImages.length >= 5" class="max-images-warning">
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <span style="color: #BB0000;">Maximum 5 images allowed</span>
                      </v-col>
                    </row>
                  </div>
                </v-card>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large" class="custom_btn_primary" @click="addProduct">Add products</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="backPageRoute()">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import ntc from 'ntc';

//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import {i18nChangeLanguage} from '@wangeditor/editor'

i18nChangeLanguage('en')
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
//This is related to wangeditor ---END

export default {
  components: {
    Editor,
    Toolbar,
  },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = {placeholder: 'Type here...'}

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END

  name: 'AddProducts',
  data() {
    return {
      dataProperty: '',
      formatting: [],
      showColorPicker: false,
      selectedColor: '#000',
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      units: [
        {Unit: 'Grams  (g)', name: 'g'},
        {Unit: 'Kilograms  (kg)', name: 'kg'},
        {Unit: 'Pound (lb)', name: 'lb'},
        {Unit: 'Liter  (L)', name: 'L'},
        {Unit: 'Milliliter (mL)', name: 'ml'},
        {Unit: 'Gallon', name: 'Gallon'},
        {Unit: 'Meters  (m)', name: 'm'},
        {Unit: 'Centimeters  (cm)', name: 'cm'},
        {Unit: 'Piece (pcs)', name: 'pcs'},
        {Unit: 'Box', name: 'Box'},
        {Unit: 'Packet (pkt)', name: 'pkt'},
        {Unit: 'Set ', name: 'Set'},
        {Unit: 'Dozen (dz)', name: 'dz'},
        {Unit: 'Pair', name: 'Pair'},
        {Unit: 'Roll', name: 'Roll'},
        {Unit: 'Bundle', name: 'Bundle'},
        {Unit: 'Unit', name: 'Unit'},
        {Unit: 'Case', name: 'Case'},
        {Unit: 'Carton', name: 'Carton'},
        {Unit: 'Tonne (ton)', name: 'ton'},
        {Unit: 'Barrel', name: 'Barrel'},
        {Unit: 'Can', name: 'Can'}
      ],

      mainCategories: [],
      productLoading: false,
      centerLoading: false,
      subCategories: [],
      form: {
        productTitle: '',
        unit: '',
        productDescription: '',
        main_category_name: '',
        mainCategory: null,
        subCategory: null,
        size: '',
        color: '#FFFFFF',
        price: '',
        discountRate: '',
        availableStock: '',
        images: []
      },

      previewImages: [],
      dialog: false,
      // modes: ['RGB', 'HSL'],
      convertColor: '',
    };
  },

  methods: {
    applyFormat(format) {
      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    getColorName(hex) {
      const color = ntc.name(hex);
      // this.convertColor = color[1];
      return color[1];
    },

    sizeValidation(value) {
      const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
      return sizePattern.test(value.trim());
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onDragOver(event) {
      event.preventDefault();
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/mainProduct', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
              return {
                id: item.id,
                name: item.main_category
              };
            });
            console.log('Main categories:', this.mainCategories);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },

    fetchSubCategories() {
      // if (!this.form.mainCategory) return;
      this.productLoading = true;
      this.form.subCategory = null;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const mainCatagoryId = this.form.mainCategory;
      console.log('Main Category ID:', this.form.mainCategory);

      axios.post(`/api/subcatagoryProduct`,
          {
            userId: userId,
            mainCatagoryId: mainCatagoryId
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            console.log('API Response:', response);
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);
            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
            this.form.main_category_name = selectedCategory ? selectedCategory.name : '';
            console.log('Selected Main Category Name:', this.form.main_category_name);
            this.productLoading = false;

          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
            this.productLoading = false;
          });
    },

    // Handle file selection
    handleFiles(files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));
      const maxImages = 5;
      const remainingSlots = maxImages - this.previewImages.length;

      if (fileArray.length === 0) {
        alert('Only image files (JPEG, PNG, GIF) are allowed!');
        return;
      }

      if (remainingSlots <= 0) {
        alert(`You can only upload up to ${maxImages} images.`);
        return;
      }

      // Limit to remaining slots
      const filesToAdd = fileArray.slice(0, remainingSlots);

      // Update form data
      this.form.images.push(...filesToAdd);

      // Create image previews
      filesToAdd.forEach(file => {
        const imageUrl = URL.createObjectURL(file);
        this.previewImages.push(imageUrl);
      });
    },

    onFileChange(event) {
      const files = Array.from(event.target.files);
      this.handleFiles(files);
      event.target.value = ''; // Reset the file input
    },

    onDrop(event) {
      const files = Array.from(event.dataTransfer.files);
      this.handleFiles(files);
    },

    // Remove an image from the selected images
    removeImage(index) {
      this.form.images.splice(index, 1); // Remove the image object (containing filename) from form data
      this.previewImages.splice(index, 1); // Remove the preview image URL from the UI
    },

    // Helper method to convert a file to a Base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Remove the prefix
            resolve(base64String);
          };
          reader.onerror = error => reject(error);
        } else {
          reject(new Error('Invalid file type'));
        }
      });
    },

    // Validate and add the product
    async addProduct() {
      // Perform individual field validation
      if (!this.form.productTitle) {
        this.showSnackAlert('error', 'Product title is required.');
        return;
      }

      if (!this.form.main_category_name) {
        this.showSnackAlert('error', 'Main category is required.');
        return;
      }

      if (!this.form.subCategory) {
        this.showSnackAlert('error', 'Sub-category is required.');
        return;
      }

      if (!this.form.price || isNaN(this.form.price) || this.form.price <= 0) {
        this.showSnackAlert('error', 'Valid price is required.');
        return;
      }

      if (this.form.discountRate === null || this.form.discountRate < 0) {
        this.showSnackAlert('error', 'Discount rate must be 0 or greater.');
        return;
      }

      if (!this.form.availableStock || isNaN(this.form.availableStock) || this.form.availableStock < 0) {
        this.showSnackAlert('error', 'Available stock must be 0 or greater.');
        return;
      }

      if (!this.form.images || this.form.images.length === 0) {
        this.showSnackAlert('error', 'At least one product image is required.');
        return;
      }


      if ((!this.form.unit || this.form.unit.length === 0) && (!this.form.size || this.form.size.length < 0)) {
        this.form.unit = "Unit";  // Assign default value
        this.form.size = 0;  // Assign default value
      }
      if (this.form.productDescription.length > 300) {
        this.showSnackAlert('error', 'The description exceeds 300 characters. Please provide a shorter description.');
        return;
      }


      // Validation passed, proceed with submission
      this.centerLoading = true;
      const formData = new FormData();
      formData.append('productTitle', this.form.productTitle);

      // console.log('fk description',this.form.productDescription);
      //const delta = this.form.productDescription;

      // Convert Delta to HTML
      // const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
      //const html = converter.convert(); // This is the styled HTML

      formData.append('productDescription', this.form.productDescription);
      formData.append('mainCatagory', this.form.main_category_name);
      formData.append('subCatagory', this.form.subCategory);
      formData.append('unit', this.form.unit || '');
      formData.append('size', this.form.size);
      const colorName = this.getColorName(this.form.color);
      formData.append('color', colorName);
      formData.append('price', this.form.price);
      formData.append('discountRate', this.form.discountRate);
      formData.append('availableStaock', this.form.availableStock);
      formData.append('userId', localStorage.getItem('userId'));

      try {
        const base64Images = await Promise.all(
            this.form.images.map(file => this.convertToBase64(file))
        );
        const imagesJson = JSON.stringify(base64Images.map(image => ({image})));
        formData.append('productImage', imagesJson);

        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }
        const token = localStorage.getItem('token');

        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.showSnackAlert('success', 'Product added successfully.');
          this.centerLoading = false;
          window.location.reload();
          this.resetForm();
        } else {
          this.showSnackAlert('error', 'Failed to add product.');
          this.centerLoading = false;
        }
      } catch (error) {

        this.centerLoading = false;

        // Check if the backend returned a specific error
        if (error.response && error.response.data && error.response.data.error) {
          this.showSnackAlert('error', error.response.data.error);
        } else {
          this.showSnackAlert('error', 'Error adding product. Please try again.');
        }
        console.error('Error adding product:', error);
      }
    },

    //Helper method to show snackbar alerts
    showSnackAlert(type, message) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = type;
      this.snackAlert.snackMessage = message;
    },

    // Reset the form after successful product addition
    resetForm() {
      this.form = {
        productTitle: '',
        productDescription: '',
        main_category_name: '',
        subCategory: '',
        size: '',
        unit: '',
        color: '',
        price: '',
        discountRate: '',
        availableStock: '',
        images: []
      };
      this.previewImages = [];
    },
    addCategoryPageRoute() {
      this.$router.push({path: '/products-category'});
    },
    backPageRoute() {
      this.$router.back();
    },
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    }
  },

  mounted() {
    // Simulate ajax async set HTMl content.
    setTimeout(() => {
      this.html = ''
    }, 1500)
  },

  created() {
    this.fetchMainCategories();
  },
};
</script>

<style>
@import "../../styles/product_management/add_products.scss";
</style>