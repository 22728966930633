<template>
  <v-app id="app_live_tracking">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">ALL AGENTS LIST</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse all agents data.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="back()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:item>
          <v-text-field v-model="search"  density="comfortable" class="custom_input_field_color w-50 mt-5 ml-2" label="Search" persistent-placeholder placeholder="Search" variant="outlined"></v-text-field>
        </template>

        <template v-slot:append>
          <v-divider vertical thickness="2"></v-divider>
          <v-card-text class="custom_tool_bar_style">
            <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
              <v-icon size="large" color="#BB0000">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>
            <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
              <v-icon size="large" color="#2E7D32">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </v-card-text>
        </template>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-data-table :headers="headers" :items="agentListDetails" :search="search" class="custom_table_normal">
            <template v-slot:[`item.action`]="{ item }" v-if="permissions.write" >
                <v-switch v-model="item.active_status" :false-value="0" :true-value="1" color="#BB0000" hide-details label="Enable" @change="toggleEnable(item)"></v-switch>
                <v-divider class="mb-2"></v-divider>
                <p class="text-caption font-weight-medium mb-3">Enable | Disable Tracking Permission</p>
            </template>

            <template v-slot:[`item.name`]="{ item }" >
                <p class="text-caption custom_page_main_sub_title font-weight-bold">{{ item.name }}</p>
                <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-3">{{ item.registration_num }}</p>
            </template>


            <template v-slot:[`item.states`]="{ item }" >
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-chip v-if="item.active_status === 0" class="custom_green_chip" size="small" variant="outlined">
                    Available
                    <v-icon  class="justify-end ml-9"  icon="mdi-check"></v-icon>
                  </v-chip>

                  <v-chip v-if="item.active_status === 1" class="custom_red_chip" size="small" variant="outlined"
                  >
                    Not Available
                    <v-icon  class="justify-end ml-3" icon="mdi-close"></v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>

    </v-container>
  </v-app>
</template>

<script>
  // import { mapGetters } from 'vuex';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  import {getPermissions} from "@/plugins/permissions";
  import axios from "axios";
  export default {
    name: "AllAgents",
    data() {
      return {
        search: '',
        permissions: {},
        headers: [
          { text: 'AGENT NAME', value: 'name', title: 'AGENT NAME' , sortable: true},
          { text: 'CURRENT ADDRESS', value: 'address', title:'CURRENT ADDRESS', sortable: true},
          { text: 'DISTRICT', value: 'district', title:'DISTRICT' , sortable: true},
          { text: 'DSD', value: 'dsd', title:'DSD' , sortable: true},
          { text: 'STATUS', value: 'states', title: 'STATUS' , sortable: true},
          { text: 'Actions', value: 'action', sortable: false , title:'ACTION' },
        ],

        agentListDetails:[]
      }
    },

    computed: {

    },

    mounted() {
      const dashboardId = 7;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);
      this.getDashboardPermissions();
    },
    created(){
      this.getAllAgents();
    },

    methods: {
      toggleEnable(item) {
        console.log('item',item);
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('agentId', item.id);
        formData.append('activeStatus', item.active_status);

        axios.post('/api/updateTracking/activeStatus', formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          if (response.status === 200) {
            // this.allOfficerDetails = response.data.ofiicerDetails;
            this.getAllAgents();

          } else {
            console.error("Error:", response.data.error);
          }
        })
        .catch(error => {
          console.error("Error fetching chart details:", error);
        });
      },

      getDashboardPermissions() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post(`/api/getDashboard?userId=${userId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
              }
            })
            .then(response => {
              const dashboards = response.data.data;
              this.permissionsItems = dashboards.map(dashboard => ({
                id: dashboard.id,
                premission_dashboard: dashboard.premission_dashboard,
                isOn: false,
                permissions: {read: false, write: false, delete: false},
              }));
              console.log('Dashboard permissions:', this.permissionsItems);
            })
            .catch(error => {
              console.error('Error fetching dashboard permissions:', error);
            });
      },


      getAllAgents(){
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId);

        axios.post('/api/getAgentList', formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })

        .then(response => {
          if (response.status === 200) {
            this.agentListDetails = response.data.data;
            console.log('agents',this.agentListDetails)
          } else {
            console.error("Error:", response.data.error);
          }
        })
        .catch(error => {
          console.error("Error fetching chart details:", error);
        });
      },

      backPageRoute() {
        this.$router.push('/dashboard');
      },

      back(){
        this.$router.push('/live-tracking');
      },

      exportPDF() {
        const doc = new jsPDF();
        // Set title with improved styling
        doc.setFontSize(16);
        doc.text('Agent Report', 14, 20);

        // Subtitle or additional information
        doc.setFontSize(10);
        doc.text('Agent report with geofence details', 14, 28);

        // Define the columns with better alignment
        const tableColumns = [
          'Agent Name', 'Reg Num', 'Type', 'Email', 'NIC', 'Gender',
          'Address', 'Province', 'District', 'DSD', 'GND',
          'MyRoute', 'RoutePlan', 'SalesGeofence', 'CollectionGeofence'
        ];

        // Prepare data for the table
        const tableData = this.agentListDetails.map((item) => [
          item.name,
          item.registration_num,
          item.user_type,
          item.email,
          item.nic,
          item.gender,
          item.address,
          item.province,
          item.district,
          item.dsd,
          item.gnd,
          item.my_route_geofence == 1 ? 'Active' : 'Inactive',
          item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
          item.sales_geofence == 1 ? 'Active' : 'Inactive',
          item.collection_geofence == 1 ? 'Active' : 'Inactive'
        ]);

        doc.autoTable({
          head: [tableColumns],
          body: tableData,
          startX: 35,
          theme: 'grid',
          headStyles: {
            fillColor: [40, 40, 200],
            textColor: [255, 255, 255],
            fontSize: 5,
            halign: 'center',
            valign: 'middle',
            cellPadding: 0.5,
          },
          bodyStyles: {
            fontSize: 5,
            textColor: [50, 50, 50],
            halign: 'center',
            valign: 'middle',
            cellPadding: 0.5,
          },
          alternateRowStyles: {
            fillColor: [240, 240, 240],
          },
          columnStyles: {
            0: { cellWidth: 12 },
            1: { cellWidth: 12 },
            2: { cellWidth: 12 },
            3: { cellWidth: 20 },
            4: { cellWidth: 12 },
            5: { cellWidth: 12 },
            6: { cellWidth: 12 },
            7: { cellWidth: 12 },
          },
          margin: { top: 10 },
        });

        // Footer with date
        doc.setFontSize(10);
        doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

        // Save the PDF
        doc.save('All Agents.pdf');
      },

      exportExcel() {
        // Prepare the data for Excel export
        const tableData = this.agentListDetails.map((item) => ({
          'AGENT NAME': item.name,
          'REG NUM': item.registration_num,
          'TYPE': item.user_type,
          'EMAIL': item.email,
          'NIC': item.nic,
          'GENDER': item.gender,
          'ADDRESS': item.address,
          'PROVINCE': item.province,
          'DISTRICT': item.district,
          'DSD': item.dsd,
          'GND': item.gnd,
          'MY ROUTE PLAN': item.my_route_geofence == 1 ? 'Active' : 'Inactive',
          'ROUTE PLAN': item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
          'SALES GEOFENCE': item.sales_geofence == 1 ? 'Active' : 'Inactive',
          'COLLECTION GEOFENCE': item.collection_geofence == 1 ? 'Active' : 'Inactive',
        }));

         // Always add headers if there's no data
         const headers = [
            'AGENT NAME', 'REG NUM', 'TYPE', 'EMAIL', 'NIC', 'GENDER', 'ADDRESS', 
            'PROVINCE', 'DISTRICT', 'DSD', 'GND', 'MY ROUTE PLAN', 'ROUTE PLAN', 
            'SALES GEOFENCE', 'COLLECTION GEOFENCE'
          ];


          // If there's no data, ensure the header is included
          if (tableData.length === 0) {
            tableData.push(headers.reduce((obj, header) => {
              obj[header] = '';
              return obj;
            }, {}));
          }

        // Create worksheet from JSON data
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set custom column widths
        worksheet['!cols'] = [
          { wch: 20 },
          { wch: 15 },
          { wch: 10 },
          { wch: 25 },
          { wch: 15 },
          { wch: 10 },
          { wch: 30 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
        ];

        // Freeze the header row
        worksheet['!freeze'] = { ySplit: 1 };

        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            font: { bold: true },
            alignment: { horizontal: 'center' },
          };
        }

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');

        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'All_Agents_Reports.xlsx');
      },
    },
  };
</script>


<style>
  @import "../../styles/tracking_management/live_tracking.scss";
</style>