<template>
  <v-app id="app_live_tracking">
    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">TRACKING ANALYSIS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analysis of daily traffic data of agents.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="back()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="allAgents()">
                <template v-slot:title>
                  <p class="custom_page_component_main_title text-body-2 font-weight-bold">ALL</p>
                </template>
                <template v-slot:item>
                  <p class="font-weight-bold custom_page_main_sub_title_very_small_red text-h4">{{ this.allOfficerDetails.length }}</p>
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img  class="ml-2" :src="require('@/img/icons/all_vechile.png')" ></v-img>
                  </v-avatar>
                </template>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="idling()">
                <template v-slot:title>
                  <p class="custom_page_component_main_title text-body-2 font-weight-bold">AVAILABLE</p>
                </template>
                <template v-slot:item>
                  <p class="font-weight-bold custom_page_main_sub_title_very_small_red text-h4">{{ this.deactiveOfficerCount}}</p>
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img  class="ml-0" :src="require('@/img/icons/availbale_vechile.png')" ></v-img>
                  </v-avatar>
                </template>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card class="mx-auto custom_card_box" elevation="0" style="border: #2A8200 solid 1.6px !important;" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="custom_page_component_main_title text-body-2 font-weight-bold">ON TRIP</p>
                </template>
                <template v-slot:item>
                  <p class="font-weight-bold  text-h4">{{ this.activeOfficerCount }}</p>
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#2A8200">
                    <v-img  class="ml-0" :src="require('@/img/icons/ontrip_vechile.png')" ></v-img>
                  </v-avatar>
                </template>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="parking()">
                <template v-slot:title>
                  <p class="custom_page_component_main_title text-body-2 font-weight-bold">NOT AVAILABLE</p>
                </template>
                <template v-slot:item>
                  <p class="font-weight-bold custom_page_main_sub_title_very_small_red text-h4">{{ this.activeOfficerCount}}</p>
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img  class="ml-0" :src="require('@/img/icons/not_availbale_vechile.png')" ></v-img>
                  </v-avatar>
                </template>
              </v-card>
            </v-col>
          </v-row>

          <v-divider class="mt-4 mb-4"></v-divider>

          <v-row>
            <!-- <v-col cols="12" xs="12" sm="12" md="8" lg="9">
              <p class="custom_page_component_main_title text-subtitle-1 font-weight-bold text-uppercase">
                Active sales representatives live locations
              </p>
              <v-divider class="mt-4 mb-4"></v-divider>

              <GMapMap  :center="mapCenter" :zoom="7" class="custom_card_box d-flex live_tracking_map">
                <GMapMarker  
                  v-for="(m, index) in markers" 
                  :key="index"
                  :icon="{ url: require('@/img/icons/vechile_icon.png'), scaledSize: { width: 40, height: 35 } }"
                  :position="m.position" 
                  @click="selectAgent(m)"
                >
                  <GMapInfoWindow 
                    v-if="selectedAgent && selectedAgent.agentId === m.agentId"
                    :position="m.position"
                    @closeclick="closeInfoWindow"
                  >
                    <div>
                      <p><strong>Agent Name:</strong> {{ selectedAgentDetails.name }}</p>
                      <p><strong>Email:</strong> {{ selectedAgentDetails.email }}</p>
                      <p><strong>Gender:</strong> {{ selectedAgentDetails.gender }}</p>
                      <p><strong>Reg Num:</strong> {{ selectedAgent.agent_regnum }}</p>
                    </div>
                  </GMapInfoWindow>
                </GMapMarker>
              </GMapMap>
            </v-col> -->

            <v-col cols="12" xs="12" sm="12" md="8" lg="9">
              <p class="custom_page_component_main_title text-subtitle-1 font-weight-bold text-uppercase">
                Active sales representatives live locations
              </p>
              <v-divider class="mt-4 mb-4"></v-divider>

              <GMapMap :center="mapCenter" :zoom="7" class="custom_card_box d-flex live_tracking_map">
                <GMapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :icon="getRotatedIcon(m)"
                  :position="m.position"
                  @click="selectAgent(m)"
                >
                  <GMapInfoWindow
                    v-if="selectedAgent && selectedAgent.agentId === m.agentId"
                    :position="m.position"
                    @closeclick="closeInfoWindow"
                  >
                    <div>
                      <p><strong>Agent Name:</strong> {{ selectedAgentDetails.name }}</p>
                      <p><strong>Email:</strong> {{ selectedAgentDetails.email }}</p>
                      <p><strong>Gender:</strong> {{ selectedAgentDetails.gender }}</p>
                      <p><strong>Reg Num:</strong> {{ selectedAgent.agent_regnum }}</p>
                    </div>
                  </GMapInfoWindow>
                </GMapMarker>
              </GMapMap>
            </v-col>




            <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                <div class="mt-8">
                  <p class="custom_page_component_main_title text-subtitle-1 font-weight-bold text-uppercase">LIST OF AGENTS</p>
                  <p class="custom_page_component_main_title text-caption font-weight-medium">List of all live agents & their details.</p>
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <!-- <v-text-field clearable label="Search" placeholder="Search Agents"
                                variant="outlined"  density="comfortable"   class="custom_input_field_color"
                  ></v-text-field> -->
                </div>
                <div style="height: 340px !important; overflow-y: auto!important;">
                  <v-card class="mx-auto mb-3 custom_card_box mr-3" elevation="0" v-for="agent in this.activeAgents" :key="agent.id"  border="sm opacity-1" rounded="md">
                  <template v-slot:prepend>
                    <v-avatar :image="require('@/img/w.jpg')"  size="60"></v-avatar>
                    <v-btn color=""
                        class="mt-10 ml-10 position-absolute custom_green_btn"
                        icon rounded size="x-small"
                        variant="outlined">
                      <v-icon size="small">mdi-crosshairs-gps</v-icon>
                      <v-tooltip activator="parent" location="top">Locate</v-tooltip>
                    </v-btn>
                  </template>
                  <template v-slot:title>
                    <p class="float-end text-body-2 font-weight-bold mb-1">{{ agent.name }}</p>
                  </template>
                  <template v-slot:item>
                    <p class="float-end font-weight-medium custom_page_main_sub_title_very_small_red text-caption mb-3">{{ agent.registration_num }}</p>
                  </template>
                </v-card>
                </div>
            </v-col>
          </v-row>
        </v-card-text>


      </v-card>


    </v-container>
  </v-app>
</template>

<script>
  import {google  } from '@fawmi/vue-google-maps';
  import axios from "axios";

  // import _ from 'lodash';

  export default {
    name: 'TrackingAnalysis',
    components: {
      // Map, Marker
    },
    data() {
      return {
        markers: {}, // Initialize markers as an empty object
        agents: [
          {
            id: 1,
            name: 'Mr. Nehan Pannaseeha',
            regNum: '1001',
            vehicle: 'BHK-4321',
            avatar: 'path/to/avatar.jpg',
            trackingEnabled: true,
            position: { lat: 7.8731, lng: 80.7718 },
            location: 'Mawaramandiya',
            lastUpdated: '30 seconds ago',
            trips: [
              { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
              { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
            ],
          },
        ],
        mapCenter: { lat: 7.8731, lng: 80.7718 },
        selectedAgent: {},
        selectedAgentDetails:{},
        dialog: false,

        allOfficerDetails:[],
        activeAgents:[],
        activeOfficerCount:0,
        deactiveOfficerCount:0,
      };
    },

    watch(){

    },

    mounted() {
        // this.listenForVehicleUpdates();
        this.getAllAgentAndCount();

        // this.listenToAgentActivity();

        if (typeof google !== 'undefined' && google.maps) {
        // API is loaded, you can safely call updateUser now or later as needed
        console.log('fkkkk');
        } else {
          console.error('Google Maps API is not loaded yet.');
        }

        window.Echo.channel('SfaTeacking')
          .listen('LocationUpdated', (e) => {
              console.log('User updated:', e.location);
              // Update the dashboard with the new user data
              this.updateUser(e.location);
              this.getAllAgentAndCount();

              const alpha = e.heading; // Device heading (0-360 degrees)
              this.updateMarkerHeading(alpha);
          });
      },

    methods: {

       // Get a rotated icon based on the marker's heading
    getRotatedIcon(marker) {
      return {
        url: require('@/img/icons/vechile_icon.png'),
        scaledSize: { width: 40, height: 35 },
        rotation: marker.heading, // Rotate the icon based on the heading
      };
    },

    // Update marker heading based on device orientation
    updateMarkerHeading(alpha) {
      this.markers.forEach((marker) => {
        marker.heading = alpha; // Update heading dynamically
      });
    },

    
   

      closeInfoWindow() {
      this.selectedAgent = null;
      },

      // listenToAgentActivity() {
      //   window.Echo.channel('SfaTeacking')
      //     .listen('AgentStatusUpdated', (data) => {

      //       console.log('User updatedfkfk:', data);
      //       this.activeAgents = data.activeAgents;


      //       this.getAllAgentAndCount();
      //       // this.allOfficerDetails = data.ofiicerDetails;
      //       // this.activeOfficerCount = data.getActiveOfiicer;
      //       // this.deactiveOfficerCount = data.getDeactiveOfiicer;

      //       // console.log('User updated:', this.activeAgents);
      //     });
      // },

      updateUser(vehicle) {
        console.log('tracking',vehicle);
         const position = {
           lat: vehicle.lat,
           lng: vehicle.lng
         };
         console.log('tracking',position);


         this.markers = [{
             agentId:vehicle.agent_id,
             agent_regnum: vehicle.agent_regnum,
             agnet_name: vehicle.agnet_name,
             position: {
               lat: parseFloat(position.lat),
               lng: parseFloat(position.lng),
             }
           }];


           const existingAgent = this.activeAgents.find(agent => agent.agentId === vehicle.agent_id);
            if (!existingAgent) {
              this.activeAgents.push({
                agentId: vehicle.agent_id,
                registration_num: vehicle.agent_regnum,
                name: vehicle.agnet_name,
              });
            }
          
          console.log('fk', this.activeAgents);



            console.log('ooooooooooooooo', this.markers)
         },


         selectAgent(agent) {

          console.log("Marker clicked:", agent);
          this.selectedAgent = agent;
            // console.log('fkfkfll',agent);

            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem("token");

            const formData = new FormData();
            formData.append("userId", userId);
            formData.append("agentId", agent.agentId);
            formData.append("itineraryId", agent.itineraryId);
            formData.append("outletId", agent.outletId);

            axios.post('/api/getDetaisFromAgent',formData,{
              headers: {'Authorization': `Bearer ${token}`}
              })
              .then(response => {
                if (response.data.http_status === 'success') {
                  this.selectedAgentDetails = response.data.officerDetails;
                  console.log("fk1",this.selectedAgentDetails);
                
                  // this.dialog = true;
                }
              })
              .catch(() => {
                console.log("fk2");
              });
              this.dialog = true;
            },

            // Get All Agent Details and Active Deactive Ofiicer Count
            getAllAgentAndCount() {
              const userId = localStorage.getItem('userId');
              const token = localStorage.getItem('token');

              const formData = new FormData();
              formData.append('userId', userId);

              axios.post('/api/getAllAgentAndCount', formData, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(response => {
                if (response.status === 200) {
                  this.allOfficerDetails = response.data.ofiicerDetails;
                  // this.activeAgents = _.filter(this.allOfficerDetails, (officer) => officer.active_status === 1 );
                  console.log('user',this.allOfficerDetails);
                  this.activeOfficerCount = response.data.getActiveOfiicer;
                  this.deactiveOfficerCount = response.data.getDeactiveOfiicer;
                  this.$store.dispatch('updateAgentList', this.allOfficerDetails);
                } else {
                  console.error("Error:", response.data.error);
                }
              })
              .catch(error => {
                console.error("Error fetching chart details:", error);
              });
            },

          allAgents() {
            this.$router.push('/all-agents');
          },

          idling(){
            this.$router.push('/idling-agents');
          },

          parking(){
            this.$router.push('/parking-agents');
          },

          back(){
            this.$router.push('/dashboard');
          }
      },
  };
</script>

<style>
  @import "../../styles/tracking_management/live_tracking.scss";
</style>