<template>
  <v-app id="app_user_list">

    <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">REGISTERED USER LIST</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse already registered user data.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="createUser()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;CREATE USER
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="userCatagory" :items="userCatagaryItems" clearable item-title="name"
                              item-value="name" label="User Catagory" multiple
                              placeholder="User Type" variant="outlined" density="comfortable"
                              class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="userRole.userType" :items="userTypes" clearable item-title="name"
                              item-value="name" label="User Role Type"
                              multiple placeholder="User Type" variant="outlined" density="comfortable"
                              class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                  v-model="search" append-inner-icon="mdi-magnify" clearable hide-details label="Search"
                  placeholder="Search" variant="outlined" density="comfortable" class="custom_input_field_color"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table v-model:page="page" :loading="loading" :headers="headers" :items="filteredItems"
                        :items-per-page="itemsPerPage" class="custom_table_normal" @page-count="totalPages = $event">
            <!-- Template for mobile_number -->
            <template v-slot:[`item.mobile_number`]="{ item }">
              <div>
                <v-col v-for="(mobile, index) in item.mobile_number" :key="index" cols="12">
                  <v-chip size="small" class="px-1" label variant="text">
                    {{ mobile.mobile_number }}
                  </v-chip>
                </v-col>
              </div>
            </template>


            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_user_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.write" @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="lockItem(item)">
                    <v-list-item-title class="custom_font_color_password_change">CHANGE PASSWORD</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View User Dialog -->
      <v-dialog v-model="view_user_dialog" fullscreen id="app_user_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="view_user_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">USER DETAILS</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="`${imageURL}/${user.user_image}`" aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #BB0000 !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                          v-if="permissions.write" class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined" @click="editItem(user)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                          v-if="permissions.delete" class="custom_delete_btn" icon rounded
                          size="small" variant="outlined" @click="confirmDelete(user)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="user.user_name" label="User Name" readonly
                        variant="outlined" density="comfortable"
                        class="px-3 custom_input_field_class_view custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.user_type" :rules="[v => !!v || 'User role is required']"
                        class="px-3 mt-2 custom_input_field_class_view" label="User Role Type *" outlined
                        placeholder="Select User Type Here"
                        variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.user_catagory.category"
                        class="px-3 mt-2 custom_input_field_class_view custom_input_field_color" label="User Catagary *"
                        outlined
                        variant="outlined" readonly density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.province"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                                  label="Province"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.district"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                                  label="District"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.dsd"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color" label="DSD"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.gnd"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color" label="GND"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.email"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                                  label="Email Address"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field v-model="user.nic" label="NIC"
                                  class="px-3 custom_input_field_class_view custom_input_field_color"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.registration_num"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                                  label="Registration Number"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>


                    <div v-for="(mobile, index) in user.mobile_number" :key="index">
                      <v-text-field v-model="mobile.mobile_number" :label="'Mobile Number ' + (index + 1)"
                                    class="px-3 custom_input_field_class_view custom_input_field_color" readonly
                                    variant="outlined" density="comfortable"
                      ></v-text-field>
                    </div>

                    <v-card-text v-if="user.user_catagory.category == 'Mobile User' ">
                      <p class="custom_page_main_sub_title">
                        <strong>GEOFENCE AVAILABILITY</strong>
                      </p>
                      <v-divider class="mt-4"></v-divider>

                      <v-timeline v-if="user.route_plan_geofence === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Route Plan Geofence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.my_route_geofence === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> My Route GeoFence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.collection_geofence === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Collection Geofance</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.sales_geofence === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Sales Geofence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.overall_tracking === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Overall Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.my_route_tracking === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong>My Route Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.route_plan_tracking === 1" align="start" density="compact">
                        <v-timeline-item dot-color="#BB0000" size="x-small">
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong>Route Plan Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>


                    <v-text-field v-model="user.gender"
                                  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                                  label="Gender" readonly
                                  variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-textarea v-model="user.address"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color" label="Address"
                                readonly
                                rows="6" variant="outlined" density="comfortable"
                    ></v-textarea>
                  </v-col>
                </v-row>


                <v-row class="pa-3" v-if="user.user_catagory.category !== 'Mobile User' ">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider class="mb-6 "></v-divider>

                    <v-card elevation="1" border="sm opacity-1" rounded="lg">
                      <v-card color="#F0F0F0" flat rounded="0">
                        <template v-slot:prepend>
                          <v-card-title class="text-subtitle-1 font-weight-bold">PERMISSIONS</v-card-title>
                        </template>
                      </v-card>
                      <v-card-text class="mb-3">
                        <v-row v-for="(item, index) in permissionsItems" :key="item.id">
                          <v-col cols="4" style="margin-bottom: -2.5% !important;">
                            <v-switch readonly v-model="item.isOn" :color="item.isOn ? '#BB0000' : 'grey'" class="mr-1"
                                      @change="handleSwitchChange(item)">
                              <template v-slot:label>
                                <v-card-title class="custom_page_main_sub_title">{{ item.premission_dashboard }}
                                </v-card-title>
                              </template>
                            </v-switch>
                          </v-col>

                          <v-col v-if="item.isOn" cols="8" style="margin-bottom: -2.5% !important;">
                            <v-row>
                              <v-col class="d-flex" cols="4">
                                <v-switch readonly v-model="item.permissions.read" color="#BB0000"
                                          @change="handlePermissionChange(item, 'read')">
                                  <template v-slot:label>
                                    <v-card-title class="custom_page_main_sub_title">Read</v-card-title>
                                  </template>
                                </v-switch>
                              </v-col>

                              <v-col class="d-flex" cols="4">
                                <v-switch readonly v-model="item.permissions.write" color="#BB0000"
                                          @change="handlePermissionChange(item, 'write')">
                                  <template v-slot:label>
                                    <v-card-title class="custom_page_main_sub_title">Write</v-card-title>
                                  </template>
                                </v-switch>
                              </v-col>

                              <v-col class="d-flex" cols="4">
                                <v-switch readonly v-model="item.permissions.delete" color="#BB0000"
                                          @change="handlePermissionChange(item, 'delete')">
                                  <template v-slot:label>
                                    <v-card-title class="custom_page_main_sub_title">Delete</v-card-title>
                                  </template>
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="edit_user_dialog" fullscreen id="app_user_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="edit_user_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">EDIT USER DETAILS
                  </v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="4" md="2" lg="2">
                        <div class="image card-h4-title" style="position: relative;">
                          <img
                              :src="user.user_image ? `${imageURL}/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                              class="user_image_view"/>

                          <v-btn class="custom_user_image_upload_btn custom_delete_btn" icon rounded size="small"
                                 variant="outlined" @click="dialog_image_upload=true">
                            <v-icon>mdi-upload</v-icon>
                            <v-tooltip activator="parent" location="top">Upload User Image</v-tooltip>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="8" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #BB0000 !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateUser">UPDATE
                        USER
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field v-model="user.name" class="px-3 custom_input_field_color" label="User Full Name *"
                                  required variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field v-model="user.user_name" class="px-3 mt-2 custom_input_field_color"
                                  label="User Name *"
                                  required variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-autocomplete v-model="user.user_type" :items="userTypes"
                                    :rules="[v => !!v || 'User role is required']"
                                    class="px-3 mt-2 custom_input_field_color"
                                    clearable item-title="name" item-value="id" label="User Role Type *"
                                    placeholder="Select User Type Here"
                                    required variant="outlined" @update:modelValue="openDashBoardSeletDialog"
                                    density="comfortable"
                    ></v-autocomplete>

                    <v-row class="px-3"
                           v-if="user.user_catagory.category !== 'Mobile User'  && checkPremissionGeoFence !== 'Mobile User'">
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-card color="white" elevation="1" border="sm opacity-1" rounded="lg" class="mb-7">
                          <v-card-text>
                            <div class="mb-4 font-weight-medium text-body-2 custom_page_main_sub_title_very_small_red ">
                              <v-icon style="margin-left: -1%!important;" size="large" color="#BB0000">
                                mdi-arrow-up-bold
                              </v-icon>
                              Hint: In above section, you can configure the appropriate permissions for user roles by
                              choosing
                              the desired role type from the dropdown menu.
                            </div>
                            <p class="mt-2 text-caption">
                              <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 1 : Open dropdown
                              menu.
                            </p>
                            <p class="mt-2 text-caption">
                              <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 2 : Choose your
                              preferred role from the dropdown list.
                            </p>
                            <p class="mt-2 text-caption">
                              <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 3 : Access the
                              permissions dialog.
                            </p>
                            <p class="mt-2 text-caption">
                              <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 4 : Set up the
                              necessary permissions.
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>


                    <v-autocomplete v-model="user.province" :clearable="user.province !== null && user.province !== ''"
                                    :items="provinces" :loading="mProvinceLoading"
                                    class="px-3 mt-2 custom_input_field_color" item-text="label" item-value="value"
                                    label="Province *" placeholder="Select province" variant="outlined"
                                    density="comfortable"
                                    @update:modelValue="(value) => {
                        user.province = value;
                        if (!value) {
                          user.district = null;
                          user.dsd = null;
                          user.gnd = null;
                          districts = [];
                          dsdList = [];
                          gndList = [];
                        } else {
                          getAllDistrictAccProv(value);
                        }
                      }"
                    ></v-autocomplete>

                    <v-autocomplete v-model="user.district" :items="districts" :loading="mDistrictLoading"
                                    class="px-3 mt-2 custom_input_field_color" item-text="label" item-value="value"
                                    label="District"
                                    placeholder="Select district" variant="outlined" @update:modelValue="getAllDsd"
                                    density="comfortable"
                    ></v-autocomplete>

                    <v-autocomplete v-model="user.dsd" :items="dsdList" :loading="mDSDLoading"
                                    class="px-3 mt-2 custom_input_field_color" item-text="label" item-value="value"
                                    label="DSD" placeholder="Select DSD" variant="outlined"
                                    @update:modelValue="getAllGnd" density="comfortable"
                    ></v-autocomplete>

                    <v-autocomplete v-model="user.gnd" :items="gndList" :loading="mGNDLoading"
                                    class="px-3 mt-2 custom_input_field_color" label="GND" placeholder="Select GND"
                                    required variant="outlined" density="comfortable"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                    <v-text-field disabled v-model="user.email" class="px-3 custom_input_field_color"
                                  label="Email Address *" placeholder="Type Email Here"
                                  required variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-text-field disabled v-model="user.registration_num" class="px-3 mt-2 custom_input_field_color"
                                  label="Registration Number *" placeholder="Type Mobile Number Here"
                                  readonly variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-row class="mb-8" style="margin-top: -5% !important;">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <v-btn class="custom_excel_btn mt-6" variant="outlined" @click="addPhoneNo">
                            <v-icon>mdi-plus-thick</v-icon>&nbsp; Add Phone Number
                          </v-btn>
                          <v-divider class="mt-3"></v-divider>
                        </v-col>
                      </v-row>

                      <div v-for="(phoneNo, index) in user.mobile_number" :key="index">
                        <v-row style="margin-top: -4% !important;">
                          <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                            <v-text-field v-model="phoneNo.mobile_number" :label="'Mobile Number ' + (index + 1)"
                                          :rules="phoneRules"
                                          density="comfortable" class="custom_input_field_color"
                                          placeholder="Type Phone Number Here" variant="outlined">
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                            <v-btn v-if="user.mobile_number.length > 1" style="width: 100% !important;"
                                   class="custom_pdf_btn"
                                   variant="outlined" @click="removePhoneNo(index)"
                            >
                              <v-icon>mdi-minus-thick</v-icon>&nbsp; Remove
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-autocomplete
                        v-if="(user.user_catagory.category === 'Mobile User' || checkPremissionGeoFence === 'Mobile User') && checkPremissionGeoFence !== 'Web User'"
                        v-model="geofence" :items="geoFences" class="px-3 custom_input_field_color"
                        label="Select Geo Fence and Tracking *" multiple
                        placeholder="Select Geo Fence and Tracking" variant="outlined" density="comfortable"
                    ></v-autocomplete>

                    <v-text-field v-model="user.nic" :rules="nicRules" class="px-3 mt-2 custom_input_field_color"
                                  label="NIC *" placeholder="Type NIC"
                                  required variant="outlined" density="comfortable"
                    ></v-text-field>

                    <v-autocomplete v-model="user.gender" :items="gender" class="px-3 mt-2 custom_input_field_color"
                                    label="Gender *"
                                    placeholder="Select Gender" required variant="outlined" density="comfortable"
                    ></v-autocomplete>

                    <v-textarea v-model="user.address" class="px-3 mt-2 custom_input_field_color" label="Address *"
                                placeholder="Type Address Here"
                                required rows="6" variant="outlined" density="comfortable"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit User dialog -->


      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="delete_user_dialog" id="app_user_list" max-height="500" max-width="500" persistent>
        <v-card id="app_user_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE USER</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="delete_user_dialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this user ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This user will be deleted immediately. Once deleted, it can no longer be used in any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="delete_user_dialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->


      <!-- Upload user image dialog -->
      <v-dialog v-model="dialog_image_upload" id="app_user_list" max-height="600" max-width="500" persistent>
        <v-card id="app_user_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">CHANGE USER IMAGE</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="dialog_image_upload = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <div class="drop-area" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent>
                <input ref="fileInput" accept="image/*" style="display: none;" type="file" @change="handleImageUpload"/>
                <div v-if="previewImage" class="image-card-container">
                  <v-card class="image-card">
                    <v-img :alt="'Image preview'" :src="previewImage" class="image-preview"></v-img>
                    <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage">
                      <v-icon color="red" size="small">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                </div>
                <div v-if="!previewImage" class="placeholder-container">
                  <v-icon size="36">mdi-cloud-upload</v-icon>
                  <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>
                </div>
              </div>


              <v-divider class="mt-4"></v-divider>

              <div class="float-end mt-4 ">
                <v-btn class="custom_btn_primary" @click="uploadImage">Upload</v-btn>
                <v-btn class="ml-3 custom_btn_secondary" @click="dialog_image_upload = false">CANCEL</v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Upload user image dialog -->


      <!-- Update user permissions dialog -->
      <v-dialog v-model="dashboardSelectDialog" id="app_user_list" max-height="700" max-width="1000" persistent>
        <v-card rounded="md" style="overflow:hidden !important;">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">SET PERMISSIONS</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="dashboardSelectDialogClose" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="7" md="7" lg="7"></v-col>
              <v-col cols="12" xs="12" sm="5" md="5" lg="5">
                <v-btn class="float-end text-caption  custom_btn_secondary"
                       v-if="this.premissionStatus !== 'viewPremission'" @click="dashboardSelectDialog = false">CONFIRM
                </v-btn>
                <v-btn class="float-end mr-3 custom_clear_all_btn" v-if="this.premissionStatus !== 'viewPremission'"
                       @click="cancelAll">Clear All
                </v-btn>
                <v-btn class="float-end mr-3 custom_select_all_btn" v-if="this.premissionStatus !== 'viewPremission'"
                       @click="selectAll">Select All
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>


            <v-card-text style="overflow-y: scroll !important;height: 450px !important;">
              <v-row v-for="(item, index) in permissionsItems" :key="item.id">
                <v-col cols="4" style="margin-bottom: -2.5% !important;">
                  <v-switch v-model="item.isOn" :color="item.isOn ? '#BB0000' : 'grey'" class="mr-1"
                            @change="handleSwitchChange(item)">
                    <template v-slot:label>
                      <v-card-title class="custom_page_main_sub_title">{{ item.premission_dashboard }}</v-card-title>
                    </template>
                  </v-switch>
                </v-col>

                <v-col v-if="item.isOn" cols="8" style="margin-bottom: -2.5% !important;">
                  <v-row>
                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.read" color="#BB0000"
                                @change="handlePermissionChange(item, 'read')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Read</v-card-title>
                        </template>
                      </v-switch>
                    </v-col>

                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.write" color="#BB0000"
                                @change="handlePermissionChange(item, 'write')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Write</v-card-title>
                        </template>
                      </v-switch>
                    </v-col>

                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.delete" color="#BB0000"
                                @change="handlePermissionChange(item, 'delete')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Delete</v-card-title>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
      <!-- Update user permissions dialog -->


      <!-- View User Dialog -->
      <v-dialog v-model="reset_password_dialog" fullscreen id="app_user_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="reset_password_dialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">RESET PASSWORD</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="`${imageURL}/${user.user_image}`" aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #BB0000 !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-tabs bg-color="#FFEBEE" v-model="tab" align-tabs="center"
                              next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
                              show-arrows>
                        <v-tab class="custom_page_main_sub_title" value="one">GENERATE RESET LINK</v-tab>
                        <v-tab class="custom_page_main_sub_title" value="two">AUTO GENERATE PASSWORD</v-tab>
                        <v-tab class="custom_page_main_sub_title" value="three">MANUAL PASSWORD CHANGE</v-tab>
                      </v-tabs>

                      <v-tabs-window v-model="tab">
                        <v-tabs-window-item class="mt-9" value="one">
                          <v-btn class="text-body-2 custom_btn_primary" size="x-large" variant="outlined"
                                 @click="generateResetLink(user)">
                            GENERATE & SEND RESET LINK
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9" value="two">
                          <v-btn class="text-body-2 custom_btn_primary" size="x-large" variant="outlined"
                                 @click="generateAndSendPassword(user)">
                            GENERATE PASSWORD & SEND PASSWORD
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9 px-1" value="three">
                          <v-text-field
                              v-model="manualPassword" :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              :rules="[
                                v => !!v || 'Password is required',
                                v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                                v => /[A-Za-z]/.test(v) || 'Password must contain at least one letter',
                                v => /\d/.test(v) || 'Password must contain at least one number',
                              ]"
                              :type="showPassword ? 'text' : 'password'" autocomplete="new-password"
                              class="mt-2 custom_input_field_color" label="Password *" placeholder="Type password here"
                              required variant="outlined" @click:append-inner="togglePasswordVisibility"
                              density="comfortable"
                          ></v-text-field>

                          <!--                          <v-row >-->
                          <!--                            <v-col cols="12" xs="12" sm="12" md="12" lg="12">-->
                          <!--                              <v-card color="white" elevation="2" class="mt-1" v-if="manualPassword">-->
                          <!--                                <v-card-text>-->
                          <!--                                  <div class="mb-4 custom_paragraph_option_2">Your password must contain:</div>-->
                          <!--                                  <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="manualPassword.length < 8" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="manualPassword.length >= 8" size="small">mdi-check</v-icon> At least 8 characters</p>-->
                          <!--                                  <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[a-z]).+$/.test(manualPassword))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[a-z]).+$/.test(manualPassword)" size="small">mdi-check</v-icon> Lower case letters (a-z)</p>-->
                          <!--                                  <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[A-Z]).+$/.test(manualPassword))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[A-Z]).+$/.test(manualPassword)" size="small">mdi-check</v-icon> Upper case letters (A-Z)</p>-->
                          <!--                                  <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[0-9]).+$/.test(manualPassword))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[0-9]).+$/.test(manualPassword)" size="small">mdi-check</v-icon> Numbers (0-9)</p>-->
                          <!--                                </v-card-text>-->
                          <!--                              </v-card>-->
                          <!--                            </v-col>-->
                          <!--                          </v-row>-->

                          <v-text-field v-model="manualConfirmPassword"
                                        :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="showConfirmPassword ? 'text' : 'password'" autocomplete="new-password"
                                        class="mt-2 custom_input_field_color" label="Confirm Password *"
                                        placeholder="Confirm your password here" required variant="outlined"
                                        @click:append-inner="toggleConfirmPasswordVisibility" density="comfortable"
                          ></v-text-field>

                          <v-divider></v-divider>

                          <v-row class="pa-3 mb-2 mt-3">
                            <v-btn class="text-body-2 custom_btn_primary mr-3" size="large" variant="outlined"
                                   @click="updatePasswordRest(user)">
                              UPDATE
                            </v-btn>
                            <v-btn class="text-body-2 custom_btn_secondary" size="large" variant="outlined"
                                   @click="reset_password_dialog = false">
                              CANCEL
                            </v-btn>
                          </v-row>
                        </v-tabs-window-item>

                      </v-tabs-window>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: 'UserRoleList',
  data() {
    return {
      imageURL: "",
      previewImage: null,
      imageBase64: null,
      checkUserCatagaryType: '',
      premissionStatus: '',
      centerLoading: false,
      permissionsItems: [],
      userRoleID: '',
      dashboardSelectDialog: false,
      dialog_image_upload: false,
      selectedFile: null,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      // Dialog state
      delete_user_dialog: false,
      view_user_dialog: false,
      edit_user_dialog: false,
      reset_password_dialog: false,
      showPassword: false,
      showConfirmPassword: false,
      //reset password three options tabs
      tab: null,
      autoGenaratePassword: null,
      geoFences: ["Route Plan Geofence", "My Route Geofence", "Collection Geofence", "Sales Geofence", "Overall Tracking", "Route Plan", "Myroute"],
      nicRules: [
        v => !!v || 'NIC is required',
        v => /^[0-9]{9}[vVxX]$|^[0-9]{12}$/.test(v) || 'Must be a valid NIC number'
      ],
      search: '',
      headers: [
        {text: 'REG: NUMBER', value: 'registration_num', title: 'REG: NUMBER', sortable: true},
        {text: 'USER NAME', value: 'user_name', title: 'USER NAME', sortable: true},
        {text: 'USER ROLE TYPE', value: 'user_type', title: 'USER ROLE TYPE', sortable: true},
        {text: 'MOBILE', value: 'mobile_number', title: 'MOBILE', sortable: true},
        {text: 'NIC', value: 'nic', title: 'NIC', sortable: true},
        {text: 'ACTION', value: 'action', title: 'ACTION'}
      ],

      items: [],

      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /^\+\d{1,3}\d{10}$/.test(v) || 'Must be a valid phone number with country code (e.g., +123123456789)',
      ],
      userTypes: [],
      gender: ['Male', 'Female'],
      checkPremissionGeoFence: '',
      userCatagory: '',
      userCatagaryItems: ['Web User', 'Mobile User'],
      itemsPerPage: 10,
      page: 1,
      totalPages: 1,
      userRole: {
        userType: '',
      },
      createdUserId: '',
      manualPassword: "",
      manualConfirmPassword: '',
      loading: false,
      user: {},
      geofence: [],
      mDistrictLoading: false,
      mProvinceLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      deleteItemId: null, // Store the ID of the item to be deleted
      permissions: {},
    };
  },

  mounted() {
    const dashboardId = 4;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.getAllProvinces();
    this.getAllUserRoles();

  },

  created() {
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    this.fetchDataintoTable();
  },

  computed: {
    filteredItems() {
      const searchLower = this.search ? this.search.toLowerCase() : '';

      return this.items.filter(item => {
        const itemUserType = item.user_type || '';
        const selectedUserTypes = this.userRole.userType || [];
        const itemUserCatagory = item.user_catagory?.category || ''; // Use optional chaining
        const selectedUserCatagory = this.userCatagory || [];

        const matchesUserRole =
            selectedUserTypes.length === 0 ||
            (Array.isArray(selectedUserTypes) && selectedUserTypes.includes(itemUserType));

        const matchesUserCatagory =
            selectedUserCatagory.length === 0 ||
            (Array.isArray(selectedUserCatagory) && selectedUserCatagory.includes(itemUserCatagory));

        const registrationNum = item.registration_num ? item.registration_num.toLowerCase() : '';
        const userName = item.user_name ? item.user_name.toLowerCase() : '';
        const userType = item.user_type ? item.user_type.toLowerCase() : '';
        const nic = item.nic ? item.nic.toLowerCase() : '';
        const catagory = item.user_catagory?.category ? item.user_catagory.category.toLowerCase() : ''; // Safe access

        // Check if the search term matches any of the fields
        const matchesSearch =
            !this.search ||
            userName.includes(searchLower) ||
            registrationNum.includes(searchLower) ||
            userType.includes(searchLower) ||
            catagory.includes(searchLower) ||
            nic.includes(searchLower);

        // Return true only if both filters match
        return matchesUserRole && matchesSearch && matchesUserCatagory;
      });
    }
  },

  methods: {
    validateForm() {
      // Validate form fields
      if (!this.manualPassword || this.manualPassword.length < 8) {
        this.showSnackbar("Password must be at least 8 characters long.", "error");
        return false;
      }
      if (!/[A-Za-z]/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one letter.", "error");
        return false;
      }
      if (!/\d/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one number.", "error");
        return false;
      }
      if (this.manualPassword !== this.manualConfirmPassword) {
        this.showSnackbar("Passwords do not match.", "error");
        return false;
      }

      return true;
    },

    removeImage() {
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },

    handlePermissionChange(item) {
      if (!item.permissions.read && !item.permissions.write) {
        item.permissions.delete = false;
      }
    },

    selectAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = true;
        item.permissions.read = true;
        item.permissions.write = true;
        item.permissions.delete = true;
      });
    },

    cancelAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = false;
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      });
    },

    getDashboardPermissions(item) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
        }
      })
          .then(response => {
            const dashboards = response.data.data;
            const permissions = item; // The permissions array from the backend

            this.permissionsItems = dashboards.map(dashboard => {
              // Find the corresponding permission for this dashboard
              const matchingPermission = permissions.find(permission => permission.dashboard_id === dashboard.id);

              if (matchingPermission) {
                return {
                  id: dashboard.id,
                  premission_dashboard: dashboard.premission_dashboard,
                  isOn: true,
                  permissions: {
                    read: matchingPermission.read_premission === 1,
                    write: matchingPermission.write_premission === 1,
                    delete: matchingPermission.delete_premission === 1
                  }
                };
              } else {
                // Return default permission structure if no matching permission is found
                return {
                  id: dashboard.id,
                  premission_dashboard: dashboard.premission_dashboard,
                  isOn: false,
                  permissions: {read: false, write: false, delete: false}
                };
              }
            });

            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    // Open DashBoard Seletec dialog
    openDashBoardSeletDialog(selectedId) {
      const selectedItem = this.userTypes.find(type => type.id === selectedId);
      if (selectedItem) {
        this.selectedRoleId = selectedItem.id;
        this.selectedRoleName = selectedItem.name;
        console.log('Selected ID:', selectedItem.id);
        console.log('Selected Name:', selectedItem.name);

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post('/api/checkUserRole', {
          userId: userId,
          userType: selectedItem.name
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              let usrRole = response.data.data;
              this.checkPremissionGeoFence = usrRole;
              console.log('User role types:', usrRole);

              // Open dialog only if the role is 'Web User'
              if (usrRole === 'Web User') {
                this.dashboardSelectDialog = true;
              } else {
                this.dashboardSelectDialog = false;
              }
            })
            .catch(error => {
              console.error('Error fetching user roles:', error);
              this.dashboardSelectDialog = false; // Close dialog in case of error
            });
      }
    },

    openViewDashBoardSeletDialog() {
      this.premissionStatus = 'viewPremission'
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/checkUserRole', {
        userId: userId,
        userType: this.user.user_type
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.checkPremissionGeoFence = usrRole;
            console.log('User role types:', usrRole);

            // Open dialog only if the role is 'Web User'
            if (usrRole === 'Web User') {
              this.dashboardSelectDialog = true;
            } else {
              this.dashboardSelectDialog = false;
            }
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
            this.dashboardSelectDialog = false; // Close dialog in case of error
          });
    },

    viewuserdialogClose() {
      this.view_user_dialog = false;
      this.premissionStatus = null;
    },

    dashboardSelectDialogClose() {
      this.dashboardSelectDialog = false;
      setTimeout(() => {
        this.premissionStatus = null;
      }, 10000);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();  // Triggers file input dialog
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result; // Set the previewImage to the base64 data URL

          const fullBase64 = e.target.result; // Full Base64 string
          this.imageBase64 = fullBase64.split(',')[1]; // Extract only the data portion
          // this.user.user_image = this.imageBase64;
        };
        reader.readAsDataURL(file); // Convert the file to a base64 string
      }
    },

    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleImageUpload({target: {files: [file]}});
    },

    uploadImage() {
      if (this.imageBase64) {
        // Send the extracted Base64 string to the server
        console.log("Image Base64 string:", this.imageBase64);
        this.dialog_image_upload = false;
      } else {
        console.error("No image to upload");
      }
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      // Define the base columns for all users
      const baseColumns = [
        'USER NAME', 'REG NUM', 'TYPE', 'MOBILE NUMBER', 'USER CATEGORY', 'EMAIL',
        'NIC', 'GENDER', 'ADDRESS', 'PROVINCE', 'DISTRICT', 'DSD', 'GND',
      ];

      // Define additional columns for non-web users
      const extraColumns = [
        'MY ROUTE PLAN', 'ROUTE PLAN', 'SALES GEOFENCE',
        'MY ROUTE TRACKING', 'ROUTE PLAN TRACKING',
        'OVERALL TRACKING', 'COLLECTION GEOFENCE'
      ];

      // Check if additional columns should be included
      const includeExtraColumns = this.filteredItems.some(
          item => item.user_catagory?.category !== 'Web User'
      );

      // Final columns to include
      const allColumns = includeExtraColumns
          ? [...baseColumns, ...extraColumns]
          : baseColumns;

      // Prepare the data for Excel export
      const tableData = this.filteredItems.map((item) => {
        const rowData = {
          'Full NAME': item.name || '',
          'REG NUM': item.registration_num || '',
          'Role': item.user_type || '',
          'MOBILE NUMBER': item.mobile_number
              ? item.mobile_number.map((numberObj) => numberObj.mobile_number).join(', ')
              : 'N/A',
          'USER CATEGORY': item.user_catagory?.category || '',
          'EMAIL': item.email || '',
          'NIC': item.nic || '',
          'GENDER': item.gender || '',
          'ADDRESS': item.address || '',
          'PROVINCE': item.province || '',
          'DISTRICT': item.district || '',
          'DSD': item.dsd || '',
          'GND': item.gnd || '',
        };

        // Add extra columns only if the user is not a web user
        if (item.user_catagory?.category !== 'Web User') {
          rowData['MY ROUTE PLAN'] = item.my_route_geofence == 1 ? 'Active' : 'Inactive';
          rowData['ROUTE PLAN'] = item.route_plan_geofence == 1 ? 'Active' : 'Inactive';
          rowData['SALES GEOFENCE'] = item.sales_geofence == 1 ? 'Active' : 'Inactive';
          rowData['MY ROUTE TRACKING'] = item.my_route_tracking == 1 ? 'Active' : 'Inactive';
          rowData['ROUTE PLAN TRACKING'] = item.route_plan_tracking == 1 ? 'Active' : 'Inactive';
          rowData['OVERALL TRACKING'] = item.overall_tracking == 1 ? 'Active' : 'Inactive';
          rowData['COLLECTION GEOFENCE'] = item.collection_geofence == 1 ? 'Active' : 'Inactive';
        }

        return rowData;
      });

      // Create worksheet from JSON data with dynamic columns
      const worksheet = XLSX.utils.json_to_sheet(tableData, {header: allColumns});

      // Set custom column widths
      worksheet['!cols'] = Array(allColumns.length).fill({wch: 20}); // Adjust width dynamically

      // Freeze the header row
      worksheet['!freeze'] = {ySplit: 1};

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_Agents_Reports.xlsx');
    },


    exportPDF() {
      const doc = new jsPDF();

      // Set title with improved styling
      doc.setFontSize(16);
      doc.text('User Report', 14, 30);

      // Subtitle or additional information
      doc.setFontSize(10);
      doc.text('User report', 14, 38);

      // Define the columns and conditions dynamically
      const tableColumns = [
        'Full Name', 'Reg Num', 'Role', 'Email', 'NIC', 'Gender',
        'Address', 'Province', 'District', 'DSD', 'GND', 'mobile',
        'MyRoute', 'RoutePlan', 'SalesGeofence', 'CollectionGeofence'
      ];

      // Filter the columns based on the condition
      const isWebUser = this.filteredItems.some(item => item.user_catagory?.category === 'Web User');

      // Exclude specific columns if `item.user_catagory.category !== 'Web User'`
      const visibleColumns = isWebUser
          ? tableColumns.slice(0, 11) // Include only the first 11 columns
          : tableColumns; // Include all columns for non-web users

      // Prepare data for the table
      const tableData = this.filteredItems.map((item) => {
        const rowData = [
          item.name || '',
          item.registration_num || '',
          item.user_type || '',
          item.email || '',
          item.nic || '',
          item.gender || '',
          item.address || '',
          item.province || '',
          item.district || '',
          item.dsd || '',
          item.gnd || '',
          // item.mobile_number?.join(', ') || ''
        ];

        // Add conditional columns only if the user is not a web user
        if (item.user_catagory?.category !== 'Web User') {
          rowData.push(
              item.my_route_geofence == 1 ? 'Active' : 'Inactive',
              item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
              item.sales_geofence == 1 ? 'Active' : 'Inactive',
              item.collection_geofence == 1 ? 'Active' : 'Inactive'
          );
        }

        return rowData;
      });

      doc.autoTable({
        head: [visibleColumns],
        body: tableData,
        startX: 35,
        theme: 'grid',
        headStyles: {
          fillColor: [40, 40, 200],
          textColor: [255, 255, 255],
          fontSize: 5,
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        bodyStyles: {
          fontSize: 5,
          textColor: [50, 50, 50],
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: {cellWidth: 12},
          1: {cellWidth: 12},
          2: {cellWidth: 12},
          3: {cellWidth: 20},
          4: {cellWidth: 12},
          5: {cellWidth: 12},
          6: {cellWidth: 12},
          7: {cellWidth: 12},
          // 8: {cellWidth: 12},
        },
        margin: {top: 40}, // Adjust top margin for the table
      });

      // Footer with date
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF
      doc.save('User Details.pdf');
    },


    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mProvinceLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.user.district = null;
      this.user.dsd = null;
      this.user.gnd = null;
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async updateUser() {

      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem('userId');

        if (!this.user.id) {
          console.error("User ID is missing");
          return;
        }

        if (!this.user.user_type) {
          this.showSnackbar('User Type is required.', 'error');
          return;
        }

        console.log("Has:", this.checkUserCatagaryType);
        // Validate at least one permission is selected
        if (this.checkUserCatagaryType === "Web User") {
          const hasPermission = this.permissionsItems.some(item =>
              // item.permissions.read || item.permissions.write || item.permissions.delete
              item?.permissions?.read || item?.permissions?.write || item?.permissions?.delete
          );

          console.log("Has Permission:", hasPermission);

          if (!hasPermission) {
            this.showSnackbar('At least one permission (Read, Write, or Delete) must be selected.', 'error');
            return;
          }
        }

        this.centerLoading = true;
        const formData = new FormData();
        formData.append("userId", this.user.id);
        formData.append("logUserId", userId);
        formData.append("userName", this.user.user_name);
        formData.append("fullName", this.user.name);

        // formData.append("userType", this.selectedRoleName);

        if (this.selectedRoleName !== undefined && this.selectedRoleName !== null) {
          formData.append('userType', this.selectedRoleName);
        } else {
          formData.append('userType', this.user.user_type);
        }


        console.log('user type fuck', this.selectedRoleName);
        formData.append("userRoleId", this.user.user_role_id);
        formData.append("province", this.user.province);
        formData.append("district", this.user.district);
        formData.append("dsd", this.user.dsd);
        formData.append("gnd", this.user.gnd);
        formData.append("password", this.user.password);
        formData.append("confirmPassword", this.user.confirmPassword);
        formData.append("emailAddress", this.user.email);
        formData.append("nic", this.user.nic);
        formData.append("registrationNumber", this.user.registration_num);
        formData.append("gender", this.user.gender);
        formData.append("address", this.user.address);
        formData.append("image", this.imageBase64);
        formData.append("checkPremissionType", this.checkPremissionGeoFence);


        formData.append("routePlane", this.geofence.includes("Route Plan Geofence") ? 1 : 0);
        formData.append("myRoute", this.geofence.includes("My Route Geofence") ? 1 : 0);
        formData.append("collection", this.geofence.includes("Collection Geofence") ? 1 : 0);
        formData.append("sales", this.geofence.includes("Sales Geofence") ? 1 : 0);
        formData.append("OverallTracking", this.geofence.includes("Overall Tracking") ? 1 : 0);
        formData.append("RoutePlan", this.geofence.includes("Route Plan") ? 1 : 0);
        formData.append("Myroute", this.geofence.includes("Myroute") ? 1 : 0);

        // Handle mobile numbers
        const mobileNumbersArray = this.user.mobile_number.map(phoneNo => phoneNo.mobile_number);
        // formData.append("mobileNumber", JSON.stringify(mobileNumbersArray));
        formData.append("mobileNumber", mobileNumbersArray);
        const dashboards = this.permissionsItems
            .filter(item => item.isOn) // Only include dashboards where the switch is ON
            .map(item => ({
              dashboardId: item.id, // Assuming item.id represents the dashboard ID
              readPremission: item.permissions.read,
              writePremission: item.permissions.write,
              deletePremission: item.permissions.delete,
            }));

        const payload = {
          userRoleId: this.userRoleID,
          accessDashboard: dashboards,
          userId,
        };

        const jsonPayload = JSON.stringify(payload);
        console.log('fuckingdata', jsonPayload);
        formData.append("accessDashboard", jsonPayload);

        // Append image if available
        if (this.user.image && this.user.image.length > 0) {
          const base64String = await this.convertToBase64(this.user.image[0]);
          formData.append("image", base64String);
        }
        console.log([...formData]);
        // API call
        const response = await axios.post(
            "/api/createdUserUpdate",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

        if (response.status === 201 || response.status === 200) {
          this.showSnackbar("User updated successfully", "success");
          this.edit_user_dialog = false;
          this.centerLoading = false;
          this.fetchDataintoTable();
        } else {
          this.showSnackbar(response.data.message || "Failed to update user", "error");
          this.centerLoading = false;
        }

      } catch (error) {
        console.error("Error updating user:", error);
        this.showSnackbar("An error occurred while updating user", "error");
        this.centerLoading = false;
      }
    },

    // Convert image to base64 string (if necessary)
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.role_type
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    fetchDataintoTable() {
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("userId", userId);

      axios.post("/api/createdUserList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        // Clean the data
        this.items = response.data.data.map(item => {
          return {
            ...item,
            gnd: item.gnd === "null" ? "" : item.gnd, // Replace "null" string with an empty string
          };
        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at

        console.log('table data', this.items);
        this.loading = false;
      }).catch((error) => {
        console.error(error);
        this.loading = false;
      });
    },


    backPageRoute() {
      this.$router.back();
    },

    viewItem(item) {
      this.user = item;
      this.view_user_dialog = true;
      console.log("View item:", item);
      this.permissionsItems = item.user_dashboards
      console.log('permission items', this.permissionsItems);
      this.getDashboardPermissions(item.user_dashboards);
    },

    editItem(item) {
      this.user = {...item};
      this.edit_user_dialog = true;
      console.log("Edit item:", item);
      this.permissionsItems = item.user_dashboards
      this.userRoleID = item.user_role_id;

      this.checkUserCatagaryType = item.user_catagory.category;


      this.createdUserId = item.id;

      const selectedGeofences = [];

      if (item.route_plan_geofence === 1) {
        selectedGeofences.push("Route Plan Geofence");
      }
      if (item.my_route_geofence === 1) {
        selectedGeofences.push("My Route Geofence");
      }
      if (item.collection_geofence === 1) {
        selectedGeofences.push("Collection Geofence");
      }
      if (item.sales_geofence === 1) {
        selectedGeofences.push("Sales Geofence");
      }
      if (item.overall_tracking === 1) {
        selectedGeofences.push("Overall Tracking");
      }
      if (item.route_plan_tracking === 1) {
        selectedGeofences.push("Route Plan");
      }
      if (item.my_route_tracking === 1) {
        selectedGeofences.push("Myroute");
      }

      // Assign the selected geofences to the v-select model
      this.geofence = selectedGeofences;
      this.getDashboardPermissions(item.user_dashboards);
    },

    lockItem(item) {
      console.log("Lock item:", item);
      this.user = item;
      this.reset_password_dialog = true;
    },

    async generateResetLink(user) {
      this.centerLoading = true;
      const emails = user.email;

      const baseUrl = window.location.origin;

      try {
        const response = await axios.post('/api/forgetPassword', {email: emails, baseUrl: baseUrl});
        if (response.status === 200) {
          this.showSnackbar("Reset Link successfully sent.Check user mail", "success");
          this.centerLoading = false;
          console.log("Reset link generated and sent to:", emails);
        } else {
          this.showSnackbar("Failed to send reset email. Please try again.", "error");
          console.error("Error generating reset link:", response.statusText);
          this.centerLoading = false;
        }
      } catch (error) {
        if (error.response) {
          const errorMsg = error.response.data.message || "Failed to send reset email.";
          this.showSnackbar(errorMsg, "error");
          this.centerLoading = false;
        } else {
          this.showSnackbar("An error occurred. Please try again later.", "error");
          this.centerLoading = false;
        }
      }
    },

    generateAndSendPassword(user) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token')?.trim();
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      console.log("Token from localStorage:", token);
      console.log("User ID:", userId);
      console.log("Email:", email);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/autogenaratePassword`,
          {
            userId: userId,
            email: email,
            token: token
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password generated and sent to user";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.centerLoading = false;
            }
          });
    },

    confirmDelete(item) {
      this.delete_user_dialog = true;
      this.deleteItemId = item.id;
    },
    deleteItem() {
      this.centerLoading = true;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const userListId = this.deleteItemId; // Use the stored ID
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/createdUserdelete`, {
        userId: userId,
        userListId: userListId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.fetchDataintoTable(); // Refresh the user list
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
            this.centerLoading = false;
            this.showSnackbar("Deleted Successfully", "success");
          })
          .catch(error => {
            console.error('Error deleting users:', error);
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
            this.centerLoading = false;
            this.showSnackbar("Delete Failed", "error");
          });
    },

    homeReload() {
      window.location.href = '/dashboard';
    },

    addPhoneNo() {
      this.user.mobile_number.push({mobile_number: ''});
    },

    removePhoneNo(index) {
      if (this.user.mobile_number.length > 1) {
        this.user.mobile_number.splice(index, 1);
      }
    },

    updatePasswordRest(user) {
      console.log("update password item:", user);

      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token')?.trim();
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        this.centerLoading = false;
        return;
      }

      // Check if password and confirm password match
      if (this.manualPassword !== this.manualConfirmPassword) {
        console.error("Password and Confirm Password do not match.");
        this.showSnackbar("Password and Confirm Password do not match.", 'error');
        this.centerLoading = false;
        return;
      }

      axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/manualEnterPassword`,
          {
            userId: userId,
            email: email,
            password: this.manualPassword,
            confirmPassword: this.manualConfirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
      )
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.showSnackbar("Password updated successfully.", 'success');
              this.manualPassword = '';
              this.manualConfirmPassword = '';
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password Updated";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.showSnackbar(error.response.data.error || "An error occurred.", 'error');
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          });
    },

    createUser() {
      this.$router.push({path: '/user-registration'});
    },
  }
};
</script>


<style>
@import "../../styles/user_management/users_list.scss";
</style>