<template>
  <v-app id="app_add_category">
    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE PRODUCTS CATEGORY</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Create a new product category according to your requirements.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Establish new product categories</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="mainCategory" :rules="[v => !!v || 'Main Category is required']"
                    label="Main Category*" placeholder="Create main category"
                    variant="outlined" class="custom_input_field_color" density="comfortable"
                    @input="alreadyHasMainCatagaryName"
                ></v-text-field>
                
                <p v-if="errorMessage" class="mt-0" style="color: red;" type="error">
                  {{ errorMessage }}
                </p>

                <v-card flat>
                  <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">Main category image *</p>
                  <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop" @dragover.prevent="onDragOver">
                    <input ref="fileInput" accept="image/*" class="hidden-file-input" type="file" @change="onFileChange"/>
                    <div v-if="previewImage" class="image-card-container">
                      <v-card class="image-card">
                        <v-img :alt="'Image preview'" :src="previewImage" class="image-preview"></v-img>
                        <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage">
                          <v-icon color="red" size="small">mdi-delete</v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <div v-if="!previewImage" class="placeholder-container">
                      <v-icon size="36">mdi-cloud-upload</v-icon>
                      <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>
                    </div>
                  </div>

                  <v-row class="mb-8">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-btn class="custom_excel_btn mt-6" variant="outlined" @click="addSubCategory">
                        <v-icon>mdi-plus-thick</v-icon>&nbsp; Add sub categories</v-btn>
                      <v-divider class="mt-3"></v-divider>
                    </v-col>
                  </v-row>

                  <div v-for="(subCategory, index) in subCategories" :key="index">
                    <v-row  style="margin-top: -4% !important;">
                      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                        <v-text-field v-model="subCategories[index]" label="Sub Category" class="custom_input_field_color"
                                      density="comfortable" placeholder="Create sub category" variant="outlined">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                        <v-btn v-if="subCategories.length > 1" style="width: 100% !important;" class="custom_pdf_btn"
                           variant="outlined" @click="removeSubCategory(index)"
                        ><v-icon>mdi-minus-thick</v-icon>&nbsp; Remove</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description </p>
                    <v-card  class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="280" min-height="280">
                      <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                      <v-divider></v-divider>
                      <Editor v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated"/>
                    </v-card>
                  </v-col>
                </v-row>
                <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large"  class="custom_btn_primary" @click="createCategory">Create category</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="cancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
    import axios from 'axios';

    //This is related to wangeditor ---START
    import '@wangeditor/editor/dist/css/style.css';
    import { i18nChangeLanguage } from '@wangeditor/editor'
    i18nChangeLanguage('en')
    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
    //This is related to wangeditor ---END

    export default {
      components: {
        Editor,
        Toolbar,
      },

      //This is related to wangeditor ---START
      setup() {
        // editor instance, use `shallowRef`
        const editorRef = shallowRef()

        // content HTML
        const valueHtml = ref('<p></p>')

        // Simulate ajax async set HTML
        onMounted(() => {
          setTimeout(() => {
            valueHtml.value = '<p></p>'
          }, 1500)
        })

        const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
        const editorConfig = { placeholder: 'Type here...' }

        // Timely destroy `editor` before vue component destroy.
        onBeforeUnmount(() => {
          const editor = editorRef.value
          if (editor == null) return
          editor.destroy()
        })

        const handleCreated = (editor) => {
          editorRef.value = editor // record editor instance
        }

        return {
          editorRef,
          mode: 'simple', // or 'simple'
          valueHtml,
          toolbarConfig,
          editorConfig,
          handleCreated
        };
      },
      //This is related to wangeditor ---END

      name: 'addCategory',
      data() {
        return {
        dataDescription:'',
        centerLoading:false,
        mainCategories:[],
        errorMessage:'',
        formatting: [],
        showColorPicker: false,
        selectedColor: '#000',
        alignment: '',
        textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
        },
          images: [],
          previewImage: null,
          mainCategory: '',
          subCategories: [''],
          categoryDescription: '',
          snackAlert: {
            snackbar: false, // Snackbar visibility
            snackColor: "", // Snackbar color
            snackMessage: "", // Snackbar message
            snackBarRight: false, // Snackbar position right
            snackBarTop: true,  // Snackbar position top
          },
        };
      },

      created() {
        this.fetchMainCategories();
      },

      methods: {
        alreadyHasMainCatagaryName() {
            if (
                Array.isArray(this.mainCategories) &&
                this.mainCategories.some(category => category.main_category === this.mainCategory)
            ) {
                this.errorMessage = 'Main Category already exists';
            } else {
                this.errorMessage = '';
            }
        },

        fetchMainCategories() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');

            axios.post('/api/mainProduct', {
              userId: userId
            }, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              this.mainCategories = response.data.data;
              console.log('Main categories:', this.mainCategories);
            })
            .catch(error => {
              console.error('Error fetching main categories:', error);
            });
          },

        applyFormat(format) {

        if (format === 'italic') {
          this.textareaStyles.fontStyle =
              this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
          } else if (format === 'bold') {
          this.textareaStyles.fontWeight =
              this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
          } else if (format === 'underline') {
          this.textareaStyles.textDecoration =
              this.textareaStyles.textDecoration === 'underline' ? 'none' : 'underline';
          } else if (format === 'color') {
          this.textareaStyles.color = this.selectedColor;
          }
        },
        setAlignment(align) {
        this.textareaStyles.textAlign = align;
      },

      backPageRoute(){
          this.$router.back();
      },

      addSubCategory() {
          this.subCategories.push('');
      },

      removeSubCategory(index) {
          this.subCategories.splice(index, 1);
      },

      sizeValidation(value) {
        const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
        return sizePattern.test(value.trim());
      },

      triggerFileInput() {
        this.$refs.fileInput.click();
      },

      onFileChange(event) {
        const file = event.target.files[0]; // Get the first file from the input
        if (file) {
          this.previewImage = URL.createObjectURL(file); // Set the preview image
          this.handleFiles(file); // Pass the file to handleFiles
        }
      },

      onDrop(event) {
        const file = event.dataTransfer.files[0]; // Get the first file from the drop
        if (file) {
          this.previewImage = URL.createObjectURL(file); // Set the preview image
          this.handleFiles(file); // Pass the file to handleFiles
        }
      },

      removeImage() {
        this.previewImage = null;
        this.$refs.fileInput.value = null;
      },

      showSnackbar(message, color) {
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
        this.snackAlert.snackbar = true; // Show the snackbar
      },

      // Add the rest of your methods here, including createCategory and validation
      validateForm() {
        // Validate main category
        if (!this.mainCategory || this.mainCategory.trim() === '') {
          this.showSnackbar('Main category is required.', 'error');
          return false;
        }

        if (!this.previewImage) {
          this.showSnackbar('Image is required.', 'error');
          return false;
        }

        
         // Check if the Lead Title already exists
         if (this.errorMessage) {
          this.showSnackbar('Main category already exists. Please choose a different Main category .', 'error');
          return false;
        }

        // Validate subcategories
        if (!this.subCategories || !Array.isArray(this.subCategories) || this.subCategories.length === 0) {
          this.showSnackbar('At least one subcategory is required.', 'error');
          return false;
        }

        // Check if any subcategory is empty or invalid
        const invalidSubCategory = this.subCategories.some(
          subCategory => !subCategory || subCategory.trim() === ''
        );

        if (invalidSubCategory) {
          this.showSnackbar('Subcategories must not be empty.', 'error');
          return false;
        }

        // Validate description (valueHtml)
        // if (!this.valueHtml || this.valueHtml.trim() === '' || this.valueHtml === '<p><br></p>') {
        //   this.showSnackbar('Category description is required.', 'error');
        //   return false;
        // }

        return true;
      },


      clearForm() {
        this.mainCategory = ''; // Clear the main category field
        this.valueHtml = ''; // Clear the description field
        this.image = null; // Clear the selected image
        this.previewImage = null; // Clear the preview image
        this.$refs.fileInput.value = null; // Reset the file input
      },

      async createCategory() {
        // Validate form before sending the request
        if (!this.validateForm()) {
          return;
        }

        console.log('fk', this.subCategories);

        console.log('fk1', this.valueHtml);

        this.centerLoading = true;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formattedSubCategories = this.subCategories.map(subCategory => ({
          subcatagaryName: subCategory
        }));

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('mainCatagary', this.mainCategory);
        formData.append('subCatagary', JSON.stringify(formattedSubCategories));

        //const delta = this.categoryDescription;

          // Convert Delta to HTML
          //const converter = new QuillDeltaToHtmlConverter(delta.ops, {});
          //const html = converter.convert(); // This is the styled HTML

        formData.append('mainCatagaryDescription', this.valueHtml);

        if (this.image) { // Check for the single image
          const base64Image = await this.convertToBase64(this.image);
          formData.append('productImage', base64Image);
        }

        try {
          const response = await axios.post('/api/addCatagary', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.status === 200) {
            this.showSnackbar('Category created successfully!', 'success');
            this.cancel(); // Reset form after successful creation
            this.centerLoading = false;
            window.location.reload();
            this.clearForm();
          } else {
            this.showSnackbar('Unexpected response status.', 'warning');
            this.centerLoading = false;
          }
        } catch (error) {
          console.error('Error creating category:', error);
          this.showSnackbar('Failed to create category.', 'error');
          this.centerLoading = false;
        }
      },

      handleFiles(file) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

        if (!validImageTypes.includes(file.type)) {
          alert('Only image files are allowed!');
          return;
        }

        if (!(file instanceof File)) {
          console.error('Not a valid File object:', file);
          return;
        }
        this.image = file; // Store the single image
      },

      convertToBase64(file) {
        return new Promise((resolve, reject) => {
          if (file instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          } else {
            reject(new Error('Invalid file type'));
          }
        });
      },

      cancel() {
        // Reset form fields
        this.mainCategory = '';
        this.subCategories = [''];
        this.categoryDescription = '';
      },
    },
    mounted() {
      // Ensure userId and token are available in local storage
      if (!localStorage.getItem('userId') || !localStorage.getItem('token')) {
        this.showSnackbar('User not authenticated.', 'error');
        this.$router.push('/login');
      }
    },
};
</script>


<style>
  @import "../../styles/product_management/add_category.scss";
</style>