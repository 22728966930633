<template>
  <div id="app_dashboard_admin">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <v-card class="mx-auto custom_card_box_dashboard" elevation="0" border="sm opacity-1" rounded="md">
            <v-card flat class="pa-2">
              <template v-slot:title>
                <p class="ml-3 font-weight-bold custom_page_component_main_title">OUTLET MAP</p>
                <div class="ml-3 mt-3">
                  <v-chip class="custom_delete_btn" label size="large" variant="outlined">
                    <div class="font-weight-bold text-body-1">{{ filteredOutletCount }}</div>
                  </v-chip>
                  <v-divider vertical></v-divider>
                  <v-chip class=" ml-3 custom_btn_secondary font-weight-bold" label size="large" variant="outlined">
                    OUTLET COUNT
                  </v-chip>
                </div>
              </template>


              <template v-slot:append>
                <v-date-input
                    class="mt-6 custom_input_field_color"
                    style="width: 330px!important;"
                    multiple="range"
                    prepend-inner-icon="$calendar"
                    label="Date Range"
                    density="comfortable"
                    clearable
                    variant="outlined"
                    :max="maxDate"
                    @update:modelValue="handleDateChange"
                />

                <v-btn class="custom_pdf_btn ml-6 mr-4" variant="outlined" @click="exportPDF">
                  <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
                </v-btn>
              </template>
            </v-card>

            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                  <!--                  <v-label class="text-caption mb-3">Select Province</v-label>-->
                  <v-autocomplete class="custom_input_field_color"
                                  v-model="outlet.provinces" :items="provinces" :loading="mProvinceLoading"
                                  clearable label="Province" placeholder="Select province(s)" variant="outlined"
                                  @update:modelValue="(value) => {
                        outlet.provinces = value;
                        if (!value) {
                          outlet.district = null;
                          outlet.dsd = null;
                          outlet.gnd = null;
                          districts = [];
                          dsdList = [];
                          gndList = [];
                        } else {
                          getAllDistrictAccProv(value);
                        }
                      }" density="comfortable"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                  <!--                  <v-label class="text-caption mb-3">Select District</v-label>-->
                  <v-autocomplete class="custom_input_field_color"
                                  v-model="outlet.districts" :items="districts" :loading="mDistrictLoading"
                                  clearable label="District" density="comfortable"
                                  placeholder="Select district(s)" variant="outlined" @update:modelValue="(value) => {
                      outlet.districts = value;
                      if (!value) {
                        outlet.dsd = null;
                        outlet.gnd = null;
                        dsdList = [];
                        gndList = [];
                      } else {
                        getAllDsd(value);
                      }
                    }"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                  <!--                  <v-label class="text-caption mb-3">Select DSD</v-label>-->
                  <v-autocomplete class="custom_input_field_color"
                                  v-model="outlet.dsdList" :items="dsdList" :loading="mDSDLoading"
                                  clearable label="DSD" placeholder="Select DSD(s)" variant="outlined"
                                  @update:modelValue="(value) => {
                      outlet.dsdList = value;
                      if (!value) {
                        outlet.gnd = null;
                        gndList = [];
                      } else {
                        getAllGnd(value);
                      }
                    }" density="comfortable"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3">
                  <!--                  <v-label class="text-caption mb-3">Select GND</v-label>-->
                  <v-autocomplete class="custom_input_field_color"
                                  v-model="outlet.gndList" :items="gndList"
                                  :loading="mGndLoading" clearable label="GND"
                                  placeholder="Select GND(s)" density="comfortable"
                                  variant="outlined"></v-autocomplete>
                </v-col>
              </v-row>
              <div>
                <!--                <button @click="toggleControls">Toggle Map Controls</button>-->
                <Map
                    ref="mapElement"
                    :center="mapCenter"
                    :zoom="12"
                    :options="mapOptions"
                    class="d-flex custom_map_div"
                >
                  <Marker
                      v-for="outlet in filteredOutlets"
                      :key="outlet.id"
                      :icon="{
          url: require('@/assets/ICONS/maker.png'),
          scaledSize: { width: 35, height: 38 },
        }"
                      :position="{ lat: parseFloat(outlet.latitude), lng: parseFloat(outlet.longitude) }"
                      :title="outlet.outlet_name"
                  ></Marker>
                </Map>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {Map, Marker,} from '@fawmi/vue-google-maps';
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {mapGetters} from "vuex";

export default {
  components: {
    Map,
    Marker,
  },
  name: 'OutletMapDashboardComponentFour',

  data() {
    return {
      showControls: true,
      mapOptions: {
        disableDefaultUI: false,
        zoomControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      },
      maxDate: new Date().toISOString().substr(0, 10), // Current date in 'YYYY-MM-DD' format
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],

      outlet: {
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],
      },


      mapCenter: {lat: 6.8738188, lng: 79.9931036}, // Default map center
      outlets: [],
    }
  },

  mounted() {
    this.getAllProvinces();
    this.getOutletLocation();
  },

  computed: {

    filteredOutlets() {
      return this.outlets.filter((outlet) => {
        return (
            (!(this.outlet.provinces?.length ?? 0) || this.outlet.provinces.includes(outlet.province)) &&
            (!(this.outlet.districts?.length ?? 0) || this.outlet.districts.includes(outlet.district)) &&
            (!(this.outlet.dsdList?.length ?? 0) || this.outlet.dsdList.includes(outlet.dsd)) &&
            (!(this.outlet.gndList?.length ?? 0) || this.outlet.gndList.includes(outlet.gnd))
        );
      });
    },

    filteredOutletCount() {
      return this.filteredOutlets.length; // Count of filtered outlets
    },

    ...mapGetters({dashboard: 'getDashboardDetails'})

  },

  methods: {

    toggleControls() {
      this.showControls = !this.showControls;
      this.mapOptions = {
        disableDefaultUI: !this.showControls,
        zoomControl: this.showControls,
        mapTypeControl: this.showControls,
        fullscreenControl: this.showControls,
      };
    },

    handleDateChange(dateRange) {
      if (Array.isArray(dateRange) && dateRange.length === 2) {
        // Date range selected (start date and end date)
        const [startDate, endDate] = dateRange;
        this.fetchOutletsByDateRange(startDate, endDate);
      } else if (dateRange) {
        // Single date selected
        this.fetchOutletsByDateRange(dateRange, dateRange);
      }
      if (!dateRange) {
        this.getOutletLocation(); // Fetch all outlets again
      }

    },

    fetchOutletsByDateRange(startDate, endDate) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);

      // Log FormData content
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      console.log('Start Date:', new Date(startDate).toLocaleDateString());
      console.log('End Date:', new Date(endDate).toLocaleDateString());

      axios
          .post('/api/getOutletsByDateRange', formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.outlets = response.data.data; // Update outlets with the filtered data
              this.setMapCenter();
            } else {
              console.error('Error fetching outlets by date range:', response.data.error);
            }
          })
          .catch((error) => {
            console.error('Error fetching outlets by date range:', error);
          });
    },
    exportPDF() {
      // Hide controls before generating the PDF
      this.toggleControls(); // Hide the controls

      // Wait for the DOM to update before capturing the map
      setTimeout(() => {
        this.$nextTick(() => {
          const mapElement = this.$refs.mapElement?.$el || this.$refs.mapElement;

          if (mapElement instanceof HTMLElement) {
            html2canvas(mapElement, {
              useCORS: true, // Use CORS to allow cross-origin tiles to be captured
              logging: true,
              scale: 2, // Increase the scale to get better resolution
            })
                .then((canvas) => {
                  const imgData = canvas.toDataURL("image/png");
                  const pdf = new jsPDF({
                    orientation: "landscape", // Adjust this based on your map size
                    unit: "pt",
                    format: [canvas.width, canvas.height], // Use the exact size of the canvas for PDF format
                  });

                  // Add the captured image to the PDF
                  pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

                  // Save the PDF
                  pdf.save("outlet_map.pdf");
                })
                .catch((error) => {
                  console.error("Error capturing the map:", error);
                })
                .finally(() => {
                  // Restore controls after generating the PDF
                  this.toggleControls(); // Show the controls
                });
          } else {
            console.error("Map element is not attached or is not a valid node.");
            // Restore controls if the map element is invalid
            this.toggleControls();
          }
        });
      }, 200); // Add a 300ms delay to ensure the controls are hidden
    },


    getOutletLocation() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);

      axios.post('/api/getOutletLocationDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            if (response.status === 200) {
              this.outlets = response.data.data;
              this.setMapCenter();
              console.log('data', this.outlets);
            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });
    },
    setMapCenter() {
      if (this.outlets.length > 0) {
        // Center the map based on the first outlet location
        const firstOutlet = this.outlets[0];
        this.mapCenter = {
          lat: parseFloat(firstOutlet.latitude),
          lng: parseFloat(firstOutlet.longitude),
        };
      }
    },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mProvinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },


    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },


    getAllDsd(district) {
      this.dsdList = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDSDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },


    getAllGnd(dsd) {
      this.gndList = [];
      this.mGndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGndLoading = false;
            }
          })
          .catch(() => {
            this.mGndLoading = false;
          });
    },
  },

  mutations: {
    updateDashboardTotalOutlet(state, count) {
      state.dashboard.totalOutlet = count;
    },
  },


  watch: {
    filteredOutletCount(newCount) {
      this.$store.commit('updateDashboardTotalOutlet', newCount);
    },

    "outlet.provinces"(newValues) {
      if (!newValues || newValues.length === 0) {
        // Reset dependent fields to empty arrays
        this.outlet.districts = [];
        this.outlet.dsdList = [];
        this.outlet.gndList = [];
      } else {
        // Fetch districts based on the selected province(s)
        this.getAllDistrictAccProv(newValues);
      }
    },
    "outlet.districts"(newValues) {
      if (!newValues || newValues.length === 0) {
        // Reset dependent fields to empty arrays
        this.outlet.dsdList = [];
        this.outlet.gndList = [];
      } else {
        // Fetch DSDs based on the selected district(s)
        this.getAllDsd(newValues);
      }
    },
    "outlet.dsdList"(newValues) {
      if (!newValues || newValues.length === 0) {
        // Reset dependent fields to empty arrays
        this.outlet.gndList = [];
      } else {
        // Fetch GNDs based on the selected DSD(s)
        this.getAllGnd(newValues);
      }
    },
  },

};
</script>

<style>
@import "../../styles/dashboard/dashboard.scss";
</style>
