<template>
  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon >mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE COLLECTIONS RECORD</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="imageSrc" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">{{ agentName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div class="d-flex mt-6">
              <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{ userType }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
          <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="createRoute">UPDATE COLLECTION DATA</v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">

        <v-text-field v-model="oderId" label="Order ID" variant="outlined" readonly
                      class="px-3 custom_input_field_color" density="comfortable"
        ></v-text-field>

        <v-text-field v-model="agentName" label="User Name" variant="outlined" readonly
                      class="px-3 mt-2 custom_input_field_color" density="comfortable"
        ></v-text-field>

        <v-autocomplete v-model="userType" :items="outletTypes" item-title="role_type" item-value="role_type" label="User Type"
                  variant="outlined" density="comfortable" class="px-3 mt-2 custom_input_field_color" readonly
        ></v-autocomplete>

        <v-autocomplete v-model="province" :items="provinces" :loading="mDistrictLoading" label="Province" placeholder="Select province" readonly
                  variant="outlined" density="comfortable" @update:modelValue="getAllDistrictAccProv"  class="px-3 mt-2 custom_input_field_color"
        ></v-autocomplete>

        <v-autocomplete v-model="district" :items="districts" :loading="mDistrictLoading" label="District" placeholder="Select district" readonly
                  variant="outlined" density="comfortable" @update:modelValue="getAllDsd"  class="px-3 mt-2 custom_input_field_color"
        ></v-autocomplete>

        <v-autocomplete v-model="dsd" :items="dsdList" :loading="mDSDLoading" label="DSD" placeholder="Select DSD" readonly
            variant="outlined" density="comfortable" @update:modelValue="getAllGnd"  class="px-3 mt-2 custom_input_field_color"
        ></v-autocomplete>

        <v-autocomplete v-model="gnd" :items="gndList" label="GND" placeholder="Select GND" readonly
                  variant="outlined" density="comfortable" class="px-3 mt-2 custom_input_field_color"
        ></v-autocomplete>

        <v-text-field v-model="storeName" label="Store Name" readonly
                      variant="outlined" density="comfortable" class="px-3 mt-2 custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="quantitySold" label="Quantity Sold"
                      variant="outlined" density="comfortable" class="px-3 mt-2 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="storeAddress" label="Store Address" readonly
            variant="outlined" density="comfortable" class="px-3  custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="payMentMethod" label="Payment Method" readonly
            variant="outlined"  density="comfortable"   class="px-3 mt-2 custom_input_field_color"
        ></v-text-field>

        <div class="px-3">
          <v-divider class="mb-13 mt-8"></v-divider>
          <v-card class="custom_card_box"   style="border: #EF9A9A 2px solid !important;" elevation="0"  border="sm opacity-1">
            <v-card-text class="mt-3">
              <v-text-field
                  v-model="salesAmount" label="Total Amount" readonly
                  variant="outlined" density="comfortable"   class="px-3 mt-2 custom_input_field_color"
              ></v-text-field>

              <v-text-field
                  v-model="paidAmount" label="Paid Amount" readonly
                  variant="outlined" density="comfortable"   class="px-3 mt-2 custom_input_field_color"
              ></v-text-field>

              <v-text-field
                  v-model="outStandingPayment" label="OutStand Payment" readonly
                  variant="outlined" density="comfortable"   class="px-3 mt-2 custom_input_field_color"
              ></v-text-field>

              <v-text-field
                  v-model="lastPaymentDate" label="Last Payment Date" readonly
                  variant="outlined" density="comfortable"   class="px-3 mt-2 custom_input_field_color"
              ></v-text-field>

              <v-text-field
                  v-if="outStandingPayment !== 0" v-model="NextPaymentDate" label="Next Payment Date" readonly
                  variant="outlined" density="comfortable"   class="px-3 mt-2 custom_input_field_color"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-3 mb-2">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider class="mt-1 mb-3"></v-divider>
        <p class="custom_page_main_sub_title">
          <strong>COLLECTION PRODUCT LIST</strong>
        </p>

        <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
          The following table shows all the products related to collections.
        </p>

        <v-card class="mt-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
          <v-toolbar color="white" class="pa-3" flat>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-col cols="5">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"  class="custom_input_field_color"
                            clearable hide-details label="Search" placeholder="Search Lead" variant="outlined"/>
            </v-col>
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text>
            <v-data-table :headers="headers" :items="salesProducts[0]?.products" :search="search" density="comfortable" class="custom_table_normal">
              <!-- Custom slot for product column -->
              <template v-slot:[`item.product_name`]="{ item }">
                <p  v-if="!item.isEditing" class="custom_page_main_sub_title_very_small_red font-weight-medium text-body-2">{{ item.product_name }}</p>
                <v-text-field density="comfortable" variant="outlined" class="custom_input_field_color mt-3 mb-3" v-else v-model="item.product_name" hide-details/>
              </template>

              <template v-slot:[`item.quantity`]="{ item }">
                <p  v-if="!item.isEditing" class="font-weight-medium text-body-2">{{ item.quantity }}</p>
                <v-text-field density="comfortable" variant="outlined" class="custom_input_field_color mt-3 mb-3" v-else v-model="item.quantity" hide-details/>
              </template>

              <template v-slot:[`item.sales_amount`]="{ item }">
                <p  v-if="!item.isEditing" class="font-weight-medium text-body-2">{{ item.sales_amount }}</p>
                <v-text-field density="comfortable" variant="outlined" class="custom_input_field_color mt-3 mb-3" v-else v-model="item.sales_amount" hide-details/>
              </template>

              <template v-slot:[`item.unit_price`]="{ item }">
                <p  v-if="!item.isEditing" class="font-weight-medium text-body-2">{{ item.unit_price }}</p>
                <v-text-field density="comfortable" variant="outlined" class="custom_input_field_color mt-3 mb-3" v-else v-model="item.unit_price"  hide-details/>
              </template>


              <!-- Custom slot for actions column -->
              <template v-slot:[`item.action`]="{ item }">
                <v-row class="flex-row mt-3 mb-3">
<!--                  <v-btn-->
<!--                      v-if="!item.isEditing" class="ml-3 mt-3 mb-3 custom_edit_btn"-->
<!--                      icon rounded size="x-small"-->
<!--                      variant="outlined"  @click="editItem(item)"-->
<!--                  >-->
<!--                    <v-icon>mdi-pencil</v-icon>-->
<!--                    <v-tooltip activator="parent" location="top">Edit</v-tooltip>-->
<!--                  </v-btn>-->

                  <v-btn
                      v-if="item.isEditing" class="ml-3 mt-3 mb-3 custom_edit_btn"  icon rounded
                      size="x-small" variant="outlined" @click="saveItem(item)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    <v-tooltip activator="parent" location="top">Save</v-tooltip>
                  </v-btn>

                  <v-btn
                      v-if="!item.isEditing" class="ml-3 mt-3 mb-3 custom_btn_primary"  icon rounded
                      size="x-small" variant="outlined"  @click="CDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                    <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                  </v-btn>

                  <v-btn
                      v-if="item.isEditing" class="ml-3 mt-3 mb-3 custom_btn_primary"  icon rounded
                      size="x-small" variant="outlined"  @click="cancelEdit(item)"
                  >
                    <v-icon>mdi-close</v-icon>
                    <v-tooltip activator="parent" location="top">Close</v-tooltip>
                  </v-btn>
                </v-row>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteCDialog" id="app_collection_management" max-height="500" max-width="500" persistent>
          <v-card  rounded="md">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat>
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">DELETE COLLECTION DATA</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="deleteCDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card color="white" flat class="mt-3">
                <v-card-item>
                  <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                    <v-icon color="warning" size="small">mdi-alert</v-icon>
                    &nbsp;&nbsp;Do you want to delete this product record ?
                  </v-card-title>

                  <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                    This product record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                  </p>

                  <v-divider class="mt-4"></v-divider>

                  <div class="float-end mt-4 ">
                    <v-btn  class="custom_btn_primary" @click="deleteOutlets">DELETE</v-btn>
                    <v-btn  class="ml-3 custom_btn_secondary" @click="deleteCDialog = false">CANCEL</v-btn>
                  </div>
                </v-card-item>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Delete Confirmation Dialog -->


        <v-snackbar v-model="snackbar" color="success" location="top-right" outlined timeout="3000">
          Update collection data successfully.
          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-overlay v-model="loading" :scrim="false" class="custom_centered_overlay">
          <div class="overlay-content">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </div>
        </v-overlay>

      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import {EventBus} from "@/plugins/event-bus";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import Swal from "sweetalert2";

  export default {
    name: "Collection_Record_Edit.vue",

    data() {
      return {
        search:'',
        snackbar: false,
        loading:false,
        paymentMethod:'',
        paidAmount: '',
        outStandingPayment:'',
        lastPaymentDate:"",
        NextPaymentDate:'',
        tab: null,
        deleteDialog: false,
        itemToDelete: null,
        closeDialog: false,
        editDialog: false,
        CitemToDelete: null,
        deleteCDialog: false,
        fetchData: '',
        oderId: '',
        userType: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        quantitySold: '',
        salesAmount: '',
        storeAddress: '',
        storeName: '',
        userImage: '',
        mobileNumber: '',
        agentId: '',
        bankSlip: null,
        agentName: '',
        products: [],
        outletTypes: [],
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],
        mDistrictLoading: false,
        mDSDLoading: false,
        mGNDLoading: false,
        headers: [
          {text: 'Product', value: 'product_name', title: 'Product'},
          {text: 'Quantity', value: 'quantity', title: 'Quantity'},
          {text: 'Sales Amount', value: 'sales_amount', title: 'Collection Amount'},
          {text: 'Unit Price', value: 'unit_price', title: 'Unit Price'},
          {text: 'Actions', value: 'action', sortable: false, title: 'Action'},
        ],
        salesProducts: [],
        access: {
          cash: false,
          bankDeposit: false,
          payLater: false,
        },
        changedProducts: [],
      };
    },

    methods: {
      editItem(item) {
        item.isEditing = true;
      },

      saveItem(item) {
        item.isEditing = false;
        // Check if the item already exists in changedProducts array
        const existingItem = this.changedProducts.find(p => p.id === item.id);
        if (existingItem) {
          // Update the existing item in the changedProducts array
          Object.assign(existingItem, item);
        } else {
          // Add the new changed item to the changedProducts array
          this.changedProducts.push({...item});
        }
      },

      cancelEdit(item) {
        item.isEditing = false;
      },

      submitToBackend() {
        // Combine original salesProducts and changedProducts into one array
        const combinedProducts = this.salesProducts[0].products.map(item => {
          const changedItem = this.changedProducts.find(p => p.id === item.id);
          return changedItem ? changedItem : item;
        });

        // Now you can pass the combinedProducts array to the backend
        console.log('Submitting to backend:', combinedProducts);
        // Example: this.$http.post('/api/submit', combinedProducts);
      },

      fetchOutletType() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/getUserRolesType`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.outletTypes = response.data.data;
          console.log('qqq', this.outletTypes)
        })
        .catch(error => {
          console.error('Error fetching routes:', error);
        });
      },

      getAllProvinces() {
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.provinces = response.data.data;
            this.mDistrictLoading = false;
          }
        })
        .catch(() => {
          this.mDistrictLoading = false;
        });
      },

      getAllDistrictAccProv(province) {
        console.log('Province selected:', province);
        this.districts = [];
        this.mDistrictLoading = true;
        const headers = {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
        .then(response => {
          console.log('District API response:', response.data);
          if (response.data.http_status === 'success') {
            this.districts = response.data.data;
            console.log('District API response:', response.data);
            this.mDistrictLoading = false;

          }
        })
        .catch(error => {
          this.mDistrictLoading = false;
          console.error('Error fetching districts:', error);

        });
      },

      getAllDsd(district) {
        this.dsdList = [];
        this.mDSDLoading = true;
        const header = {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.dsdList = response.data.data;
            this.mDSDLoading = false;
          }
        })
        .catch(() => {
          this.mDSDLoading = false;
        });
      },

      getAllGnd(dsd) {
        this.gndList = [];
        this.mGNDLoading = true;
        const header = {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.gndList = response.data.data;
            this.mGNDLoading = false;
          }
        })
        .catch(() => {
          this.mGNDLoading = false;
        });
      },

      confirmDelete(item) {
        this.itemToDelete = item;
        this.deleteCDialog = true;
      },

      deleteItem() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/delete/${this.itemToDelete.id}`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.collections = this.collections.filter(sale => sale.id !== this.itemToDelete.id);
          this.deleteCDialog = false;
          this.itemToDelete = null;
        })
        .catch(error => {
          console.error("There was an error deleting the sale!", error);
        });
      },

      CDelete(item) {
        this.CitemToDelete = item;
        this.deleteCDialog = true;
      },

      deleteOutlets() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collection-product/delete/${this.CitemToDelete.id}`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.salesProducts[0].products = this.salesProducts[0]?.products.filter(sale => sale.id !== this.CitemToDelete.id);
          this.deleteCDialog = false;
          this.CitemToDelete = null;
        })
        .catch(error => {
          console.error("There was an error deleting the sale!", error);
        });
      },

      exportPDF() {
        //  functionality to export table data as PDF
      },

      cancell() {
        EventBus.emit('collectionsRecodeManagementButtonClick2');
      },

      closeD() {
        this.viewDialog = false;
        this.editDialog = false;
      },

      async createRoute() {
        this.loading = true;
        const id = this.sharedDataCollection;
        const userId = localStorage.getItem('userId');
        const outletData = {
          order_id: this.oderId,
          store_name: this.storeName,
          user_type: this.userType,
          agent_id: this.agentId,
          address: this.storeAddress,
          quantity_sold: this.quantitySold,
          province: this.province,
          district: this.district,
          dsd: this.dsd,
          gnd: this.gnd,
          sales_amount: this.salesAmount,
          payment_method: this.paymentMethod,
          bank_slip_file: this.bankSlip,
          products: this.salesProducts[0].products,
          userId: userId
        };

        try {
          const token = localStorage.getItem('token');

          const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/collection/update/${id}`,
          outletData,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
          )
          .then(response => {
            if (response.status === 200) {
              this.snackbar = true;
            }
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
          });
          console.log(response);
          // Clear form fields (add your logic here if necessary)
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response?.data?.message || 'Something went wrong!',
        });

        } finally {
          this.loading = false;
          setTimeout(() => {
            EventBus.emit('collectionsRecodeManagementButtonClick2');
            this.editDialog = false;
          }, 3000);
        }
      },

      fetchSalesRecodeData() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const id = this.sharedDataCollection;
        console.log('Shared Data:', this.sharedDataCollection);
        console.log('fk', id);

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/${id}`, {userId}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
        .then(response => {
          console.log("API Response all:", response.data);
          if (response.data.sale) {
            // Convert the sales object into an array with a single element if necessary
            this.salesProducts = Array.isArray(response.data.sale) ? response.data.sale : [response.data.sale];
            console.log("this.salesProducts:", this.salesProducts);

            if (this.salesProducts.length > 0) {
              const sale = this.salesProducts[0];
              this.agentName = sale.agent.name;
              this.oderId = sale?.order_id;
              this.userType = sale.user_type;
              this.quantitySold = sale?.quantity_sold;
              this.salesAmount = sale?.sales_amount;
              this.storeAddress = sale?.address;
              this.storeName = sale?.store_name;
              this.userImage = sale?.agent?.user_image;
              this.agentId = sale?.agent_id;
              this.province = sale?.province;
              this.district = sale?.district;
              this.dsd = sale?.dsd;
              this.collectionsAmount = sale?.sales_amount;
              this.gnd = sale?.gnd;
              this.payMentMethod = sale.payment_method;
              this.paidAmount = sale.paid_amount
              this.outStandingPayment = this.collectionsAmount - this.paidAmount;
              const updatedAtDate = new Date(sale.updated_at);
              this.lastPaymentDate = updatedAtDate.toISOString().split('T')[0];
              const nextPaymentDate = new Date(updatedAtDate);
              nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);
              this.NextPaymentDate = nextPaymentDate.toISOString().split('T')[0];
              this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;

              console.log("sahan", this.mobileNumber)

              // Check the payment method and set access flags
              this.paymentMethod = sale.payment_method;

              console.log(
                  "paymentMethod:", this.paymentMethod,
              )

              // Reset access flags
              this.access = {
                cash: false,
                bankDeposit: false,
                payLater: false,
              };

              // Set access based on the payment method
              if (this.paymentMethod === 'cash') {
                this.access.cash = true;
                this.tab = 'tab-1'; // Set to cash tab
              } else if (this.paymentMethod === 'bank_deposit') {
                this.access.bankDeposit = true;
                this.tab = 'tab-2'; // Set to bank deposit tab
              } else if (this.paymentMethod === 'pay_later') {
                this.access.payLater = true;
                this.tab = 'tab-3'; // Set to pay later tab
              }
            }
          } else {
            this.salesProducts = [];
          }
        })
        .catch(error => {
          console.log(error);
          this.salesProducts = [];
        });
      }
    },

    computed: {
      ...mapGetters(['sharedDataCollection']),
      imageSrc() {
        return `${process.env.VUE_APP_API_BASE_URL}/SFA/public/${this.userImage}`;
      }
    },

    created() {
      this.fetchSalesRecodeData();
    },

    mounted() {
      this.fetchSalesRecodeData();
      this.fetchOutletType();
      this.getAllProvinces();
    },

    watch: {
      'province'(newValue) {
        this.getAllDistrictAccProv(newValue);
      },
      'district'(newValue) {
        this.getAllDsd(newValue);
      },
      'dsd'(newValue) {
        this.getAllGnd(newValue);
      }
    }
  };
</script>


<style>
  @import "../../styles/collection_management/collection_management.scss";
</style>