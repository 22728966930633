<template>
  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon>mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">OUTLETS DETAILS</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="require('@/img/icons/shop.png')" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Outlet Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">
                {{ outletName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div v-if="note" class="d-flex mt-2">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">
                Status Change Reason: &nbsp;
              </p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">
                {{ note }}
              </p>
            </div>

            <div class="d-flex mt-6">
              <v-chip v-if="outletStates === 'ALLOWED'" class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon>mdi-check-circle-outline</v-icon>&nbsp;
                OUTLET STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ outletStates }}</span>
              </v-chip>

              <v-chip v-if="outletStates === 'NOT ALLOWED'" class="text-body-1 mr-3 custom_red_chip" variant="outlined">
                <v-icon>mdi-check-circle-outline</v-icon>&nbsp;
                OUTLET STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ outletStates }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
          <v-btn
              v-if="permissions.write" class="mr-3 custom_edit_btn"
              icon rounded size="small"
              variant="outlined" @click="editViewOutlet"
          >
            <v-icon>mdi-pencil</v-icon>
            <v-tooltip activator="parent" location="top">Edit</v-tooltip>
          </v-btn>

          <v-btn
              v-if="permissions.delete" class="custom_delete_btn" icon rounded
              size="small" variant="outlined" @click="confirmDelete"
          >
            <v-icon>mdi-trash-can</v-icon>
            <v-tooltip activator="parent" location="top">Delete</v-tooltip>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-card-text>
      <v-form ref="form">
        <v-row class="mt-2">
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="outletName" ref="outletNameField" density="comfortable"
                          class="custom_input_field_class_view custom_input_field_color"
                          label="Outlet Name *" placeholder="Type outlet name" variant="outlined" readonly
            />
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="mobileNumber" readonly
                          label="Mobile Number *" maxlength="10" placeholder="Type mobile number" variant="outlined"
                          density="comfortable"
                          class="custom_input_field_class_view custom_input_field_color"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="ownerName" readonly
                          label="Owner Name *" placeholder="Type owner name" variant="outlined" density="comfortable"
                          class="custom_input_field_class_view custom_input_field_color"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="outletType" readonly label="Select Outlet Type *" variant="outlined"
                          density="comfortable"
                          class="custom_input_field_class_view custom_input_field_color"
            ></v-text-field>
          </v-col>



          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="7" lg="7">
                <p class="custom_page_main_sub_title">
                  <strong>SELECTED OUTLET LOCATION</strong>
                </p>
                <p class="custom_page_main_sub_title mt-2 " style="opacity: 0.6;line-height: 26px">
                  This reflects the location you provided for the outlet when you created it.
                </p>
                <v-divider class="mb-4 mt-4"></v-divider>
                <Map ref="map" :center="mapCenter" :zoom="13"
                     class="d-flex custom_outlet_selection_map mt-4 custom_card_box">
                  <Marker v-if="markerPosition" :icon="customIcon" :position="markerPosition"/>
                </Map>
                <v-btn class="position-absolute ml-2 mb-15 custom_lat_lon_label_edit_View">
                  lat: {{ latitude }} | lng: {{ longitude }}
                </v-btn>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                <v-row>
<!--                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">-->
<!--                    <v-card border="md opacity-1">-->
<!--                      <v-card-text class="ma-4 mb-0">-->
<!--                        <v-text-field-->
<!--                            label="Outlet Address" placeholder="Type outlet address" variant="outlined"-->
<!--                            density="comfortable" class="custom_input_field_class_view custom_input_field_color"-->
<!--                        />-->
<!--                      </v-card-text>-->
<!--                    </v-card>-->
<!--                    <v-divider class="mt-6"></v-divider>-->
<!--                  </v-col>-->
                  <v-divider class="mt-12"></v-divider>
                  <v-divider class="mt-12"></v-divider>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="outletAddress" ref="outletAddressField" label="Street Address *" readonly
                                  placeholder="Type street address" variant="outlined" density="comfortable"
                                  class="custom_input_field_class_view custom_input_field_color"></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="province" label="Province" readonly
                                  variant="outlined" density="comfortable"
                                  class="custom_input_field_class_view custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="district" label="District" readonly variant="outlined"
                                  density="comfortable" class="custom_input_field_class_view custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="dsd" label="DSD" readonly variant="outlined"
                                  density="comfortable" class="custom_input_field_class_view custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="gnd" label="GND" readonly variant="outlined"
                                  density="comfortable" class="custom_input_field_class_view custom_input_field_color"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>


    <!-- Delete User confirmation dialog -->
    <v-dialog v-model="deleteDialog" id="app_outlets_list" max-height="500" max-width="500" persistent>
      <v-card rounded="md">
        <div class="pa-4">
          <v-card color="#F0F0F0" flat>
            <template v-slot:prepend>
              <v-card-title class="text-subtitle-1 font-weight-bold">DELETE OUTLET DATA</v-card-title>
            </template>
            <v-spacer></v-spacer>
            <template v-slot:append>
              <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
            </template>
          </v-card>

          <v-card color="white" flat class="mt-3">
            <v-card-item>
              <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                <v-icon color="warning" size="small">mdi-alert</v-icon>
                &nbsp;&nbsp;Do you want to delete this outlet record ?
              </v-card-title>

              <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                This outlet record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
              </p>

              <v-divider class="mt-4"></v-divider>

              <div class="float-end mt-4 ">
                <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                <v-btn class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
              </div>
            </v-card-item>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <!-- Delete User confirmation dialog -->

  </v-card>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {Map, Marker} from '@fawmi/vue-google-maps';
import {EventBus} from "@/plugins/event-bus";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: "Outlet_Detail.vue",
  components: {
    Map,
    Marker,
  },
  data() {
    return {
      mapCenter: {},
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      fetchData: '',
      outletStates: '',
      ownerName: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      outletType: '',
      latitude: '',
      longitude: '',
      note: '',
      outletProducts: [],
      markerPosition: null,
      customIcon: {
        url: require('@/img/icons/maker.png'), // Path to your custom marker icon
        scaledSize: {width: 35, height: 40} // Adjust the size if needed
      },
      permissions: {},
    };
  },

  methods: {

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.deleteDialog = false;
            this.itemToDelete = null;
            EventBus.emit('outletManagementButtonClick2');
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },

    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    editViewOutlet() {
      //  functionality to edit item details
      this.editDialog = true;
      EventBus.emit('outletManagementButtonClick');
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data) {

              this.outletProducts = Array.isArray(response.data) ? response.data : [response.data];
              console.log("this.outletProducts:", this.outletProducts);

              if (this.outletProducts.length > 0) {
                const route = this.outletProducts[0];
                this.ownerName = route.owner_name;
                this.latitude = route?.latitude;
                this.longitude = route?.longitude;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.outletStates = route?.outlet_status;
                this.outletName = route?.outlet_name;
                this.outletType = route?.outlet_type.outlet_name;
                this.outletAddress = route?.outlet_address;
                this.mobileNumber = route.mobile_number;
                this.note = route?.status_reasons[0]?.reason;
                console.log("sahan", this.mobileNumber);

                this.markerPosition = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};
                this.mapCenter = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};

              }
            } else {
              this.outletProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.outletProducts = [];
          });
    }
  },

  computed: {
    ...mapGetters(['sharedDataOutlet']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },

  created() {
    this.fetchOutletRecodeData();
  },

  mounted() {
    const dashboardId = 9;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);
    this.fetchOutletRecodeData();
  },
};
</script>


<style>
@import "../../styles/outlet_management/outlet_list.scss";
</style>