<template>
  <v-app id="app_create_routes">

    <v-overlay v-model="loading" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE ROUTE PLAN</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Create route plan according to your requirements.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn  color="#BB0000" size="x-large" variant="text" @click="go">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;CREATE OUTLETS DATA
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="goBack">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Create route details</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="routeName" ref="routeNameField" :rules="[v => isClearing || !!v || 'Route Name is required']"
                     label="Route Name *" persistent-placeholder placeholder="Type name here" variant="outlined" density="comfortable"
                     class="custom_input_field_color"
                />
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="routeCategory" ref="routeCategoryField" :items="routeCategories" :rules="routeCategoryRules" clearable item-title="category_name"
                    item-value="category_name" label="Route Category *" placeholder="Select category" required variant="outlined"
                    @update:modelValue="fetchOutlets" density="comfortable" class="custom_input_field_color"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="province" ref="provinceField" :items="provinces" :loading="mDistrictLoading" :rules="provinceRules" label="Province *" placeholder="Select province"
                    required clearable variant="outlined" @update:modelValue="onProvinceChange" density="comfortable" class="custom_input_field_color mt-2"
                ></v-autocomplete>
                <v-label class="text-caption custom_page_main_sub_title_very_small_red font-weight-medium">
                  <v-icon color="#BB0000">mdi-arrow-up-bold</v-icon> Hint: Once you select a province, it can't be changed. To modify it, just refresh the page.
                </v-label>
              </v-col>



              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="district" ref="districtField" :items="districts" :loading="mDistrictLoading" :rules="districtRules"
                    label="District"  placeholder="Select district" variant="outlined" @update:modelValue="onDistrictChange"
                    density="comfortable" class="custom_input_field_color mt-2"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="dsd" ref="dsdField" :items="dsdList" :loading="mDSDLoading" :rules="dsdRules" label="DSD"
                     placeholder="Select DSD" variant="outlined" @update:modelValue="onDsdChange" density="comfortable"
                    class="custom_input_field_color mt-2"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="gnd" ref="dsdField" :items="gndList" :loading="mGNDLoading" :rules="gndRules" label="GND"
                     placeholder="Select GND" variant="outlined"  density="comfortable"
                    class="custom_input_field_color mt-2"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete v-model="assignAgent" :items="filteredAgent" ref="assignAgentField" clearable :rules="assignAgentRules" item-title="name" item-value="id"
                                label="Assign Agent *" persistent-placeholder placeholder="Select agent" required variant="outlined" density="comfortable" class="custom_input_field_color mt-2"
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium">
                        Reg num: {{ item.raw.regNum }}
                      </p>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-autocomplete :items="filteredOutlet" item-title="outlet_name" item-value="id" label="Outlets *"
                                multiple placeholder="Select outlets" required variant="outlined"  density="comfortable"  class="custom_input_field_color mt-2"
                >

                  <template v-slot:item="{ item }">
                    <v-list  density="compact" >
                      <v-list-item>
                      <template v-slot:prepend>
                        <v-switch v-model="item.selected" class="ml-4" color="#BB0000" @change="handleSwitchChange(item)"></v-switch>
                      </template>

                      <template v-slot:default>
                        <div class="mb-2 ml-4">
                          <p class="custom_page_component_main_title text-caption font-weight-medium">
                            {{ item.raw.outlet_name }}
                          </p>

                          <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium">
                            outlet num : {{ item.raw.id }}
                          </p>
                        </div>
                      </template>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-date-input v-model="assignDate" ref="assignDateField" :min="new Date().toISOString().split('T')[0]" :rules="assignDateRules"
                    label="Assign Date *" prepend-inner-icon="$calendar" prepend-icon=""  placeholder="Select a Date" required variant="outlined" density="comfortable"  class="custom_input_field_color mt-2"
                ></v-date-input>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider class="mb-4"></v-divider>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider class="mt-1 mb-3"></v-divider>
                <p class="custom_page_main_sub_title">
                  <strong>SELECT ROUTE WITH OUTLET LIST</strong>
                </p>
                <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
                  The following table shows all the outlets related to the above-defined route.
                </p>


                <v-card class="mt-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
                  <v-toolbar color="white" class="pa-3" flat>
                    <v-divider vertical></v-divider>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-col cols="5">
                      <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"  class="custom_input_field_color"
                                    clearable hide-details label="Search" placeholder="Search Lead" variant="outlined"/>
                    </v-col>
                  </v-toolbar>

                  <v-divider></v-divider>

                  <v-card-text>
                    <v-data-table  :headers="headers" :items="filteredOutlets" :search="search" density="comfortable" class="custom_table_normal">
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn class="custom_delete_btn"  icon rounded
                            size="x-small" variant="outlined" @click="handleMinesSwitchChange(item)"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                          <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large"  class="custom_btn_primary" @click="submitForm">Create Route</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="cancelPageOutlet()">Cancel</v-btn>
        </v-card-actions>
      </v-card>


      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
        {{ snackbarMessage }}
      </v-snackbar>

    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isClearing: false,
      valid: false,
      loading: false,
      snackbar: false,
      selectedOutletData: null,
      assignDate: null,
      menu: false,
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      menuPosition: {x: 0, y: 0},
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      outlets: [],
      assignAgent: '',
      search: '',
      snackbarMessage: '',
      snackbarColor: '',
      routeCategories: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      outletOptions: [],
      filteredOutlet: [],
      filteredAgent: [],
      agentDataOptions: [],
      routes: [],
      excludedCategories: [],
      categoryIds: [],
      selectedOutlet: [],
      headers: [
        {text: 'Category', value: 'route_category', title: 'Category' , sortable: true},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name', sortable: true},
        {text: 'Address', value: 'address', title: 'Address', sortable: true},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      routeCategoryRules: [v => this.isClearing || !!v || 'Route Category is required'],
      provinceRules: [v => this.isClearing || !!v || 'Province is required'],
      districtRules: [v => this.isClearing || !!v || 'District is required'],
      dsdRules: [v => this.isClearing || !!v || 'DSD is required'],
      gndRules: [v => this.isClearing || !!v || 'GND is required'],
      assignDateRules: [v => this.isClearing || !!v || 'Assign Date is required'],
      assignAgentRules: [v => this.isClearing || !!v || 'Assign Agent is required'],
    };
  },

  methods: {

    onProvinceChange(value) {
      this.district = null; // Clear district when province changes
      this.dsd = null;      // Clear DSD when province changes
      this.gnd = null;      // Clear GND when province changes
      this.isClearing = true;
      this.assignAgent = '';
      this.filteredAgent = []; // Clear filtered agents by setting to an empty array
      this.selectedOutlet = [];

      // Reset validation states for all fields
      const fields = [
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });

      this.filterOutlets({ province: value });

      // Only apply the agent filter if the province is defined
      if (value) {
        this.filterAgents({ province: value });
      } else {
        this.filteredAgent = [];  // Clear the agents if no province selected
      }

      this.getAllDistrictAccProv(value);
    },

    onDistrictChange(value) {
      this.dsd = null;      // Clear DSD when district changes
      this.gnd = null;      // Clear GND when district changes
      this.isClearing = true;
      this.assignAgent = '';
      this.selectedOutlet = [];


      // Reset validation states for all fields
      const fields = [
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });
      this.filterOutlets({ district: value });
      this.filterAgents({ district: value });
      this.getAllDsd(value);
    },

    onDsdChange(value) {
      this.gnd = null;      // Clear GND when DSD changes
      this.filterOutlets({ dsd: value });
      this.filterAgents({ dsd: value });
      this.getAllGnd(value);
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.provinces = response.data.data;
          this.mDistrictLoading = false;
        }
      })
      .catch(() => {
        this.mDistrictLoading = false;
      });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
        .then(response => {
          console.log('District API response:', response.data);
          if (response.data.http_status === 'success') {
            this.districts = response.data.data;
            console.log('District API response:', response.data);
            this.mDistrictLoading = false;

          }
        })
        .catch(error => {
          this.mDistrictLoading = false;
          console.error('Error fetching districts:', error);

        });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.dsdList = response.data.data;
          this.mDSDLoading = false;
        }
      })
      .catch(() => {
        this.mDSDLoading = false;
      });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;

      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
      .then(response => {
        if (response.data.http_status === 'success') {
          this.gndList = response.data.data;
          this.mGNDLoading = false;
        }
      })
      .catch(() => {
        this.mGNDLoading = false;
      });
    },

    async submitForm() {
      // List of fields with their labels and values
      const fieldsToValidate = [
        {value: this.routeName, label: 'Route Name', rules: [v => !!v || 'Route Name is required']},
        {value: this.routeCategory, label: 'Route Category', rules: [v => !!v || 'Route Category is required']},
        {value: this.province, label: 'Province', rules: [v => !!v || 'Province is required']},
        {value: this.assignDate, label: 'Assign Date', rules: [v => !!v || 'Assign Date is required']},
        {value: this.assignAgent, label: 'Assign Agent', rules: [v => !!v || 'Assign Agent is required']},
        { value: this.selectedOutlet, label: 'Selected Outlet', rules: [v => v.length > 0 || 'At least one outlet must be selected'] },
      ];

      // Loop through each field and validate
      for (const field of fieldsToValidate) {
        for (const rule of field.rules) {
          const errorMessage = rule(field.value);
          if (errorMessage !== true) {
            // Show error in snackbar for the first invalid field
            this.snackbarMessage = `${field.label}: ${errorMessage}`;
            this.snackbarColor = 'error';
            this.snackbar = true;
            return; // Stop validation on the first error
          }
        }
      }

      // If all fields are valid, proceed with form submission
      try {
        await this.createRoute();
        // this.snackbarMessage = 'The Route Created successfully!';
        // this.snackbarColor = 'success';
        // this.snackbar = true;

        // Optionally reload or redirect
        // window.location.reload();
      } catch (error) {
        // Handle submission error
        this.snackbarMessage = 'Failed to submit the form. Please try again.';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }
    },

    async createRoute() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;
      const userId = localStorage.getItem('userId');
      const routeData = {
        userId: userId,
        route_name: this.routeName,
        route_category: this.routeCategory,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        assign_date: new Date(new Date(this.assignDate).setDate(new Date(this.assignDate).getDate() + 1)).toISOString().split('T')[0],
        visited_date: new Date(new Date(this.assignDate).setDate(new Date(this.assignDate).getDate() + 1)).toISOString().split('T')[0],
        assigner_id: userId,
        agent_id: this.assignAgent,
        selected_outlets: this.selectedOutlet,
      };

      console.log("input data check",routeData);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });

        // Handle successful response
        console.log("response", response);
        this.snackbarMessage = 'Route created successfully!';
        this.snackbarColor = 'success';
        this.snackbar = true;
        console.log('route save', response);
        window.location.reload();

      } catch (error) {
        // Handle error
        console.error("Error response:", error);

        if (error.response && error.response.data && error.response.data.errors) {
          // Extract the error message from the response
          const errorMessages = Object.values(error.response.data.errors)
              .flat()
              .join(', ');

          this.snackbarMessage = errorMessages;
        } else {
          // Generic error message for unexpected errors
          this.snackbarMessage = error.message || 'An unexpected error occurred';
        }

        this.snackbarColor = 'error';
        this.snackbar = true;
      } finally {
        this.loading = false;
      }
    },

    validateForm() {
      if (!this.$refs.form.validate()) {
        this.snackbarMessage = 'Please fill in all required fields!';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return false;
      }
      return true;
    },

    handleSwitchChange(item) {
      if (window.ResizeObserver) {
        const ro = new ResizeObserver(() => {
        });
        ro.observe(document.body);
      }
      if (item.selected) {
        this.selectedOutletData = item;

        // Find the corresponding route category
        const category = this.routeCategories.find(cat => cat.category_name === this.routeCategory);

        // Push the selected outlet with route_category_id into the selectedOutlet array
        this.selectedOutlet.push({
          outlet_id: item.raw.id,
          route_category: this.routeCategory,
          outlet_name: item.raw.outlet_name,
          address: item.raw.outlet_address,
          status: item.raw.outlet_status,
          visited_date: new Date(this.assignDate).toISOString().split('T')[0],
          route_category_id: category ? category.id : null // Assign category id or null if not found
        });

        console.log('Selected Outlets:', this.selectedOutlet);
      } else {
        this.selectedOutletData = null;

        // Use outlet_id to filter out the deselected item
        this.selectedOutlet = this.selectedOutlet.filter(outlet => outlet.outlet_id !== item.raw.id);
        console.log('Updated Selected Outlets after removal:', this.selectedOutlet);
      }
    },

    handleMinesSwitchChange(item) {
      if (window.ResizeObserver) {
        const ro = new ResizeObserver(() => {
        });
        ro.observe(document.body);
      }
      // Find the index of the item in the selectedOutlet array
      const selectedOutletIndex = this.selectedOutlet.findIndex(outlet => outlet.id === item.id);

      if (selectedOutletIndex !== -1) {
        // Remove the item from selectedOutlet array
        this.selectedOutlet.splice(selectedOutletIndex, 1);

        // Turn off the corresponding switch in outletOptions
        const outletToDeselect = this.outletOptions.find(outlet => outlet.id === item.id);
        if (outletToDeselect) {
          outletToDeselect.selected = false;
        }

        this.outletOptions.forEach(outlet => {
          if (outlet.category === item.category) {
            outlet.selected = false;
          }
        });

        // If the category of the item is in the excludedCategories array, remove it
        const isCategoryExcluded = this.excludedCategories.includes(item.category);
        if (isCategoryExcluded) {
          this.excludedCategories = this.excludedCategories.filter(category => category !== item.category);
        }
      }
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.routeCategories = response.data;
        console.log("API Response category list:", response.data);
      })
      .catch(error => {
        console.error('Error fetching main categories:', error);
      });
    },

    fetchOutlets() {
      console.log('Category selected:', this.routeCategory);
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      // Determine the API endpoint based on the routeCategory
      const apiEndpoint = this.routeCategory === 'COLLECTION'
          ? `${process.env.VUE_APP_API_BASE_URL}/api/outlet/view/CollectionManagement`
          : `${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`;

      axios.post(apiEndpoint, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        // Filter the response data to include only outlets with outlet_status = 'ALLOWED'
        const allowedOutlets = response.data.filter(outlet => outlet.outlet_status === 'ALLOWED');

        // Assign filtered data to outletOptions
        this.outletOptions = allowedOutlets;
        console.log("Filtered API Response:", allowedOutlets);
      })
      .catch(error => {
        console.error('Error fetching outlets:', error);
      });
    },

    filterOutlets(filters) {
      // Log the current filter inputs for debugging
      console.log("Filters applied:", filters);

      // If no province filter is applied, clear the filtered outlets
      if (!filters.province && !filters.district && !filters.dsd) {
        this.filteredOutlet = []; // Clear outlets if no filters are applied
      } else {
        // Otherwise, filter outlets based on the provided filters
        this.filteredOutlet = this.outletOptions.filter(outlet => {
          return (!filters.province || outlet.province === filters.province) &&
              (!filters.district || outlet.district === filters.district) &&
              (!filters.dsd || outlet.dsd === filters.dsd);
        });
      }
    },

    filterAgents(filters) {
      console.log("Agent Filters applied:", filters);

      // If no province filter is applied, don't show all agents
      if (!filters.province && !filters.district && !filters.dsd) {
        this.filteredAgent = []; // Clear outlets if no filters are applied
      } else {
        // Otherwise, filter based on the applied filters
        this.filteredAgent = this.agentDataOptions.filter(agent => {
          return (!filters.province || agent.province === filters.province) &&
              (!filters.district || agent.district === filters.district) &&
              (!filters.dsd || agent.dsd === filters.dsd);
        });
      }
    },

    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/agentUser`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.excludedCategories = response.data.data;
        console.log("API Response:", response.data.data);

        // Check if response.data is an array
        if (Array.isArray(this.excludedCategories)) {
          this.excludedCategories.forEach(agent => {
            this.agentDataOptions.push({
              id: agent.id,
              name: agent.name,
              regNum: agent.registration_num,
              district: agent.district,
              province: agent.province,
              dsd: agent.dsd,
            });
          });
        } else {
          // If it's a single object
          this.agentDataOptions.push({
            id: this.excludedCategories.id,
            name: this.excludedCategories.name,
            regNum: this.excludedCategories.registration_num,
          });
        }

        console.log("API Response ex:", this.excludedCategories);
        console.log('Agent Options:', this.agentDataOptions);
      })
      .catch(error => {
        console.error('Error fetching main categories:', error);
      });
    },

    go() {
      this.$router.push('/create-outlet');
    },

    goBack() {
      this.$router.push('/dashboard');
    },

    cancelPageOutlet() {
      // Set the clearing flag to bypass validation
      this.isClearing = true;

      // Reset all form data
      this.routeName = '';
      this.routeCategory = '';
      this.province = '';
      this.district = '';
      this.dsd = '';
      this.gnd = '';
      this.assignDate = null;
      this.assignAgent = '';
      this.selectedOutlet = [];

      // Reset validation states for all fields
      const fields = [
        this.$refs.routeNameField,
        this.$refs.routeCategoryField,
        this.$refs.provinceField,
        this.$refs.districtField,
        this.$refs.dsdField,
        this.$refs.gndField,
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });

      // Allow a brief delay to ensure the fields are reset
      setTimeout(() => {
        this.isClearing = false; // Re-enable validation
      }, 50);
    },
  },

  mounted() {
    this.getAllProvinces();
    this.fetchOutlets();
    this.fetchMainCategories();
    this.fetchAllAgents();
  },

  watch: {
    menu(newValue) {
      console.log('Menu state changed:', newValue);
    },
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    },
  },

  created() {

  },

  computed: {
    filteredOutlets() {
      return this.selectedOutlet.filter((sale) => {
        if (!sale) return false;
        console.log(sale);

        // Check if the sale matches the search query
        // Return true if both conditions are met
        return !this.search ||
          (sale.route_category && sale.route_category.toLowerCase().includes(this.search.toLowerCase())) ||
          (sale.outlet_name && sale.outlet_name && sale.outlet_name.toLowerCase().includes(this.search.toLowerCase())) ||
          (sale.address && sale.address.toLowerCase().includes(this.search.toLowerCase())) ||
          (sale.sales_amount && sale.sales_amount.toString().includes(this.search));
      });
    }
  },
};
</script>


<style>
  @import "../../styles/route_management/create_routes.scss";
</style>