<template>
  <v-app id="app_add_category_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CATEGORY LIST</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse product categories in your outlet.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn  color="#BB0000" size="x-large" variant="text" @click="addProductsPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD CATEGORY
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row>
          <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="selectedMainCategory" :items="mainCategories" clearable
                  item-title="name" item-value="id" label="Main Category"
                  placeholder="Select main category" variant="outlined"
                  @update:modelValue="fetchSubCategories()" class="custom_input_field_color" density="comfortable"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="selectedSubCategory" :items="subCategories" :loading="productLoading"
                  clearable item-title="sub_category_name" item-value="sub_category_name"
                  label="Sub Category" multiple placeholder="Select sub category"
                  variant="outlined" class="custom_input_field_color" density="comfortable"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" clearable hide-details
                  label="Search" placeholder="Search Products" variant="outlined" class="custom_input_field_color" density="comfortable"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table
              v-model:page="page" :headers="headers" :loading="loading"
              :items="filteredItems" :items-per-page="itemsPerPage"
              @page="fetchProducts" class="custom_table_normal"
              @page-count="totalPages = $event"
          >

            <template v-slot:[`item.image_path`]="{ item }">
              <div class="product-column">
                <v-row>
                  <v-col cols="5">
                    <v-img v-if="item.image_path && item.image_path.length > 0"
                        :src="`${imageURL}/${item.image_path}`"
                         aspect-ratio="1" class="product_image mt-2 mb-1"
                        @click="selectImage(`${imageURL}/${item.image_path}`)"
                    ></v-img>
                  </v-col>
                  <v-col class="d-flex align-center" cols="7">
                    <span class="product-title">{{ item.product_title }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>


            <template v-slot:[`item.productsubcatogary`]="{ item }">
              <span v-if="item.productsubcatogary && item.productsubcatogary.length">
                {{ item.productsubcatogary.map(sub => sub.sub_category_name).join(', ') }}
              </span>
            </template>

            <template v-slot:[`item.category_desc`]="{ item }">
              <span v-if="item.category_desc && item.category_desc.length" v-html="getSanitizedHTML(item.category_desc)">
              </span>
            </template>

            <template v-slot:[`item.action`]="{ item }" >
              <v-menu transition="slide-x-transition" id="app_product_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="permissions.write" @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View Products Dialog -->
      <v-dialog v-model="view_product_dialog" fullscreen id="app_add_category_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="view_product_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15 custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon >mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">PRODUCT CATEGORY DETAILS</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="2">
                        <v-img :src="selectedImage ? selectedImage : ''" aspect-ratio="1" class="product_image_view"></v-img>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                          v-if="permissions.write" class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined"  @click="editItem(product)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                          v-if="permissions.delete" class="custom_delete_btn"  icon rounded
                          size="small" variant="outlined" @click="confirmDelete(product)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="product.main_category" class="px-3 custom_input_field_class_view custom_input_field_color" label="Main Category" readonly
                        variant="outlined" density="comfortable"
                    ></v-text-field>

                    <div v-for="(subCategory, index) in product.productsubcatogary" :key="subCategory.id" class="mb-2">
                      <v-text-field
                          v-model="subCategory.sub_category_name" :label="'Sub Category ' + (index + 1)"  readonly
                          variant="outlined" density="comfortable"  class="px-3 custom_input_field_class_view custom_input_field_color"
                      ></v-text-field>
                    </div>

                    <!-- <v-textarea
                        v-model="product.category_desc" class="px-3 custom_input_field_class_view custom_input_field_color" label="Product Description" readonly
                        variant="outlined"  density="comfortable"
                    ></v-textarea> -->

                    <div class="px-3 mb-4">
                      <p class="custom_page_main_sub_title">Category Description</p>
                      <v-divider class="mt-3 mb-4"></v-divider>
                      <v-card color="#F7F7F7" flat border="sm opacity-1" outlined density="comfortable">
                        <v-card-text>
                          <div  v-html="getSanitizedHTML(product.category_desc)" class=" ml-3 ql-container ql-snow"></div>
                        </v-card-text>
                      </v-card>
                    </div>

                  </v-col>

                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View products dialog -->

      <!-- Edit products dialog -->
      <v-dialog v-model="edit_product_dialog"  fullscreen id="app_add_category_list">
        <v-card>
          <v-toolbar style="border-radius: 0px !important;" class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="edit_product_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row  class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon >mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE PRODUCT CATEGORY</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="2">
                        <v-img :src="selectedImage ? selectedImage : ''" aspect-ratio="1" class="product_image_view"></v-img>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateProduct">UPDATE PRODUCT CATEGORY</v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="product.main_category" :rules="[v => !!v || 'Main category is required', v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Only letters, numbers, and spaces are allowed']" class="custom_input_field_color"  label="Main Category *" required variant="outlined" @update:modelValue="fetchSubCategories()"
                         density="comfortable"
                    ></v-text-field>


                    <v-card flat>
                      <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">Update / Change main category image</p>
                      <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop" @dragover.prevent="onDragOver">
                        <input ref="fileInput" accept="image/*" class="hidden-file-input" type="file" @change="onFileChange"/>
                        <div v-if="previewImage" class="image-card-container">
                          <v-card class="image-card">
                            <v-img :alt="'Image preview'" :src="previewImage" class="image-preview"></v-img>
                            <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage">
                              <v-icon color="red" size="small">mdi-delete</v-icon>
                            </v-btn>
                          </v-card>
                        </div>
                        <div v-if="!previewImage" class="placeholder-container">
                          <v-icon size="36">mdi-cloud-upload</v-icon>
                          <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>
                        </div>
                      </div>

                      <v-row class="mb-8">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <v-btn class="custom_excel_btn mt-6" variant="outlined" @click="addSubCategory">
                            <v-icon>mdi-plus-thick</v-icon>&nbsp; Add sub categories</v-btn>
                          <v-divider class="mt-3"></v-divider>
                        </v-col>
                      </v-row>

                      <div v-for="(subCategories, index) in subCategory" :key="subCategories.id">
                        <v-row  style="margin-top: -2% !important;">
                          <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                            <v-text-field
                                v-model="subCategories.sub_category_name"
                                :label="'Sub Category ' + (index + 1)"
                                 density="comfortable"
                                placeholder="Create sub category"
                                variant="outlined" class="custom_input_field_color"
                                :rules="[ v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Only letters, numbers, and spaces are allowed']"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                            <v-btn v-if="subCategory.length > 0" style="width: 100% !important;" class="custom_pdf_btn"
                                   variant="outlined" @click="removeSubCategory(index)"
                            ><v-icon>mdi-minus-thick</v-icon>&nbsp; Remove</v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>

                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description </p>
                        <v-card  class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="280" min-height="280">
                          <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                          <v-divider></v-divider>
                          <Editor v-model="product.category_desc" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated"/>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->

                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit products dialog -->


      <!-- Delete product confirmation dialog -->
      <v-dialog v-model="delete_product_Catagary_dialog" id="app_add_category_list" max-height="500" max-width="500" persistent>
        <v-card id="app_product_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE PRODUCT CATAGORY</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="delete_product_Catagary_dialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this Catagary ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This product Catagary will be deleted immediately. Once deleted, it can no longer be used in any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="delete_product_Catagary_dialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete product confirmation dialog -->

      <!-- Snackbar to show notifications -->
      <v-snackbar  v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";
import DOMPurify from 'dompurify';

//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import { i18nChangeLanguage } from '@wangeditor/editor'
i18nChangeLanguage('en')
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
//This is related to wangeditor ---END

export default {
  components: {
    Editor,
    Toolbar,
  },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = { placeholder: 'Type here...' }

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END


  name: 'CategoryLit',
  data() {
    return {
      imageURL:"",
        dataDescription:'',
        formatting: [],
        showColorPicker: false,
        selectedColor: '#000',
        alignment: '',
        textareaStyles: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          textDecoration: 'none',
          color: '#000',
          textAlign: 'left',
        },

      productLoading:false,
      subCategory:[],

      form: {
        images:'',
      },

      search: '',
      selectedMainCategory: null,
      selectedSubCategory: [],

      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,

      headers: [
        { text: 'MAIN CATEGORY IMAGE', value: 'image_path', title: 'MAIN CATEGORY IMAGE', sortable: true },
        { text: 'MAIN CATEGORY', value: 'main_category', title: 'MAIN CATEGORY', sortable: true },
        { text: 'SUB CATEGORY', value: 'productsubcatogary', title: 'SUB CATEGORY', sortable: true },
        { text: 'DESCRIPTION', value: 'category_desc', title: 'DESCRIPTION', sortable: true },
        { text: 'ACTION', value: 'action', sortable: false, title: 'ACTION' },
      ],


      // Dialog state
      delete_product_Catagary_dialog: false,
      view_product_dialog: false,
      edit_product_dialog: false,
      selectedProductId: null,
      product: {},

      numericRule: value => !isNaN(parseFloat(value)) || 'Price must be a number',

      item:[],
      mainCategories: [],
      subCategories: [],
      loading:false,
      // List of products
      items: [],

      // Additional fields
      product_image: [], // List of images for upload

      // Image handling
      previewImage: null,
      selectedImage: '',
      permissions: {},

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      productcheck: null,
      dialog: false,
      // mode: 'hsla',
      modes: ['hsla', 'rgba', 'hexa'],
    };
  },
  created() {
    // this.fetchCategories();
    this.imageURL = process.env.VUE_APP_IMAGE_URL;
    this.fetchProducts();
    this.fetchMainCategories();
  },
  methods: {

    getSanitizedHTML(html) {
    return DOMPurify.sanitize(html);
    },
    convertToPlainText(html) {
      const sanitizedHTML = this.getSanitizedHTML(html);
      // Create a temporary DOM element to strip HTML tags
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = sanitizedHTML;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
            this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
            this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
          this.textareaStyles.color = this.selectedColor;
        }
      },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    addSubCategory() {
      // Add a new sub-category object with an empty name
      this.subCategory.push({id: Date.now(), subcatagaryName: ''});
    },
    // Remove a sub-category
    removeSubCategory(index) {
      // Remove the sub-category at the specified index
      this.subCategory.splice(index, 1);
    },

    sizeValidation(value) {
      const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
      return sizePattern.test(value.trim());
    },
    exportExcel() {

      function stripHTMLTags(input) {
          const doc = new DOMParser().parseFromString(input, 'text/html');
          return doc.body.textContent || "";
        }

      const tableData = this.filteredItems.map((item) => ({
       
        'PRODUCT MAIN CATEGORY NAME': item.main_category,
        'SUBCATEGORY': item.productsubcatogary && item.productsubcatogary.length > 0
          ? item.productsubcatogary.map(sub => sub.sub_category_name).join(', ')
          : '',
        'DESCRIPTION':  stripHTMLTags(item.category_desc) || '',
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Products Catagory');
      XLSX.writeFile(workbook, 'products_catagary_analysis.xlsx');
    },


    exportPDF() {
      const doc = new jsPDF();
      // Set the title for the document
      doc.text('Product Catagory Details', 14, 10);

      // Function to strip HTML tags
      function stripHTMLTags(input) {
          const doc = new DOMParser().parseFromString(input, 'text/html');
          return doc.body.textContent || "";
        }


      // Prepare the data for the table
      const tableData = this.filteredItems.map((item, index) => [
        index + 1,
        item.main_category,
        // Join all subcategory names into a single string
        item.productsubcatogary && item.productsubcatogary.length > 0 
          ? item.productsubcatogary.map(sub => sub.sub_category_name).join(', ') 
          : '',
          item.category_desc 
      ? stripHTMLTags(item.category_desc).substring(0, 100) + (item.category_desc.length > 100 ? '...' : '')
      : '',
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'MAIN CATEGORY','SUBCATEGORY','DESCRIPTION'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF with a name
      doc.save('products_category_analysis.pdf');
    },


    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/mainProduct', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        let sample = response.data.data;
        this.mainCategories = sample.map(item => {
          return {
            id: item.id,
            name: item.main_category
          };
        });
        console.log('Main categories:', this.mainCategories);
      })
      .catch(error => {
        console.error('Error fetching main categories:', error);
      });
    },


    fetchSubCategories() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      this.productLoading = true;
      this.selectedSubCategory = null;
      this.product.sub_category = null;
      let mainCatagoryId = this.selectedMainCategory || this.product.main_category
      // const mainCatagoryId = this.product.main_category || this.selectedMainCategory;
      console.log('Main Category ID:', this.product.main_category);
      console.log('Main Category ID t:', this.selectedMainCategory);

      axios.post(`/api/subcatagoryProduct`,
          {
            userId: userId,
            mainCatagoryId: mainCatagoryId
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

      .then(response => {
        console.log('API Response:', response);
        this.subCategories = response.data.data;
        console.log('Subcategories:', this.subCategories);
        // Find and set the main category name
        const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
        this.product.main_category_name = selectedCategory ? selectedCategory.name : '';
        this.selectedMainCategory = selectedCategory ? selectedCategory.name : '';
        this.productLoading = false;
        console.log('Selected Main Category Name:', this.product.main_category_name);
      })
      .catch(error => {
        console.error('Error fetching sub categories:', error);
        this.productLoading = false;
      });
    },

    getCategoryName(categoryId) {
      console.log('Looking up categoryId:', categoryId);
      if (typeof categoryId === 'string') {
        categoryId = parseInt(categoryId, 10); // Convert string ID to number if needed
      }
      const category = this.mainCategories.find(cat => cat.id === categoryId);
      console.log('Found category:', category);
      return category ? category.name : 'Unknown';
    },

    // Add the rest of your methods here, including createCategory and validation
    validateForm() {
        if (!this.product.main_category) {
          this.showSnackbar('Main category is required.', 'error');
          return false;
        }

        // Ensure Main Category contains only letters, numbers, and spaces
        const mainCategoryRegex = /^[a-zA-Z0-9 ]+$/;
        if (!mainCategoryRegex.test(this.product.main_category)) {
          this.showSnackbar('Main category must contain only letters, numbers, and spaces.', 'error');
          return false;
        }

        if (!this.subCategory.length) {
          this.showSnackbar('Al least One Sub category is required.', 'error');
          return false;
        }
       

        if (!this.product.category_desc) {
          this.showSnackbar('Category description is required.', 'error');
          return false;
        }
        return true;
      },

    async updateProduct() {

      if (!this.validateForm()) {
          return;
      }


      const formData = new FormData();
      formData.append('mainCatagaryId', this.product.id);
      formData.append('mainCatagaryName', this.product.main_category);
      formData.append('mainCatagaryDescription', this.product.category_desc);
      formData.append('userId', localStorage.getItem('userId'));

      // Transform subCategory array by renaming sub_category_name to subcatagaryName
      const transformedSubCategory = this.subCategory.map(sub => ({
        ...sub,
        subcatagaryName: sub.sub_category_name, // Rename the field
        sub_category_name: undefined // Remove original sub_category_name
      }));

      const subCatagoryJson = JSON.stringify(transformedSubCategory);
      formData.append('subCatagaryDetails', subCatagoryJson);
      console.log('Transformed subCategory:', subCatagoryJson);

      try {
        console.log('jjjjjjj', this.form.images)
        if (this.form.images && this.form.images.size > 0) {
          console.log('ssssss')
          const base64Images = await this.convertToBase64(this.form.images);
          formData.append('mainCatagaryImage', base64Images);

          console.log('fuk img',base64Images);
        }

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        // Send FormData to the API
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/updateCatagaryDetails`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'Product updated successfully';
          this.view_product_dialog = false;
          this.edit_product_dialog = false;
          this.fetchProducts();
          this.product.main_category_name = '';
          this.selectedMainCategory = '';
          window.location.href = "/category-list"
        } else {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Failed to update product';
          this.fetchProducts();
        }
      } catch (error) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
        console.error('Error updating product:', error);
      }
    },


    convertToBase64(file) {
      console.log('ffffffffffffff',file);
      return new Promise((resolve, reject) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        } else {
          reject(new Error('Invalid file type'));
        }
      });
    },

    homeReload() {
      window.location.href = '/dashboard';
    },

    fetchProducts() {
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("userId", userId);

      axios.post('/api/getCatagaryDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        // Sort the data by created_at in descending order (newest first)
        this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        // this.items = response.data.data;
        this.loading = false;
        console.log('Result', response.data.data);
        // this.subCategories = response.data.subCategories;
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        this.loading = false;
      });
    },

    addProductsPageRoute() {
      this.$router.push({path: '/products-category'});
    },


    viewItem(item) {
      this.selectedProductId = item.id;
      this.product = {...item};
      this.selectedImage = `${this.imageURL}/${item.image_path}` || '';
      this.previewImages = item.product_image || [];
      this.view_product_dialog = true;
      this.subCategory = item.productsubcatogary;
      console.log("View item:", item);
      console.log("View sub:", this.subCategory);
    },

    editItem(item) {
      this.edit_product_dialog = true;
      this.selectedProductId = item.id;
      this.product = {...item}; // Create a copy of the item
      this.selectedImage = `${this.imageURL}/${item.image_path}` || '';
      this.previewImages = item.product_image || [];
      // this.previewImages = item.product_image.map(img => img.image_url) || [];
      this.subCategory = item.productsubcatogary;
      this.form.images = [];
      console.log('Editing item:', item);
      console.log("Edit item:", item);
      console.log("View sub:", this.subCategory);
    },

    confirmDelete(item) {
      this.delete_product_Catagary_dialog = true;
      this.selectedProductId = item.id;
      console.log('Deleting item:', this.selectedProductId);
    },

    deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const ProductID = this.selectedProductId;
      console.log('delete id', ProductID);

      axios.post(`/api/deleteCatagaryDetails`, {
        userId: userId,
        mainCatagaryId: ProductID,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      .then(() => {
        // Handle success - remove the deleted item from the table data
        this.fetchProducts(); // Refresh the user list
        this.delete_product_Catagary_dialog = false; // Close the confirmation dialog
        this.view_product_dialog = false;
        console.log('product deleted successfully');
        this.showSnackbar("Product Category delete successfully!", "success");
      })
      .catch((error) => {
        console.log('Error deleting Product', error);
        this.delete_product_Catagary_dialog = false;
        this.fetchProducts();
      });
    },

    backPageRoute() {
      this.$router.back();
    },

    selectImage(image) {
      this.selectedImage = image;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0]; // Get the first file from the input
      if (file) {
        this.previewImage = URL.createObjectURL(file); // Set the preview image
        this.handleFiles(file); // Pass the file to handleFiles
      }
    },

    onDrop(event) {
      const file = event.dataTransfer.files[0]; // Get the first file from the drop
      if (file) {
        this.previewImage = URL.createObjectURL(file); // Set the preview image
        this.handleFiles(file); // Pass the file to handleFiles
      }
    },

    onDragOver(event) {
      event.preventDefault();
    },

    removeImage() {
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    },

    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      if (!(file instanceof File)) {
        console.error('Not a valid File object:', file);
        return;
      }

      this.form.images = file; // Store the single image
    },
  },

  mounted() {
    const dashboardId = 2;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    if (this.product.product_image && this.product.product_image.length > 0) {
      this.selectedImage = this.product.product_image[0];
    }
  },

  computed: {
    oldPrice() {
      const price = parseFloat(this.product.price);
      const discountRate = parseFloat(this.product.discount_rate);
      if (discountRate > 0) {
        return (price / (1 - discountRate / 100)).toFixed(2);
      }
      return price.toFixed(2); // If no discount, old price is the current price
    },
    
    filteredItems() {
      return this.items.filter(product => {
        console.log('Filtering product:', product);

        // Matches main category
        const matchesMainCategory = !this.selectedMainCategory || product.main_category === this.selectedMainCategory;

        // Matches subcategories
        const matchesSubCategory = !this.selectedSubCategory ||
          this.selectedSubCategory.length === 0 ||
          (product.productsubcatogary && product.productsubcatogary.some(sub =>
            this.selectedSubCategory.includes(sub.sub_category_name)
          ));

        // Matches search criteria
        const matchesSearch = !this.search ||
        (product.main_category && product.main_category.toLowerCase().includes(this.search.toLowerCase())) ||
        (product.productsubcatogary && product.productsubcatogary.some(sub =>
          sub.sub_category_name && sub.sub_category_name.toLowerCase().includes(this.search.toLowerCase())
        ));
        
        // Debug log the filtering results
        console.log('Matches main category:', matchesMainCategory);
        console.log('Matches subcategory:', matchesSubCategory);
        console.log('Matches search:', matchesSearch);

        // Return filtered results
        return matchesMainCategory && matchesSubCategory && matchesSearch;
      });
    }
  },
};
</script>

<style>
  @import "../../styles/product_management/category_list.scss";
</style>