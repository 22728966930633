import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './routes'
import 'vuetify/styles'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import store from './store/store'
import Echo from 'laravel-echo';
import axios from 'axios';
import Pusher from 'pusher-js';


// Load fonts
loadFonts();

// window.Pusher = require('pusher-js');
window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '835daa77c116f26de07e',
    cluster: 'ap2',
    encrypted: true
});


// Set the base URL for Axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// Create Vue app
const app = createApp(App);


// Use plugins and router
app.use(router);
app.use(vuetify);
app.use(store);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCQMADkuuUCAUyisqVPYbzPXthJWrSzUe0',
        libraries: 'places',
    },
})

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to the login page
            router.push({ name: 'Login' });
        }
        return Promise.reject(error);
    }
);

// Mount the app
app.mount('#app');


