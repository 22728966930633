<template>
  <v-app id="app_sales_management">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">SALES DATA</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse sales data in a variety of stores.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>
        <v-expansion-panels>
          <v-expansion-panel elevation="0">
            <v-expansion-panel-title expand-icon="mdi-menu-down">
              <v-icon class="mr-3 ">mdi-filter-menu-outline</v-icon>
              <span class="text-body-1 font-weight-bold">FILTERS</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-row class="mt-2">
                <v-col cols="12" md="4">
                  <v-date-input
                      v-model="dates" append-inner-icon="mdi-close" prepend-inner-icon="$calendar" prepend-icon=""
                      label="Select Date Range" multiple="range" density="comfortable"   class="custom_input_field_color"
                      variant="outlined" @click:append-inner="clearDateRange"
                  ></v-date-input>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedAgent" :items="agentLists" density="comfortable"   class="custom_input_field_color" clearable
                                  item-title="name" item-value="name" label="Agent" multiple placeholder="Select agent name" variant="outlined"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedRoute" :items="getAllRouteName" clearable
                                  item-title="text" item-value="value" label="Route" multiple
                                  placeholder="Select Route" variant="outlined" density="comfortable"   class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectDistrict" :items="getAllDoneDistrict" clearable item-title="text" item-value="value"
                                  label="District" multiple placeholder="Select District" variant="outlined" density="comfortable"   class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectDsd" :items="getAllDsd" clearable item-title="text" item-value="value" label="DSD" multiple
                                  placeholder="Select DSD" variant="outlined" density="comfortable"   class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectGnd" :items="getAllGnd" clearable item-title="text" item-value="value"
                                  label="GND" multiple placeholder="Select GND" variant="outlined" density="comfortable"   class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete v-model="selectedSalesType" :items="getAllSalesType" clearable item-title="text" item-value="value" label="Sales Type"
                                  multiple placeholder="Select sub category" variant="outlined" density="comfortable"   class="custom_input_field_color"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field v-model="search" clearable label="Search" placeholder="Search"
                                variant="outlined"  density="comfortable"   class="custom_input_field_color"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="mb-3 mt-4"></v-divider>

<!--              <v-row class="mt-0">-->
<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="mainCategory" clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Main Category" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Main Category" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->

<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="subCategory"  clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Sub Category" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Sub Category" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->

<!--                <v-col cols="12" md="4">-->
<!--                  <v-autocomplete v-model="products"  clearable density="comfortable"-->
<!--                                  item-title="text" item-value="value" label="Products" class="custom_input_field_color"-->
<!--                                  multiple placeholder="Select Products" variant="outlined"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--              </v-row>-->

              <v-divider class="mb-6"></v-divider>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-text>
          <v-data-table  :headers="headers" :items="filteredRoutes" :items-per-page="5" :search="search" class="px-2 custom_table_normal" @page-count="totalPages = $event">

            <template v-slot:[`item.agent.name`]="{ item }">
              <div>
                <div>{{ item.agent.name}}</div>
                <div style="color: #BB0000">Reg num: {{ item.agent.registration_num }}</div>
              </div>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <!-- Format and display only the date part -->
              {{ new Date(item.created_at).toISOString().split('T')[0] }}
            </template>


            <template v-slot:[`item.action`]="{ item }" >
              <v-menu transition="slide-x-transition" id="app_sales_management">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item  v-if="permissions.write"  @click="editItem(item)">-->
<!--                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>-->
<!--                  </v-list-item>-->
                  <v-list-item  v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View User Dialog -->
      <v-dialog v-model="view_Dialog" fullscreen id="app_sales_management">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="view_Dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <Sales_Record/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="editDialog" fullscreen id="app_sales_management">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="backPageRoute()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="editDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <Sales_Record_Edit/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit User dialog -->


      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteDialog" id="app_sales_management" max-height="500" max-width="500" persistent>
        <v-card id="app_sales_management" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE SALES DATA</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this sales record ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This sales record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->


      <!-- Snackbar to show notifications -->
      <v-snackbar  v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
  import axios from 'axios';
  import Sales_Record_Edit from "@/components/SALES_MANAGEMENT/Sales_Recod_Edit.vue";
  import Sales_Record from "@/components/SALES_MANAGEMENT/Sales_Record.vue";
  import { EventBus } from "@/plugins/event-bus";
  import {mapActions} from "vuex";
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  import _ from 'lodash';
  import {getPermissions} from "@/plugins/permissions";

  export default {
    components: {
      Sales_Record,
      Sales_Record_Edit
    },
    data() {
      return {
        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
        mainCategory:"",
        subCategory:"",
        products:"",
        agentLists:[],
        selectedAgent:[],
        getAllDoneDistrict:[],
        getAllSalesType:[],
        getAllRouteName: [],
        getAllDsd:[],
        getAllGnd:[],
        selectDsd:[],
        selectGnd:[],
        selectDistrict:[],
        selectedSalesType:[],
        selectedRoute:[],
        menu: false,
        dates: [],
        dateRange: '',
        search: '',
        deleteDialog: false,
        itemToDelete: null,
        view_Dialog: false,
        editDialog: false,
        agentID: null,
        headers: [
          { text: 'Order Id', value: 'order_id', title: 'ORDER ID ' , sortable: true},
          { text: 'Outlet Name', value: 'store_name', title: 'OUTLET NAME' , sortable: true},
          { text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME' , sortable: true},
          { text: 'Total Sales', value: 'sales_amount', title: 'TOTAL SALES' , sortable: true},
          { text: 'Route Name', value: 'route_name', title: 'ROUTE NAME' , sortable: true},
          { text: 'Sales Date', value: 'created_at', title: 'SALES DATE' , sortable: true},
          { text: 'Sales Type', value: 'sales_type', title: 'SALES TYPE' , sortable: true},
          { text: 'Actions', value: 'action', sortable: false, title: 'ACTION' },
        ],
        sales: [],
        permissions: {},
      }
    },

    methods: {
      ...mapActions(['updateSharedData']),

      clearDateRange() {
        this.dates = [];
      },

      // Get Agent List
      getAllAgentsDeteails() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`/api/agentUser`, { userId }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.agentLists = response.data.data;
          console.log("API Response:", response.data.data);
        })
        .catch(error => {
          console.error("There was an error fetching the sales data!", error);
        });
      },


      fetchSalesData() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales`, { userId }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log("API Response:", response.data);
          this.sales = Array.isArray(response.data) ? response.data : [];

          console.log('fucking Data',this.sales);

          const selectableDistricts = _.groupBy(this.sales, 'district');
          const selectableDsd = _.groupBy(this.sales, 'dsd');
          const selectableGnd = _.groupBy(this.sales, 'gnd');
          const selectableRoute = _.groupBy(this.sales, 'route_name');
          const selectableType = _.groupBy(this.sales, 'sales_type');

          this.getAllDoneDistrict = Object.keys(selectableDistricts).map(district => ({
            text: district,
            value: district
          }));

          this.getAllRouteName = Object.keys(selectableRoute).map(route => ({
            text: route,
            value: route
          }));

          this.getAllSalesType = Object.keys(selectableType).map(type => ({
            text: type,
            value: type
          }));

          this.getAllDsd = Object.keys(selectableDsd).map(dsd => ({
            text: dsd,
            value: dsd
          }));

          this.getAllGnd = Object.keys(selectableGnd).map(gnd => ({
            text: gnd,
            value: gnd
          }));

          console.log("District", this.getAllDoneDistrict);
          console.log("Sales after assignment:", this.sales);
        })
        .catch(error => {
          console.error("There was an error fetching the sales data!", error);
          this.sales = [];
        });
      },

      confirmDelete(item) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },


      deleteItem() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales/delete/${this.itemToDelete.id}`,{ userId },{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.sales = this.sales.filter(sale => sale.id !== this.itemToDelete.id);
          this.deleteDialog = false;
          this.itemToDelete = null;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'Sales record was deleted successfully';
        })
        .catch(error => {
          console.error("There was an error deleting the sale!", error);
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Sales record was delete Error';
        });
      },

      updateDateRange() {
        if (this.dates.length === 2) {
          this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
        }
      },
      exportPDF() {
        const doc = new jsPDF();
        // Set the title for the document
        doc.text('Sales Analysis', 14, 10);
        // Prepare the data for the table based on filtered routes
        const tableData = this.filteredRoutes.map((item, index) => [
          index + 1,
          item.order_id,
          item.store_name,
          item.agent.name,
          item.sales_amount,
          item.route_name,
          item.created_at.split('T')[0],
          item.sales_type
        ]);

        // Define the columns for the table
        const tableColumns = ['#', 'Order ID', 'Outlet Name', 'Agent Name', 'Total Sales', 'Route Name', 'Sales Date', 'Sales Type'];

        // AutoTable plugin to create a table
        doc.autoTable({
          head: [tableColumns],
          body: tableData,
        });
        // Save the PDF with a name
        doc.save('sales_analysis_filtered.pdf');
      },

      exportExcel() {
        const tableData = this.filteredRoutes.map((item, index) => ({
          '#': index + 1,
          'Order ID': item.order_id,
          'Route Name': item.route_name,
          'Outlet Name': item.store_name,
          'Agent Name': item.agent.name,
          'Total Sales': item.sales_amount,
          'Sales Date': item.created_at.split('T')[0],
          'Sales Type': item.sales_type,
          'Quantity Sold': item.quantity_sold,
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set column widths
        const columnWidths = [
          { wpx: 50 },
          { wpx: 150 },
          { wpx: 120 },
          { wpx: 120 },
          { wpx: 80 },
          { wpx: 100 },
          { wpx: 80 },
          { wpx: 110 },
          { wpx: 80 },

        ];
        worksheet['!cols'] = columnWidths;

        // Style header cells
        Object.keys(worksheet).forEach(cell => {
          if (cell.startsWith('A1')) {
            worksheet[cell].s = {
              font: { bold: true, color: { rgb: "FFFFFF" } },
              fill: { fgColor: { rgb: "1F4E78" } },
              alignment: { horizontal: "center" },
            };
          }
        });
        // Create workbook and append the sheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Analysis');
        XLSX.writeFile(workbook, 'sales_analysis_filtered.xlsx');
      },

      viewItem(item) {
        this.updateSharedData(item.id);
        this.view_Dialog = true;
      },

      editItem(item) {
        //  functionality to edit item details
        this.editDialog = true;
        this.updateSharedData(item.id);
      },

      closeD() {
        this.view_Dialog = false;
        this.editDialog = false;
      },

      goBack() {
        this.$router.back();
      },

      SalesManagementButtonClick() {
        this.editDialog = true;
        this.view_Dialog = false;
      },
      sale2(){
        this.editDialog = false;
        this.view_Dialog = false;

      },
      setupEventListeners() {
        EventBus.on('SalesRecodeManagementButtonClick', this.SalesManagementButtonClick);
        EventBus.on('SalesRecodeManagementButtonClick2', this.sale2);
      },

      removeEventListeners() {
        EventBus.off('SalesRecodeManagementButtonClick', this.SalesManagementButtonClick);
        EventBus.off('SalesRecodeManagementButtonClick2', this.sale2);
      },

      backPageRoute(){
        this.$router.push('/dashboard');
      }
    },

    computed: {
      filteredRoutes() {
        return this.sales.filter((sale) => {
          if (window.ResizeObserver) {
            const ro = new ResizeObserver(() => {});
            ro.observe(document.body);
          }
          if (!sale) return false;

          const formatDate = (date, addOneDay = false) => {
            if (!date) return null;
            const d = new Date(date);
            if (isNaN(d.getTime())) return null;
            if (addOneDay) {
              d.setDate(d.getDate() + 1);
            }
            return d.toISOString().split('T')[0];
          };

          const startDate = this.dates.length ? formatDate(this.dates[0], true) : null;
          const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

          console.log('Start Date:', startDate);
          console.log('End Date:', endDate);

          const startDateObj = startDate ? new Date(startDate) : null;
          const endDateObj = endDate ? new Date(endDate) : null;

          const saleAssignDate = sale.created_at ? sale.created_at.split('T')[0] : null;
          const saleAssignDateObj = saleAssignDate ? new Date(saleAssignDate) : null;

          const withinDateRange = !startDateObj || !endDateObj || (saleAssignDateObj && saleAssignDateObj >= startDateObj && saleAssignDateObj <= endDateObj);

          console.log('Within Date Range:', withinDateRange);
          console.log('Sale Date:', formatDate(sale.assign_date));
          console.log('Start Date:', startDate);
          console.log('End Date:', endDate);

          // Other filters remain unchanged
          const matchesSearch =
              !this.search ||
              (sale.store_name && sale.store_name.includes(this.search)) ||
              (sale.agent && sale.agent.name && sale.agent.name.includes(this.search)) ||
              (sale.order_id && sale.order_id.toString().includes(this.search)) ||
              (sale.sales_amount && sale.sales_amount.toString().includes(this.search));

          const matchesAgent =
              !this.selectedAgent.length ||
              this.selectedAgent.includes(sale.agent.name);

          const matchesRoute =
              !this.selectedRoute.length ||
              this.selectedRoute.includes(sale.route_name);

          const matchesSalesType =
              !this.selectedSalesType.length ||
              this.selectedSalesType.includes(sale.sales_type);

          const matchesDistrict =
              !this.selectDistrict.length ||
              this.selectDistrict.includes(sale.district);

          const matchesDsd =
              !this.selectDsd.length ||
              this.selectDsd.includes(sale.dsd);

          const matchesGnd =
              !this.selectGnd.length ||
              this.selectGnd.includes(sale.gnd);

          return (
              withinDateRange &&
              matchesSearch &&
              matchesAgent &&
              matchesRoute &&
              matchesSalesType &&
              matchesDistrict &&
              matchesDsd &&
              matchesGnd
          );
        });
      }
    },

    mounted() {
      const dashboardId = 5;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);

      this.setupEventListeners();
      this.fetchSalesData();
      this.getAllAgentsDeteails();
      console.log('Headers:', this.headers);
      console.log('Sales:', this.sales);
    },

    beforeUnmount() {
      this.removeEventListeners();
    },

    watch: {
      dates() {
        this.updateDateRange();
      }
    },
  }
</script>


<style>
  @import "../../styles/sales_management/sales_management.scss";
</style>