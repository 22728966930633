<template>
  <v-app id="app_create_user_role">

    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE USER ROLES</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">These created user roles help you grant permissions for functions.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Create new user roles</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-alert color="#F0F0F0"  prominent density="default" border="border-lg"
                        text="These user roles help you grant permissions and assign related functions to users in the system.">
                      <template v-slot:prepend>
                        <v-icon color="orange" size="x-large">mdi-alert</v-icon>
                      </template>
                    </v-alert>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <p class="custom_page_main_sub_title">
                        <strong>
                            SELECT USER CATEGORY
                        </strong>
                      </p>
                    <v-divider class="mt-4"></v-divider>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox v-model="selectedUser" :value="'Web User'" color="#BB0000"
                                hide-details label="WEB USER" rounded></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox v-model="selectedUser" :value="'Mobile User'"
                                color="#BB0000" hide-details label="MOBILE USER"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-text-field
                      v-model="selectedItem.user_role" label="Role type *" placeholder="Type User Role Here"
                      variant="outlined" @input="validateUserRole" density="comfortable"  color="#BB0000"
                  ></v-text-field>


                <p v-if="errorMessage" class="mt-0" style="color: red;" type="error">
                  {{ errorMessage }}
                </p>

                <v-textarea
                    v-model="selectedItem.user_role_description" class="mt-2" label="Description *"
                    placeholder="Type user description here, Word Count 300" required rows="6" variant="outlined"
                    color="#BB0000"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large"  class="custom_btn_primary" @click="createNewRole">Create User role</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="backPageRoute()">Cancel</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight" :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>
    
<script>
import axios from 'axios';
export default {
  name: 'CreateUserRole',
  data() {
    return {
      centerLoading:false,
      permissionsItems: [],
      userTypes: [],

      userRoleDialog: false,
      webUser:"",
      mobileUser:"",
      selectedUser:"",
      userRoleType: '', // This will hold the role type from the dialog
      userRoleDescription: '', // This will hold the description (for now, this will be an empty string)

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      selectedItem: {
        user_role: null,
        user_role_description: '',
      },

      userRolesCheckDetails: {
        name: [], 
      },

      errorMessage: '', 
    };
  },
  mounted() {
    this.getAllUserRoles();
    this.getDashboardPermissions();
    this.getAllCreatedUserRoles();
  },
  computed: {

   
  },
  
  methods: {
    getAllCreatedUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios
        .post(
          '/api/allCreatedUserRoles',
          { userId: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const role = response.data.data;
          console.log('Fetched roles from API:', role);

          // Extract `role_type` into the `name` array
          if (Array.isArray(role)) {
            this.userRolesCheckDetails.name = role.map((item) => item.role_type);
          } else {
            console.error('Unexpected response format:', role);
          }
          console.log('Roles:', this.userRolesCheckDetails.name);
        })
        .catch((error) => {
          console.error('Error fetching roles:', error);
        });
    },

    validateUserRole() {
      // Allow only alphabetic characters and spaces
      const textRegex = /^[a-zA-Z\s]*$/;

      // If the input doesn't match the regex, revert to the last valid input
      if (!textRegex.test(this.selectedItem.user_role)) {
        this.selectedItem.user_role = this.selectedItem.user_role.replace(/[^a-zA-Z\s]/g, '');
      }
      // Check if the role already exists
      this.chechalreadyHasUserRole();
    },

    chechalreadyHasUserRole() {
      if (
        Array.isArray(this.userRolesCheckDetails.name) && 
        this.userRolesCheckDetails.name.includes(this.selectedItem.user_role.trim())
      ) {
        this.errorMessage = 'User role already exists';
      } else {
        this.errorMessage = '';
      }
    },


    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`, 
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
          }
        })
        .then(response => {
          const dashboards = response.data.data;
          this.permissionsItems = dashboards.map(dashboard => ({
            id: dashboard.id,
            premission_dashboard: dashboard.premission_dashboard,
            isOn: false,
            permissions: { read: false, write: false, delete: false },
          }));
          console.log('Dashboard permissions:', this.permissionsItems);
        })
        .catch(error => {
          console.error('Error fetching dashboard permissions:', error);
        });
    },

    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },

    handlePermissionChange(item, permissionType) {
      // You can perform additional logic here if needed
      // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
      if (permissionType === 'delete' && item.permissions.delete) {
        item.permissions.read = true;
        item.permissions.write = true;
      }

      // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
      if (!item.permissions.read && !item.permissions.write) {
        item.permissions.delete = false;
      }
    },


    validateForm() {
      if (!this.selectedUser) {
        this.showSnackbar('Please select a user category.', 'error');
        return false;
      }

      if (!this.selectedItem.user_role) {
        this.showSnackbar('Role type is required.', 'error');
        return false;
      }

      if (!this.selectedItem.user_role_description) {
        this.showSnackbar('Description is required.', 'error');
        return false;
      }
      if (this.selectedItem.user_role_description.length > 300) {
        this.showSnackbar( 'The description exceeds 300 characters. Please provide a shorter description.','error');
        return false;
      }
      return true; // Form is valid
    },

    // Method to show the snackbar messages
    showSnackbar(message, color) {
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
      this.snackAlert.snackbar = true; // Show snackbar
    },

    // Method to create a new role
    createNewRole() {
      // First, validate the form
      if (!this.validateForm()) {
        return;
      }

      if (this.selectedItem.user_role_description.length > 300) {
        this.showSnackbar('The description exceeds 300 characters. Please provide a shorter description.','error');
        return;
      }

      this.centerLoading = true;
      const userId = parseInt(localStorage.getItem('userId'), 10); // Ensure userId is an integer
      const token = localStorage.getItem('token');
      const payload = {
        userRoleType: this.selectedItem.user_role || '' , // Ensure userRoleId is an integer
        userRoleDescription: this.selectedItem.user_role_description || '', // Map to description
        userRoleCategory: this.selectedUser || '', // Selected user category
        userId, // Integer value for userId
      };

      // Print the payload as a formatted JSON string
      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      // Convert the payload to a JSON string for sending in the request
      const jsonPayload = JSON.stringify(payload);

      // Make the API request
      axios.post('/api/createUserRole', jsonPayload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log('User role created:', response.data);
        this.showSnackbar('User role created successfully!', 'success');
        this.centerLoading = false;
        this.selectedUser = '';
        this.selectedItem.user_role = '';
        this.selectedItem.user_role_description = '';
        // Handle successful creation (e.g., reset form fields)
      })
      .catch(error => {
        console.error('Error creating user role:', error);
        this.showSnackbar('Failed to create user role.', 'error');
        this.centerLoading = false;
      });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        let usrRole = response.data.data;
        this.userTypes = usrRole.map(item => ({
          id: item.id,
          name: item.role_type
        }));
        this.selectedItem.user_role = "";
        this.selectedItem.user_role_description = "";
        this.selectedItem.user_role = "";
        console.log('User role types:', this.userTypes);
      })
      .catch(error => {
        console.error('Error fetching user roles:', error);
      });
    },

    backPageRoute(){
      this.$router.back();
    },

    async createRole() {
      // Retrieve token and userId from localStorage
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      // Prepare the data to be sent
      const roleData = {
        userId,
        userRoleType: this.userRoleType,
        userRoleDescription: this.userRoleDescription // This is currently set to ''
      };

      try {
        // Make the API call to create the new role
        const response = await axios.post('/api/createUserRole', roleData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 201) {
          this.showSnackbar("User role created successfully!", "success");
          this.getAllUserRoles();
          // this.cancel(); // Reset form after successful creation
        } else {
          this.showSnackbar("Unexpected response status.", "warning");
        }
        console.log('Role created successfully:', response.data);
        
        // Handle success - Close the dialog and reset the form
        this.userRoleDialog = false;
        this.userRoleType = ''; // Clear the form after success
      } catch (error) {
        console.error('Error creating role:', error);
        // Handle error - Optionally, show an error message using a snackbar or toast
      }
    },
    cancel() {
      this.userRoleType = ''; // Reset the role type field
      this.userRoleDialog = false; // Close the dialog
    },
  }
};
</script>



<style>
  @import "../../styles/user_management/create_user_role.scss";
</style>