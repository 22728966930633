<template>
  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon >mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">COLLECTIONS RECORD</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="imageSrc" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">{{ agentName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div class="d-flex mt-6">
              <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{ userType }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
<!--          <v-btn-->
<!--              v-if="permissions.write" class="mr-3 custom_edit_btn"-->
<!--              icon rounded size="small"-->
<!--              variant="outlined"  @click="editViewCollections"-->
<!--          >-->
<!--            <v-icon>mdi-pencil</v-icon>-->
<!--            <v-tooltip activator="parent" location="top">Edit</v-tooltip>-->
<!--          </v-btn>-->

          <v-btn
              v-if="permissions.delete" class="custom_delete_btn"  icon rounded
              size="small" variant="outlined" @click="confirmDelete"
          >
            <v-icon>mdi-trash-can</v-icon>
            <v-tooltip activator="parent" location="top">Delete</v-tooltip>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="oderId" label="Order ID" readonly variant="outlined"
                      density="comfortable" class="px-3 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="agentName" label="User Name" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="userType" label="User Type" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="province" label="Province" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="district" label="District" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="dsd" label="DSD" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="gnd" label="GND" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="storeName" label="Store Name" readonly variant="outlined"
                      density="comfortable" class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <v-text-field v-model="quantitySold" label="Quantity Sold" readonly variant="outlined"
                      density="comfortable"  class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="storeAddress" label="Store Address" readonly variant="outlined"
                      density="comfortable"  class="px-3  custom_input_field_class_view custom_input_field_color"
        ></v-text-field>

        <div class="px-3">
          <v-card v-if="payMentMethod == 'bank_deposit'" color="#ECEFF1" border="lg opacity-1">
            <p class="ml-4 mt-3 custom_page_main_sub_title text-body-1 font-weight-bold">BANK DEPOSIT</p>
            <p class="ml-4 mb-3 mt-1 custom_page_main_sub_title_very_small_red">PAYMENT TYPE</p>
          </v-card>


          <v-card v-if="payMentMethod == 'cash'" color="#ECEFF1" border="lg opacity-1">
            <p class="ml-4 mt-3 custom_page_main_sub_title text-body-1 font-weight-bold">CASH</p>
            <p class="ml-4 mb-3 mt-1 custom_page_main_sub_title_very_small_red">PAYMENT TYPE</p>
          </v-card>


          <v-card v-if="payMentMethod == 'pay_later'" color="#ECEFF1" border="lg opacity-1">
            <p class="ml-4 mt-3 custom_page_main_sub_title text-body-1 font-weight-bold">PAY LATER</p>
            <p class="ml-4 mb-3 mt-1 custom_page_main_sub_title_very_small_red">PAYMENT TYPE</p>
          </v-card>
        </div>

        

        <!-- Bank Deposit Slip or Cheque Tab -->
        <v-card v-if="access.bankDeposit" class="px-3 mt-4 " border="sm opacity-1" rounded="md">
          <template v-slot:prepend>
            <v-avatar color="#BB0000">
              <v-icon icon="mdi-file"></v-icon>
            </v-avatar>
          </template>

          <template v-slot:title>
            <v-card-title class="ml-3 custom_page_main_sub_title text-body-1 font-weight-bold">Bank-slip</v-card-title>
          </template>

          <template v-slot:subtitle>
            <v-card-subtitle class="ml-3 custom_page_main_sub_title text-body-2 font-weight-medium">• file size – 92 kb</v-card-subtitle>
          </template>

          <template v-slot:append>
            <v-btn
                :href="`https://sfa.backend.celata.org/SFA/public${bankSlip}`" class="custom_delete_btn"  icon rounded
                size="small" variant="outlined" target="_blank"
            >
              <v-icon>mdi-eye</v-icon>
              <v-tooltip activator="parent" location="top">View</v-tooltip>
            </v-btn>
          </template>
        </v-card>

        <div class="px-3">
          <v-divider class="mb-7 mt-4"></v-divider>
            <v-card class="custom_card_box"   style="border: #EF9A9A 2px solid !important;" elevation="0"  border="sm opacity-1">
              <v-card-text class="mt-3">
                  <v-text-field v-model="collectionsAmount" label="Total Amount" readonly variant="outlined"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                  ></v-text-field>

                  <v-text-field v-model="paidAmount" label="Paid Amount" readonly variant="outlined"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                  ></v-text-field>

                  <v-text-field v-model="outStandingPayment" label="OutStand Payment" readonly variant="outlined"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                  ></v-text-field>

                  <v-text-field v-model="lastPaymentDate" label="Last Payment Date" readonly variant="outlined"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                  ></v-text-field>

                  <v-text-field v-if="outStandingPayment !== 0" v-model="NextPaymentDate" label="Next Payment Date" readonly variant="outlined"
                                class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                  ></v-text-field>
              </v-card-text>
            </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-3 mb-2">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider class="mt-1 mb-3"></v-divider>
        <p class="custom_page_main_sub_title">
          <strong>COLLECTION PRODUCT LIST</strong>
        </p>

        <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
          The following table shows all the products related to collections.
        </p>

        <v-card class="mt-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
          <v-toolbar color="white" class="pa-3" flat>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-col cols="5">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"  class="custom_input_field_color"
                            clearable hide-details label="Search" placeholder="Search Lead" variant="outlined"/>
            </v-col>
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text>
            <v-data-table  :headers="headers" :items="collectionsProducts[0]?.products" :search="search" density="comfortable" class="custom_table_normal">
              <!-- Custom slot for product column -->
              <template v-slot:[`item.product_name`]="{ item }">
                <p class="custom_page_main_sub_title_very_small_red font-weight-medium text-body-2">{{ item.product_name }}</p>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" id="app_collection_management" max-height="500" max-width="500" persistent>
          <v-card  rounded="md">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat>
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">DELETE COLLECTION DATA</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card color="white" flat class="mt-3">
                <v-card-item>
                  <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                    <v-icon color="warning" size="small">mdi-alert</v-icon>
                    &nbsp;&nbsp;Do you want to delete this collection record ?
                  </v-card-title>

                  <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                    This collection record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                  </p>

                  <v-divider class="mt-4"></v-divider>

                  <div class="float-end mt-4 ">
                    <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                    <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                  </div>
                </v-card-item>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Delete Confirmation Dialog -->

      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  // import {EventBus} from "@/plugins/event-bus";
  // import Collection_Record_Edit from "@/components/COLLECTION_MANAGEMENT/Collection_Record_Edit.vue";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/plugins/event-bus";
  import {getPermissions} from "@/plugins/permissions";

  export default {
    name: "Collection_Record.vue",
    components: {
      // Collection_Record_Edit,
    },
    data() {
      return {
        search:'',
        tab:null,
        deleteDialog: false,
        itemToDelete: null,
        closeDialog: false,
        editDialog: false,
        fetchData:'',
        agentName:'',
        oderId:'',
        userType:'',
        province:'',
        district:'',
        dsd:'',
        gnd:'',
        quantitySold:'',
        collectionsAmount:'',
        storeAddress:'',
        payMentMethod:'',
        storeName:'',
        userImage:'',
        totalAmount: '',
        paymentMethod:'',
        paidAmount: '',
        outStandingPayment:'',
        lastPaymentDate:"",
        NextPaymentDate:'',
        oustandPayment: '',
        nextPaymentDate: '',
        bankSlip: null,
        mobileNumber:'',
        headers: [
          {text: 'Product', value: 'product_name', title: 'Product'},
          {text: 'Quantity', value: 'quantity', title: 'Quantity'},
          {text: 'Collection Amount', value: 'sales_amount', title: 'Collection Amount'},
          {text: 'Unit Price', value: 'unit_price', title: 'Unit Price'},
        ],
        collectionsProducts: [],
        access: {
          cash: false,
          bankDeposit: false,
          payLater: false,
        },
        permissions: {},
      };
    },

    methods: {
      confirmDelete(item) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },

      deleteItem() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const id = this.sharedDataCollection;

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/delete/${id}`,{ userId },{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          this.deleteDialog = false;
          this.itemToDelete = null;
          EventBus.emit('collectionsRecodeManagementButtonClick2');
        })
        .catch(error => {
          console.error("There was an error deleting the sale!", error);
        });
      },

      exportPDF() {
        //  functionality to export table data as PDF
      },

      editViewCollections() {
        //  functionality to edit item details
        this.editDialog = true;
        EventBus.emit('collectionsRecodeManagementButtonClick');
      },

      closeD() {
        this.viewDialog = false;
        this.editDialog = false;
      },

      fetchCollectionsRecodeData() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const id = this.sharedDataCollection;

        console.log('Shared Data:', this.sharedDataCollection);
        console.log('fk', id);

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/${id}`, { userId }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
        .then(response => {
          console.log("API Response all:", response.data);
          if (response.data.sale) {
            // Convert the collections object into an array with a single element if necessary
            this.collectionsProducts = Array.isArray(response.data.sale) ? response.data.sale : [response.data.sale];
            console.log("this.collectionsProducts:", this.collectionsProducts);

            if (this.collectionsProducts.length > 0) {
              const sale = this.collectionsProducts[0];
              this.agentName = sale.agent.name;
              this.oderId = sale?.order_id;
              this.userType = sale.user_type;
              this.province = sale?.province;
              this.district = sale?.district;
              this.dsd = sale?.dsd;
              this.gnd = sale?.gnd;
              this.quantitySold = sale?.quantity_sold;
              this.collectionsAmount = sale?.sales_amount;
              this.storeAddress = sale?.address;
              this.payMentMethod = sale.payment_method;
              this.bankSlip = sale?.bank_slip_file;
              this.paidAmount = sale.paid_amount
              this.outStandingPayment = this.collectionsAmount - this.paidAmount;
              const updatedAtDate = new Date(sale.updated_at);
              this.lastPaymentDate = updatedAtDate.toISOString().split('T')[0];
              const nextPaymentDate = new Date(updatedAtDate);
              nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);
              // this.NextPaymentDate = nextPaymentDate.toISOString().split('T')[0];
              this.NextPaymentDate = sale.due_date;
              this.storeName = sale?.store_name;
              this.userImage = sale?.agent?.user_image;
              this.mobileNumber = sale.agent.mobile_number.length > 0 ? sale.agent.mobile_number[0].mobile_number : null;

              // Check the payment method and set access flags
              this.paymentMethod = sale?.payment_method;

              console.log(
                  "paymentMethod:" + this.paymentMethod,
              )

              // Reset access flags
              this.access = {
                cash: false,
                bankDeposit: false,
                payLater: false,
              };

              // Set access based on the payment method
              if (this.paymentMethod === 'cash') {
                this.access.cash = true;
                this.tab = 'tab-1'; // Set to cash tab
              } else if (this.paymentMethod === 'bank_deposit') {
                this.access.bankDeposit = true;
                this.tab = 'tab-2'; // Set to bank deposit tab
              } else if (this.paymentMethod === 'pay_later') {
                this.access.payLater = true;
                this.tab = 'tab-3'; // Set to pay later tab
              }
            }
          } else {
            this.collectionsProducts = [];
          }
        })
        .catch(error => {
          console.log(error);
          this.collectionsProducts = [];
        });
      }
    },

    computed: {
      ...mapGetters(['sharedDataCollection']),
      imageSrc() {
        return `${process.env.VUE_APP_API_BASE_URL}/SFA/public/${this.userImage}`;
      }
    },
    created() {
      this.fetchCollectionsRecodeData();
    },

    mounted() {
      const dashboardId = 6;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);
      this.fetchCollectionsRecodeData();
    },
  };
</script>

<style>
  @import "../../styles/collection_management/collection_management.scss";
</style>