<template>
  <v-app id="app_authentication">
    <v-navigation-drawer class="custom_navigation_drawer" width="30%"
                         :image="require('@/img/background_images/image_3.png')" v-model="drawer"
                         app></v-navigation-drawer>
    <v-main class="custom_border_with_small_devices custom_class_alignment">
      <v-container color="white" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="2"></v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card flat style="background: transparent !important;">
              <template v-slot:title>
                <v-card-title class="custom_topic_sign_in">
                  Log In
                </v-card-title>
              </template>

              <template v-slot:append>
                <v-divider :thickness="3" vertical class="mb-0 mt-0 mr-3"></v-divider>
                <img style="width: 180px; height: 40px" :src="require('@/img/company_logo/logo_full_5.png')"/>
              </template>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <v-card-text class="mt-5">
                <v-form ref="form" v-model="valid">

                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="email"
                          :error-messages="email_errors"
                          :rules="emailRules"
                          autocomplete="username"
                          color="#546E7A" clearable
                          label="Email Address"
                          placeholder="Email Address"
                          prepend-inner-icon="mdi mdi-email-outline"
                          required
                          variant="outlined"
                          @keyup.enter="submit"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="password"
                          :append-inner-icon=" e1? 'mdi-eye': 'mdi-eye-off'"
                          :error-messages="password_errors"
                          :rules="passwordRules"
                          :type="e1 ? 'text' : 'password'"
                          autocomplete="current-password"
                          label="Password"
                          min="8" color="#546E7A"
                          placeholder="Password"
                          prepend-inner-icon="mdi-lock-outline"
                          required
                          variant="outlined"
                          @click:append-inner="e1 = !e1"
                          @keyup.enter="submit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                  <v-spacer></v-spacer>
                  <p class="custom_paragraph_option_2 mb-8">
                    <router-link class="custom_link custom_class_text_decoration_none" to="/forgot_password">
                      <v-icon left>mdi mdi-lock-reset</v-icon>
                      Forgot password?
                    </router-link>
                  </p>
                </div>

                <v-row align="center" justify="center">
                  <!--                  <v-col cols="12" sm="12" md="12">-->
                  <!--                    <v-alert  v-model="snackAlert.snackbar" text-->
                  <!--                              class="mt-3"-->
                  <!--                              :color="snackAlert.snackColor"-->
                  <!--                              :right="snackAlert.snackBarRight"-->
                  <!--                              closable-->
                  <!--                              timeout="3000"-->
                  <!--                              variant="tonal"-->
                  <!--                              theme="dark"-->
                  <!--                              border>-->
                  <!--                      {{ snackAlert.snackMessage }}-->
                  <!--                    </v-alert>-->
                  <!--                  </v-col>-->

                  <v-col sm="12" md="12">
                    <v-btn @click="submit" :loading="cardLoader" size="x-large" block
                           class="custom_paragraph_option_btn text-white">
                      <v-icon size="large" left>mdi mdi-account-arrow-right-outline</v-icon>
                      &nbsp;Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <v-card-subtitle class="mt-2">
                <div>
                  <p class="custom_paragraph_option_2 text-center">
                    POWERED BY CELATATECH
                  </p>
                </div>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="2"></v-col>
        </v-row>
      </v-container>

      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :right="snackAlert.snackBarRight"
          :top="snackAlert.snackBarTop"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>
    </v-main>
  </v-app>
</template>


<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      valid: false,
      drawer: null,
      window: {
        width: 0,
        height: 0
      },

      snackAlert: {
        snackbar: false,
        snackColor: "",
        snackMessage: "",
        snackBarRight: true,
        snackBarTop: true,
      },

      password: "",
      password_errors: [],
      passwordRules: [v => !!v || "Password is required"],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'Must be a valid email'
      ],

      email_errors: [],
      email: "",
      cardLoader: false,
      e1: false,
    };
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    async submit() {
      this.cardLoader = true;

      if (this.valid) {
        try {
          const response = await axios.post(`/api/login`, {
            email: this.email,
            password: this.password
          });

          if (response.data.validate === "success") {
            this.showSnackbar("Login Successful. Redirecting...", "success");
            localStorage.setItem("userId", response.data.user.id);
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("userName", response.data.user.user_name);
            localStorage.setItem("email", response.data.user.email);
            localStorage.setItem(
                "mobileNumbers",
                response.data.user.mobile_number.length > 0
                    ? response.data.user.mobile_number[0].mobile_number
                    : null
            );
            localStorage.setItem("accessDashboard", JSON.stringify(response.data.accessDashboard));

            console.log("userDetails", response.data);

            // Redirect based on user permissions
            const accessDashboard = response.data.accessDashboard;
            if (accessDashboard && accessDashboard.length > 0) {
              const dashboardPathMap = {
                1: {read: "/dashboard", write: "/dashboard"},
                2: {read: "/products-list", write: "/add-products"},
                3: {read: "/leads-list", write: "/add-leads"},
                4: {read: "/users-list", write: "/user-registration"},
                5: {read: "/sales-management", write: "/sales-management"},
                6: {read: "/collection-management", write: "/collection-management"},
                7: {read: "/live-tracking", write: "/live-tracking"},
                8: {read: "/routes-list", write: "/create-routes"},
                9: {read: "/outlet-list", write: "/create-outlet"}
              };

              let redirected = false;

              for (const dashboard of accessDashboard) {
                const dashboardId = dashboard.dashboard_id;

                if (dashboardPathMap[dashboardId]) {
                  if (dashboard.read_premission === 1) {
                    this.$router.push({path: dashboardPathMap[dashboardId].read});
                    redirected = true;
                    break; // Stop after the first valid redirection
                  } else if (dashboard.write_premission === 1) {
                    this.$router.push({path: dashboardPathMap[dashboardId].write});
                    redirected = true;
                    break; // Stop after the first valid redirection
                  }
                }
              }

              if (!redirected) {
                this.showSnackbar("No valid dashboard access found.", "error");
              }
            } else {
              this.showSnackbar("No dashboard access configured for this user.", "error");
            }
          } else if (response.data.validate === "user_deleted") {
            this.showSnackbar(response.data.message || "This account has been deleted.", "error");
          } else if (response.data.validate === "invalid Details") {
            this.showSnackbar("Invalid details provided. Please check your credentials.", "error");
          } else {
            this.showSnackbar("Invalid email or password.", "error");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.validate === "user_deleted") {
              this.showSnackbar(error.response.data.message || "This account has been deleted.", "error");
            } else if (error.response.data.validate === "invalid Details") {
              this.showSnackbar("Invalid details provided. Please check your credentials.", "error");
            } else {
              const errorMsg = error.response.data.message || "Invalid email or password.";
              this.showSnackbar(errorMsg, "error");
            }
          } else {
            this.showSnackbar("An error occurred. Please try again later.", "error");
          }
        }
      } else {
        this.showSnackbar("Please fill in all required fields correctly.", "error");
      }
      this.cardLoader = false;
    },

      // if (this.valid) {
    //     try {
    //       const response = await axios.post(`/api/login`, {
    //         email: this.email,
    //         password: this.password
    //       });
    //
    //       if (response.data.validate === "success") {
    //         this.showSnackbar("Login Successful. Redirecting...", "success");
    //         localStorage.setItem("userId", response.data.user.id);
    //         localStorage.setItem("token", response.data.access_token);
    //         localStorage.setItem("userName", response.data.user.user_name);
    //         localStorage.setItem("email", response.data.user.email);
    //         localStorage.setItem("mobileNumbers", response.data.user.mobile_number.length > 0 ? response.data.user.mobile_number[0].mobile_number : null);
    //         localStorage.setItem("accessDashboard", response.data.accessDashboard);
    //         localStorage.setItem("accessDashboard", JSON.stringify(response.data.accessDashboard));
    //
    //         console.log('userDetails',response.data);
    //
    //         this.$router.push({ path: '/dashboard' });
    //       } else if (response.data.validate === "user_deleted") {
    //         this.showSnackbar(response.data.message || "This account has been deleted.", "error");
    //       } else if (response.data.validate === "invalid Details") {
    //         this.showSnackbar("Invalid details provided. Please check your credentials.", "error");
    //       }
    //       else {
    //         this.showSnackbar("Invalid email or password.", "error");
    //       }
    //     } catch (error) {
    //       if (error.response) {
    //         // Check if the error response contains 'validate' as 'user_deleted'
    //         if (error.response.data.validate === "user_deleted") {
    //           this.showSnackbar(error.response.data.message || "This account has been deleted.", "error");
    //         }
    //         else if (error.response.data.validate === "invalid Details") {
    //         this.showSnackbar("Invalid details provided. Please check your credentials.", "error");
    //         } else {
    //           // Handle other errors that might occur during login
    //           const errorMsg = error.response.data.message || "Invalid email or password.";
    //           this.showSnackbar(errorMsg, "error");
    //         }
    //       } else {
    //         this.showSnackbar("An error occurred. Please try again later.", "error");
    //       }
    //     }
    //   } else {
    //     this.showSnackbar("Please fill in all required fields correctly.", "error");
    //   }
    //   this.cardLoader = false;
    // },


    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    }
  },
};
</script>


<style>
@import "../styles/auth/authentication.scss";
</style>
