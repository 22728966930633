<template>
  <v-app id="app_outlets_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">OUTLET LISTS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse the created outlet list using a variety of
            parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="6">
              <v-autocomplete
                  v-model="selectedOutlet"
                  :items="outletTypes"
                  append-inner-icon="mdi-close"
                  chips
                  item-title="outlet_name"
                  item-value="id"
                  label="Outlet Type"
                  multiple
                  variant="outlined"
                  @click:append-inner="clearOutlet"
                  density="comfortable"
                  class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutletStatus"
                  :items="outletSates"
                  append-inner-icon="mdi-close"
                  chips
                  item-title="outlet_status"
                  item-value="outlet_status"
                  label="Outlet Status"
                  multiple
                  variant="outlined"
                  @click:append-inner="clearOutletStatus"
                  density="comfortable"
                  class="custom_input_field_color"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Search Input Field -->
          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field
                  v-model="searchQuery"
                  label="Search by Outlet Name or Address"
                  variant="outlined"
                  append-icon="mdi-magnify"
                  dense
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table
              :headers="headers"
              :items="filteredOutlets"
              :items-per-page="5"
              class="custom_table_normal"
              @page-count="totalPages = $event"
          >
            <!-- Custom Template for Outlet Status -->
            <template v-slot:[`item.outlet_status`]="{ item }">
              <v-row class="mb-2 mt-2">
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-chip
                      v-if="item.outlet_status === 'ALLOWED'"
                      class="custom_green_chip mt-2"
                      size="small"
                      variant="outlined"
                  >
                    ALLOWED
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip
                      v-if="item.outlet_status === 'NOT ALLOWED'"
                      class="custom_red_chip mt-2"
                      size="small"
                      variant="outlined"
                  >
                    NOT ALLOWED
                    <v-icon class="justify-end ml-6">mdi-close</v-icon>
                  </v-chip>

                  <v-chip
                      v-if="item.outlet_status === 'NEED APPROVAL'"
                      class="custom_blue_chip mt-2"
                      size="small"
                      variant="outlined"
                  >
                    NEED APPROVAL
                    <v-icon class="justify-end ml-4" >mdi-help-circle-outline</v-icon>
                  </v-chip>
                </v-col>
                <v-divider vertical class="mt-4 mb-4"  v-if="permissions.write"></v-divider>
                <v-col cols="12" sm="12" md="8" lg="8">
                  <v-btn
                      class="custom_btn_secondary"
                      flat
                      @click="updateStates(item)"
                      v-if="permissions.write"
                  >
                    UPDATE STATUS
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <!-- Custom Template for Actions -->
            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_outlets_list">
                <template v-slot:activator="{ props }">
                  <v-btn
                      class="custom_btn_primary"
                      variant="flat"
                      size="large"
                      v-bind="props"
                  >
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.write" @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>

      </v-card>


      <!--update states dialog-->
      <v-dialog v-model="updateDialog" max-height="700" id="app_outlets_list" max-width="600">
        <v-card rounded="md" style="overflow:hidden !important;">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold" v-if="permissions.write">UPDATE STATUS</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="updateDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card flat class="mt-3">
              <v-text-field v-model="updateDetails.outlet_name" label="Outlet Name"
                            readonly variant="outlined" density="comfortable" class="mt-4 custom_input_field_color"
              ></v-text-field>

              <v-text-field v-model="updateDetails.owner_name" label="Owner Name"
                            readonly variant="outlined" density="comfortable" class="mt-2 custom_input_field_color"
              ></v-text-field>

              <v-text-field v-model="updateDetails.mobile_number" label="Mobile Number"
                            readonly variant="outlined" density="comfortable" class="mt-2 custom_input_field_color"
              ></v-text-field>

              <v-select v-model="selectOS" :items="outletSates" item-title="outlet_status"
                        item-value="outlet_status" label="Outlet Status" variant="outlined" density="comfortable"
                        class="mt-2 custom_input_field_color"
              ></v-select>

              <v-textarea rows="3" v-model="reason" label="Reason"
                          variant="outlined" density="comfortable" class="custom_input_field_color"
              ></v-textarea>

              <v-divider class="mt-4"></v-divider>
              <div class="float-end mt-4" @click="updateDialog = false">
                <v-btn class="custom_btn_primary" @click="reminderSend(updateDetails.id)">UPDATE</v-btn>
                <v-btn class="ml-3 custom_btn_secondary" @click="updateDialog = false">CANCEL</v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!--update states dialog-->


      <!-- View User Dialog -->
      <v-dialog v-model="view_Dialog" fullscreen id="app_outlets_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="closeD">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <OutletDetails/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="editDialog" fullscreen id="app_outlets_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="closeD">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <OutletDetailsEdit/>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit User dialog -->


      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteDialog" id="app_outlets_list" max-height="500" max-width="500" persistent>
        <v-card rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE OUTLET DATA</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this outlet record ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This outlet record will be deleted immediately. Once deleted, it can no longer be used for any
                  purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
        {{ snackbarMessage }}
      </v-snackbar>
      <!-- Delete User confirmation dialog -->

    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import {EventBus} from '@/plugins/event-bus';
import OutletDetails from "@/components/OutletManagement/OutletDetails.vue";
import OutletDetailsEdit from "@/components/OutletManagement/OutletDetailsEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {getPermissions} from "@/plugins/permissions";

export default {
  name: "OutletList",
  components: {
    OutletDetails,
    OutletDetailsEdit
  },
  data() {
    return {
      searchQuery: "",
      Snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      loading: false,
      snackbar: false,
      outletName: '',
      ownerName: '',
      mobileNumber: '',
      reason: '',
      search: '',
      deleteDialog: false,
      updateDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      selectOS: '',
      selectedOutlet: [],
      outletTypes: [],
      selectedOutletStatus: [],
      outletSates: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      headers: [
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'outlet_address', title: 'OUTLET ADDRESS'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      routes: [], // Initially empty, will be filled by API call
      updateDetails: [],
      id: '',
      permissions: {},
    };
  },

  methods: {
    ...mapActions(['updateSharedDataOutlet']),

    updateStates(item) {
      this.selectOS = item.outlet_status
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = item.id;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.updateDetails = response.data;
            console.log("API Response:", response.data);
            this.updateDialog = true;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchRoutes() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routes = response.data;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
            this.snackbarMessage = 'Successfully Delete the Outlet';
            this.snackbarColor = 'success';
            this.snackbar = true;
          })
          .catch(error => {
            this.Snackbar = true;
            this.snackbarMessage = error.message;
            this.snackbarColor = "error"
            console.error('Error deleting route:', error);
          });
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.setFontSize(14);
      doc.text('Outlet Details', 14, 10);

      // Prepare the data for the table
      // const tableData = this.filteredRoutes.map((item, index) => [
      //   index + 1,
      //   item.outlet_type.outlet_name,
      //   item.outlet_name,
      //   item.owner_name,
      //   item.mobile_number,
      //   item.outlet_status,
      //   item.outlet_address,
      //   item.dsd,
      //   item.gnd,
      // ]);

      // Define the columns for the table
      const tableColumns = [
        {header: '#', dataKey: 'index'},
        {header: 'Outlet Type', dataKey: 'outletType'},
        {header: 'Outlet Name', dataKey: 'outletName'},
        {header: 'Owner Name', dataKey: 'ownerName'},
        {header: 'Mobile Number', dataKey: 'mobileNumber'},
        {header: 'Outlet Status', dataKey: 'outletStatus'},
        {header: 'Outlet Address', dataKey: 'outletAddress'},
        {header: 'DSD', dataKey: 'dsd'},
        {header: 'GND', dataKey: 'gnd'},
      ];

      // Map the data to the corresponding keys
      const formattedData = this.filteredRoutes.map((item, index) => ({
        index: index + 1,
        outletType: item.outlet_type.outlet_name,
        outletName: item.outlet_name,
        ownerName: item.owner_name,
        mobileNumber: item.mobile_number,
        outletStatus: item.outlet_status,
        outletAddress: item.outlet_address,
        dsd: item.dsd,
        gnd: item.gnd,
      }));

      // Configure the table with fixed widths
      doc.autoTable({
        head: [tableColumns.map(col => col.header)],
        body: formattedData.map(row => tableColumns.map(col => row[col.dataKey])),
        startY: 20, // Start below the title
        theme: 'grid', // Table theme
        styles: {fontSize: 10}, // Font size for the table
        columnStyles: {
          0: {cellWidth: 10}, // Fixed width for index column
          1: {cellWidth: 30}, // Outlet Type
          2: {cellWidth: 30}, // Outlet Name
          3: {cellWidth: 30}, // Owner Name
          4: {cellWidth: 25}, // Mobile Number
          5: {cellWidth: 25}, // Outlet Status
          6: {cellWidth: 50}, // Outlet Address
          7: {cellWidth: 15}, // DSD
          8: {cellWidth: 15}, // GND
        },
        margin: {top: 20, left: 10, right: 10}, // Margins for the page
      });

      // Save the PDF with a name
      doc.save('outlet_details.pdf');
    },

    exportExcel() {
      const tableData = this.filteredRoutes.map((item, index) => ({
        '#': index + 1,
        'ID': item.id,
        'Outlet Type': item.outlet_type.outlet_name,
        'Outlet Type ID': item.outlet_type.id,
        'Outlet Name': item.outlet_name,
        'Outlet Owner Name': item.owner_name,
        'Outlet Mobile Number': item.mobile_number,
        'Outlet Status': item.outlet_status,
        'Outlet Address': item.outlet_address,
        'Outlet District': item.district,
        'Outlet Province': item.province,
        'Outlet DSD': item.dsd,
        'Outlet GND': item.gnd,
        'Outlet Latitude': item.latitude,
        'Outlet Longitude': item.longitude,
        'Created At': item.created_at,
      }));

      // Create worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Outlet Details');

      // Set column widths for better readability
      const columnWidths = [
        {wch: 5},   // For column "#"
        {wch: 10},  // For column "ID"
        {wch: 15},  // "Outlet Type"
        {wch: 15},  // "Outlet Type ID"
        {wch: 20},  // "Outlet Name"
        {wch: 20},  // "Outlet Owner Name"
        {wch: 15},  // "Outlet Mobile Number"
        {wch: 15},  // "Outlet Status"
        {wch: 30},  // "Outlet Address"
        {wch: 15},  // "Outlet District"
        {wch: 15},  // "Outlet Province"
        {wch: 10},  // "Outlet DSD"
        {wch: 10},  // "Outlet GND"
        {wch: 15},  // "Outlet Latitude"
        {wch: 15},  // "Outlet Longitude"
        {wch: 20},  // "Created At"
      ];
      worksheet['!cols'] = columnWidths;

      // Optional: Add cell styling if using xlsx-style (e.g., bold header)
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: {bold: true},
            alignment: {vertical: 'center', horizontal: 'center'},
          };
        }
      }
      // Write to file
      XLSX.writeFile(workbook, 'outlet_details.xlsx');
    },

    viewItem(item) {
      this.id = item.id
      this.updateSharedDataOutlet(item.id);
      this.view_Dialog = true;
    },

    editItem(item) {
      // Functionality to edit item details
      this.updateSharedDataOutlet(item.id);
      this.editDialog = true;
    },

    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },

    outletManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.editDialog = true;
      this.view_Dialog = false;
    },

    backPageRoute() {
      this.$router.push('/dashboard');
    },

    o2() {
      this.editDialog = false;
      this.view_Dialog = false;
      this.routes = this.routes.filter(route => route.id !== this.id);
      this.id = null;
    },

    setupEventListeners() {
      EventBus.on('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.on('outletManagementButtonClick2', this.o2);
    },

    removeEventListeners() {
      EventBus.off('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.off('outletManagementButtonClick2', this.o2);
    },

    toggleSwitches() {
      this.showSwitches = !this.showSwitches;  // Toggle the visibility of the switches
    },

    reminderSend(id) {
      this.loading = true;
      console.log('xxxxxx', id)
      const userId = localStorage.getItem('userId');
      const outletData = {
        status: this.selectOS,
        reason: this.reason,
        userId: userId,
      };

      const token = localStorage.getItem('token');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`, outletData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log('reminder send successfully:', response.data);
            this.fetchRoutes();
            this.snackbar = true;
          })
          .catch(error => {
            console.error('Error adding reminder:', error);

          })
          .finally(() => {
            this.loading = false;
          });
    },
    clearOutlet() {
      this.selectedOutlet = [];
    },
    clearOutletStatus() {
      this.selectedOutletStatus = [];
    }
  },

  mounted() {
    const dashboardId = 9;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);
    this.setupEventListeners();
    this.fetchRoutes();
    this.fetchOutletType();
  },

  beforeUnmount() {
    this.removeEventListeners();
  },

  computed: {
    filteredOutlets() {
      const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
      return this.filteredRoutes.filter((outlet) => {
        const name = outlet.outlet_name ? outlet.outlet_name.toLowerCase() : '';
        const address = outlet.outlet_address ? outlet.outlet_address.toLowerCase() : '';
        return name.includes(query) || address.includes(query);
      });
    },
    filteredRoutes() {
      return this.routes.filter((route) => {

        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {
          });
          ro.observe(document.body);
        }
        const withinDateRange =
            !this.dateRange ||
            (new Date(route.assignedDate) >= new Date(this.dates[0]) &&
                new Date(route.assignedDate) <= new Date(this.dates[1]));

        const matchesCategory =
            !this.selectedOutlet.length ||
            this.selectedOutlet.includes(route.outlet_type_id); // Assuming 'outletTypeId' is the field in your routes data.

        const matchesRouteStatus =
            !this.selectedOutletStatus.length ||
            this.selectedOutletStatus.includes(route.outlet_status); // Assuming 'outletStatus' is the field in your routes data.

        return withinDateRange && matchesCategory && matchesRouteStatus;
      });
    },
  }
}
</script>


<style>
@import "../../styles/outlet_management/outlet_list.scss";
</style>