<template>
  <div id="app_dashboard_admin">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <OutletMapDashboardComponentFour/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import OutletMapDashboardComponentFour from "@/components/MAP/dashboard_outlet_map_component_four.vue";
  export default {
    name: 'outletMap',
    components: {
      OutletMapDashboardComponentFour
    },

    data() {
      return {

      };
    },

    methods: {

    }
  };
</script>

<style>
  @import "../../styles/dashboard/dashboard.scss";
</style>
