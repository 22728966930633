<template>
  <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <v-app id="app_lead_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">LEADS DATA</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse leads data in a variety of parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="createLead()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;CREATE LEAD
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-date-input v-model="dates" @click:append-inner="clearDateRange" append-inner-icon="mdi-close"
                            label="Date Range" prepend-inner-icon="$calendar" multiple="range"
                            prepend-icon="" placeholder="Select a Date Range" variant="outlined" density="comfortable"
                            class="custom_input_field_color"
              ></v-date-input>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="filters.visitedStatus" :items="visitStatus" chips clearable label="Visited Status"
                  multiple placeholder="Select status" variant="outlined" @change="fetchDataintoTable"
                  density="comfortable" class="custom_input_field_color"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                  v-model="filters.crm_status" :items="CRMstatus" chips clearable label="CRM status"
                  multiple placeholder="Select status" variant="outlined" @change="fetchDataintoTable"
                  density="comfortable" class="custom_input_field_color"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table v-model:page="page" :headers="headers" :items="filteredItems" :items-per-page="itemsPerPage"
                        :loading="loading" :server-items-length="totalItems" class="custom_table_normal"
                        item-key="id" item-value="id" @update:page="fetchDataintoTable"
          >
            <template v-slot:[`item.agent_name`]="{ item }">
              <div>{{ item.agent_name }}</div>
              <div class="red-text">Reg num: {{ item.reg_num }}</div>
            </template>

            <template v-slot:[`item.visited_status`]="{ item }">
              <v-row>
                <v-col cols="12" md="8" lg="8">
                  <v-chip v-if="item.visited_status === 'VISITED'" class="custom_green_chip" size="small"
                          variant="outlined">
                    Visited
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.visited_status === 'PENDING'" class="custom_orange_chip" size="small"
                          variant="outlined">
                    Pending
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.visited_status === 'NOT VISITED'" class="custom_red_chip" size="small"
                          variant="outlined">
                    Not Visited
                    <v-icon class="justify-end ml-6">mdi-close</v-icon>
                  </v-chip>
                </v-col>

                <v-col cols="12" md="4" lg="4">
                  <div><span class="text-blue">{{ countVisitedOutlets(item) }}</span></div>
                  <div v-if="item.visitedDate" class="red-text mb-3">Date - {{ item.visitedDate }}</div>
                </v-col>
              </v-row>
            </template>


            <template v-slot:[`item.crm_status`]="{ item }">
              <v-chip v-if="item.crm_status === 'Created'" class="custom_green_chip" size="small" variant="outlined">
                Completed
                <v-icon class="justify-end ml-10">mdi-check</v-icon>
              </v-chip>

              <v-chip v-if="item.crm_status === 'NotCreated'" class="custom_red_chip" size="small" variant="outlined">
                Not Completed
                <v-icon class="justify-end ml-4">mdi-close</v-icon>
              </v-chip>
            </template>


            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_product_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="permissions.write && (item.visited_status !== 'VISITED') && (item.visited_status !== 'PENDING')"
                      @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>


      <!-- View User Dialog -->
      <v-dialog v-model="view_leads_dialog" fullscreen id="app_lead_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="view_leads_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">LEAD DETAILS</v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="4" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                        <v-img
                            :src="UserResponseData.user_image ? `${imageURL}/${UserResponseData.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                            aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="9" lg="10" xl="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name:
                            &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #BB0000 !important;">{{ selectedLead.agent_name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ selectedLead.mobile_number }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip v-if="selectedLead.visited_status === 'NOT VISITED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-chip v-if="selectedLead.visited_status === 'VISITED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-divider class="mx-3" vertical></v-divider>

                          <v-chip v-if="selectedLead.crm_status === 'CREATED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;CRM ENTRY &nbsp; {{
                              selectedLead.crm_status
                            }}
                          </v-chip>

                          <v-chip :v-if="selectedLead.crm_status === 'NOT CREATED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;CRM ENTRY &nbsp; {{ selectedLead.crm_status }}
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                          v-if="permissions.write && (selectedLead.visited_status !== 'VISITED') && (selectedLead.visited_status !== 'PENDING')"
                          class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined" @click="editItem(selectedLead)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                          v-if="permissions.delete" class="custom_delete_btn" icon rounded
                          size="small" variant="outlined" @click="confirmDelete(selectedLead)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-text-field
                        v-model="selectedLead.lead_title"
                        class="px-3 custom_input_field_class_view  custom_input_field_color"
                        label="Lead Title" placeholder="Type title here" readonly variant="outlined"
                        density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-text-field
                        v-model="selectedLead.lead_main_category" density="comfortable"
                        class=" px-3 custom_input_field_class_view  custom_input_field_color" label="Main Category"
                        readonly variant="outlined"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-text-field
                        v-model="selectedLead.lead_sub_category" density="comfortable"
                        class=" px-3 custom_input_field_class_view  custom_input_field_color" label="Sub Category"
                        readonly variant="outlined"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="selectedLead.province" density="comfortable"
                        class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color" label="Province"
                        readonly variant="outlined"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-text-field
                        v-model="selectedLead.agent_name" label="Assign Agent" readonly density="comfortable"
                        variant="outlined" class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-text-field
                        v-model="selectedLead.district" label="District" density="comfortable"
                        readonly variant="outlined"
                        class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-text-field
                        v-model="selectedLead.dsd" label="DSD" density="comfortable"
                        readonly variant="outlined"
                        class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-text-field
                        v-model="selectedLead.gnd" label="GND" density="comfortable"
                        readonly variant="outlined"
                        class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-text-field
                        v-model="selectedLead.assign_date" density="comfortable"
                        label="Assign Date" readonly
                        class="mt-2 px-3 custom_input_field_class_view  custom_input_field_color" variant="outlined"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <!-- <v-textarea
                        v-model="selectedLead.lead_description" density="comfortable"
                        class="mt-2 custom_input_field_class_view px-3 custom_input_field_color" label="Lead Description" readonly variant="outlined"
                    ></v-textarea> -->


                    <div class="px-3 mb-4">
                      <p class="custom_page_main_sub_title">Lead Description</p>
                      <v-divider class="mt-3 mb-4"></v-divider>
                      <v-card color="#F7F7F7" flat border="sm opacity-1" outlined density="comfortable">
                        <v-card-text>
                          <div v-html="getSanitizedHTML(selectedLead.lead_description)"
                               class=" ml-3 ql-container ql-snow"></div>
                        </v-card-text>
                      </v-card>
                    </div>

                  </v-col>
                </v-row>

                <v-row class="px-3">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider class="mt-1 mb-3"></v-divider>
                    <p class="custom_page_main_sub_title">
                      <strong>RELATED LEADS LIST</strong>
                    </p>
                    <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
                      The following table shows all the outlets/institutes related to the above-defined lead.
                    </p>


                    <v-card class="mt-4 mb-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
                      <v-toolbar color="white" class="pa-3" flat>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <v-col cols="5">
                          <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"
                                        class="custom_input_field_color"
                                        clearable hide-details label="Search" placeholder="Search Lead"
                                        variant="outlined"/>
                        </v-col>
                      </v-toolbar>

                      <v-divider></v-divider>

                      <v-card-text>
                        <v-data-table :headers="headers_two" :items="selectedLead.selected_lead"
                                      :items-per-page="itemsPerPage" :search="search" density="default"
                                      class="custom_table_normal">
                          <template v-slot:[`item.sub_category`]="{ item }">
                            <span class="red-text">{{ item.sub_category }}</span>
                          </template>


                          <template v-slot:[`item.crmStatus`]="{ item }">
                            <v-chip class="custom_red_chip" size="small" variant="outlined">
                              <v-icon>mdi-close</v-icon>&nbsp; Not Compeleted {{ item.sa }}
                            </v-chip>
                          </template>

                          <template v-slot:[`item.visited_status`]="{ item }">
                            <v-chip v-if="item.visited_status === 'VISITED'" class="custom_green_chip" size="small"
                                    variant="outlined">
                              <v-icon>mdi-check-circle-outline</v-icon>&nbsp; {{ item.visited_status }}
                            </v-chip>

                            <v-chip v-if="item.visited_status === 'NOT VISITED'" class="custom_red_chip" size="small"
                                    variant="outlined">
                              <v-icon>mdi-close</v-icon>&nbsp; {{ item.visited_status }}
                            </v-chip>
                          </template>

                          <template v-slot:[`item.visited_date`]="{ item }">
                            <span v-if="item.visited_status === 'VISITED'">{{ item.visited_date.split(' ')[0] }}</span>
                            <v-btn v-else class="custom_btn_primary" @click="openReminderDialog(item)">REMINDER</v-btn>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- View User dialog -->


      <!-- Edit User dialog -->
      <v-dialog v-model="edit_leads_dialog" fullscreen id="app_lead_list">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="edit_leads_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE LEAD DETAILS
                  </v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="4" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                        <v-img
                            :src="UserResponseData.user_image ? `${imageURL}/${UserResponseData.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                            aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="9" lg="10" xl="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name:
                            &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #BB0000 !important;">{{ selectedLead.agent_name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ selectedLead.mobile_number }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip v-if="selectedLead.visited_status === 'NOT VISITED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-chip v-if="selectedLead.visited_status === 'VISITED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-divider class="mx-3" vertical></v-divider>

                          <v-chip v-if="selectedLead.crm_status === 'CREATED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;CRM ENTRY &nbsp; {{
                              selectedLead.crm_status
                            }}
                          </v-chip>

                          <v-chip :v-if="selectedLead.crm_status === 'NOT CREATED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;CRM ENTRY &nbsp; {{ selectedLead.crm_status }}
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateLead()">UPDATE
                        LEAD
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-text-field
                        v-model="selectedLead.lead_title" :disabled="selectedLead.visited_status === 'VISITED'"
                        class="px-3 custom_input_field_color"
                        label="Lead Title *" placeholder="Type title here" variant="outlined" density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-autocomplete
                        v-model="selectedLead.lead_main_category" :disabled="true" :items="categories"
                        :loading="mainFilterLoading" density="comfortable"
                        class=" px-3  custom_input_field_color" label="Main Category *" readonly variant="outlined"
                        item-text="main_filter" item-value="id"
                        @update:modelValue="fetchSubCategories(selectedLead.lead_main_category)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-autocomplete
                        v-model="selectedLead.lead_sub_category" :disabled="true" :items="subCategories"
                        :loading="subFilterLoading" :v-if="isDisabledProvinceList" density="comfortable"
                        class=" px-3  custom_input_field_color" label="Sub Category *" readonly variant="outlined"
                        item-text="sub_filter" item-value="id"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-autocomplete
                        v-model="selectedLead.province" :disabled="true" :items="provinces" :loading="mDistrictLoading"
                        item-text="label" item-value="value" label="Province *" placeholder="Select province" readonly
                        variant="outlined" density="comfortable" @update:modelValue="getAllDistrictAccProv"
                        class="mt-2 px-3  custom_input_field_color"
                    ></v-autocomplete>
                    <!--                    <v-label class="text-caption custom_page_main_sub_title_very_small_red font-weight-medium ml-2">-->
                    <!--                      <v-icon color="#BB0000">mdi-arrow-up-bold</v-icon> Hint: Once you select a province, it can't be changed. To modify it, just refresh the page.-->
                    <!--                    </v-label>-->
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-autocomplete class="mt-2 px-3  custom_input_field_color" density="comfortable"
                                    v-model="selectedLead.agent_name"
                                    :disabled="selectedLead.visited_status === 'VISITED'" :items="agentProvinceWise"
                                    item-title="name" item-value="name" label="Assign Agent *"
                                    placeholder="Select agent" variant="outlined"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-autocomplete v-model="selectedLead.district" :disabled="isDisabledDistrictList"
                                    :items="districts" :loading="mDistrictLoading"
                                    item-text="label" item-value="value" label="District" placeholder="Select district"
                                    density="comfortable"
                                    readonly variant="outlined" @update:modelValue="getAllDsd"
                                    class="mt-2 px-3  custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-autocomplete v-model="selectedLead.dsd" :disabled="isDisabledDSDLIST" :items="dsdList"
                                    :loading="mDSDLoading"
                                    item-text="label" item-value="value" label="DSD" placeholder="Select divisional"
                                    readonly density="comfortable"
                                    variant="outlined" @update:modelValue="getAllGnd"
                                    class="mt-2 px-3  custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-autocomplete v-model="selectedLead.gnd" :disabled="isDisabledGNDList" :items="gndList"
                                    :loading="mDSDLoading"
                                    label="GND" placeholder="Select GND" readonly variant="outlined"
                                    class="mt-2 px-3  custom_input_field_color"
                                    @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                                    density="comfortable"
                    ></v-autocomplete>
                  </v-col>


                  <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                    <v-date-input v-model="assignDateObject" :disabled="selectedLead.visited_status === 'VISITED'"
                                  label="Assign Date *" persistent-placeholder
                                  variant="outlined" density="comfortable" class="px-3 custom_input_field_color"
                                  prepend-icon="" prepend-inner-icon="$calendar"
                    ></v-date-input>
                  </v-col>


                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                    <v-row class="px-3">
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <p class="custom_page_main_sub_title" style="opacity: 0.6">Type Description </p>
                        <v-divider class="mt-4 mb-3"></v-divider>
                        <v-card class="mt-5" elevation="1" border="sm opacity-1" rounded="lg" max-height="355"
                                min-height="355">
                          <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"/>
                          <v-divider></v-divider>
                          <Editor v-model="selectedLead.lead_description" :defaultConfig="editorConfig" :mode="mode"
                                  @onCreated="handleCreated"/>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!--Description editor resource  https://www.wangeditor.com/en/v5/for-frame.html#installation-1-->
                  </v-col>
                </v-row>

                <v-row class="px-3">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider class="mt-1 mb-3"></v-divider>
                    <p class="custom_page_main_sub_title">
                      <strong>RELATED LEADS LIST</strong>
                    </p>
                    <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
                      The following table shows all the outlets/institutes related to the above-defined lead.
                    </p>


                    <v-card class="mt-4 mb-4 custom_card_box" elevation="1" border="sm opacity-1" rounded="lg">
                      <v-toolbar color="white" class="pa-3" flat>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <v-col cols="5">
                          <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"
                                        class="custom_input_field_color"
                                        clearable hide-details label="Search" placeholder="Search Lead"
                                        variant="outlined"/>
                        </v-col>
                      </v-toolbar>

                      <v-divider></v-divider>

                      <v-card-text>
                        <v-data-table :headers="headers_two" :items="selectedLead.selected_lead"
                                      :items-per-page="itemsPerPage" :search="search" density="default"
                                      class="custom_table_normal">

                          <template v-slot:[`item.sub_category`]="{ item }">
                            <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium">
                              {{ item.sub_category }}</p>
                          </template>

                          <template v-slot:[`item.crmStatus`]="{ item }">
                            <v-chip class="custom_red_chip" size="small" variant="outlined">
                              <v-icon>mdi-close</v-icon>&nbsp; Not Compeleted {{ item.sa }}
                            </v-chip>
                          </template>

                          <template v-slot:[`item.visited_status`]="{ item }">
                            <v-chip v-if="item.visited_status === 'VISITED'" class="custom_green_chip" size="small"
                                    variant="outlined">
                              <v-icon>mdi-check-circle-outline</v-icon>&nbsp; {{ item.visited_status }}
                            </v-chip>

                            <v-chip v-if="item.visited_status === 'NOT VISITED'" class="custom_red_chip" size="small"
                                    variant="outlined">
                              <v-icon>mdi-close</v-icon>&nbsp; {{ item.visited_status }}
                            </v-chip>
                          </template>

                          <template v-slot:[`item.visited_date`]="{ item }">
                            <span v-if="item.visited_status === 'VISITED'">{{ item.visited_date.split(' ')[0] }}</span>

                            <v-btn v-else class="custom_btn_primary" dark @click="openReminderDialog(item)">REMINDER
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Edit User dialog -->


      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteLeadDialog" id="app_lead_list" max-height="500" max-width="500" persistent>
        <v-card rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE LEAD</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteLeadDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this Lead ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This lead will be deleted immediately. Once deleted, it can no longer be used in any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="deleteLeadDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->


      <v-dialog v-model="reminderDialog" max-height="700" id="app_lead_list" max-width="600">
        <v-card rounded="md" style="overflow:hidden !important;">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">SEND REMINDER</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="reminderDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card flat class="mt-3">
              <div style="overflow-y: auto !important;height: 450px !important;margin-left: -1% !important;"
                   class="px-2">
                <v-tabs bg-color="#FFEBEE" v-model="tab" align-tabs="left" next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
                  <v-tab class="custom_page_main_sub_title" value="one">REASON</v-tab>
                  <v-tab class="custom_page_main_sub_title" value="two">SEND REMINDER</v-tab>
                </v-tabs>

                <v-tabs-window v-model="tab">
                  <v-tabs-window-item class="mt-9" value="one">
                    <v-textarea
                        :value="selectedItem.not_visited_leads.length > 0 ? selectedItem.not_visited_leads[0].not_visited_reason : 'No data available'"
                        readonly
                        @input="handleInput"
                        density="comfortable"
                        name="input-7-4"
                        variant="outlined"
                        class="custom_input_field_color"
                    />
                  </v-tabs-window-item>

                  <v-tabs-window-item class="mt-9" value="two">
                    <v-text-field
                        v-model="selectedLead.lead_title"
                        :rules="[v => !!v || 'Lead Title is required']"
                        density="comfortable"
                        readonly
                        label="Lead Title"
                        placeholder="Type Lead Title"
                        required
                        variant="outlined"
                        class="custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="selectedItem.institute_name"
                        :rules="[v => !!v || 'Outlet Name is required']"
                        density="comfortable"
                        readonly
                        label="Outlet Name"
                        placeholder="Type Outlet Name"
                        required
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="selectedItem.address"
                        :rules="[v => !!v || 'Outlet Address is required']"
                        density="comfortable"
                        readonly
                        label="Outlet Address"
                        placeholder="Type Outlet Address"
                        required
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="selectedLead.agent_name"
                        :rules="[v => !!v || 'Assignee is required']"
                        density="comfortable"
                        readonly
                        label="Assignee"
                        placeholder="Type Assignee"
                        required
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="selectedLead.mobile_number"
                        :rules="[v => !!v || 'Mobile number is required']"
                        density="comfortable"
                        readonly
                        label="Mobile"
                        placeholder="Type Mobile Number Here"
                        required
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-text-field>

                    <v-text-field
                        v-model="UserResponseData.email"
                        :rules="[v => !!v || 'Email is required']"
                        density="comfortable"
                        readonly
                        label="Email"
                        placeholder="Type Email Here"
                        required
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-text-field>

                    <v-textarea
                        v-model="selectedItem.note"
                        :rules="[v => !!v || 'Note is required']"
                        density="comfortable"
                        label="Note"
                        placeholder="Type Note Here"
                        required
                        rows="3"
                        variant="outlined"
                        class="mt-2 custom_input_field_color"
                    ></v-textarea>
                  </v-tabs-window-item>
                </v-tabs-window>
              </div>
              <v-divider class="mt-4"></v-divider>
              <div v-if="tab === 'two'" class="float-end mt-4">
                <v-btn class="custom_btn_primary" @click="sendReminder">SEND</v-btn>
                <v-btn class="ml-3 custom_btn_secondary" @click="reminderDialog = false">CANCEL</v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-dialog>

      <!-- Send reminder for not visited records -->
      <!--      <v-dialog  v-model="reminderDialog" max-height="700" id="app_lead_list" max-width="600" >-->
      <!--        <v-card  rounded="md"  style="overflow:hidden !important;">-->
      <!--          <div class="pa-4">-->
      <!--            <v-card color="#F0F0F0" flat >-->
      <!--              <template v-slot:prepend>-->
      <!--                <v-card-title class="text-subtitle-1 font-weight-bold">SEND REMINDER</v-card-title>-->
      <!--              </template>-->
      <!--              <v-spacer></v-spacer>-->
      <!--              <template v-slot:append>-->
      <!--                <v-btn @click="reminderDialog = false" icon="mdi-close" size="x-small"></v-btn>-->
      <!--              </template>-->
      <!--            </v-card>-->

      <!--            <v-card flat class="mt-3">-->
      <!--              <div style="overflow-y: auto !important;height: 450px !important;margin-left: -1% !important;" class="px-2">-->
      <!--                <v-tabs  bg-color="#FFEBEE" v-model="tab"  align-tabs="left"  next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>-->
      <!--                  <v-tab class="custom_page_main_sub_title" value="one">REASON</v-tab>-->
      <!--                  <v-tab class="custom_page_main_sub_title" value="two">SEND REMINDER</v-tab>-->
      <!--                </v-tabs>-->

      <!--                <v-tabs-window v-model="tab">-->
      <!--                  <v-tabs-window-item class="mt-9" value="one">-->
      <!--                    <v-textarea-->
      <!--                        :value="selectedItem.not_visited_leads.length > 0 ? selectedItem.not_visited_leads[0].not_visited_reason : 'No data available'" readonly-->
      <!--                        @input="handleInput" density="comfortable" name="input-7-4"-->
      <!--                        variant="outlined" class="custom_input_field_color"-->
      <!--                    />-->

      <!--                  </v-tabs-window-item>-->

      <!--                  <v-tabs-window-item class="mt-9" value="two">-->
      <!--                    <v-text-field-->
      <!--                        v-model="selectedLead.lead_title" :rules="[v => !!v || 'Lead Title is required']" density="comfortable" readonly-->
      <!--                        label="Lead Title" placeholder="Type Lead Title" required variant="outlined" class="custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-text-field-->
      <!--                        v-model="selectedItem.institute_name" :rules="[v => !!v || 'Outlet Name is required']" density="comfortable" readonly-->
      <!--                        label="Outlet Name" placeholder="Type Outlet Name" required variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-text-field v-model="selectedItem.address" :rules="[v => !!v || 'Outlet Address is required']" density="comfortable" readonly-->
      <!--                        label="Outlet Address" placeholder="Type Outlet Address" required variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-text-field v-model="selectedLead.agent_name" :rules="[v => !!v || 'Assignee is required']" density="comfortable" readonly-->
      <!--                        label="Assignee" placeholder="Type Assignee" required variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-text-field v-model="selectedLead.mobile_number" :rules="[v => !!v || 'Mobile number is required']" density="comfortable" readonly-->
      <!--                        label="Mobile" placeholder="Type Mobile Number Here" required variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-text-field v-model="UserResponseData.email" :rules="[v => !!v || 'Email is required']" density="comfortable" readonly-->
      <!--                        label="Email" placeholder="Type Email Here" required variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-text-field>-->

      <!--                    <v-textarea v-model="selectedItem.note" :rules="[v => !!v || 'Note is required']" density="comfortable"-->
      <!--                        label="Note" placeholder="Type Note Here" required rows="3" variant="outlined" class="mt-2 custom_input_field_color"-->
      <!--                    ></v-textarea>-->
      <!--                  </v-tabs-window-item>-->

      <!--                </v-tabs-window>-->
      <!--              </div>-->
      <!--              <v-divider class="mt-4"></v-divider>-->
      <!--              <div class="float-end mt-4">-->
      <!--                <v-btn  class="custom_btn_primary" @click="sendReminder">SEND</v-btn>-->
      <!--                <v-btn  class="ml-3 custom_btn_secondary" @click="reminderDialog = false">CANCEL</v-btn>-->
      <!--              </div>-->
      <!--            </v-card>-->
      <!--          </div>-->
      <!--        </v-card>-->
      <!--      </v-dialog>-->
      <!-- Send reminder for not visited records -->


      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";
import DOMPurify from 'dompurify';

//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import {i18nChangeLanguage} from '@wangeditor/editor'

i18nChangeLanguage('en')
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
//This is related to wangeditor ---END

export default {
  components: {
    Editor,
    Toolbar,
  },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = {placeholder: 'Type here...'}

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END

  name: 'LeadsList',
  data() {
    return {
      UserResponseData: {
        email: '',
      },
      imageURL: "",
      centerLoading: false,
      dataDescription: '',
      formatting: [],
      showColorPicker: false,
      selectedColor: '#000',
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },
      tab: null,
      smsMobile: '',
      smsEmail: '',
      smsNote: '',

      headers: [
        {text: 'Category', value: 'lead_sub_category', sortable: true, title: 'Category'},
        {text: 'Agent Name', value: 'agent_name', sortable: true, title: 'Agent Name'},
        {text: 'Agent Visited Status', value: 'visited_status', sortable: true, title: 'Agent Visited Status'},
        {text: 'CRM Status', value: 'crm_status', sortable: true, title: 'CRM Status'},
        {text: 'Assign Date', value: 'assign_date', sortable: true, title: 'Assign Date'},
        {text: 'Action', value: 'actions', sortable: false, title: 'Action'}
      ],
      items: [],
      totalItems: 5, // Set the correct total number of items
      page: 1,
      itemsPerPage: 10,
      filters: {
        startDate: '',
        endDate: '',
        visitedStatus: [],
        crm_status: [],
        date: [],
      },
      visitStatus: ['VISITED', 'NOT VISITED', 'PENDING'],
      CRMstatus: ['Created', 'NotCreated'],
      view_leads_dialog: false,
      edit_leads_dialog: false,
      assignDateObject: new Date(),
      deleteLeadDialog: false,
      deleteLeadListEditDialog: false,
      reminderDialog: false,
      selectedItem: {},
      smsDialog: false,
      dates: [],
      file: null,
      showLeadsCard: true,
      lead: {
        title: '',
        mainCategory: '',
        sub_category: '',
        province: '',
        district: '',
        description: '',
        dsd: '',
        gnd: '',
        assignAgent: '',
        assign_date: '',
      },
      headersTwoT: [
        {text: '', value: 'data-table-select', title: 'data-table-select'},
        {text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category'},
        {text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name'},
        {text: 'Address', value: 'address', sortable: true, title: 'Address'},
      ],

      // add leads
      headers_two: [
        {text: 'Sub Category', value: 'sub_category', sortable: true, title: 'Sub Category'},
        {text: 'Institute Name', value: 'institute_name', sortable: true, title: 'Institute Name'},
        {text: 'Address', value: 'address', sortable: true, title: 'Address'},
        {text: 'Agent Visited Status', value: 'visited_status', sortable: true, title: 'Agent Visited Status'},
        {text: 'CRM Status', value: 'crmStatus', sortable: true, title: 'CRM Status'},
        {text: 'Visited Date', value: 'visited_date', sortable: true, title: 'Visited Date'},
      ],
      items_two: [],


      // add leads bulk upload
      headers_dialog: [
        {text: 'Main Category', value: 'mainCategory', title: 'Main Category'},
        {text: 'Sub Category', value: 'sub_category', title: 'Sub Category'},
        {text: 'Province', value: 'province', title: 'Province'},
        {text: 'District', value: 'district', title: 'District'},
        {text: 'DSD', value: 'dsd', title: 'DSD'},
        {text: 'GND', value: 'gnd', title: 'GND'},
        {text: 'Assign Agent', value: 'assignAgent', title: 'Assign Agent'},
        {text: 'Assign Date', value: 'assign_date', title: 'Assign Date'},
        {text: 'Description', value: 'description', title: 'Description'},
        {text: 'Lead List', value: 'leadList', title: 'Lead List'},
      ],
      // items_dialog: [],
      items_dialog: [],
      //search
      search: '',
      // Dialog state
      leadsBulkUploadDialog: false,
      loading: false,
      //select date
      assignDateMenu: false,
      selectedLeadId: null,
      selectedLead: null, // Store current lead details
      selectedLeadEdit: null, // Store current lead details for edit
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackVisible: false,
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      selectedCategory: null,
      selectAll: false,
      mainFilterLoading: false,
      subFilterLoading: false,
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      isDisabledProvinceList: false,
      isDisabledDistrictList: false,
      isDisabledDSDLIST: false,
      isDisabledGNDList: false,
      selectedDistrict: [],
      selectedProvince: "",
      selectedDSD: [],
      selectedGND: [],
      agents: [],
      agentProvinceWise: [],
      categories: [],
      subCategories: [],
      allCategories: [],
      permissions: {},
    };
  },

  computed: {
    filteredItems() {
      let filtered = this.items;

      // Apply visited status filter
      if (this.filters.visitedStatus.length) {
        filtered = filtered.filter(item =>
            this.filters.visitedStatus.includes(item.visited_status)
        );
      }

      // Apply CRM status filter
      if (this.filters.crm_status.length) {
        filtered = filtered.filter(item =>
            this.filters.crm_status.includes(item.crm_status)
        );
      }

      const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0];
      };

      // Get start and end dates from the date picker
      const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
      const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

      // Apply date range filter only if startDate and endDate are set
      if (startDate && endDate) {
        filtered = filtered.filter(item => {
          return item.assign_date >= startDate && item.assign_date <= endDate;
        });
      }

      // Map visited status based on `selected_lead` array
      filtered = filtered.map(item => {
        const visitedStatus = item.selected_lead.map(lead => lead.visited_status);
        const allVisited = visitedStatus.every(status => status === 'VISITED');
        const anyVisited = visitedStatus.some(status => status === 'VISITED');

        if (allVisited) {
          item.visited_status = 'VISITED';
        } else if (anyVisited) {
          item.visited_status = 'PENDING';
        } else {
          item.visited_status = 'NOT VISITED';
        }

        return item;
      });

      return filtered;
    }
  },


  created() {
    this.fetchDataintoTable();
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
  },

  mounted() {
    const dashboardId = 3;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.fetchMainCategories();
    this.getAllProvinces();
    this.fetchAllAgents();
    // this.loadLeadsList('All','province',null);
  },

  methods: {

    getSanitizedHTML(html) {
      return DOMPurify.sanitize(html);
    },
    convertToPlainText(html) {
      const sanitizedHTML = this.getSanitizedHTML(html);
      // Create a temporary DOM element to strip HTML tags
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = sanitizedHTML;
      return tempDiv.textContent || tempDiv.innerText || '';
    },

    handleInput(value) {
      if (this.selectedItem.not_visited_leads.length > 0) {
        this.selectedItem.not_visited_leads[0].not_visited_reason = value;
      }
    },

    handleDateChange(newValue) {
      if (newValue) {
        // Ensure newValue is properly formatted
        const parsedDate = new Date(newValue);
        if (!isNaN(parsedDate)) {
          this.selectedLead.assign_date = parsedDate.toISOString().split('T')[0];
        } else {
          console.error('Invalid date selected');
        }
      }
    },


    // handleDateChange(date) {
    //   if (date) {
    //     console.log("Selected Date:", date);
    //     this.selectedLead.assign_date = date;
    //   } else {
    //     console.log("Date selection cleared");
    //   }
    // },
    clearDateRange() {
      this.dates = [];
    },

    countVisitedOutlets(item) {
      console.log("Route count:", item.selected_lead);

      if (!item.selected_lead || item.selected_lead.length === 0) {
        return "0/0"; // No outlets case
      }

      const totalOutlets = item.selected_lead.length;
      const visitedOutlets = item.selected_lead.filter(outlet => outlet.visited_status === "VISITED").length;

      return `${visitedOutlets}/${totalOutlets}`;
    },


    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline' ? 'none' : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      // Function to strip HTML tags
      function stripHTMLTags(input) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.body.textContent || "";
      }

      const tableData = this.filteredItems.map((item, index) => ({
        '#': index + 1,
        'LEAD TITLE': item.lead_title,
        'DESCRIPTION': stripHTMLTags(item.lead_description),
        'LEAD MAIN CATEGORY': item.lead_main_category,
        'LEAD SUB CATEGORY': item.lead_sub_category,
        'AGENT NAME': item.agent_name,
        'REG NUM': item.reg_num,
        'ASSIGN DATE': item.assign_date,
        'STATUS': item.visited_status,
        'PROVINCE': item.province,
        'DISTRICT': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set column widths
      const columnWidths = [
        {wpx: 50},
        {wpx: 150},
        {wpx: 120},
        {wpx: 120},
        {wpx: 80},
        {wpx: 100},
        {wpx: 100},
        {wpx: 80},
        {wpx: 110},
        {wpx: 80},
        {wpx: 80},
        {wpx: 80},
      ];
      worksheet['!cols'] = columnWidths;

      // Style header cells
      Object.keys(worksheet).forEach(cell => {
        if (cell.startsWith('A1')) {
          worksheet[cell].s = {
            font: {bold: true, color: {rgb: "FFFFFF"}},
            fill: {fgColor: {rgb: "1F4E78"}},
            alignment: {horizontal: "center"},
          };
        }
      });

      // Create workbook and append the sheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Lead Analysis');
      XLSX.writeFile(workbook, 'leads_analysis.xlsx');
    },

    exportPDF() {
      const doc = new jsPDF();
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text('Lead Analysis', 14, 10);

      // Add a subtitle or additional text if needed
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      doc.text('A comprehensive analysis of Lead data', 14, 28);

      // Prepare the data for the table using filtered items
      const tableData = this.filteredItems.map((item, index) => [
        index + 1,
        item.lead_title,
        item.lead_main_category,
        // item.lead_sub_category,
        item.agent_name,
        item.reg_num,
        item.assign_date,
        item.visited_status,
        item.district,
        item.dsd,
      ]);

      // Define the columns for the table
      const tableColumns = [
        {header: '#', dataKey: 'index'},
        {header: 'LEAD TITLE', dataKey: 'lead_title'},
        {header: 'MAIN CATEGORY', dataKey: 'lead_main_category'},
        // {header: 'SUb CATAGARY', dataKey: 'lead_sub_category'},
        {header: 'AGENT NAME', dataKey: 'agent_name'},
        {header: 'REG NUM', dataKey: 'reg_num'},
        {header: 'ASSIGN DATE', dataKey: 'assign_date'},
        {header: 'VISITED STATUS', dataKey: 'visited_status'},
        {header: 'DISTRICT', dataKey: 'district'},
        {header: 'DSD', dataKey: 'dsd'},
      ];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns.map(col => col.header)],
        body: tableData,
        startY: 35,
        startX: 5,
        headStyles: {fillColor: [41, 128, 185], textColor: [255, 255, 255], fontSize: 8},
        bodyStyles: {fontSize: 8},
        columnStyles: {
          0: {cellWidth: 10},
          1: {cellWidth: 30},
          2: {cellWidth: 25},
          3: {cellWidth: 25},
          4: {cellWidth: 15},
          5: {cellWidth: 20},
          6: {cellWidth: 15},
          7: {cellWidth: 20},
          8: {cellWidth: 15},
          9: {cellWidth: 15},
        },
        styles: {cellPadding: 3, overflow: 'linebreak'},
        theme: 'striped',
        didDrawPage: function (data) {
          let pageSize = doc.internal.pageSize;
          let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text('Page ' + doc.internal.getNumberOfPages(), data.settings.margin.left, pageHeight - 10);
        },
      });

      // Save the PDF with a name
      doc.save('leads_analysis.pdf');
    },


    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },

    confirmDeleteList() {
      this.deleteLeadListEditDialog = true;
      // Perform delete operation here
    },

    deleteLeadsListItem() {
      // Then update card visibility
      this.showLeadsCard = false;
      this.deleteLeadListEditDialog = false;
    },


    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/agentUser', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        }
      })
          .then(response => {
            let sample = response.data.data;
            console.log('Agents1111:', sample);
            this.agents = sample.map(item => {
              return {
                id: item.id,
                name: item.name,
                province: item.province
              };
            });
            console.log('Agents:', this.agents);
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');
          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
              .filter(val => val.spatial_type !== 'Point')
              .map(val => ({
                data: null,
                name: val.sub_filter,
                main_type: val.main_filter,
                sub_directory: val.data_model
              }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset) {
      // if (filter === 'main'){
      this.isDisabledDistrictList = false;
      this.isDisabledDSDLIST = false;
      this.isDisabledGNDList = false;
      this.lead.province = null;
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.selectedLead.lead_sub_category = "";
      console.log('Dataset:', dataset);

      // Ensure that `this.allCategories` is correctly populated
      console.log('All Categories:', this.allCategories);

      let sub_filter = _.map(this.allCategories, function (o) {
        if (o.main_filter === dataset)
          return o.sub_filter
      });

      sub_filter = _.reject(sub_filter, _.isNil);
      this.subCategories = sub_filter.sort();

      // Debugging: Verify the final result
      console.log('Sub Categories:', this.subCategories);

    },

    fetchDataintoTable() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
      this.loading = true;
      const formData = new FormData();
      formData.append("userId", userId);

      axios.post("/api/getLeadList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {

        this.items = response.data.data.map(lead => {

          let formattedDate = null;
          if (lead.assign_date) {
            const assignDate = new Date(lead.assign_date);
            formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
          }
          this.filterDataByDateRange();

          // Return a new object with the formatted date
          return {
            ...lead,
            assign_date: formattedDate
          };

        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at descending
        // this.items= response.data.data;
        // // this.totalItems = response.data.totalItems;
        console.log('lead list data', this.items);
        this.loading = false;
      })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },

    filterDataByDateRange() {
      if (this.dates.length === 2) { // Ensure there are start and end dates
        const [startDate, endDate] = this.dates.map(date => new Date(date).toISOString().split('T')[0]);

        this.items = this.allItems.filter(lead => {
          const assignDate = lead.assign_date ? new Date(lead.assign_date).toISOString().split('T')[0] : null;
          return assignDate && assignDate >= startDate && assignDate <= endDate;
        });
      } else {
        // If no date range is selected, show all items
        this.items = this.allItems;
      }
    },

    backPageRoute() {
      this.$router.back();
    },
    homeReload() {
      window.location.href = '/dashboard';
    },

    async viewItem(item) {
      try {
        this.selectedLead = item; // Set the selected item
        this.view_leads_dialog = true; // Open the dialog
        console.log('View item:', item);

        // Step 1: Extract registration number
        const registrationNum = this.selectedLead.reg_num;
        console.log("registration_num", registrationNum);

        // Step 2: Fetch user data
        const token = localStorage.getItem('token');
        const userResponse = await axios.get(`/api/user/${registrationNum}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        // Step 3: Assign user response data
        this.UserResponseData = userResponse.data.data; // Ensure data contains the expected structure
        console.log("User data fetched:", this.UserResponseData);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      }
    },
    async editItem(item) {
      try {
        this.selectedLead = {...item};
        this.edit_leads_dialog = true;
        console.log('View item:', item);

        const provincePrivious = item.province;
        this.agentProvinceWise = _.filter(this.agents, (item) => item.province === provincePrivious);

        this.assignDateObject = new Date(this.selectedLead.assign_date);

        // Step 1: Extract registration number
        const registrationNum = this.selectedLead.reg_num;
        console.log("registration_num", registrationNum);

        // Step 2: Fetch user data
        const token = localStorage.getItem('token');
        const userResponse = await axios.get(`/api/user/${registrationNum}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        // Step 3: Assign user response data
        this.UserResponseData = userResponse.data.data; // Ensure data contains the expected structure
        console.log("User data fetched:", this.UserResponseData);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      }

    },

    sendSms(item) {
      this.selectedItem = {...item};
      this.smsDialog = true;
    },
    sendSMSSubmit() {
      // Validate required fields
      if (!this.selectedItem.mobile_number || !this.selectedItem.email || !this.selectedItem.note) {
        // Show snackbar message
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackVisible = true;
        return; // Stop the function execution if validation fails
      }
      // Extract the required data
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const sendSMSTo = {
        mobileNumber: this.selectedItem.mobile_number,
        email: this.selectedItem.email,
        note: this.selectedItem.note,
        userID: userId
      };
      // Log the reminder data
      console.log("SMS sent to:", this.selectedItem);

      // Make API call using axios
      axios.post('/api/message', sendSMSTo, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Success:', response.data);
            // Handle success response if needed
            this.smsDialog = false;
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle error response if needed
          });
    },

    validateForm() {
      // Validate Lead Title
      if (!this.selectedLead.lead_title || this.selectedLead.lead_title.trim() === '') {
        this.showSnackbar('Lead Title is required.', 'error');
        return false;
      }


      // // Check if the Lead Title already exists
      // if (this.errorMessage) {
      //   this.showSnackbar('Lead Title already exists. Please choose a different title.', 'error');
      //   return false;
      // }

      // Validate Main Category
      if (!this.selectedLead.lead_main_category) {
        this.showSnackbar('Main Category is required.', 'error');
        return false;
      }

      // Validate Sub Category
      if (!this.selectedLead.lead_sub_category) {
        this.showSnackbar('Sub Category is required.', 'error');
        return false;
      }

      // Validate Province
      if (!this.selectedLead.province) {
        this.showSnackbar('Province is required.', 'error');
        return false;
      }

      // Validate Assign Agent
      if (!this.selectedLead.agent_name) {
        this.showSnackbar('Assign Agent is required.', 'error');
        return false;
      }

      // Validate Assign Date
      if (!this.assignDateObject) {
        this.showSnackbar('Assign Date is required.', 'error');
        return false;
      }


      // If all validations pass
      return true;
    },

    updateLead() {

      const isValid = this.validateForm();
      if (!isValid) {
        return;
      }

      this.centerLoading = true;

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      // Gather selected leads and map to the required format
      const selectedLeads = this.items
          .filter(item => item.selected)
          .map((item, index) => ({
            id: index + 1, // Assigning a unique ID (you may adjust this as needed)
            instiName: item.instituteName,
            address: item.address
          }));

      // Convert the mapped leads to a JSON string

      const selectedLeadsJSON = JSON.stringify(selectedLeads);

      const date = new Date(this.assignDateObject);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const assignDate = date.toISOString().split('T')[0];


      console.log('fucking date111:', assignDate);
      const leadData = {
        leadsTitle: this.selectedLead.lead_title,
        leadId: this.selectedLead.id,
        mainCatagory: this.selectedLead.lead_main_category,
        subCatagory: this.selectedLead.lead_sub_category,
        province: this.selectedLead.province,
        district: this.selectedLead.district,
        // dsd: this.selectedLead.dsd,
        // gnd: this.selectedLead.gnd,
        dsd: Array.isArray(this.selectedLead.dsd) && this.selectedLead.dsd.length > 0 ? this.selectedLead.dsd.join(',') : '',
        gnd: Array.isArray(this.selectedLead.gnd) && this.selectedLead.gnd.length > 0 ? this.selectedLead.gnd.join(',') : '',
        assignAgent: this.selectedLead.agent_name,
        assignDate: assignDate,
        // leadsLists: //here json stringfy the lead list
        leadsLists: selectedLeadsJSON,
        description: this.selectedLead.lead_description,
        userId,
      };

      axios.post('/api/addLeads', leadData, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
          .then(response => {
            console.log('Lead added successfully:', response.data);
            this.showSnackbar("Lead data was updated successfully!", "success");
            this.centerLoading = false;
            setTimeout(() => {
              window.location.reload();
            }, 1000);

          })

          .catch(error => {
            console.error('Error adding lead:', error);
            this.showSnackbar("Error!", "error");
            this.centerLoading = false;
            // Handle error (e.g., show error message)
          });
    },

    openReminderDialog(item) {
      this.selectedItem = {...item};
      console.log("Opening reminder dialog for item:", this.selectedItem);
      this.reminderDialog = true;
    },

    async sendReminder() {
      try {
        // Show loading snackbar
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'info';
        this.snackAlert.snackMessage = 'Sending reminder...';

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authorization token is missing');
        }

        const reminderData = {
          title: this.selectedLead.lead_title,
          outletName: this.selectedItem.institute_name,
          outletAddress: this.selectedItem.address,
          assignee: this.selectedLead.agent_name,
          mobileNumber: this.selectedLead.mobile_number,
          email: this.UserResponseData.email,
          note: this.selectedItem.note,
          userID: localStorage.getItem('userId'),
        };

        console.log("reminderData", reminderData);

        // Send reminder API request
        const response = await axios.post('/api/reminder', reminderData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        // Show success snackbar
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'success';
        this.snackAlert.snackMessage = 'Reminder sent successfully.';
        this.reminderDialog = false;

        console.log('Success:', response.data);

      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        if (error.response) {
          switch (error.response.status) {
            case 401:
              this.snackAlert.snackMessage = 'Unauthorized. Please log in again.';
              break;
            case 500:
              this.snackAlert.snackMessage = 'Server error. Please try later.';
              break;
            default:
              this.snackAlert.snackMessage = 'Failed to send reminder. Please try again.';
          }
        } else {
          this.snackAlert.snackMessage = 'Network error. Check your connection.';
        }
        this.snackAlert.snackColor = 'red';
      }
    },

    // Opens the delete confirmation dialog and sets the selectedLeadId
    confirmDelete(item) {
      this.selectedLeadId = item.id; // Save the ID of the record to delete
      this.deleteLeadDialog = true; // Show the confirmation dialog
    },

    // Deletes the selected lead
    deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const leadId = this.selectedLeadId;
      console.log('lead id', leadId);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteLead`, {
        userId: userId,
        leadId: leadId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchDataintoTable(); // Refresh the user list
            this.deleteLeadDialog = false; // Close the confirmation dialog
            console.log('Lead deleted successfully');
            this.showSnackbar("Lead delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting lead', error);
            this.deleteLeadDialog = false;
            this.view_leads_dialog = false;
            this.edit_leads_dialog = false;
            this.fetchDataintoTable();
          });
    },

    createLead() {
      this.$router.push({path: '/add-leads'});
    },

  }
};
</script>


<style>
@import "../../styles/lead_management/leads_list.scss";
</style>