<template>
  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon >mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE ROUTE PLAN RECORD</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="imageSrc" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Agent Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">{{ agentName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div class="d-flex mt-6">
              <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                ROUTE STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ routeStates }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
          <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="createRoute">UPDATE</v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="routeName" label="Route Name" variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-autocomplete v-model="routeCategory" :items="routeCategories" item-title="category_name" label="Route Category"
             placeholder="Select category" readonly variant="outlined" density="comfortable"   class="px-3 custom_input_field_color"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="province" label="Province" readonly variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-text-field v-model="agentName" label="Agent Name" readonly variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="district" label="District" readonly variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="dsd" label="DSD" readonly variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field v-model="gnd" label="GND" readonly variant="outlined"
                      density="comfortable"   class="px-3 custom_input_field_color"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-date-input v-model="assignDate" :disabled="canEditSelectField" label="Assign Date" persistent-placeholder
            variant="outlined" density="comfortable"   class="px-3 custom_input_field_color" prepend-icon="" prepend-inner-icon="$calendar"
        ></v-date-input>
      </v-col>
    </v-row>

    <v-row class="px-3">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider class="mt-1 mb-3"></v-divider>
        <p class="custom_page_main_sub_title">
          <strong>SELECTED OUTLET LIST</strong>
        </p>
        <p class="custom_page_main_sub_title mt-2" style="opacity: 0.6">
          The following table shows all the outlets related to the above-mentioned route.
        </p>

        <v-card class="mt-4 custom_card_box mb-3" elevation="1" border="sm opacity-1" rounded="lg">
          <v-card  flat color="white">
            <v-card-text class="custom_tool_bar_style">
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="comfortable"  class="float-start custom_input_field_color"
                            clearable hide-details  label="Search" placeholder="Search Lead" variant="outlined"></v-text-field>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

<!--              <v-btn v-if="OverallStatus !== 'Visited'"  class=" custom_btn_primary" variant="outlined" size="x-large" @click="opensendReminderDialog()">-->
<!--                SEND REMINDER-->
<!--              </v-btn>-->
            </v-card-text>
          </v-card>

          <v-divider></v-divider>

          <v-card-text>
            <v-data-table :headers="headers" :items="outletData" :search="search" density="comfortable" class="custom_table_normal">
              <template v-slot:[`item.route_category`]="{ item }">
                <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium">{{ item.route_category }}</p>
              </template>

              <template v-slot:[`item.outletName`]="{ item }">
                <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.outletName }}</p>
                <v-divider class="mt-2 mb-2"></v-divider>
                <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-2">Outlet Num: {{ item.id }}</p>
              </template>

              <template v-slot:[`item.visited_date`]="{ item }">
                <p v-if="item.status === 'Visited'" class="custom_page_main_sub_title_very_small_red font-weight-medium text-body-2 mb-2 mt-2">{{ item.visited_date }}</p>
                <v-btn v-if="item.status === 'Not Visited'"  size="large" class="float-end mb-4 mt-4 custom_btn_secondary"
                       @click="reasonBox(item) ">REASON
                </v-btn>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status === 'Visited'" class="custom_green_chip" size="small" variant="outlined">
                  <v-icon>mdi-check-circle-outline</v-icon>&nbsp;  VISITED
                </v-chip>

                <v-chip v-if="item.status === 'Not Visited'"  class="custom_red_chip" size="small" variant="outlined">
                  <v-icon>mdi-close</v-icon>&nbsp;   NOT VISITED
                </v-chip>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>


        <!-- Send reminder for not visited records -->
        <v-dialog  v-model="sendReminderDialog" max-height="700" id="app_routes_list" max-width="600" >
          <v-card  rounded="md"  style="overflow:hidden !important;">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat >
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">SEND REMINDER</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="sendReminderDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card flat class="mt-3">
                <v-text-field v-model="routeName" density="comfortable" label="Route Name" placeholder="Route Name" required
                    variant="outlined" class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="assdineeDetails.name" density="comfortable" label="Assignee"
                    placeholder="Assignee" required variant="outlined" class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="agentDetails.mobile_number[0].mobile_number" :rules="[v => !!v || 'Outlet Address is required']"
                    density="comfortable" label="Mobile" placeholder="Mobile" required variant="outlined"
                    class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="agentDetails.email" :rules="[v => !!v || 'Email is required']"
                    density="comfortable" label="Email" placeholder="Type Email Here" required variant="outlined"
                    class="mt-2 custom_input_field_color"
                ></v-text-field>

                <v-textarea v-model="senderNote" :rules="[v => !!v || 'Note is required']" density="comfortable"
                    label="Note" placeholder="Type Note Here" required rows="3" variant="outlined"
                    class="mt-2 custom_input_field_color"
                ></v-textarea>

                <v-divider class="mt-4"></v-divider>
                <div class="float-end mt-4">
                  <v-btn  class="custom_btn_primary" @click="sendReminder">SEND</v-btn>
                  <v-overlay :value="loading" opacity="0.7">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-overlay>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="sendReminderDialog = false">CANCEL</v-btn>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Send reminder for not visited records -->


        <!-- Agent message for details -->
        <v-dialog  v-model="reasonDialog" max-height="700" id="app_routes_list" max-width="600" >
          <v-card  rounded="md"  style="overflow:hidden !important;">
            <div class="pa-4">
              <v-card color="#F0F0F0" flat >
                <template v-slot:prepend>
                  <v-card-title class="text-subtitle-1 font-weight-bold">AGENT MESSAGE</v-card-title>
                </template>
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-btn @click="reasonDialog = false" icon="mdi-close" size="x-small"></v-btn>
                </template>
              </v-card>

              <v-card flat class="mt-3">
                <v-card flat>
                  <v-textarea  v-model="notVisitedReason" density="comfortable" label="Not Visited Reason" placeholder="Not Visited yet" readonly
                              variant="outlined" class="mt-2  custom_input_field_class_view custom_input_field_color"
                  ></v-textarea>
                </v-card>
                <v-divider class="mt-4"></v-divider>
                <div class="float-end mt-4">
                  <v-btn  class="ml-3 custom_btn_secondary" @click="reasonDialog = false">CANCEL</v-btn>
                </div>
              </v-card>
            </div>
          </v-card>
        </v-dialog>
        <!-- Agent message for details -->
      </v-col>
    </v-row>
  </v-card>


  <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" timeout="5000">
    {{ snackAlert.snackMessage }}
  </v-snackbar>

  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top-right" outlined>
    {{ snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

  <v-overlay v-model="loading" :scrim="false" class="centered-overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "Route_Plan.vue",
  data() {
    return {
      search:'',
      snackAlert: {
        snackbar: false,
        snackColor: '',
        snackMessage: ''
      },
      notVisitedReason: "",
      reasonDialog: false,
      sendReminderDialog: false,
      OverallStatus: '',
      snackbarMessage: '',
      snackbarColor: '',
      snackbarReminder : false,
      loading :false,
      snackbar: false,
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      showFirstCard: true,
      showFirstIcon: true,
      fetchData: '',
      routeStates: '',
      agentName: '',
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      assignDate: new Date(),
      visitedDate: '',
      userImage: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      email: '',
      agentId: '',
      note: 'You should visit this place as soon as possible and report the information',
      routeCategories: [],
      headers: [
        {text: 'Category', value: 'route_category.category_name', title: 'Category'},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name'},
        {text: 'Outlet Address', value: 'address', title: 'Outlet Address'},
        {text: 'States', value: 'status', title: 'States'},
        {text: 'Visit Date', value: 'visited_date', title: 'Visit Date'},
      ],
      routeProducts: [],
      outletData: [],
      admin: {
        userName: '',
        mobileNum: '',
        email: '',
      },
      agentDetails: "",
      assdineeDetails: "",
      senderNote: '',
      reason:'',
    };
  },

  methods: {
    reasonBox(item){
      this.reasonDialog = true;
      this.notVisitedReason = item.my_route_outlet.
          not_visited_reason;
      console.log('reason item', item);
    },

    sendReminder() {
      // Show loading snackbar
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = 'info';
      this.snackAlert.snackMessage = 'Sending reminder...';
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("routeName", this.routeName);
      formData.append("assignee", this.agentName);
      formData.append("email", this.agentDetails.email);
      formData.append("senderNote", this.senderNote);

      axios.post(`/api/sendReminder`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        // Show success snackbar
        this.snackAlert.snackColor = 'success';
        this.snackAlert.snackMessage = 'Reminder sent successfully.';

        // Close dialog
        this.sendReminderDialog = false;
        console.log('Reminder sent successfully');
      })
      .catch(error => {
        // Show error snackbar
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Failed to send reminder. Please try again.';
        console.error('Error sending reminder:', error);
      });
    },

    opensendReminderDialog() {
      this.sendReminderDialog = true;
    },

    reminderBox(item) {
      this.reminderDialog = true;
      const userName = localStorage.getItem('userName');
      const email = localStorage.getItem('email');
      const mobile = localStorage.getItem('mobileNumbers');
      this.outletName = item.outlet_name;
      this.outletAddress = item.address;
      this.admin.email = email;
      this.admin.mobileNum = mobile;
      this.admin.userName = userName;
    },

    snackbarM() {
      this.snackbar = false;
      EventBus.emit('routeRecodeManagementButtonClick2');
    },

    cancell() {
      EventBus.emit('routeRecodeManagementButtonClick2');
      window.location.reload();
    },

    toggleCard() {
      this.showFirstCard = !this.showFirstCard;
      this.showFirstIcon = !this.showFirstIcon;
      this.deleteDialog = false;
      const ids = this.outletData.map(outlet => outlet.id);
      console.log('ids array', ids);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/route/outlets/delete`, {ids, userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error('Error deleting route:', error);
      });
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },

    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    reminderSend() {
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const routeData = {
        route_name: this.routeName,
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        assignee: userId,
        mobile_number: this.mobileNumber,
        email: this.email,
        note: this.note,
      };

      axios.post('/api/route/reminder', {routeData, userId})
        .then(response => {
          console.log('reminder send successfully:', response.data);
          this.snackbarMessage = 'reminder send successfully';
          this.snackbarColor = 'success';
          this.snackbar = true;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error adding reminder:', error);
          this.snackbarMessage = 'reminder send Unsuccessfully';
          this.snackbarColor = 'error';
          this.snackbar = true;
        })
    },

    fetchOutletRecodeData() {
      this.OverallStatus = this.sharedDataRoutes.overallStatus;
      console.log('eeeeeeeee:', this.OverallStatus);
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        console.log("API Response all:", response.data.route);
        if (response.data.route) {

          this.routeProducts = Array.isArray(response.data.route) ? response.data.route : [response.data.route];
          console.log("this.outletProducts:", this.routeProducts);

          if (this.routeProducts.length > 0) {
            const route = this.routeProducts[0];
            console.log("data",route);
            this.agentId = route.agent.id;
            this.agentName = route.agent.name;
            this.routeName = route.route_name;
            this.routeCategory = route.route_category;
            this.province = route.province;
            this.district = route.district;
            this.dsd = route.dsd;
            this.gnd = route.gnd;
            this.assignDate = route.assign_date ? new Date(route.assign_date) : null;
            this.visitedDate = route.visited_date;
            this.routeStates = route.route_status;
            this.userImage = route.agent.user_image;
            this.mobileNumber = route.agent.mobile_number.length > 0 ? route.agent.mobile_number[0].mobile_number : null;
            this.admin.userName = route.assigner.name;
            this.admin.email = route.assigner.email;
            console.log("sahan", this.mobileNumber)
          }
          console.log('main', this.agentName)
        } else {
          this.routeProducts = [];
        }
      })
      .catch(error => {
        console.log(error);
        this.routeProducts = [];
      });
    },

    async createRoute() {
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      const routeData = {
        route_name: this.routeName,
        route_category: this.routeCategory,
        assign_date: new Date(this.assignDate).toISOString().split('T')[0],
        assigner_id: userId,
        agent_id: this.agentId,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        userId: userId,
        id: id,
      };

      try {
        const token = localStorage.getItem('token');
        console.log("route is ", routeData);
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        console.log(response);
        this.snackbarMessage = 'Successfully Update Route';
        this.snackbarColor = 'success';
        this.snackbar = true;
        this.loading = false;
        setTimeout(() => {
          // window.location.reload();
        }, 2000);

      } catch (error) {
        this.snackbarMessage = error.response?.data?.message || 'An unexpected error occurred';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }finally {
        this.loading = false;
        setTimeout(() => {
          EventBus.emit('routeRecodeManagementButtonClick2');
        }, 3000);
      }
    },

    fetchOutletData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        const data = response.data;
        this.agentDetails = data.route.agent;
        this.assdineeDetails = data.route.assigner;
        console.log('fk Assignee', this.assdineeDetails);
        console.log('fk data', this.agentDetails);

        if (data && data.route && data.route.selected_outlets) {
          this.outletData = data.route.selected_outlets;
          console.log("API Response outlet list:", this.outletData);

          // Extracting notVisitedReason from the first outlet's my_route_outlet
          const firstOutlet = this.outletData[0]; // Adjust index if necessary
          if (firstOutlet && firstOutlet.my_route_outlet) {
            this.notVisitedReason = firstOutlet.my_route_outlet.not_visited_reason || "No reason provided";
          } else {
            this.notVisitedReason = "No reason provided";
          }

        } else {
          console.log("Selected outlets data not found in the response");
        }
      })
      .catch(error => {
        console.log(error);
      });
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.routeCategories = response.data;
        console.log("API Response category list:", response.data);
      })
      .catch(error => {
        console.error('Error fetching main categories:', error);
      });
    },
  },

  computed: {
    ...mapGetters(['sharedDataRoutes']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}/SFA/public/${this.userImage}`;
    },
    canEditSelectField() {
      return this.outletData.every(outlet => {
        return outlet.status === "Visited" && outlet.date && outlet.date instanceof Date;
      });
    }
  },

  created() {
    this.fetchOutletRecodeData();
  },

  mounted() {
    this.fetchOutletRecodeData();
    this.fetchOutletData();
    this.fetchMainCategories();
  },
};
</script>


<style>
  @import "../../styles/route_management/routes_list.scss";
</style>