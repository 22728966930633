<template>
  <v-app id="app_user_registration">

    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="#2A8200" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">USER REGISTRATION</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Register users details according to your
            requirements.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#BB0000" size="x-large" variant="text" @click="createUserRole()">
              <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Add user details</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                <v-text-field
                    v-model="user.fullName"
                    :rules="[v => !!v || 'Full Name is required', v => /^[a-zA-Z\s]+$/.test(v) || 'Only letters and spaces are allowed' ]"
                    label="Full Name *" placeholder="Type full name here" required variant="outlined"
                    density="comfortable" class="custom_input_field_color"
                ></v-text-field>

                <v-text-field v-model="user.userName"
                              :rules="[v => !!v || 'Display is required', v => /^[a-zA-Z0-9\s]+$/.test(v) || 'Only letters, numbers, and spaces are allowed']"
                              label="Display Name *" placeholder="Type Display name here"
                              class="mt-2 custom_input_field_color" required variant="outlined"
                              @input="chechalreadyHasUserName" density="comfortable"
                ></v-text-field>

                <p v-if="errorMessage" class="mt-0 mb-2" style="color: #BB0000;" type="error">
                  {{ errorMessage }}
                </p>

                <v-autocomplete v-model="user.userType" :items="userTypes"
                                :rules="[v => !!v || 'User role is required']" clearable
                                item-title="name" item-value="id" label="User Role Type *"
                                placeholder="Select User Role Type Here"
                                required variant="outlined" @update:modelValue="openDashBoardSeletDialog"
                                density="comfortable"
                                class="mt-4 custom_input_field_color"
                ></v-autocomplete>


                <v-card color="white" elevation="1" border="sm opacity-1" rounded="lg" class=" mb-7"
                        v-if="checkPremissionGeoFence !== 'Mobile User' && (checkPremissionGeoFence !== null || checkPremissionGeoFence !== undefined)">
                  <v-card-text>
                    <div class="mb-4 font-weight-medium text-body-2 custom_page_main_sub_title_very_small_red ">
                      <v-icon style="margin-left: -1%!important;" size="large" color="#BB0000">mdi-arrow-up-bold
                      </v-icon>
                      Hint: In above section, you can configure the appropriate permissions for user roles by choosing
                      the desired role type from the dropdown menu.
                    </div>
                    <p class="mt-2 text-caption">
                      <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 1 : Open dropdown menu.
                    </p>
                    <p class="mt-2 text-caption">
                      <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 2 : Choose your preferred role
                      from the dropdown list.
                    </p>
                    <p class="mt-2 text-caption">
                      <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 3 : Access the permissions
                      dialog.
                    </p>
                    <p class="mt-2 text-caption">
                      <v-icon color="#2A8200" size="small">mdi-check</v-icon>&nbsp;STEP - 4 : Set up the necessary
                      permissions.
                    </p>
                  </v-card-text>
                </v-card>


                <v-autocomplete
                    v-model="user.geofence" :items="geoFences" :menu-props="{ maxHeight: '700' }"
                    label="Select Geo Fence and Tracking" multiple placeholder="Select geofence and Tracking access"
                    variant="outlined" v-if="checkPremissionGeoFence !== 'Web User'" density="comfortable"
                    class="mt-2 custom_input_field_color"
                ></v-autocomplete>


                <v-text-field
                    v-model="user.emailAddress" :rules="emailRules" label="Email Address *"
                    placeholder="Type email here" required variant="outlined" density="comfortable"
                    class="mt-2 custom_input_field_color"
                ></v-text-field>


                <v-row class="mb-8">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-btn class="custom_excel_btn mt-6" variant="outlined" @click="addPhoneNo">
                      <v-icon>mdi-plus-thick</v-icon>&nbsp; Add Phone Number
                    </v-btn>
                    <v-divider class="mt-3"></v-divider>
                  </v-col>
                </v-row>

                <div v-for="(phoneNo, index) in phoneNumbers" :key="index">
                  <v-row style="margin-top: -4% !important;">
                    <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                      <v-text-field v-model="phoneNumbers[index]" :rules="phoneRules" label="Phone Number *"
                                    density="comfortable" placeholder="Type Phone Number Here" variant="outlined"
                                    class="custom_input_field_color">
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                      <v-btn v-if="phoneNumbers.length > 1" style="width: 100% !important;" class="custom_pdf_btn"
                             variant="outlined" @click="removePhoneNo(index)"
                      >
                        <v-icon>mdi-minus-thick</v-icon>&nbsp; Remove
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="user.nic" :rules="nicRules" label="NIC *" placeholder="Type NIC number here"
                    required variant="outlined" density="comfortable" class="custom_input_field_color"
                ></v-text-field>

                <v-autocomplete
                    v-model="user.gender" :items="gender" :rules="[v => !!v || 'Gender is required']"
                    class="mt-2 custom_input_field_color"
                    label="Gender *" placeholder="Select Gender" required variant="outlined" density="comfortable"
                ></v-autocomplete>

                <v-autocomplete
                    v-model="user.province" :clearable="user.province !== null && user.province !== ''"
                    :items="provinces" :loading="mProvinceLoading"
                    :rules="[v => !!v || 'Province is required']" item-text="label" item-value="value"
                    label="Province *"
                    placeholder="Select province" variant="outlined" density="comfortable"
                    class="custom_input_field_color"
                    @update:modelValue="(value) => {
                        user.province = value;
                        if (!value) {
                          user.district = null;
                          user.dsd = null;
                          user.gnd = null;
                          districts = [];
                          dsdList = [];
                          gndList = [];
                        } else {
                          getAllDistrictAccProv(value);
                        }
                      }"
                />


                <v-autocomplete
                    v-model="user.district" :clearable="user.district !== null && user.district !== ''"
                    :items="districts" :loading="mDistrictLoading" :rules="[v => !!v || 'District is required']"
                    class="mt-2 custom_input_field_color" item-text="label" item-value="value" label="District *"
                    placeholder="Select district" variant="outlined"
                    density="comfortable"
                    @update:modelValue="(value) => {
                      user.district = value;
                      if (!value) {
                        user.dsd = null;
                        user.gnd = null;
                        dsdList = [];
                        gndList = [];
                      } else {
                        getAllDsd(value);
                      }
                    }"
                ></v-autocomplete>

                <v-autocomplete
                    v-model="user.dsd" :clearable="user.dsd !== null && user.dsd !== ''" :items="dsdList"
                    :loading="mDSDLoading" :rules="[v => !!v || 'Dsd is required']"
                    class="mt-2 custom_input_field_color" item-text="label" item-value="value" label="DSD *"
                    placeholder="Select divisional" variant="outlined"
                    density="comfortable"
                    @update:modelValue="(value) => {
                      user.dsd = value;
                      if (!value) {
                        user.gnd = null;
                        gndList = [];
                      } else {
                        getAllGnd(value);
                      }
                    }"
                ></v-autocomplete>

                <v-autocomplete
                    v-model="user.gnd" :clearable="user.gnd !== null && user.gnd !== ''" :items="gndList"
                    :loading="mGNDLoading"
                    class="mt-2 custom_input_field_color" label="GND" placeholder="Select GND" required
                    variant="outlined" density="comfortable"
                    @update:modelValue="(value) => {
                      user.gnd = value;
                      if (!value) {
                        user.gnd = null;
                      }
                    }"
                ></v-autocomplete>

                <v-textarea v-model="user.address" :rules="[v => !!v || 'Address is required']"
                            class="mt-2 custom_input_field_color" label="Address *"
                            placeholder="Type user address here" required rows="4" variant="outlined"
                            density="comfortable"
                ></v-textarea>

                <v-card flat>
                  <p class="custom_page_main_sub_title mb-4" style="opacity: 0.6">User Image</p>

                  <div class="drop-area" @click="triggerFileInput" @drop.prevent="onDrop"
                       @dragover.prevent="onDragOver">
                    <input ref="fileInput" accept="image/*" class="hidden-file-input" multiple type="file"
                           @change="onFileChange"/>
                    <div v-if="previewImages.length > 0" class="image-card-container">
                      <v-card class="image-card">
                        <v-img :src="previewImages[0]" alt="Image preview" class="image-preview"></v-img>
                        <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage(0)">
                          <v-icon color="red" size="small">mdi-delete</v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <div v-if="previewImages.length === 0" class="placeholder-container">
                      <v-icon size="36">mdi-cloud-upload</v-icon>
                      <span>Drag your photo here OR click to <span
                          style="color:#156CF7;">Browse from device</span></span>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn size="x-large" class="custom_btn_primary" @click="registerUser">Register User</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="cancel()">Cancel</v-btn>
        </v-card-actions>
      </v-card>


      <!-- Add user permissions dialog -->
      <v-dialog v-model="dashboardSelectDialog" id="app_user_registration" max-height="700" max-width="1000" persistent>
        <v-card id="app_user_registration" rounded="md" style="overflow:hidden !important;">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">SET PERMISSIONS</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="dashboardSelectDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="7" md="7" lg="7"></v-col>
              <v-col cols="12" xs="12" sm="5" md="5" lg="5">
                <v-btn class="float-end text-caption  custom_btn_secondary" @click="dashboardSelectDialog = false">
                  CONFIRM
                </v-btn>
                <v-btn class="float-end  mr-3 custom_clear_all_btn" @click="cancelAll">Clear All</v-btn>
                <v-btn class="float-end  mr-3 custom_select_all_btn" @click="selectAll">Select All</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>


            <v-card-text style="overflow-y: scroll !important;height: 450px !important;">
              <v-row v-for="(item, index) in permissionsItems" :key="item.id">
                <v-col cols="4" style="margin-bottom: -2.5% !important;">
                  <v-switch v-model="item.isOn" :color="item.isOn ? '#BB0000' : 'grey'" class="mr-1"
                            @change="handleSwitchChange(item)">
                    <template v-slot:label>
                      <v-card-title class="custom_page_main_sub_title">{{ item.premission_dashboard }}</v-card-title>
                    </template>
                  </v-switch>
                </v-col>

                <v-col v-if="item.isOn" cols="8" style="margin-bottom: -2.5% !important;">
                  <v-row>
                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.read" color="#BB0000"
                                @change="handlePermissionChange(item, 'read')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Read</v-card-title>
                        </template>
                      </v-switch>

                    </v-col>

                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.write" color="#BB0000"
                                @change="handlePermissionChange(item, 'write')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Write</v-card-title>
                        </template>
                      </v-switch>
                    </v-col>

                    <v-col class="d-flex" cols="4">
                      <v-switch v-model="item.permissions.delete" color="#BB0000"
                                @change="handlePermissionChange(item, 'delete')">
                        <template v-slot:label>
                          <v-card-title class="custom_page_main_sub_title">Delete</v-card-title>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
      <!-- Add user permissions dialog -->

      <!-- Snackbar to show notifications -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserRegistration',
  data() {
    return {
      centerLoading: false,
      permissionsItems: [],
      dashboardSelectDialog: false,
      user: {
        fullName: '',
        userName: '',
        userType: '',
        geofence: [],
        emailAddress: '',
        phoneNumber: [""],
        nic: '',
        gender: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        address: '',
        image: [],
        profilePhoto: null,
      },
      selectedRoleId: '',
      selectedRoleName: '',
      geoFences: ["Route Plan Geofence", "My Route Geofence", "Collection Geofence", "Sales Geofence", "Overall Tracking", "Route Plan", "Myroute"],

      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /^\+\d{1,3}\d{10}$/.test(v) || 'Must be a valid phone number with country code (e.g., +123123456789)',
        (v) => this.phoneNumbers.filter((number) => number === v).length <= 1 || "Phone Number already exists",
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Must be a valid email'
      ],
      nicRules: [
        v => !!v || 'NIC is required',
        v => /^[0-9]{9}[vVxX]$|^[0-9]{12}$/.test(v) || 'Must be a valid NIC number'
      ],
      e1: false,
      e2: false,
      previewImages: [],
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      checkPremissionGeoFence: '',
      phoneNumbers: [""],
      userTypes: [],
      gnds: ['GND 1', 'GND 2'], // Example GNDs
      gender: ['Male', 'Female'],
      mDistrictLoading: false,
      mProvinceLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      userRolesCheckDetails: {
        name: [],
      },
      errorMessage: '',
    };
  },
  mounted() {
    this.getAllProvinces();
    this.getAllUserRoles();
    this.getDashboardPermissions();
    this.fetchDataintoTable();
  },
  methods: {
    fetchDataintoTable() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);


      axios.post("/api/createdUserList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        const existUserName = response.data.data

        console.log('fkname', existUserName);

        if (Array.isArray(existUserName)) {
          this.userRolesCheckDetails.name = existUserName.map((item) => item.user_name);
        } else {
          console.error('Unexpected response format:', existUserName);
        }
        console.log('table data', existUserName);

      })
          .catch((error) => {
            console.log(error);
          });
    },


    chechalreadyHasUserName() {
      if (
          Array.isArray(this.userRolesCheckDetails.name) &&
          this.userRolesCheckDetails.name.includes(this.user.userName)
      ) {
        this.errorMessage = 'Display Name already exists';
        console.log('sasa', this.errorMessage);
      } else {
        this.errorMessage = '';
      }
    },

    handleSwitchChange(item) {
      if (item.isOn) {
        // Enable the "Read" permission by default if none of the permissions are enabled
        if (!item.permissions.read && !item.permissions.write) {
          item.permissions.read = true; // Enable "Read" by default
        }
      } else {
        // If the main switch is turned off, disable all permissions
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },

    handlePermissionChange(item, permissionType) {
      // You can perform additional logic here if needed
      // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
      if (permissionType === 'delete' && item.permissions.delete) {
        item.permissions.read = true;
        item.permissions.write = true;
      }

      // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
      if (!item.permissions.read && !item.permissions.write) {
        item.permissions.delete = false;
      }

      item.isOn = !(!item.permissions.read && !item.permissions.write && !item.permissions.delete);
    },

    selectAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = true;
        item.permissions.read = true;
        item.permissions.write = true;
        item.permissions.delete = true;
      });
    },

    cancelAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = false;
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      });
    },

    // Get Dashboard
    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: {read: false, write: false, delete: false},
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    // Open DashBoard Seletec dialog
    openDashBoardSeletDialog(selectedId) {
      // Clear premission when we open premission dashboard
      this.permissionsItems.forEach((item) => {
        item.isOn = false;
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      });

      const selectedItem = this.userTypes.find(type => type.id === selectedId);
      if (selectedItem) {
        this.selectedRoleId = selectedItem.id;
        this.selectedRoleName = selectedItem.name;
        console.log('Selected ID:', selectedItem.id);
        console.log('Selected Name:', selectedItem.name);

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post('/api/checkUserRole', {
          userId: userId,
          userType: selectedItem.name
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              let usrRole = response.data.data;
              this.checkPremissionGeoFence = usrRole;
              console.log('User role types:', usrRole);

              // Open dialog only if the role is 'Web User'
              if (usrRole === 'Web User') {
                this.dashboardSelectDialog = true;
              } else {
                this.dashboardSelectDialog = false;
              }
            })
            .catch(error => {
              console.error('Error fetching user roles:', error);
              this.dashboardSelectDialog = false; // Close dialog in case of error
            });
      }
    },


    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.role_type
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },
    getAllProvinces() {
      this.provinces = [];
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.user.province = null;
      this.user.district = null;
      this.user.dsd = null;
      this.user.gnd = null;
      this.mProvinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDSDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    backPageRoute() {
      this.$router.back();
    },

    addPhoneNo() {
      this.phoneNumbers.push('');
    },

    removePhoneNo(index) {
      this.phoneNumbers.splice(index, 1);
    },

    // Method to convert a file to a base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const result = reader.result;
          // Remove the "data:image/png;base64," part
          const base64String = result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = error => reject(error);
      });
    },

    validateForm() {

      if (!this.user.fullName || !/^[a-zA-Z\s]+$/.test(this.user.fullName)) {
        this.showSnackbar('Full Name is required and should contain only letters and spaces.', 'error');
        return false;
      }
      if (!this.user.userName || !/^[a-zA-Z0-9\s]+$/.test(this.user.userName)) {
        this.showSnackbar('Display Name is required and should contain only letters, numbers, and spaces.', 'error');
        return false;
      }
      // Check if the username already exists in the list
      if (this.userRolesCheckDetails.name.includes(this.user.userName)) {
        this.showSnackbar('Display Name already exists.', 'error');
        return false;
      }
      if (!this.user.userType) {
        this.showSnackbar('User Type is required.', 'error');
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.user.emailAddress || !emailRegex.test(this.user.emailAddress)) {
        this.showSnackbar('A valid Email Address is required.', 'error');
        return false;
      }
      const phonePatternWithCountryCode = /^\+[1-9]{1,3}[0-9]{10}$/;

      if (
          this.phoneNumbers.some((phone, index) => {
            // Check if phone is empty or invalid
            if (!phone || !phonePatternWithCountryCode.test(phone)) {
              this.showSnackbar('All phone numbers must be valid and include a country code (e.g., +1234567890).', 'error');
              return true;
            }
            // Check for duplicates
            if (this.phoneNumbers.indexOf(phone) !== index) {
              this.showSnackbar('Duplicate phone numbers are not allowed.', 'error');
              return true;
            }
            return false;
          })
      ) {
        return false;
      }


      const nicPattern = /^(\d{9}[VvXx]|[0-9]{12})$/;
      if (!this.user.nic || !nicPattern.test(this.user.nic)) {
        this.showSnackbar(
            'NIC must be either 9 digits followed by "V/v" or "X/x" (e.g., 123456789V or 123456789X) or exactly 12 digits (e.g., 123456789012).',
            'error'
        );
        return false;
      }

      if (!this.user.gender) {
        this.showSnackbar('Gender is required.', 'error');
        return false;
      }

      if (!this.user.province) {
        this.showSnackbar('Province is required.', 'error');
        return false;
      }

      if (!this.user.district) {
        this.showSnackbar('District is required.', 'error');
        return false;
      }

      if (!this.user.dsd) {
        this.showSnackbar('DSD is required.', 'error');
        return false;
      }

      if (!this.user.address) {
        this.showSnackbar('Address is required.', 'error');
        return false;
      }

      // Validate at least one permission is selected
      if (this.checkPremissionGeoFence === "Web User") {
        const hasPermission = this.permissionsItems.some(item =>
            item.permissions.read || item.permissions.write || item.permissions.delete
        );

        if (!hasPermission) {
          this.showSnackbar('At least one permission (Read, Write, or Delete) must be selected.', 'error');
          return false;
        }
      }


      return true;
    },


    async registerUser() {
      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      if (this.$refs.userRegistrationFormValidate) {
        const isValid = this.$refs.userRegistrationFormValidate.validate();
        if (!isValid) {
          console.log("Form reference validation failed!");
          return;
        }
      }

      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");
        this.centerLoading = true;

        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("fullName", this.user.fullName);
        formData.append("userName", this.user.userName);
        formData.append("userType", this.selectedRoleName);
        formData.append("userRoleId", this.selectedRoleId);
        formData.append("emailAddress", this.user.emailAddress);
        formData.append("nic", this.user.nic);
        formData.append("gender", this.user.gender);
        formData.append("province", this.user.province|| '');
        formData.append("district", this.user.district|| '');
        formData.append("dsd", this.user.dsd || '');
        formData.append("gnd", this.user.gnd|| '');
        formData.append("address", this.user.address);


        formData.append("routePlane", this.user.geofence.includes("Route Plan Geofence") ? 1 : 0);
        formData.append("myRoute", this.user.geofence.includes("My Route Geofence") ? 1 : 0);
        formData.append("collection", this.user.geofence.includes("Collection Geofence") ? 1 : 0);
        formData.append("sales", this.user.geofence.includes("Sales Geofence") ? 1 : 0);
        formData.append("OverallTracking", this.user.geofence.includes("Overall Tracking") ? 1 : 0);
        formData.append("RoutePlan", this.user.geofence.includes("Route Plan") ? 1 : 0);
        formData.append("Myroute", this.user.geofence.includes("Myroute") ? 1 : 0);

        // Add phone numbers
        formData.append("mobileNumber", this.phoneNumbers);

        // Prepare dashboard permissions payload
        const dashboards = this.permissionsItems
            .filter(item => item.isOn)
            .map(item => ({
              dashboardId: item.id,
              readPremission: item.permissions.read,
              writePremission: item.permissions.write,
              deletePremission: item.permissions.delete,
            }));
        const payload = {
          userRoleId: this.selectedRoleId,
          accessDashboard: dashboards,
          userId,
        };

        const jsonPayload = JSON.stringify(payload);
        formData.append("accessDashboard", jsonPayload);

        // Add user image (if provided)
        if (this.user.image && this.user.image.length > 0) {
          await this.convertToBase64(this.user.image[0]).then(base64String => {
            formData.append("image", base64String);
          });
        }

        // Step 3: API call to backend
        const response = await axios.post("/api/createUser", formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 201) {
          this.showSnackbar("NEW USER WAS REGISTERED SUCCESSFULLY!", "success");
          this.centerLoading = false;
          window.location.href = '/user-registration';
        } else {
          this.showSnackbar("Unexpected response status.", "warning");
        }

      } catch (error) {
        // Handle errors
        if (error.response) {
          if (error.response.status === 400 && error.response.data.error === 'User already exists') {
            this.showSnackbar('User already exists. Please use a different NIC.', "error");
          } else {
            console.error('Error:', error.response.data);
            this.showSnackbar("Failed to register user Email already exists. Please check Email", "error");
          }
        } else if (error.request) {
          console.error('Error request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.centerLoading = false;
      }
    },

    cancel() {
      // Reset form fields
      this.user = {
        fullName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        userType: "",
        emailAddress: "",
        nic: "",
        gender: "",
        province: "",
        district: "",
        dsd: "",
        gnd: "",
        address: "",
        image: [],
        phoneNo: "",
      };
      this.phoneNumbers = [];
      this.previewImages = [];
      // this.permissionsItems = [];
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.handleFiles(file);
      }
      event.target.value = '';
    },

    onDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },

    onDragOver(event) {
      event.preventDefault();
    },

    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      // Clear previous image
      this.user.image = [];
      this.previewImages = [];

      this.user.image.push(file);
      this.previewImages.push(URL.createObjectURL(file));
    },

    removeImage(index) {
      if (index >= 0 && index < this.user.image.length) {
        this.user.image.splice(index, 1);
        this.previewImages.splice(index, 1);
      }
    },

    createUserRole() {
      this.$router.push({path: '/create-user-role'});
    },
  }
};
</script>


<style>
@import "../../styles/user_management/user_registration.scss";
</style>