<template>
  <div id="app_dashboard_admin">
    <v-container fluid>
      <v-card flat>
        <template v-slot:prepend>
          <v-card-title class="mb-2 custom_page_main_title">DASHBOARD</v-card-title>
          <v-divider style="height: 50px !important;" vertical class="ml-4" thickness="2"></v-divider>
        </template>

        <template v-slot:default>
          <v-divider thickness="2"></v-divider>
        </template>
      </v-card>


      <v-card style="border-top-left-radius: 50px !important;" class="mx-auto mt-5 custom_card_box_dashboard"
              elevation="0" border="sm opacity-1">
        <v-row>
          <v-col cols="4" xs="4" sm="4" md="4" lg="4"></v-col>
          <v-col cols="8" xs="8" sm="8" md="8" lg="8">
            <v-date-input v-model="dates"
                          class="float-end mt-6 mr-5 custom_input_field_color"
                          style="width: 330px!important;"
                          multiple="range"
                          prepend-inner-icon="$calendar" prepend-icon=""
                          label="Date Range" density="comfortable"
                          variant="outlined"
                          clearable
                          :max="maxDate"
                          @click:append-inner="clearDateRange"

            ></v-date-input>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto " elevation="0" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">TOTAL SALES</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="custom_page_main_sub_title_very_small_red">Current Month</p>-->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img class="ml-4" :src="require('@/img/icons/total_sales_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <v-icon v-if="profitShowIcon" style="margin-left: -1%!important;" size="small"
                              :color="iconColor">
                        {{ iconName }}
                      </v-icon>
                      <strong>{{ dashboard.totalSales }}</strong></div>
                    <div class="custom_page_main_sub_title_very_small_gray mt-2" v-if="salesStatus !== 'N/A'">
                      <v-icon v-if="showIcon" :size="'small'" :color="iconColor">
                        {{ iconName }}
                      </v-icon>
                      <strong :style="{ color: iconColor }"> {{ dashboard.SalesPresentage }}</strong>
                      &nbsp; {{ statusMessage }}
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto " elevation="0" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">TOTAL COLLECTIONS</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="custom_page_main_sub_title_very_small_red">Current Month</p>-->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img :src="require('@/img/icons/total_collections_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <v-icon v-if="profitShowIcon" style="margin-left: -1%!important;" size="small"
                              :color="collectionIconColor">
                        {{ collectionIconName }}
                      </v-icon>
                      <strong>{{ dashboard.totalCollection }}</strong></div>
                    <div class="custom_page_main_sub_title_very_small_gray mt-2" v-if="collectionStatus !== 'N/A'">
                      <v-icon v-if="collectionShowIcon" :size="'small'" :color="collectionIconColor">
                        {{ collectionIconName }}
                      </v-icon>
                      <strong :style="{ color: collectionIconColor }"> {{ dashboard.CollectionPresentage }}</strong>
                      &nbsp; {{ collectionStatusMessage }}
                    </div>
                  </template>
                </v-card-text>

              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto" elevation="0" border="sm opacity-1" rounded="md" style="min-height: 190px">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">TOTAL PROFIT</p>
                </template>
                <template v-slot:subtitle>
                  <!-- <p class="custom_page_main_sub_title_very_small_red">Current Month</p> -->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img class="ml-1" :src="require('@/img/icons/profit_&_loss_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <v-icon v-if="profitShowIcon" style="margin-left: -1%!important;" size="small" :color="profitIconColor">
                        {{ profitIconName }}
                      </v-icon>
                      <strong>{{ dashboard.totalProfit }}</strong>
                    </div>
                    <!-- Use v-show instead of v-if to ensure the card doesn't shrink when profitStatus changes -->
                    <div class="custom_page_main_sub_title_very_small_gray mt-2" v-show="profitStatus !== 'N/A'">
                      <v-icon v-if="profitShowIcon" size="small" :color="profitIconColor">
                        {{ profitIconName }}
                      </v-icon>
                      <strong :style="{ color: profitIconColor }"> {{ dashboard.profitPresantage }}</strong>
                      &nbsp; {{ profitStatusMessage }}
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto " elevation="0" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">TOTAL USERS</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="custom_page_main_sub_title_very_small_red">Current Month</p>-->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img class="ml-1" :src="require('@/img/icons/users_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <strong>{{ dashboard.totalStock }}</strong>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto " elevation="0" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">TOTAL LEADS</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="custom_page_main_sub_title_very_small_red">Current Month</p>-->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img class="ml-1" :src="require('@/img/icons/ongoing_leads_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <strong>{{ dashboard.totalLeasds }}</strong>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" lg="4">
              <v-card class="mx-auto " elevation="0" border="sm opacity-1" rounded="md">
                <template v-slot:title>
                  <p class="font-weight-bold custom_page_component_main_title">REGISTERED OUTLETS</p>
                </template>
                <template v-slot:subtitle>
<!--                  <p class="custom_page_main_sub_title_very_small_red">Current Month</p>-->
                </template>
                <template v-slot:append>
                  <v-avatar tile size="80" class="pa-3" rounded color="#F5D9D9">
                    <v-img class="ml-1" :src="require('@/img/icons/re_outlets_d.png')"></v-img>
                  </v-avatar>
                </template>
                <v-divider></v-divider>
                <v-card-text>
                  <template v-slot:default>
                    <div class="font-weight-black text-h5">
                      <strong>{{ dashboard.totalOutlet }}</strong>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from "axios";

export default {
  name: 'ReportCard',
  data() {
    return {
      maxDate: new Date().toISOString().substr(0, 10), // Current date in 'YYYY-MM-DD' format
      dates: null,
    };
  },
  computed: {
    salesStatus() {
      const value = this.dashboard.SalesPresentage;
      if (!value || value === "N/A") return "N/A";
      const numericValue = parseFloat(value.replace("%", ""));
      return numericValue < 0 ? "decrease" : "increase";
    },
    showIcon() {
      return this.salesStatus !== "N/A";
    },
    iconName() {
      return this.salesStatus === "decrease" ? "mdi-arrow-down-bold" : "mdi-arrow-up-bold";
    },
    iconColor() {
      return this.salesStatus === "decrease" ? "#FF0000" : "#2A8200"; // Red for decrease, green for increase
    },
    statusMessage() {
      return this.salesStatus === "decrease"
          ? "Decrease compared to last month"
          : "Increase compared to last month";
    },

    // Collection-related computed properties
    collectionStatus() {
      const value = this.dashboard.CollectionPresentage;
      if (!value || value === "N/A") return "N/A";
      const numericValue = parseFloat(value.replace("%", ""));
      return numericValue < 0 ? "decrease" : "increase";
    },
    collectionShowIcon() {
      return this.collectionStatus !== "N/A";
    },
    collectionIconName() {
      return this.collectionStatus === "decrease" ? "mdi-arrow-down-bold" : "mdi-arrow-up-bold";
    },
    collectionIconColor() {
      return this.collectionStatus === "decrease" ? "#FF0000" : "#2A8200"; // Red for decrease, green for increase
    },
    collectionStatusMessage() {
      return this.collectionStatus === "decrease"
          ? "Decrease compared to last month"
          : "Increase compared to last month";
    },

    // Profit-related computed properties
    profitStatus() {
      const value = this.dashboard.profitPresantage;
      if (!value || value === "N/A") return "N/A";
      const numericValue = parseFloat(value.replace("%", ""));
      return numericValue < 0 ? "decrease" : "increase";
    },
    profitShowIcon() {
      return this.profitStatus !== "N/A";
    },
    profitIconName() {
      return this.profitStatus === "decrease" ? "mdi-arrow-down-bold" : "mdi-arrow-up-bold";
    },
    profitIconColor() {
      return this.profitStatus === "decrease" ? "#FF0000" : "#2A8200"; // Red for decrease, green for increase
    },
    profitStatusMessage() {
      return this.profitStatus === "decrease"
          ? "Decrease compared to last month"
          : "Increase compared to last month";
    },


    ...mapGetters({dashboard: 'getDashboardDetails'})
  },
  created() {

  },

  methods: {
    clearDateRange() {
      this.dates = null; // or [] for a range
    },
    async callApi(startDate, endDate) {
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/getFilteredCount`,
            { startDate, endDate }, // Pass the payload directly as the second argument
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
        );

        console.log('Filtered Data:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching filtered count:', error);
      }
    }
  },
  watch: {
    dates(newDates) {
      // const formatDate = (date) => {
      //   const d = new Date(date);
      //   return d.toISOString().split('T')[0];
      // };
      //
      // if (newDates && newDates.length === 2) {
      //   const startDate = formatDate(newDates[0]);
      //   const endDate = formatDate(newDates[1]);
      //   console.log("START DATE IS ", startDate);
      //   console.log("END DATE IS ", endDate);
      //   this.callApi(startDate, endDate);
      //   console.log("DATE IS ", startDate, endDate);
      // }
      let datesObject = {
        start : newDates[0],
        end: newDates[newDates.length - 1],
      }
      console.log("DATE IS ", datesObject);

      this.$emit('filterDateRange', datesObject);
    },
  },
}
</script>

<style>
@import "../../styles/dashboard/dashboard.scss";
</style>