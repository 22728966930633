<template>
  <v-app id="app_main_navigation_drawer">
    <!--------------------------**************************************----------------------------------------------->
    <!-------------------START------------------------DISPLAY MAIN APP BAR ------------------------------------------>
    <v-app-bar style="border-radius: 0px !important;" class="custom_card_box" border="sm opacity-1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title class="display_only_mobile_appBar">
        <v-img class="custom_company_logo_nav ml-4" :src="require('@/img/company_logo/logo_full_5.png')"></v-img>
      </v-app-bar-title>

      <v-app-bar-title class="display_only_web_appBar">
        <v-img class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')"></v-img>
        <p class="text-caption ml-1  mb-2" style="font-size: 8px !important;">
          POWERED BY CELATATECH
        </p>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-menu v-model="notificationDialog" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn class="mr-4" icon v-bind="props" variant="text">
            <v-avatar>
              <v-btn icon="mdi-bell-outline" variant="text"></v-btn>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="mx-auto custom_card_box" elevation="1" border="sm opacity-1" rounded="md" min-width="400px">
          <v-card flat>
            <template v-slot:prepend>
              <v-card-title class="text-subtitle-1 font-weight-bold">NOTIFICATIONS</v-card-title>
            </template>
            <v-spacer></v-spacer>
            <template v-slot:append>
              <v-btn variant="flat" class="custom_red_btn" @click="clearNotification">CLEAR ALL</v-btn>
            </template>
          </v-card>

          <v-divider class="pb-4"></v-divider>
          <div class="custom_scroll_option_notification">
            <v-card-item
                v-for="(message, index) in formattedNotifications"
                :key="index"
                class="notification-item"
            >
              <template v-slot:prepend>
                <v-card-title class="text-body-2">

                  <div class="ml-0">
                    <v-icon color="success" size="small">mdi-check-circle</v-icon>
                    <span class="ml-3 bold">{{ message.title }}</span>
                    <!-- Small text for the note -->
                    <div class="text-body-2 text-gray-500" style="font-size: 0.75rem;">
                      {{ message.note }}
                    </div>
                  </div>
                </v-card-title>
              </template>

              <template v-slot:append>
                <v-list-item-title>{{ message.displayDate }}</v-list-item-title>
              </template>
            </v-card-item>
          </div>
          <v-divider class="pt-4"></v-divider>
        </v-card>
      </v-menu>


      <v-menu v-model="menu" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn class="mr-4" icon v-bind="props" variant="text">
            <v-avatar>
              <v-img
                  :src="userDetials.user_image ? `${imageURL}/${userDetials.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                  class="avatar-image"/>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="mx-auto custom_card_box" elevation="1" border="sm opacity-1" rounded="md">
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center align-center" cols="3">
                <v-card-title>
                  <v-avatar size="x-large">
                    <v-img
                        :src="userDetials.user_image ? `${imageURL}/${userDetials.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                        class="avatar-image"/>
                  </v-avatar>
                </v-card-title>
              </v-col>

              <v-col class="text-left" cols="9">
                <h6 style="color: #BB0000" class="font-weight-medium text-body-2">{{ this.userDetials.user_type }}</h6>
                <h3 class="font-weight-bold text-subtitle-1">{{ this.userDetials.name }}</h3>
                <h6 class="font-weight-medium text-caption">{{ this.userDetials.email }}</h6>
              </v-col>
            </v-row>

            <v-divider class="mt-3"></v-divider>

            <div class="float-end mb-3 mt-2">
              <v-btn variant="flat" class="custom_red_btn" @click="isLogOut()">
                SIGN OUT
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!--------------------------**************************************----------------------------------------------->
    <!-------------------END------------------------DISPLAY MAIN APP BAR -------------------------------------------->


    <v-navigation-drawer style="border: none !important;" v-model="drawer" app clipped>
      <v-card border="sm opacity-1" class="mx-2 mt-3 drawer-content" elevation="1" rounded="lg">
        <v-list background dense density="compact" nav rounded>
          <v-list-item class="custom_list_group" v-if="isVisible('Dashboard', 1)" active-class="red-background" link
                       to="/dashboard">
            <template v-slot:prepend>
              <v-icon size="small">mdi-view-dashboard-outline</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">Dashboard</v-list-item-title>
          </v-list-item>


          <v-list-item v-if="isVisible('Product Management',2)" :class="{ 'red-background': isActiveParentMenu('A') }"
                       link @click="toggleSubMenu('A')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-package-variant</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">Product Management
            </v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>


          <v-list-item-group v-if="activeSubMenu === 'A'">
            <v-list-item v-if="hasPermission(2, 'write_premission')" class="custom_active font-weight-medium text-body-1" link to="/add-products" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Add Products"></v-list-item>
            <v-list-item v-if="hasPermission(2, 'read_premission')" class="custom_active font-weight-medium text-body-1" to="/products-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Products List"></v-list-item>
            <v-list-item v-if="hasPermission(2, 'write_premission')"  class="custom_active font-weight-medium text-body-1" to="/products-category" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Add Category"></v-list-item>
            <v-list-item v-if="hasPermission(2, 'read_premission')" class="custom_active font-weight-medium text-body-1" to="/category-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Category List"></v-list-item>
            <!-- <v-list-item class="custom_active font-weight-medium text-body-1" color="#BB0000" prepend-icon="mdi mdi-menu-right" title="Measurements"></v-list-item> -->
          </v-list-item-group>


          <v-list-item v-if="isVisible('Leads Management', 3)" :class="{ 'red-background': isActiveParentMenu('B') }"
                       link @click="toggleSubMenu('B')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-clipboard-clock-outline</v-icon>
            </template>
            <v-list-item-title class="custom_active">Lead Management</v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>

          <v-list-item-group v-if="activeSubMenu === 'B'">
            <v-list-item v-if="hasPermission(3, 'write_premission')" class="font-weight-medium text-body-1" to="/add-leads" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Add Leads"></v-list-item>
            <v-list-item v-if="hasPermission(3, 'read_premission')" class="font-weight-medium text-body-1" to="/leads-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Leads List"></v-list-item>
          </v-list-item-group>


          <v-list-item v-if="isVisible('User Management', 4)" :class="{ 'red-background': isActiveParentMenu('C')}" link
                       @click="toggleSubMenu('C')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-account-edit-outline</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">User Management</v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>

          <v-list-item-group v-if="activeSubMenu === 'C'">
            <v-list-item v-if="hasPermission(4, 'write_premission')" class="font-weight-medium text-body-1" to="/user-registration" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="User Registration"></v-list-item>
            <v-list-item v-if="hasPermission(4, 'read_premission')" class="font-weight-medium text-body-1" to="/users-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Users List"></v-list-item>
            <v-list-item v-if="hasPermission(4, 'write_premission')" class="font-weight-medium text-body-1" to="/create-user-role" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Create User Role"></v-list-item>
            <v-list-item v-if="hasPermission(4, 'read_premission')" class="font-weight-medium text-body-1" to="/user-role-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="User Role List"></v-list-item>
          </v-list-item-group>


          <v-list-item v-if="isVisible('Sales Management', 5)" active-class="red-background" link
                       to="/sales-management">
            <template v-slot:prepend>
              <v-icon size="small">mdi-chart-bell-curve-cumulative</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">Sales Management</v-list-item-title>
          </v-list-item>


          <v-list-item v-if="isVisible('Collection Management', 6)" active-class="red-background" link
                       to="/collection-management">
            <template v-slot:prepend>
              <v-icon size="small">mdi-sale</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">Collection Management
            </v-list-item-title>
          </v-list-item>


          <v-list-item v-if="isVisible('Tracking Management', 7)" :class="{ 'red-background': isActiveParentMenu('D') }"
                       link @click="toggleSubMenu('D')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-map-marker-path</v-icon>
            </template>
            <v-list-item-title class="custom_active font-weight-medium text-body-1">Tracking Management
            </v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>

          <v-list-item-group v-if="activeSubMenu === 'D'">
            <v-list-item class="font-weight-medium text-body-1" to="/live-tracking" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Live Tracking"></v-list-item>
            <v-list-item v-if="hasPermission(7, 'read_premission')" class="font-weight-medium text-body-1" to="/overall-tracking-history" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Overall Tracking History"></v-list-item>
            <v-list-item v-if="hasPermission(7, 'read_premission')" class="font-weight-medium text-body-1" to="/tracking-history" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Route-Plan Tracking History"></v-list-item>
            <v-list-item v-if="hasPermission(7, 'read_premission')" class="font-weight-medium text-body-1" to="/myroute-tracking-history" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="My-Route Tracking History"></v-list-item>
          </v-list-item-group>


          <v-list-item v-if="isVisible('Route Management', 8)" :class="{ 'red-background': isActiveParentMenu('E') }"
                       link @click="toggleSubMenu('E')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-road-variant</v-icon>
            </template>
            <v-list-item-title class="font-weight-medium text-body-1 custom_active">Route Management</v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>

          <v-list-item-group v-if="activeSubMenu === 'E'">
            <v-list-item v-if="hasPermission(8, 'write_premission')" class="font-weight-medium text-body-1" to="/create-routes" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Create Routes"></v-list-item>
            <v-list-item v-if="hasPermission(8, 'read_premission')" class="font-weight-medium text-body-1" to="/routes-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Routes List"></v-list-item>
          </v-list-item-group>


          <v-list-item v-if="isVisible('Outlet Management', 9)" :class="{ 'red-background': isActiveParentMenu('F') }"
                       link @click="toggleSubMenu('F')">
            <template v-slot:prepend>
              <v-icon size="small">mdi-storefront-outline</v-icon>
            </template>
            <v-list-item-title class="font-weight-medium text-body-1 custom_active">Outlet Management
            </v-list-item-title>
            <template v-slot:append>
              <v-icon size="small">mdi-chevron-down</v-icon>
            </template>
          </v-list-item>

          <v-list-item-group v-if="activeSubMenu === 'F'">
            <v-list-item v-if="hasPermission(9, 'write_premission')" class="font-weight-medium text-body-1" to="/create-outlet" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Create Outlet"></v-list-item>
            <v-list-item v-if="hasPermission(9, 'read_premission')" class="font-weight-medium text-body-1" to="/outlet-list" color="#BB0000"
                         prepend-icon="mdi mdi-menu-right" title="Outlet List"></v-list-item>
<!--            <v-list-item class="font-weight-medium text-body-1" color="#BB0000" prepend-icon="mdi mdi-menu-right"-->
<!--                         title="Outlet Types"></v-list-item>-->
          </v-list-item-group>


          <v-list-item class="custom_list_group" active-class="red-background" link to="/organization-management">
            <template v-slot:prepend>
              <v-icon size="small">mdi-office-building-marker</v-icon>
            </template>
            <v-list-item-title class="font-weight-medium text-body-1 custom_active">Organization Management
            </v-list-item-title>
          </v-list-item>


          <v-list-item active-class="red-background" link to="/user-log">
            <template v-slot:prepend>
              <v-icon size="small">mdi-text-box-search-outline</v-icon>
            </template>
            <v-list-item-title class="font-weight-medium  text-body-1 custom_active">User Logs</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-card-title class="text-center">
          <v-divider></v-divider>
        </v-card-title>
      </v-card>
    </v-navigation-drawer>


    <v-main :class="{'drawer-expanded': drawer}">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import axios from 'axios';

export default {
  data: () => ({
    imageURL: "",
    drawer: true,
    dialogLogout: false,
    group: null,
    activeSubMenu: null,
    menu: false,
    notificationDialog: false,
    userDetials: "",
    notification: [],
    accessDash: [],

  }),

  mounted() {
    this.checkDashboardPremission();
  },

  watch: {
    group() {
      this.drawer = false
    },

  },
  created() {
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    this.getUserDetails();
    this.getAllNotification();
    // this.checkDashboardPremission();
  },
  computed: {
    ...mapState(['organizationSettings']),

    isVisible() {
      return (section, dashboardId = null) => {
        // Check if the section is enabled in Vuex
        //   const vuexSettings = this.organizationSettings?.[section];
        //   if (vuexSettings !== undefined) {
        //     if (vuexSettings !== '1') return false; // Not enabled in Vuex
        //   }

        // Check if the section is enabled in localStorage
        const localSettings = JSON.parse(localStorage.getItem('organizationSettings')) || {};
        if (localSettings[section] !== undefined) {
          if (localSettings[section] !== '1') return false; // Not enabled in localStorage
        }

        // New requirement: Check dashboard_id in accessDashboard
        if (dashboardId !== null) {
          return this.accessDash.some((item) => item.dashboard_id === dashboardId);
        }

        // Default: visible if no restrictions are found
        return true;
      };
    },


    formattedNotifications() {
      const now = new Date();
      return this.notification.map((message) => {
        const createdAt = new Date(message.created_at);
        const diffInMs = now - createdAt;
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMinutes / 60);

        let displayDate;
        if (createdAt.toDateString() === now.toDateString()) {
          // Same day notification
          if (diffInMinutes < 60) {
            displayDate = `${diffInMinutes} min ago`;
          } else {
            displayDate = `${diffInHours} h ago`;
          }
        } else {
          // Different day notification
          displayDate = createdAt.toISOString().split("T")[0]; // Format: YYYY-MM-DD
        }

        return {
          ...message,
          displayDate,
        };
      });
    },

  },

  methods: {
    checkDashboardPremission() {
      const accessDashboard = JSON.parse(localStorage.getItem("accessDashboard"));
      console.log("No accessDashboard data found111.", accessDashboard);
      if (accessDashboard && Array.isArray(accessDashboard)) {
        const dashboards = accessDashboard;
        this.accessDash = dashboards;
        console.log('dash', this.accessDash);
      } else {
        console.log("No accessDashboard data found.");
      }
    },
    hasPermission(dashboardId, permissionType) {
      const accessDash = JSON.parse(localStorage.getItem('accessDashboard')) || [];
      const dashboardAccess = accessDash.find((item) => item.dashboard_id === dashboardId);
      return dashboardAccess && dashboardAccess[permissionType] === 1;
    },

    clearNotification() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);

      axios.post('/api/delete/notifications/sale-collection', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            if (response.status === 200) {

              this.getAllNotification();
              this.notificationDialog = false;
              console.log('Clear',);


            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });

    },

    getUserDetails() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);

      axios.post('/api/getUserDetailsIntoDashboard', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            if (response.status === 200) {
              this.userDetials = response.data.data;
              console.log('pakoo', this.userDetials);

            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });

    },

    getAllNotification() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);

      axios.post('/api/get/notifications/sale-collection', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            if (response.status === 200) {
              this.notification = response.data.data;
              console.log('notification', this.notification);

            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });

    },

    async isLogOut() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId);
      try {
        // Send the logout request to the API
        await axios.post('/api/logout', formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Clear the token from local storage
        localStorage.removeItem('token');

        // Redirect to the login page
        window.location.href = '/login';
      } catch (error) {
        console.error('Error logging out:', error);
        // Handle the error (e.g., show a message or notification)
      }
    },

    // isLogOut() {
    //     window.location.href = '/login';
    // },

    homeReload() {
      window.location.href = '/dashboard';
    },

    toggleSubMenu(menu) {
      this.activeSubMenu = this.activeSubMenu === menu ? null : menu;
    },

    isActiveParentMenu(menu) {
      const route = this.$route.path;
      const subMenus = {
        A: ['/add-products', '/products-list'],
        B: ['/add-leads', '/leads-list'],
        C: ['/user-registration', '/users-list', '/create-user-role', '/user-role-list'],
        D: ['/live-tracking', '/tracking-history'],
        E: ['/create-routes', '/routes-list'],
        F: ['/create-outlet', '/outlet-list'],
      };
      return subMenus[menu] && subMenus[menu].includes(route);
    },
  },
}
</script>


<style>
@import "../styles/navigation_drawer/main_navigation_drawer.scss";
</style>
