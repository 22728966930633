<template>
  <v-app id="app_route_plan_tracking_history">

    <v-overlay v-model="loadningScreen" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">ROUTE PLAN TRACKING HISTORY</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyze the tracking history of sales and collections based on agents.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:append>
          <v-card-text class="custom_tool_bar_style">
            <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
              <v-icon size="large" color="#BB0000">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>
            <v-btn  class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
              <v-icon size="large" color="#2E7D32">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </v-card-text>
        </template>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-date-input v-model="dates" append-inner-icon="mdi-close" prepend-inner-icon="$calendar" prepend-icon="" label="Select Date Range" multiple="range"
                  variant="outlined" @click:append-inner="clearDateRange" density="comfortable" class="custom_input_field_color"
              ></v-date-input>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="selectedCategory" :items="categories" clearable label="Category" placeholder="Select category"
                        variant="outlined" density="comfortable" class="custom_input_field_color"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="serachModel"  label="Search" persistent-placeholder placeholder="Search"
                            variant="outlined" density="comfortable" class="custom_input_field_color"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table :headers="headers" :items="filteredData" :loading="loading" :search="search" class="custom_table_normal">
            <template v-slot:[`item.user_deateails.name`]="{ item }" >
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.user_deateails.name }}</p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-2">{{ item.registrationNumber  }}</p>
            </template>


            <template v-slot:[`item.route_Deateails.route_name`]="{ item }" >
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.route_Deateails?.route_name || 'N/A' }}</p>
              
            </template>
           
            <template v-slot:[`item.firstOutlet`]="{ item }" >
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.firstOutlet}}</p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p class="custom_font_color_view text-caption font-weight-medium mb-2">{{ item.trackingStartTime }}</p>
            </template>

            <template v-slot:[`item.lastOutlet`]="{ item }" >
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">{{ item.lastOutlet}}</p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p class="custom_font_color_view text-caption font-weight-medium mb-2">{{ item.tracingEndTime }}</p>
            </template>

            <template v-slot:[`item.visiteDateCheck`]="{ item }" >
              <p class="text-caption custom_page_main_sub_title font-weight-bold mt-2">Visited Date : {{ item. visiteDate}}</p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-2">Assign Date : {{ item.route_Deateails?.assign_date || 'N/A' }}</p>
            </template>

            <template v-slot:[`item.actions`]="{ item }" >
              <v-menu transition="slide-x-transition" id="app_user_role_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="permissions.read" @click="mapView(item)">
                    <v-list-item-title class="custom_font_color_view">MAP VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item   v-if="permissions.delete" @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>



      <!--  collection recode edit view component call to dialog box-->
      <v-dialog v-model="mapViewDialog" fullscreen id="app_route_plan_tracking_history">
        <v-card flat>
          <v-toolbar style="border-radius: 0px !important;"  class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img  class="custom_company_logo_nav" :src="require('@/img/company_logo/logo_full_5.png')" @click="closeD()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn"  variant="flat" @click="mapViewDialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon >mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">MAP VIEW</v-card-title>
                </template>
              </v-card>



              <v-card ref="mapElement" class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="4" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                        <v-img :src="OfficerDetails.user_image ? `${baseUrl}/SFA/public/${OfficerDetails.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                               max-width="150" class="user_image_view"></v-img>

                      </v-col>
                      <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5">
                        <v-table density="compact" class="custom_table_normal">
                          <tbody>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-body-2">Agent Name &nbsp;</p></td>
                              <td><p class="font-weight-bold custom_page_component_main_title text-body-2" style="color: #BB0000 !important;">{{ this.agentName }}</p></td>
                            </tr>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-caption">Route &nbsp;</p></td>
                              <td><p class="font-weight-medium text-caption custom_page_component_main_title mt-1">{{ this.routeName }}</p></td>
                            </tr>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-caption">Assign Date &nbsp;</p></td>
                              <td><p class="font-weight-medium text-caption custom_page_component_main_title mt-1">{{ this.assignDateRoute }}</p></td>
                            </tr>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-caption">Visited Date &nbsp;</p></td>
                              <td><p class="font-weight-medium text-caption custom_page_component_main_title mt-1">{{ this.visitedDate }}</p></td>
                            </tr>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-caption">Total Distance &nbsp;</p></td>
                              <td><p class="font-weight-medium text-caption custom_page_component_main_title mt-1">{{ this.totalDistance }}</p></td>
                            </tr>
                            <tr>
                              <td><p class="font-weight-bold custom_page_component_main_title text-caption">category &nbsp;</p></td>
                              <td><p class="font-weight-medium text-caption custom_page_component_main_title mt-1">{{ this.routeCatagary }}</p></td>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-col>
                    </v-row>
                  </v-col>


                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="downloadPdf" v-if="showDownloadButton">Download PDF</v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>


                <v-row class="px-3">
                  <v-col cols="12" xs="12" sm="12" md="8" lg="9">
                    <GMapMap :center="center" :zoom="6.5"  class="rounded-lg custom_card_box d-flex route_plan_tracking_map mb-3">

                      <GMapPolyline :options="polylineOptions" :path="pathCoordinates"  />

                      <!-- Start Point Marker -->
                      <GMapMarker :icon="startIcon" :position="startPoint" :title="'Start Point'"/>

                      <!-- End Point Marker -->
                      <GMapMarker :icon="endIcon" :position="endPoint" :title="'End Point'"/>

                      <!-- Outlet Marker -->
                      <GMapMarker
                          v-for="(coordinate, index) in pathCoordinatesOutlet" :key="index"
                          :icon="outletIcon" :position="coordinate" :title="'OutLets'"
                      />

                    </GMapMap>
                  </v-col>


                  <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                    <div class="mt-1">
                      <v-divider class="mt-1 mb-4"></v-divider>
                      <p class="custom_page_component_main_title text-body-1 font-weight-bold text-uppercase"> JOURNEY DETAILS</p>
                      <v-divider class="mt-4 mb-4"></v-divider>

                      <v-card class="custom_card_box" elevation="0"  border="sm opacity-1" rounded="md">
                        <template v-slot:prepend>
                          <v-avatar color="#F5D9D9" class="mb-6">
                            <v-icon color="#BB0000" icon="mdi-location-enter"></v-icon>
                          </v-avatar>
                        </template>
                        <template v-slot:item>
                          <div class="text-body-2 font-weight-bold mt-3 ml-4">
                            {{ startLocation }}
                          </div>

                          <div class="font-weight-medium custom_page_main_sub_title_very_small_red text-body-2 ml-4 mt-2">
                            {{ fristOutletAddress}}
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex ml-4">
                            <div class="custom_page_main_sub_title_very_small_red text-body-2 font-weight-medium mt-1"> {{ trackingStartTime }}</div>
                            <v-divider vertical class=" mr-2 ml-2"></v-divider>
                            <div><v-chip class="custom_green_chip " size="small" variant="outlined">Start Outlet</v-chip></div>
                          </div>
                        </template>
                      </v-card>
                      <v-card class="custom_card_box mt-3" elevation="0"  border="sm opacity-1" rounded="md">
                        <template v-slot:prepend>
                          <v-avatar color="#F5D9D9" class="mb-6">
                            <v-icon color="#BB0000" icon="mdi-location-exit"></v-icon>
                          </v-avatar>
                        </template>
                        <template v-slot:item>
                          <div class="text-body-2 font-weight-bold mt-3 ml-4">
                            {{ endLocation }}
                          </div>

                          <div class="font-weight-medium custom_page_main_sub_title_very_small_red text-body-2 ml-4 mt-2">
                            {{ lastOutletAddress}}
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex ml-4">
                            <div class="custom_page_main_sub_title_very_small_red text-body-2 font-weight-medium mt-1"> {{ tracingEndTime }}</div>
                            <v-divider vertical class=" mr-2 ml-2"></v-divider>
                            <div><v-chip class="custom_red_chip " size="small" variant="outlined">End Outlet</v-chip></div>
                          </div>
                        </template>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!--  collection recode edit view component call to dialog box-->

      <!-- Delete User confirmation dialog -->
      <v-dialog v-model="deleteDialog" id="app_route_plan_tracking_history" max-height="500" max-width="500" persistent>
        <v-card  rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">DELETE TRACKING DATA</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="deleteDialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #BB0000" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Do you want to delete this tracking record ?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This route plan tracking record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn  class="custom_btn_primary" @click="deleteItem">DELETE</v-btn>
                  <v-btn  class="ml-3 custom_btn_secondary" @click="deleteDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Delete User confirmation dialog -->
      <v-snackbar v-model="snackAlert.snackbar" :color="snackAlert.snackColor" :right="snackAlert.snackBarRight"
                  :top="snackAlert.snackBarTop" timeout="3000">
        {{ snackAlert.snackMessage }}
      </v-snackbar>

    </v-container>
  </v-app>
</template>

<script>
  import index from "vuex";
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  import html2canvas from "html2canvas";
  import {getPermissions} from "@/plugins/permissions";
  export default {
    components: {
      // Map, MapPolyline, MapMarker
    },
    data() {
      return {

        snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
        },

        loading:false,
        loadningScreen:false,
        agentName:"",
        totalDistance:"",
        startTime:"",
        routeName:'',
        assignDateRoute:'',
        routeCatagary:'',
        visitedDate:'',
        baseUrl:"",
        endTime:"",
        trackingStartTime:"",
        tracingEndTime:"",
        startLocation:"",
        endLocation:"",
        showDownloadButton:true,
        lastOutletAddress:"",
        fristOutletAddress:"",
        categories: ['sales', 'Collection'],
        selectedCategory:"",
        dates: [],
        serachModel:"",
        firstOutlet:"",
        secondOutlet:"",
        search: ' ',
        deleteDialog: false,
        itemToDelete: null,
        deletedDate:"",
        deletedType:"",
        routeId:"",
        mapViewDialog: false,
        headers: [
          {text: 'AGENT NAME', value: 'user_deateails.name', title: 'AGENT NAME', sortable: true},
          {text: 'Route NAME', value: 'route_Deateails.route_name', title: 'ROUTE NAME', sortable: true},
          {text: 'CATEGORY', value: 'type', title: 'CATEGORY', sortable: true},
          {text: 'START OUTLET', value: 'firstOutlet', title: 'START OUTLET', sortable: true},
          {text: 'END OUTLET', value: 'lastOutlet', title: 'END OUTLET', sortable: true},
          {text: 'DATE', value: 'visiteDateCheck', title: 'DATE', sortable: true},
          {text: 'Action', value: 'actions', sortable: false, title: 'ACTION' }
        ],
       trackingData:[],
       trackingHistary:[],
       OfficerDetails:"",
       center: { lat: 7.375783804167988, lng: 80.63752981355924 },
        pathCoordinates: [
          { lat: "", lng: "" },
        ],
        pathCoordinatesOutlet: [
          { lat: "", lng: "" },
        ],
        polylineOptions: {
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 3,
        },
        startPoint:"",
        endPoint:"",
        startIcon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
        },
        endIcon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
        },
        outletIcon: {
          url: require('@/img/icons/maker.png'),
          scaledSize: {
            width: 35,
            height: 40,
          },
        },
        outLetIcon:{
          url: require('@/img/icons/maker.png'),
          scaledSize: {
            width: 35,
            height: 40,
          },
        },
       agents: [
          {
            location: 'Mawaramandiya',
            lastUpdated: '30 seconds ago',
            locAddress: 'bla blaa blaaaaa',
            locationType: 'startLocation',
            trips: [
              { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
              { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
            ],
          },
        ],
        mapCenter: { lat: 6.023129913557325, lng: 80.79783798218944 },
        permissions: {},
      };
    },

    computed: {
      index() {
        return index
      },

      filteredData() {
        let filtered = this.trackingData;
        // Filter by category
        if (this.selectedCategory) {
          filtered = filtered.filter(item =>
            item.type.toLowerCase().includes(this.selectedCategory.toLowerCase())
          );
        }

       // Filter by search model (agent name or type)
       if (this.serachModel) {
          filtered = filtered.filter(item =>
            item.user_deateails.name.toLowerCase().includes(this.serachModel.toLowerCase()) ||
            item.type.toLowerCase().includes(this.serachModel.toLowerCase()) ||
            item.route_Deateails.route_name.toLowerCase().includes(this.serachModel.toLowerCase())
          );
        }

       // Filter by date range if `dates` contains two dates
       const formatDate = (date) => {
          const d = new Date(date);
          return d.toISOString().split('T')[0];
        };

        // Get start and end dates from the date picker
        const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

        // Apply date range filter only if startDate and endDate are set
        if (startDate && endDate) {
          filtered = filtered.filter(item => {
            return item.visiteDate >= startDate && item.visiteDate <= endDate;
          });
        }
        return filtered;
      }
    },

    mounted(){
      const dashboardId = 7;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);

      this.getAllTracking();
      this.getBaseUrl();
    },

    methods: {

    backPageRoute(){
      this.$router.push('/dashboard');
    },

    getBaseUrl(){
      this.baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
    },

    downloadPdf() {
      this.showDownloadButton = false;
      
      this.$nextTick(() => {
        const mapElement = this.$refs.mapElement?.$el || this.$refs.mapElement;

        if (mapElement instanceof HTMLElement) {
          html2canvas(mapElement, {
            useCORS: true, // Use CORS to allow cross-origin tiles to be captured
            logging: true,
            scale: 2 // Increase the scale to get better resolution
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape', // You can adjust this based on your map size
              unit: 'pt',
              format: [canvas.width, canvas.height] // Use the exact size of the canvas for PDF format
            });

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

            // Save the PDF
            pdf.save('routePlanTracking.pdf');
          }).catch((error) => {
            console.error('Error capturing the map:', error);
          }).finally(() => {
              // Show the button again after generating the PDF
              this.showDownloadButton = true;
            });
        } else {
          console.error('Map element is not attached or is not a valid node.');
          this.showDownloadButton = true;
        }
      });
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Tracking report', 14, 10);

      // Prepare the data for the table
      const tableData = this.filteredData.map((item, index) => [
        index + 1,
        item.user_deateails.name,
        item.firstOutlet,
        item.lastOutlet,
        item.type,
        item.visiteDate,
      ]);

      console.log('pdfdata111', tableData);

      // Define the columns for the table
      const tableColumns = ['#', 'AGENT NAME', 'START LOCATION', 'END LOCATION', 'TRACKING TYPE', 'VISITED DATE'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('RoutePlan tracking Histary.pdf');
    },

    clearDateRange() {
      this.dates = [];
    },

    exportExcel() {
      const tableData = this.filteredData.map((item) => ({
        // '#': index + 1,
        'AGENT NAME': item.user_deateails.name,
        'START LOCATION': item.firstOutlet,
        'END LOCATION': item.lastOutlet,
        'TRACKING TYPE': item.type,
        'VISITED DATE': item.visiteDate
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tracking');
      XLSX.writeFile(workbook, 'Tracking.xlsx');
    },


    // Get All Tracing Historay
    getAllTracking() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      this.loading = true;

      axios.post(`api/getTrackingDetials`, {
        userId: userId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.trackingData = response.data.data;
          console.log('fkfkfkTracking112222',this.trackingData);

          // console.log('ASSIGN DATE', this.trackingData[0].route_Deateails?.assign_date);
          this.loading = false;
      })
      .catch(error => {
          console.error('Error fetching Agents:', error);
          this.loading = false;
      });
    },

    getOutletDeteails(firstId,lastId){
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`api/getOutletDetails`, {
        userId: userId,
        firstId:firstId,
        lastId:lastId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.firstOutlet = response.data.first;
          this.secondOutlet = response.data.first;
      })
      .catch(error => {
          console.error('Error fetching Agents:', error);
      });
    },

    mapView(item) {
    console.log('item', item);
    this.loadningScreen = true;
    let agentId = item.agent_id;
    let routeId = item.route_id;
    let type = item.type;
    this.agentName = item.user_deateails.name;
    this.startTime = item.trackingStartTime
    this.routeName = item.route_Deateails.route_name;
    this.assignDateRoute = item.route_Deateails.assign_date;
    this.visitedDate = item.visiteDate;
    this.routeCatagary = item.type;
    this.endTime = item.tracingEndTime;
    this.startLocation = item.firstOutlet;
    this.endLocation = item.lastOutlet;
    this.fristOutletAddress = item.fristOutletAddress;
    this.lastOutletAddress = item.lastOutletAddress;
    this.trackingStartTime = item.trackingStartTime;
    this.tracingEndTime = item.tracingEndTime;

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    axios.post(`api/getTrackingHistary`, {
      userId: userId,agentId:agentId,routeId:routeId,type:type
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.trackingHistary = response.data.data;
        console.log('tracking data',this.trackingHistary);
        this.OfficerDetails= response.data.userDetails;
        console.log('userdetails',this.OfficerDetails);
        this.mapViewDialog = true;
        this.loadningScreen = false;

        if (this.trackingHistary.length > 0) {

        this.pathCoordinates = this.trackingHistary.map(point => ({
          lat: point.latitude,
          lng: point.longitude,
        }))

        this.totalDistance = this.calculateTotalDistance(this.pathCoordinates);
        // Update the map center to the first point in the history
        this.mapCenter = {
          lat: this.pathCoordinates[0].latitude,
          lng: this.pathCoordinates[0].longitude
        };

        this.startPoint = this.pathCoordinates[0];
        this.endPoint = this.pathCoordinates[this.pathCoordinates.length - 1];

        // -------------------------------------------------------------------
          let outletIds = [];
          let outlets = item.FullDetails.filter(item => item.outlet_id !== null);
          console.log('outeltData',outlets);

          this.pathCoordinatesOutlet = outlets.map(point => ({
          lat: point.latitude,
          lng: point.longitude,
          }))
          console.log('outeltData1111',this.pathCoordinatesOutlet);
            outlets.forEach(outlet => {
             outletIds.push(outlet.outlet_id);
            });

            const firstId = outletIds[0];
            const lastId = outletIds[outletIds.length - 1];
            this.getOutletDeteails(firstId,lastId)
            outletIds = []
        } else {
          console.warn('No tracking data available');
        }
      })
      .catch(error => {
          console.error('Error fetching Agents:', error);
      });
    },

    // Function to calculate total distance between the path coordinates using Haversine formula
    calculateTotalDistance(pathCoordinates) {
    // Function to convert degrees to radians
    const toRadians = (deg) => deg * (Math.PI / 180);

    // Haversine formula
    const haversineDistance = (lat1, lng1, lat2, lng2) => {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRadians(lat2 - lat1);
      const dLng = toRadians(lng2 - lng1);

      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    };

    let totalDistance = 0;

    // Loop through pathCoordinates and sum the distance between consecutive points
    for (let i = 0; i < pathCoordinates.length - 1; i++) {
      const point1 = pathCoordinates[i];
      const point2 = pathCoordinates[i + 1];

      totalDistance += haversineDistance(point1.lat, point1.lng, point2.lat, point2.lng);
      }
      return totalDistance;
    },

    closeD(){
      this.mapViewDialog = false;
    },

    confirmDelete(item) {
      this.itemToDelete = item.agent_id;
      this.routeId = item.route_id;
      this.deletedType = item.type;
      this.deletedDate = item.visiteDate;

      this.deleteDialog = true;
    },

    showSnackAlert(type, message) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = type;
        this.snackAlert.snackMessage = message;
      },


    deleteItem() {
      this.loadningScreen =true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      console.log('fk')


      axios.post(`api/deletRoutePlanTrackingDetails`, {
        userId: userId,trackingId:this.itemToDelete,deletedType:this.deletedType,deletedDate:this.deletedDate,routeId:this.routeId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        .then(response => {
          this.deleteDialog = false;
          console.log("deleted",response.data);
          this.showSnackAlert('success','delete successfully!');
          this.getAllTracking();
          this.loadningScreen = false;
        })
      .catch(error => {
        console.error('Error fetching Agents:', error);
        this.loadningScreen = false;
      });
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
      },
    },
  }
</script>


<style>
  @import "../../styles/tracking_management/route_plan_tracking_history.scss";
</style>