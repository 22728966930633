<template>

  <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
    <template v-slot:prepend>
      <v-icon>mdi-view-dashboard</v-icon>
      <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE OUTLETS DETAILS</v-card-title>
    </template>
  </v-card>

  <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
    <v-row class="ma-0">
      <v-col cols="12" xs="12" sm="9" md="9" lg="9">
        <v-row>
          <v-col cols="12" xs="12" sm="3" md="2" lg="2">
            <v-img :src="require('@/img/icons/shop.png')" class="user_image_view"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="9" md="10" lg="10">
            <div class="d-flex">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Outlet Name: &nbsp;</p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">
                {{ outletName }}</p>
            </div>

            <div class="d-flex mt-1">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
              <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">{{ mobileNumber }}</p>
            </div>

            <div v-if="note" class="d-flex mt-2">
              <p class="font-weight-bold custom_page_component_main_title text-body-1">
                Status Change Reason: &nbsp;
              </p>
              <p class="font-weight-bold custom_page_component_main_title" style="color: #BB0000 !important;">
                {{ note }}
              </p>
            </div>

            <div class="d-flex mt-6">
              <v-chip v-if="outletStates === 'ALLOWED'" class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                <v-icon>mdi-check-circle-outline</v-icon>&nbsp;
                OUTLET STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ outletStates }}</span>
              </v-chip>

              <v-chip v-if="outletStates  === 'NOT ALLOWED'" class="text-body-1 mr-3 custom_red_chip"
                      variant="outlined">
                <v-icon>mdi-check-circle-outline</v-icon>&nbsp;
                OUTLET STATUS - &nbsp;<span style="text-transform: uppercase !important;">{{ outletStates }}</span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="3" md="3" lg="3">
        <v-row class="text-right mt-3 mr-3">
          <v-spacer></v-spacer>
          <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="createRoute">UPDATE OUTLET DATA
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-card-text>
      <v-form ref="form">
        <v-row class="mt-2">
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="outletName" :rules="[v => !!v || 'Outlet Name is required']" density="comfortable"
                          class="custom_input_field_color"
                          label="Outlet Name *" placeholder="Type outlet name" variant="outlined"
            />
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="mobileNumber" :rules="[v => !!v || 'Mobile Number is required', v => /^[0-9]*$/.test(v) || 'Mobile Number must be numeric', v => v.length === 10 || 'Mobile Number must be 10 digits long']"
                          label="Mobile Number *" maxlength="10" placeholder="Type mobile number" variant="outlined"
                          density="comfortable"
                          class="custom_input_field_color"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field v-model="ownerName" :rules="[v => !!v || 'Owner Name is required']"
                          label="Owner Name *" placeholder="Type owner name" variant="outlined" density="comfortable"
                          class="custom_input_field_color"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-autocomplete v-model="outletType" :items="outletTypes" :rules="[v => !!v || 'Select Outlet is required']"
                            item-title="outlet_name" item-value="id"
                            label="Select Outlet" variant="outlined" @click="fetchOutletType"
                            class="custom_input_field_color" density="comfortable"
            ></v-autocomplete>
          </v-col>

          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <div class="px-3">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="10" lg="10">
                    <v-autocomplete v-model="outletStates" :items="outletStatess"
                                    :rules="[v => !!v || 'Outlet Status is required']" item-title="outlet_status"
                                    item-value="outlet_status" label="Outlet Status"
                                    variant="outlined" class="custom_input_field_color" density="comfortable"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="2" lg="2">
                    <v-btn class="float-end custom_btn_secondary" flat height="48" size="large"
                           @click="reasoncard = !reasoncard">
                      Reason&nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-textarea v-if="reasoncard" v-model="reason" :rules="[v => !!v || 'Reason is required']"
                                label="Reason" variant="outlined" class="custom_input_field_color" density="comfortable"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>


          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="7" lg="7">
                <p class="custom_page_main_sub_title">
                  <strong>SELECTED OUTLET LOCATION</strong>
                </p>
                <p class="custom_page_main_sub_title mt-2 " style="opacity: 0.6;line-height: 26px">
                  This reflects the location you provided for the outlet when you created it.
                </p>
                <v-divider class="mb-4 mt-4"></v-divider>
                <Map ref="map" :center="mapCenter" :zoom="13"
                     class="d-flex custom_outlet_selection_map mt-4 custom_card_box" @click="mapClicked">
                  <Marker v-if="markerPosition" :icon="customIcon" :position="markerPosition"/>
                </Map>
                <v-btn class="position-absolute ml-2 mb-15 custom_lat_lon_label_edit_View">
                  lat: {{ latitude }} | lng: {{ longitude }}
                </v-btn>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-card border="md opacity-1">
                      <v-card-text class="ma-4 mb-0">
                        <v-text-field append-inner-icon="mdi-magnify"
                                      label="Outlet Address" placeholder="Type outlet address" variant="outlined"
                                      density="comfortable" class="custom_input_field_color"
                        />

                        <v-text-field style="display: none !important;" v-model="latitude"
                                      :rules="[v => !!v || 'Latitude is required']" label="Latitude"
                                      variant="outlined"></v-text-field>
                        <v-text-field style="display: none !important;" v-model="longitude"
                                      :rules="[v => !!v || 'Longitude is required']" label="Longitude"
                                      variant="outlined"></v-text-field>
                      </v-card-text>
                    </v-card>
                    <v-divider class="mt-6"></v-divider>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="outletAddress" :rules="[v => !!v || 'Outlet Address is required']"
                                  ref="outletAddressField" label="Street Address *" placeholder="Type street address"
                                  variant="outlined" density="comfortable"
                                  class="custom_input_field_color"></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-autocomplete v-model="province" :items="provinces" :loading="mDistrictLoading"
                                    :rules="[v => !!v || 'Province is required']" label="Province"
                                    placeholder="Select province" variant="outlined" :filterable="false"
                                    @update:modelValue="getAllDistrictAccProv" density="comfortable"
                                    class="custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-autocomplete v-model="district" :items="districts" :loading="mDistrictLoading"
                                    :rules="[v => !!v || 'District is required']" label="District" :filterable="false"
                                    placeholder="Select district" variant="outlined" @update:modelValue="getAllDsd"
                                    density="comfortable" class="custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-autocomplete v-model="dsd" :items="dsdList" :loading="mDSDLoading"
                                    :rules="[v => !!v || 'DSD is required']" label="DSD" :filterable="false"
                                    placeholder="Select DSD" variant="outlined" @update:modelValue="getAllGnd"
                                    density="comfortable" class="custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-autocomplete v-model="gnd" :items="gndList" :rules="[v => !!v || 'GND is required']" label="GND"
                                    placeholder="Select GND" variant="outlined" density="comfortable" :filterable="false"
                                    class="custom_input_field_color"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top-right" outlined>
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading">
      <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
import {Map, Marker} from '@fawmi/vue-google-maps';
import axios from "axios";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "RoutePlan",
  components: {Marker, Map},
  data() {
    return {
      snackbarMessage: '',
      snackbarColor: '',
      loading: false,
      centerLoading: false,
      snackbar: false,
      alignment: 1,
      formatting: [],
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      reasoncard: false,
      mapCenter: {},
      outletTypeId: '',
      reason: '',
      fetchData: '',
      outletStates: '',
      ownerName: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      outletType: '',
      note: '',
      latitude: '',
      longitude: '',
      statesDefault: '',
      outletTypes: [],
      outletStatess: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletProducts: [],
      markerPosition: null,
      customIcon: {
        url: require('@/img/icons/maker.png'), // Path to your custom marker icon
        scaledSize: {width: 35, height: 40} // Adjust the size if needed
      }
    };
  },

  methods: {
    cancell() {
      //  functionality to edit item details
      EventBus.emit('outletManagementButtonClick2');
    },

    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitude = this.markerPosition.lat;
      this.longitude = this.markerPosition.lng;
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },

    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;
      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data) {

              this.outletProducts = Array.isArray(response.data) ? response.data : [response.data];
              console.log("this.outletProducts:", this.outletProducts);

              if (this.outletProducts.length > 0) {
                const route = this.outletProducts[0];
                this.ownerName = route.owner_name;
                this.latitude = route?.latitude;
                this.longitude = route?.longitude;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.outletStates = route?.outlet_status;
                this.statesDefault = route?.outlet_status;
                this.outletName = route?.outlet_name;
                this.outletType = route?.outlet_type.outlet_name;
                this.outletAddress = route?.outlet_address;
                this.mobileNumber = route.mobile_number;
                this.outletTypeId = route.outlet_type_id;
                this.note = route?.status_reasons[0]?.reason;
                console.log("sahan", this.mobileNumber);

                this.markerPosition = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};
                this.mapCenter = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};

              }
            } else {
              this.outletProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.outletProducts = [];
          });
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);
          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      const header = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async createRoute() {
      // Check if outletStates is updated and a reason is not provided
      if (this.outletStates !== this.statesDefault && !this.reason) {
        this.snackbarMessage = 'Please provide a reason for changing the outlet status.';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return; // Stop execution
      }

      this.centerLoading = true;

      // Check if the selected outlet type exists; if not, set it to the outletTypeId
      if (!this.outletTypes.some((outlet) => outlet.id === this.outletType)) {
        this.outletType = this.outletTypeId;
      }

      const id = this.sharedDataOutlet;
      console.log('Shared Data:', this.sharedDataOutlet);
      // console.log('fk', id);
      const userId = localStorage.getItem('userId');
      console.log('ssssssss:', this.outletName);
      const outletData = {
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        latitude: this.latitude,
        longitude: this.longitude,
        mobile_number: this.mobileNumber,
        outlet_type_id: this.outletType,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        owner_name: this.ownerName,
        outlet_status: this.outletStates,
        userId: userId,
        id: id,
      };

      try {
        const token = localStorage.getItem('token');
        console.log("outletData is ", outletData);

        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`,
            outletData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        );

        if (response.status === 200) {
          // If outletStates is updated, update the outlet status
          if (this.outletStates !== this.statesDefault) {
            const updateData = {
              reason: this.reason,
              status: this.outletStates,
              userId: userId,
            };

            const updateResponse = await axios.post(
                `${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`,
                updateData,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                }
            );

            console.log(response);
            console.log(updateResponse);
          }

          // Show success message using the Snackbar
          this.snackbarMessage = 'Outlet updated successfully!';
          this.snackbarColor = 'success';
          this.snackbar = true;

          // Set a timeout to refresh the page after showing the snackbar
          setTimeout(() => {
            window.location.reload();
          }, 2000); // 3000 milliseconds = 3 seconds

        } else {
          this.snackbarMessage = 'Error updating outlet!';
          this.snackbarColor = 'error';
          this.snackbar = true;
        }

      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.snackbarMessage = 'Outlet address already exists!';
          this.snackbar = true;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response?.data?.message || 'Something went wrong!',
          });
        }
      } finally {
        this.centerLoading = false;

        // Emit event and close the dialog after 3 seconds
        setTimeout(() => {
          EventBus.emit('outletManagementButtonClick2');
          this.editDialog = false;
        }, 3000);
      }
    },
  },

  computed: {
    ...mapGetters(['sharedDataOutlet']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },

  created() {
    this.fetchOutletRecodeData();
  },

  mounted() {
    this.fetchOutletRecodeData();
    this.getAllProvinces();
  },

  watch: {
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }
};
</script>


<style>
@import "../../styles/outlet_management/outlet_list.scss";
</style>